/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useRef } from "react";
import { walletConnect } from "../../contexts/WalletConnect";
import { formatAmount } from "../Function/commonFunction";

function LiquidityTxSubmittedModel({ spinner, setSpinner }) {
  const { selectedChain } = useContext(walletConnect);
  const modalRef = useRef(null);
  const closeSwapModel = () => {
    setSpinner((prev) => ({
      ...prev,
      addLiquiSpinner: false,
      addLiquiTxSubModal: false,
      addLiquiTxHash: "",
    }));
  };
  return spinner?.addLiquiTxSubModal ? (
    <>
      <div className="row justify-content-center">
        <div ref={modalRef} className="col-10 col-sm-3 col-md-4 col-lg-4 col-xl-2 rounded-15 modal-overlay_swapToken bg-white-new">
          <div className="modal-container_swapToken bg-white-new" onClick={(e) => e.stopPropagation()}>
            <div className="d-flex justify-content-end">
              <i className="bi bi-x-lg pointer dark-text" onClick={closeSwapModel}></i>
            </div>
            <div className="mt-3 d-flex justify-content-center align-items-center">
              <i className="bi bi-arrow-up-circle display1-size dark-text"></i>
            </div>
            <div className=" d-flex justify-content-center align-items-center flex-column">
              <h5 className="mt-3 d-flex justify-content-center font-md dark-text">
                <span>Transaction receipt</span>
              </h5>
              <a
                className="mt-4 btn rounded-15 d-flex justify-content-center align-items-center font-xs text-white pointer form-select w-75 p-2 b-none"
                href={`${selectedChain?.explorerUrl}/tx/${spinner?.addLiquiTxHash}`}
                target="_blank"
              >
                <span>View </span>
                <span className="ms-1">{spinner?.addLiquiTxHash?.substr(0, 8) + "..."}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={spinner?.addLiquiTxSubModal !== 0 ? "modal-backdrop-swaptoken fade show" : "d-none"}></div>
    </>
  ) : (
    ""
  );
}

export default LiquidityTxSubmittedModel;
