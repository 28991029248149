import React from "react";
import { Link } from "react-router-dom";
import { walletConnect } from "../../../../contexts/WalletConnect";
import { formatAmount } from "../../../../Components/Function/commonFunction";

function ImportV2PoolButton({
  selectedTokenOne,
  selectedTokenTwo,
  buttonStatus,
  pair,
}) {
  return (
    <>
      {buttonStatus === 0 ? (
        <button className="btn header-btn  rajdhani-600 rounded-15 p-4 font-sm dark-text bg-light-gray w-100 mt-4">
          <span>Connect to a wallet to find pools</span>
        </button>
      ) : buttonStatus === 1 ? (
        <button className="btn header-btn  rajdhani-600 rounded-15 p-4 font-sm dark-text bg-light-gray w-100 mt-4">
          <span>Select a token to find your liquidity.</span>
        </button>
      ) : buttonStatus === 2 ? (
        <button className="btn header-btn  rajdhani-600 rounded-15 p-4 font-sm dark-text bg-light-gray w-100 mt-4">
          <div className="d-grid">
            <span className="dark-text font-xs">No pair found.</span>
            <div className="mt-2">
              <Link
                className="btn rajdhani-600 rounded-10 font-xs text-blue border-blue p-2 px-sm-2"
                to="/addLiquidity-v2"
              >
                <span>Create Pair</span>
              </Link>
            </div>
          </div>
        </button>
      ) : buttonStatus === 3 ? (
        <div className="d-grid">
          <div
            className="card-body mt-2 dark-border border border-light border-2 rounded-15 p-2 px-3 dashboard-bg bg-info"
            style={{
              height: 165,
              transition: "all 0.3s ease-in 0s",
              overflow: "hidden",
            }}
          >
            <div className="d-flex mt-1 mb-0 p-0 justify-content-start align-items-center  ">
              <div className="text-gray-900 font-sm rajdhani-400  mb-0 text-blue ">
                LP tokens in yours wallet
              </div>
            </div>
            <div className="d-flex mb-1 justify-content-between align-items-top mt-2 ">
              <div className="text-gray-700 rajdhani-400 mb-0 ">
                <div className="d-flex align-items-center">
                  {selectedTokenOne?.icon ? (
                    <img
                      src={selectedTokenOne?.icon}
                      alt="user"
                      className="h-20 w-20 me-2 rounded-circle"
                    />
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl"
                      color="text"
                      width="20px"
                      height="20px"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "8px" }}
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                    </svg>
                  )}
                  {selectedTokenTwo?.icon ? (
                    <img
                      src={selectedTokenTwo?.icon}
                      alt="user"
                      className="h-20 w-20 me-2 rounded-circle"
                    />
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl"
                      color="text"
                      width="20px"
                      height="20px"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "8px" }}
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                    </svg>
                  )}
                  <div className="text-gray-700 rajdhani-400 mb-0 dark-text">
                    <p className="rajdhani-400 mb-0 opacity-75">
                      {selectedTokenTwo?.symbol}-{selectedTokenOne?.symbol} LP
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
                <div>
                  <p className="rajdhani-400 dark-text mb-0">
                    <span>{formatAmount(pair?.lptokens)}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex mb-1 justify-content-between align-items-top mt-1 ">
              <div className="text-gray-700 rajdhani-400 mb-0 dark-text">
                <p className="rajdhani-400 mb-0 opacity-75">
                  Share in Trading pair
                </p>
              </div>
              <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
                <div>
                  <p className="rajdhani-400 dark-text mb-0">
                    <span>{formatAmount(pair?.share)}%</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex mb-1 justify-content-between align-items-top mt-1 ">
              <div className="text-gray-700 rajdhani-400 mb-0 dark-text">
                <p className="rajdhani-400 mb-0 opacity-75">
                  Pooled {selectedTokenTwo?.symbol}
                </p>
              </div>
              <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
                <div>
                  <p className="rajdhani-400 dark-text mb-0">
                    <span>{formatAmount(pair?.token1Amount)}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex mb-1 justify-content-between align-items-top mt-1 ">
              <div className="text-gray-700 rajdhani-400 mb-0 dark-text">
                <p className="rajdhani-400 mb-0 opacity-75">
                  Pooled {selectedTokenOne?.symbol}
                </p>
              </div>
              <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
                <div>
                  <p className="rajdhani-400 dark-text mb-0">
                    <span>{formatAmount(pair?.token0Amount)}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <Link
              className="btn rajdhani-600 w-100 rounded-10 font-xs text-blue border-blue p-2 px-sm-2"
              to={{
                pathname: "/v2liquidityInfo",
                search: `inputAddress=${selectedTokenOne?.address}&outputAddress=${selectedTokenTwo?.address}`,
              }}
            >
              <span>Manage this pair</span>
            </Link>
          </div>
        </div>
      ) : buttonStatus === 4 ? (
        <button className="btn header-btn  rajdhani-600 rounded-15 p-4 font-sm dark-text bg-light-gray w-100 mt-4">
          <div className="d-grid">
            <span className="dark-text font-xs">
              You don't have liquidity in this pair yet.
            </span>
            <div className="mt-2">
              <Link
                className="btn rajdhani-600 rounded-10 font-xs text-blue border-blue p-2 px-sm-2"
                to="/addLiquidity-v2"
              >
                <span>Add Liquidity</span>
              </Link>
            </div>
          </div>
        </button>
      ) : (
        ""
      )}
    </>
  );
}

export default ImportV2PoolButton;
