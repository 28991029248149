/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import TokenModel from "../../Components/Coman/TokenModel";
import AddTokenModel from "../../Components/Coman/AddTokenModel";
import SwapSettingsModel from "../../Components/Coman/SwapSettingsModel";
import SwapBox from "./SwapBox";
import { globalStates } from "../../contexts/GlobalStates";
import { walletConnect } from "../../contexts/WalletConnect";
import { getTokenAllowanceForSwap, getTokenBalance, getTokenDecimals } from "../../Components/Function/ethersFunctions";
import SwapRouterPrice from "./SwapRouterPrice";
import { calculateAmountIn, calculateAmountOut } from "../../Components/Function/swapFunctions";
import SwitchChainModal from "../../Components/Coman/SwitchChainModal";

export default function SwapBoxContainer({ setSwapBoxModalChange, swapBoxModalChange }) {
  const { provider, address, selectedRouter, selectedChain, routerId, setRouterId } = useContext(walletConnect);
  const { chainTokens, handleTokenSelectSearchParams } = useContext(globalStates);
  const { inputToken, outputToken } = useContext(walletConnect);
  const [routerWithFilledValue, setRouterWithFilledValue] = useState([]);
  const [priceSpinner, setPriceSpinner] = useState(false);

  const [inputWay, setInputWay] = useState(0);

  const [selectedTokenOne, setSelectedTokenOne] = useState(null);
  const [selectedTokenTwo, setSelectedTokenTwo] = useState(null);

  const [inputAmountOne, setInputAmountOne] = useState("");
  const [inputAmountTwo, setInputAmountTwo] = useState("");

  const handleSelecteTokneOne = (item) => {
    setRouterId(101);
    handleTokenSelectSearchParams(item.address?.toLowerCase(), "input");
    setSwapBoxModalChange("swap");
  };

  const handleSelecteTokneTwo = (item) => {
    setRouterId(101);
    handleTokenSelectSearchParams(item.address?.toLowerCase(), "output");
    setSwapBoxModalChange("swap");
  };

  const setTokenInfo = async () => {
    if (inputToken) {
      const findToken = chainTokens.find((item) => item?.address?.toLowerCase() === inputToken?.toLowerCase());
      if (findToken) {
        setSelectedTokenOne(findToken);
        if (provider) {
          const balance = await getTokenBalance(findToken?.address, address, provider, selectedRouter);
          const allowance = await getTokenAllowanceForSwap(findToken?.address, address, selectedRouter, provider);
          const decimals = await getTokenDecimals(findToken?.address, provider);
          setSelectedTokenOne((prev) => ({
            ...prev,
            balance,
            allowanceV2: allowance?.v2 ? allowance?.v2 : "0",
            allowanceV3: allowance?.v3 ? allowance?.v3 : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenOne(null);
      }
    } else {
      if (chainTokens[0] && outputToken?.toLowerCase() !== chainTokens[0]?.address?.toLowerCase()) {
        setSelectedTokenOne(chainTokens[0]);
        if (provider) {
          const balance = await getTokenBalance(chainTokens[0]?.address, address, provider, selectedRouter);
          const allowance = await getTokenAllowanceForSwap(chainTokens[0]?.address, address, selectedRouter, provider);
          const decimals = await getTokenDecimals(chainTokens[0]?.address, provider);
          setSelectedTokenOne((prev) => ({
            ...prev,
            balance,
            allowanceV2: allowance?.v2 ? allowance?.v2 : "0",
            allowanceV3: allowance?.v3 ? allowance?.v3 : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenOne(null);
      }
    }
    if (outputToken) {
      const findToken = chainTokens.find((item) => item?.address?.toLowerCase() === outputToken?.toLowerCase());
      if (findToken) {
        setSelectedTokenTwo(findToken);
        if (address && provider && findToken) {
          const balance = await getTokenBalance(findToken?.address, address, provider, selectedRouter);
          const allowance = await getTokenAllowanceForSwap(findToken?.address, address, selectedRouter, provider);
          const decimals = await getTokenDecimals(findToken?.address, provider);
          setSelectedTokenTwo((prev) => ({
            ...prev,
            balance,
            allowanceV2: allowance?.v2 ? allowance?.v2 : "0",
            allowanceV3: allowance?.v3 ? allowance?.v3 : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenTwo(null);
      }
    } else {
      setSelectedTokenTwo(null);
    }
  };
  useEffect(() => {
    if (chainTokens) {
      setTokenInfo();
    }
  }, [inputToken, outputToken, chainTokens, provider]);

  const fetchRouterWithFilledValue = async (inputWay, value) => {
    const result = [];
    let bestRouter = { id: "", amount: 0 };
    if (inputWay === 0 && (Number(inputAmountTwo) <= 0 || !inputAmountTwo || selectedRouter?.id !== 101)) {
      setPriceSpinner(true);
      for (let selRouter of selectedChain?.routers) {
        let amountOut = await calculateAmountOut(value, selectedTokenOne, selectedTokenTwo, selRouter, provider);
        if (amountOut?.poolAddress && Number(amountOut?.amountOut) > 0) {
          result.push({
            ...selRouter,
            pool: amountOut?.poolAddress,
            fee: amountOut?.fee,
            version: amountOut?.routerVersion,
            amount: amountOut?.amountOut,
          });
        }
        if (bestRouter?.amount < Number(amountOut?.amountOut)) {
          bestRouter = { id: selRouter?.id, amount: amountOut?.amountOut };
        }
      }
      setRouterWithFilledValue([...result]);
      setPriceSpinner(false);
      if (bestRouter?.id && selectedRouter?.id === 101) {
        setRouterId(bestRouter?.id);
      }
    } else if (inputWay === 1 && (Number(inputAmountOne) <= 0 || !inputAmountOne)) {
      setPriceSpinner(true);
      for (let selRouter of selectedChain?.routers) {
        let amountOut = await calculateAmountIn(value, selectedTokenOne, selectedTokenTwo, selRouter, provider);
        if (amountOut?.poolAddress && Number(amountOut?.amountOut) > 0) {
          result.push({
            ...selRouter,
            pool: amountOut?.poolAddress,
            fee: amountOut?.fee,
            version: amountOut?.routerVersion,
            amount: amountOut?.amountOut,
          });
        }
        if (bestRouter?.amount > Number(amountOut?.amountOut)) {
          bestRouter = { id: selRouter?.id, amount: amountOut?.amountOut };
        }
      }
      if (bestRouter?.id && selectedRouter?.id === 101) {
        setRouterId(bestRouter?.id);
      }
      setRouterWithFilledValue([...result]);
      setPriceSpinner(false);
    } else if (!inputAmountOne && !inputAmountTwo) {
      setRouterWithFilledValue([]);
    }
  };

  // useEffect(() => {
  //   if ((inputAmountOne || inputAmountTwo) && selectedTokenOne && selectedTokenTwo && selectedChain?.routers?.length) {
  //     fetchRouterWithFilledValue();
  //   } else {
  //     setPriceSpinner(false);
  //     setRouterWithFilledValue(null);
  //   }
  // }, [inputAmountOne, inputAmountTwo, selectedTokenOne, selectedTokenTwo, selectedChain]);

  return (
    <>
      <div className="">
        <div className="row justify-content-center mx-1" onClick={(e) => e.stopPropagation()}>
          {swapBoxModalChange === "addToken" ? (
            <AddTokenModel setSwapBoxModalChange={setSwapBoxModalChange} />
          ) : swapBoxModalChange === "setting" ? (
            <div className=" mt-3">
              <SwapSettingsModel setSwapBoxModalChange={setSwapBoxModalChange} swapBoxModalChange={swapBoxModalChange} />
            </div>
          ) : swapBoxModalChange === "selectTokenModalInput" ? (
            <div className=" mt-3">
              <TokenModel
                setSwapBoxModalChange={setSwapBoxModalChange}
                handleSelecteTokne={handleSelecteTokneOne}
                selectedTokenOne={selectedTokenOne}
                selectedTokenTwo={selectedTokenTwo}
              />
            </div>
          ) : swapBoxModalChange === "selectTokenModalOutput" ? (
            <div className=" mt-3">
              <TokenModel
                setSwapBoxModalChange={setSwapBoxModalChange}
                handleSelecteTokne={handleSelecteTokneTwo}
                selectedTokenOne={selectedTokenOne}
                selectedTokenTwo={selectedTokenTwo}
              />
            </div>
          ) : (
            <SwapBox
              setSwapBoxModalChange={setSwapBoxModalChange}
              selectedTokenOne={selectedTokenOne}
              selectedTokenTwo={selectedTokenTwo}
              inputAmountOne={inputAmountOne}
              inputAmountTwo={inputAmountTwo}
              setInputAmountOne={setInputAmountOne}
              setInputAmountTwo={setInputAmountTwo}
              setTokenInfo={setTokenInfo}
              inputWay={inputWay}
              setInputWay={setInputWay}
              fetchRouterWithFilledValue={fetchRouterWithFilledValue}
              setRouterWithFilledValue={setRouterWithFilledValue}
            />
          )}
        </div>
      </div>
      <SwapRouterPrice
        selectedTokenOne={selectedTokenOne}
        selectedTokenTwo={selectedTokenTwo}
        priceSpinner={priceSpinner}
        setPriceSpinner={setPriceSpinner}
        routerWithFilledValue={routerWithFilledValue}
        setRouterWithFilledValue={setRouterWithFilledValue}
        inputWay={inputWay}
      />
      <SwitchChainModal />
    </>
  );
}
