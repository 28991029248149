import React, { useContext, useEffect, useRef, useState } from "react";
import { globalStates } from "../../contexts/GlobalStates";
import { formatAmount } from "../Function/commonFunction";

function SwapDetailsModel({
  selectedTokenOne,
  selectedTokenTwo,
  inputAmountOne,
  inputAmountTwo,
  tradeInfo,
  swap,
  spinner,
  confirmSwapModal,
  setConfirmSwapModal,
}) {
  const { settingData } = useContext(globalStates);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!dropdownRef?.current?.contains(e.target)) {
        setConfirmSwapModal(false);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);
  return (
    <>
      {confirmSwapModal && (
        <>
          <div className="row justify-content-center">
            <div
              ref={dropdownRef}
              className="col-10 col-sm-3 col-md-4 col-lg-4 col-xl-2 rounded-15 p-3 modal-overlay_swapDetails bg-white-new m-0 in-model in-model-auto "
            >
              <div
                className="modal-container_swapDetails bg-white-new rounded-15 p-0 m-0"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="d-flex justify-content-between mt-1 mb-3">
                  <h5 className="d-flex justify-content-center font-xs dark-text">
                    <b>Confirm Swap</b>
                  </h5>
                  <i
                    className="bi bi-x-lg pointer dark-text"
                    onClick={() => setConfirmSwapModal(false)}
                  ></i>
                </div>
                <div className="mod-in-over  px-2">
                  <span className="d-flex justify-content-start font-xsss dark-text opacity-50">
                    Sell
                  </span>
                  <div className="mt-1 d-flex justify-content-between align-items-center">
                    <h5 className=" font-sm dark-text mb-0">
                      <span>
                        {inputAmountOne} {selectedTokenOne?.symbol}
                      </span>
                    </h5>
                    <h5 className=" font-xss dark-text mb-0 d-flex  align-items-center">
                      {selectedTokenOne?.icon ? (
                        <img
                          className=" h-30 rounded-circle mx-1"
                          src={selectedTokenOne?.icon}
                          alt=""
                        />
                      ) : (
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                          color="text"
                          width="30px"
                          height="30px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                        </svg>
                      )}
                    </h5>
                  </div>
                  <span className="mt-1 d-flex justify-content-start font-xsss dark-text opacity-50">
                    $0
                  </span>
                  <span className="mt-3 d-flex justify-content-start font-xsss dark-text opacity-50">
                    Buy
                  </span>
                  <div className="mt-1 d-flex justify-content-between align-items-center">
                    <h5 className=" font-sm dark-text mb-0">
                      <span>
                        {inputAmountTwo} {selectedTokenTwo?.symbol}{" "}
                      </span>
                    </h5>
                    <h5 className=" font-xss dark-text mb-0 d-flex  align-items-center">
                      {selectedTokenTwo?.icon ? (
                        <img
                          className=" h-30 rounded-circle mx-1"
                          src={selectedTokenTwo?.icon}
                          alt=""
                        />
                      ) : (
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                          color="text"
                          width="30px"
                          height="30px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                        </svg>
                      )}
                    </h5>
                  </div>
                  <span className="mt-1 d-flex justify-content-start font-xsss dark-text opacity-50">
                    $0
                  </span>
                  <div
                    className="card-body mt-2  px-0  pointer"
                    onClick={() => setShowConfirmModal(!showConfirmModal)}
                    style={
                      !showConfirmModal
                        ? {
                            height: "8rem",
                            transition: " 0.3s ease-in",
                            overflow: "hidden",
                          }
                        : {
                            height: "176px",
                            overflow: "hidden",
                            transition: " 0.3s ease-in",
                          }
                    }
                  >
                    <div className="d-flex  justify-content-between align-items-center ">
                      <span className="dark-text font-xss border-top wp-30 opacity-50"></span>
                      <span className="dark-text font-xss opacity-50">
                        Show Less
                        <span
                          className={`dark-text arrow2 ${
                            !showConfirmModal ? "down" : "up"
                          }`}
                        ></span>
                      </span>
                      <span className="dark-text font-xss border-top wp-30 opacity-50"></span>
                    </div>
                    <div className="d-flex  justify-content-between align-items-center mt-2 ">
                      <div className="text-gray-700 rajdhani-500 mb-0 ">
                        <p className="rajdhani-500 dark-text mb-1 opacity-50">
                          Rate
                        </p>
                      </div>
                      <div className="text-gray-900 d-flex justify-content-end rajdhani-600 mb-0 ">
                        <div>
                          <p className="rajdhani-500 dark-text mb-1 ">
                            1 {selectedTokenOne?.symbol} ={" "}
                            {formatAmount(
                              Number(inputAmountTwo) / Number(inputAmountOne)
                            )}
                            <span className="mx-1">
                              {selectedTokenTwo?.symbol}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex  justify-content-between align-items-center  ">
                      <div className="text-gray-700 rajdhani-500 mb-0 dark-text">
                        <p className="rajdhani-500 mb-1 opacity-50">
                          Slippage tolerance
                        </p>
                      </div>
                      <div className="text-gray-900 d-flex justify-content-end rajdhani-500 mb-0 ">
                        <div>
                          <p className="rajdhani-500 dark-text mb-1 ">
                            <span>{settingData?.slippage}%</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex  justify-content-between align-items-center  ">
                      <div className="text-gray-700 rajdhani-500 mb-0 dark-text">
                        <p className="rajdhani-500 mb-1 opacity-50">
                          Minimum receive
                        </p>
                      </div>
                      <div className="text-gray-900 d-flex justify-content-end rajdhani-500 mb-0 ">
                        <div>
                          <p className="rajdhani-500 dark-text mb-1 ">
                            <span>
                              {formatAmount(tradeInfo?.minAmountOut)}{" "}
                              {selectedTokenTwo?.symbol}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex  justify-content-between align-items-center  ">
                      <div className="text-gray-700 rajdhani-500 mb-0 dark-text">
                        <p className="rajdhani-500 mb-1 opacity-50">
                          Trading Fee
                        </p>
                      </div>
                      <div className="text-gray-900 d-flex justify-content-end rajdhani-500 mb-0 ">
                        <div>
                          <p className="rajdhani-500 dark-text mb-1 ">
                            <span>
                              {formatAmount(tradeInfo?.feeAmount)}{" "}
                              {selectedTokenOne?.symbol}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex  justify-content-between align-items-center  ">
                      <div className="text-gray-700 rajdhani-500 mb-0 dark-text">
                        <p className="rajdhani-500 mb-1 opacity-50">
                          Price Impact
                        </p>
                      </div>
                      <div className="text-gray-900 d-flex justify-content-end rajdhani-500 mb-0 ">
                        <div>
                          <p className="rajdhani-500 dark-text mb-1">
                            <span>
                              {tradeInfo?.priceImpact
                                ? formatAmount(tradeInfo?.priceImpact)
                                : 0}
                              %
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {spinner?.confirmSwapSpinner ? (
                    <button
                      className="btn header-btn rajdhani-600 rounded-10  px-4 font-sm  text-blue bg-gray-300 text-gray-900 btn-new-color w-100 mt-3"
                      disabled
                    >
                      <div className="nb-spinner-big"></div>
                    </button>
                  ) : (
                    <button
                      className="btn header-btn  rajdhani-700 rounded-10  px-4 font-sm  text-blue bg-wallet btn-new-color w-100 mt-3"
                      onClick={swap}
                    >
                      <span className="">Confirm Swap</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              confirmSwapModal ? "modal-backdrop-swaptoken fade show" : "d-none"
            }
          ></div>
        </>
      )}
    </>
  );
}

export default SwapDetailsModel;
