import ERC20 from "../../abis/ERC20.json";

import IUniswapV2Factory from "../../abis/IUniswapV2Factory.json";
import IUniswapV2Pair from "../../abis/IUniswapV2Pair.json";
import IUniswapV2Router from "../../abis/UniswapV2Router.json";

import IUniswapV3Pool from "../../abis/IUniswapV3Pool.json";
import IUniswapv3Factory from "../../abis/IUniswapv3Factory.json";
import IUniswapv3Router from "../../abis/IUniswapv3Router.json";

import QuoterV2 from "../../abis/QuoterV2.json";
import QuoterV1 from "../../abis/QuoterV1.json";

import { BigNumber, Contract, ethers } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { getReserveTokenInPair, getWethV2Address, getWethV3Address } from "./ethersFunctions";

// Function to get the pool address for a given input token, output token, fee, and router
export async function getPool(inputToken, outputToken, fee, selectedRouter, provider, type) {
  try {
    // Check if the type is V2
    if (type === "v2") {
      // Create an instance of the Uniswap V2 router contract
      const routerV2 = new Contract(selectedRouter?.routerV2, IUniswapV2Router.abi, provider);

      // Get the factory address and contract for V2
      const factoryAddressV2 = await routerV2.factory();
      const factoryV2 = new Contract(factoryAddressV2, IUniswapV2Factory.abi, provider);

      // Get the pool address for V2
      return await factoryV2.getPair(inputToken, outputToken);
    } else {
      // Create an instance of the Uniswap V3 router contract
      const routerV3 = new Contract(selectedRouter?.routerV3, IUniswapv3Router.abi, provider);

      // Get the factory address and contract for V3
      const factoryAddressV3 = await routerV3.factory();
      const factoryV3 = new Contract(factoryAddressV3, IUniswapv3Factory.abi, provider);

      // Get the pool address for V3
      return await factoryV3.getPool(inputToken, outputToken, fee);
    }
  } catch {
    // Return a default pool address if an error occurs
    return ethers.constants.AddressZero;
  }
}

// Function to calculate the amount out for a given input amount, input token, output token, and router
export async function calculateAmountOut(amountIn, inputToken, outputToken, selectedRouter, provider) {
  try {
    // Assign a signer to interact with the blockchain
    let zeroAdd = ethers.constants.AddressZero;
    const signer = await provider.getSigner();

    // Create an instance of the Quoter V2 contract to quote exact input single
    const quoterV2 = new Contract(selectedRouter?.quoterV2, QuoterV2.abi, signer);

    // Initialize variables to store the best amount out and pool address for V3
    let bestAmountOutV3 = null;
    let bestAmountOutV3Wei = null;
    let poolV3Address = null;
    let swapFee = null;
    let sqrtPriceX96After = null;

    // Iterate through different fees (500, 2500, 10000) to find the best amount out for V3
    for (const fee of selectedRouter?.feeV3) {
      try {
        // Get the pool address for the current fee
        const poolAddress = await getPool(
          inputToken?.address !== zeroAdd ? inputToken?.address : selectedRouter?.weth,
          outputToken?.address !== zeroAdd ? outputToken?.address : selectedRouter?.weth,
          fee,
          selectedRouter,
          provider,
          "v3"
        );
        if (poolAddress !== zeroAdd) {
          // Quote the exact input single for the current pool and fee
          const amount = await quoterV2.callStatic.quoteExactInputSingle([
            inputToken?.address !== zeroAdd ? inputToken?.address : selectedRouter?.weth,
            outputToken?.address !== zeroAdd ? outputToken?.address : selectedRouter?.weth,
            parseUnits(amountIn, inputToken?.decimals).toString(),
            fee,
            "0",
          ]);
          // Calculate the amount out in the output token's decimals
          const amountOut = formatUnits(amount.amountOut, outputToken?.decimals);

          // Update the best amount out and pool address if the current amount out is higher
          if (Number(bestAmountOutV3) < Number(amountOut)) {
            bestAmountOutV3 = amountOut;
            bestAmountOutV3Wei = amount.amountOut;
            poolV3Address = poolAddress;
            swapFee = fee;
            sqrtPriceX96After = amount.sqrtPriceX96After;
          } else if (bestAmountOutV3 === null) {
            bestAmountOutV3 = amountOut;
            bestAmountOutV3Wei = amount.amountOut;
            poolV3Address = poolAddress;
            swapFee = fee;
            sqrtPriceX96After = amount.sqrtPriceX96After;
          }
        }
      } catch {}
    }

    // Create instances of the Uniswap V2 router contracts
    const routerV2 = new Contract(selectedRouter?.routerV2, IUniswapV2Router.abi, provider);

    // Get the pool address for V2
    const poolV2Address = await getPool(
      inputToken?.address !== zeroAdd ? inputToken?.address : selectedRouter?.weth,
      outputToken?.address !== zeroAdd ? outputToken?.address : selectedRouter?.weth,
      null,
      selectedRouter,
      provider,
      "v2"
    );

    let bestAmountOutV2 = null;
    let bestAmountOutV2Wei = null;

    if (poolV2Address !== zeroAdd) {
      // Calculate the amount out for V2
      const amountv2Out = await routerV2.getAmountsOut(parseUnits(amountIn, inputToken?.decimals), [
        inputToken?.address !== zeroAdd ? inputToken?.address : selectedRouter?.weth,
        outputToken?.address !== zeroAdd ? outputToken?.address : selectedRouter?.weth,
      ]);
      bestAmountOutV2 = formatUnits(amountv2Out[1], outputToken?.decimals);
      bestAmountOutV2Wei = amountv2Out[1];
    }

    // Return the pool address and amount out for the better option between V2 and V3
    if (bestAmountOutV3 === null || (bestAmountOutV2 !== null && Number(bestAmountOutV2) > Number(bestAmountOutV3))) {
      return {
        poolAddress: poolV2Address,
        amountOut: bestAmountOutV2,
        amountOutWei: bestAmountOutV2Wei,
        routerVersion: "v2",
        fee: selectedRouter?.feeV2,
        router: selectedRouter?.routerV2,
      };
    } else {
      return {
        poolAddress: poolV3Address,
        amountOut: bestAmountOutV3,
        amountOutWei: bestAmountOutV3Wei,
        routerVersion: "v3",
        fee: swapFee,
        router: selectedRouter?.routerV3,
        sqrtPriceX96After,
      };
    }
  } catch (err) {
    // Log any errors that occur during the calculation
    console.log("error calculateAmountOut : ", err);
    return false;
  }
}

export function sqrtPrices(sqrt, decimal0, decimal1, token0IsInput = true) {
  const numerator = sqrt ** 2;
  const denominator = 2 ** 192;
  let ratio = numerator / denominator;
  const shiftDecimals = Math.pow(10, decimal0 - decimal1);
  ratio = ratio * shiftDecimals;
  if (token0IsInput) {
    ratio = 1 / ratio;
  }
  return ratio;
}

export async function getTradeInfo(amountInfo, inputToken, outputToken, inputAmountOne, selectedRouter, slippage, provider) {
  try {
    let zeroAdd = ethers.constants.AddressZero;
    if (amountInfo?.routerVersion === "v2") {
      const wethV2 = await getWethV2Address(selectedRouter, provider);
      const minAmountOut = amountInfo?.amountOutWei.sub(amountInfo?.amountOutWei.mul(Number(slippage * 10000)).div(100 * 10000));
      const reserve = await getReserveTokenInPair(
        inputToken?.address !== zeroAdd ? inputToken?.address : wethV2,
        outputToken?.address !== zeroAdd ? outputToken?.address : wethV2,
        amountInfo?.poolAddress,
        "v2",
        provider
      );
      const reserve0 = formatUnits(reserve[0], inputToken.decimals);
      const reserve1 = formatUnits(reserve[1], outputToken.decimals);

      return {
        feeAmount: ((Number(inputAmountOne) * Number(selectedRouter?.feeV2 / 10000)) / 100).toString(), // Calculate the fee amount
        minAmountOut: formatUnits(minAmountOut, outputToken?.decimals), // Format the min amount out
        priceImpact: (Number(amountInfo?.amountOut) / Number(reserve1)) * 100,
      };
    } else {
      const wethV3 = await getWethV3Address(selectedRouter, provider);
      const poolAddress = await getPool(
        inputToken?.address !== zeroAdd ? inputToken?.address : wethV3,
        outputToken?.address !== zeroAdd ? outputToken?.address : wethV3,
        amountInfo?.fee,
        selectedRouter,
        provider,
        "v3",
        true
      );

      const pool = new Contract(poolAddress, IUniswapV3Pool?.abi, provider);
      const slot0 = await pool.slot0();
      const sqrtPriceX96 = slot0.sqrtPriceX96;
      const token0 = await pool.token0();
      const token1 = await pool.token1();

      const token0IsInput = inputToken?.address?.toLowerCase() === token0?.toLowerCase();

      const price = sqrtPrices(sqrtPriceX96, inputToken?.decimals, outputToken?.decimals, token0IsInput);
      const priceAfter = sqrtPrices(amountInfo?.sqrtPriceX96After, inputToken?.decimals, outputToken?.decimals, token0IsInput);

      const absoluteChange = price - priceAfter;
      const percentageChange = (absoluteChange / priceAfter) * 100;

      const minAmountOut = amountInfo?.amountOutWei.sub(amountInfo?.amountOutWei.mul(Number(slippage * 10000)).div(100 * 10000)).toString();

      return {
        feeAmount: ((Number(inputAmountOne) * Number(amountInfo?.fee / 10000)) / 100).toString(), // Calculate the fee amount
        minAmountOut: formatUnits(minAmountOut, outputToken?.decimals), // Format the min amount out
        priceImpact: Math.abs(percentageChange),
      };
    }
  } catch (err) {
    console.log("error in getTradeInfo : ", err);
  }
}

export async function calculateAmountIn(amountIn, inputToken, outputToken, selectedRouter, provider) {
  try {
    // Assign a signer to interact with the blockchain
    let zeroAdd = ethers.constants.AddressZero;
    const signer = await provider.getSigner();

    // Create an instance of the Quoter V2 contract to quote exact input single
    const quoterV2 = new Contract(selectedRouter?.quoterV2, QuoterV2.abi, signer);

    const wethV2 = await getWethV2Address(selectedRouter, provider);
    const wethV3 = await getWethV3Address(selectedRouter, provider);

    // Initialize variables to store the best amount out and pool address for V3
    let bestAmountOutV3 = null;
    let bestAmountOutV3Wei = null;
    let poolV3Address = null;
    let swapFee = null;

    // Iterate through different fees (500, 2500, 10000) to find the best amount out for V3
    for (const fee of selectedRouter?.feeV3) {
      try {
        // Get the pool address for the current fee
        const poolAddress = await getPool(
          inputToken?.address !== zeroAdd ? inputToken?.address : wethV3,
          outputToken?.address !== zeroAdd ? outputToken?.address : wethV3,
          fee,
          selectedRouter,
          provider,
          "v3"
        );
        if (poolAddress !== zeroAdd) {
          // Quote the exact input single for the current pool and fee
          const amount = await quoterV2.callStatic.quoteExactOutputSingle([
            inputToken?.address !== zeroAdd ? inputToken?.address : wethV3,
            outputToken?.address !== zeroAdd ? outputToken?.address : wethV3,
            parseUnits(amountIn, inputToken?.decimals).toString(),
            fee,
            "0",
          ]);
          // console.log("v3", fee, " : ", amount.amountIn?.toString());
          // Calculate the amount out in the output token's decimals
          const amountOut = formatUnits(amount.amountIn, outputToken?.decimals);

          // Update the best amount out and pool address if the current amount out is higher
          if (bestAmountOutV3 !== null && Number(bestAmountOutV3) > Number(amountOut)) {
            bestAmountOutV3 = amountOut;
            bestAmountOutV3Wei = amount.amountOut;
            poolV3Address = poolAddress;
            swapFee = fee;
          } else if (bestAmountOutV3 === null) {
            bestAmountOutV3 = amountOut;
            bestAmountOutV3Wei = amount.amountOut;
            poolV3Address = poolAddress;
            swapFee = fee;
          }
        }
      } catch (err) {
        // console.log("v3", fee, " : ", "not found");
      }
    }
    // Create instances of the Uniswap V2 router contracts
    const routerV2 = new Contract(selectedRouter?.routerV2, IUniswapV2Router.abi, provider);

    // Get the pool address for V2
    const poolV2Address = await getPool(
      inputToken?.address !== zeroAdd ? inputToken?.address : wethV2,
      outputToken?.address !== zeroAdd ? outputToken?.address : wethV2,
      null,
      selectedRouter,
      provider,
      "v2"
    );

    let bestAmountOutV2 = null;
    let bestAmountOutV2Wei = null;

    if (poolV2Address !== zeroAdd) {
      // Calculate the amount out for V2
      try {
        const amountv2Out = await routerV2.getAmountsIn(parseUnits(amountIn, outputToken?.decimals), [
          inputToken?.address !== zeroAdd ? inputToken?.address : wethV2,
          outputToken?.address !== zeroAdd ? outputToken?.address : wethV2,
        ]);
        bestAmountOutV2 = formatUnits(amountv2Out[0], inputToken?.decimals);
        bestAmountOutV2Wei = amountv2Out[0];
      } catch {}
    }
    // Return the pool address and amount out for the better option between V2 and V3
    if (bestAmountOutV3 === null || (bestAmountOutV2 !== null && Number(bestAmountOutV2) < Number(bestAmountOutV3))) {
      return {
        poolAddress: poolV2Address,
        amountOut: bestAmountOutV2,
        amountOutWei: bestAmountOutV2Wei,
        routerVersion: "v2",
        fee: selectedRouter?.feeV2,
        router: selectedRouter?.routerV2,
      };
    } else {
      return {
        poolAddress: poolV3Address,
        amountOut: bestAmountOutV3,
        amountOutWei: bestAmountOutV3Wei,
        routerVersion: "v3",
        fee: swapFee,
        router: selectedRouter?.routerV3,
      };
    }
  } catch (err) {
    // Log any errors that occur during the calculation
    console.log("error calculateAmountIn : ", err);
    return false;
  }
}

export async function swapInV2(
  selectedTokenOne,
  selectedTokenTwo,
  account,
  inputAmountOne,
  inputAmountTwo,
  selectedRouter,
  slippage,
  txDeadline,
  tradeInfo,
  provider
) {
  try {
    const signer = await provider.getSigner();
    const wethV2 = await getWethV2Address(selectedRouter, provider);

    const router = new Contract(selectedRouter?.routerV2, IUniswapV2Router?.abi, signer);
    const amountIn = parseUnits(
      Number(inputAmountOne)?.toFixed(selectedTokenOne?.decimals)?.toString(),
      selectedTokenOne?.decimals ? selectedTokenOne?.decimals : "18"
    );
    const amountOut = parseUnits(
      Number(inputAmountTwo)?.toFixed(selectedTokenTwo?.decimals)?.toString(),
      selectedTokenTwo?.decimals ? selectedTokenTwo?.decimals : "18"
    );

    const slippageAdjustedAmountOutMin = amountOut.sub(amountOut.mul(Number(slippage * 10000)).div(100 * 10000)).toString();

    const time = Math.floor(Date.now() / 1000) + Number(txDeadline) * 60;
    const deadline = ethers.BigNumber.from(time);

    if (selectedTokenOne?.address === ethers.constants.AddressZero) {
      const tx = await router.swapExactETHForTokens(slippageAdjustedAmountOutMin, [wethV2, selectedTokenTwo?.address], account, deadline, {
        value: amountIn,
      });
      return tx;
    } else if (selectedTokenTwo?.address === ethers.constants.AddressZero) {
      const tx = await router.swapExactTokensForETH(
        amountIn,
        slippageAdjustedAmountOutMin,
        [selectedTokenOne?.address, wethV2],
        account,
        deadline
      );
      return tx;
    } else {
      const tx = await router.swapExactTokensForTokens(
        amountIn,
        slippageAdjustedAmountOutMin,
        [selectedTokenOne?.address, selectedTokenTwo?.address],
        account,
        deadline
      );
      return tx;
    }
  } catch (err) {
    console.log("error in swapInV2", err);
    return false;
  }
}

export async function swapInV3(
  selectedTokenOne,
  selectedTokenTwo,
  account,
  inputAmountOne,
  inputAmountTwo,
  selectedRouter,
  slippage,
  txDeadline,
  tradeInfo,
  provider
) {
  try {
    const signer = await provider.getSigner();
    const wethV3 = await getWethV3Address(selectedRouter, provider);

    const router = new Contract(selectedRouter?.routerV3, IUniswapv3Router?.abi, signer);

    const amountIn = parseUnits(
      Number(inputAmountOne)?.toFixed(selectedTokenOne?.decimals)?.toString(),
      selectedTokenOne?.decimals ? selectedTokenOne?.decimals : "18"
    );
    const amountOut = parseUnits(
      Number(inputAmountTwo)?.toFixed(selectedTokenTwo?.decimals)?.toString(),
      selectedTokenTwo?.decimals ? selectedTokenTwo?.decimals : "18"
    );

    const slippageAdjustedAmountOutMin = amountOut.sub(amountOut.mul(Number(slippage * 10000)).div(100 * 10000)).toString();

    const time = Math.floor(Date.now() / 1000) + Number(txDeadline) + 1000;
    const deadline = ethers.BigNumber.from(time);

    if (selectedTokenOne?.address === ethers.constants.AddressZero) {
      const params = {
        tokenIn: wethV3,
        tokenOut: selectedTokenTwo?.address,
        fee: tradeInfo?.fee, // Example fee tier, adjust as needed
        recipient: account,
        deadline: deadline,
        amountIn: amountIn,
        amountOutMinimum: slippageAdjustedAmountOutMin,
        sqrtPriceLimitX96: 0, // No price limit
      };
      const tx = await router.exactInputSingle(params, {
        value: amountIn,
      });
      // await tx.wait();
      return tx;
    } else if (selectedTokenTwo?.address === ethers.constants.AddressZero) {
      const params = {
        tokenIn: selectedTokenOne?.address,
        tokenOut: wethV3,
        fee: tradeInfo?.fee, // Example fee tier, adjust as needed
        recipient: account,
        deadline: deadline,
        amountIn: amountIn,
        amountOutMinimum: slippageAdjustedAmountOutMin,
        sqrtPriceLimitX96: 0, // No price limit
      };
      const tx = await router.exactInputSingle(params);
      // await tx.wait();
      return tx;
    } else {
      const params = {
        tokenIn: selectedTokenOne?.address,
        tokenOut: selectedTokenTwo?.address,
        fee: tradeInfo?.fee, // Example fee tier, adjust as needed
        recipient: account,
        deadline: deadline,
        amountIn: amountIn,
        amountOutMinimum: slippageAdjustedAmountOutMin,
        sqrtPriceLimitX96: 0, // No price limit
      };
      const tx = await router.exactInputSingle(params, {
        gasLimit: ethers.utils.hexlify(1000000), // Adjust the gas limit as needed
      });
      // await tx.wait();
      return tx;
    }
  } catch (err) {
    console.log("error in swapInV3 : ", err);
    return false;
  }
}
