import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatAmount } from "../../../Components/Function/commonFunction";

function V2PoolLiquidity({ item, index }) {
  const [showV3LiquidityInfo, setShowV3LiquidityInfo] = useState(false);

  return (
    <div
      className="card-body dark-text mt-2 rounded-15 p-sm-0 p-0 m-0 d-flex justify-content-center align-items-center"
      key={index}
    >
      <div
        className="card-body w-100 bg-wallet-2 rounded-15 pt-3 px-sm-1  px-1"
        style={
          !showV3LiquidityInfo
            ? {
                height: "68px",
                transition: " 0.3s ease-in",
                overflow: "hidden",
              }
            : {
                height: "160px",
                overflow: "hidden",
                transition: " 0.3s ease-in",
              }
        }
      >
        <div className="d-flex justify-content-between align-items-center  mx-1 mt-sm-1 mt-md-0 mt-1">
          <Link
            to={`/v2liquidityInfo?inputAddress=${item.token0?.address}&outputAddress=${item.token1?.address}`}
            className="text-gray-900 rajdhani-600 mb-0 font-xss d-flex  align-items-center"
          >
            <div className="d-flex align-items-center">
              {item?.token0?.icon ? (
                <img
                  src={item?.token0?.icon}
                  alt="user"
                  className="h-20  rounded-circle mx-1"
                />
              ) : (
                <svg
                  viewBox="0 0 24 24"
                  class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                  color="text"
                  width="20px"
                  height="20px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                </svg>
              )}
              {item?.token1?.icon ? (
                <img
                  src={item?.token1?.icon}
                  alt="user"
                  className="h-20  rounded-circle mx-1"
                />
              ) : (
                <svg
                  viewBox="0 0 24 24"
                  class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                  color="text"
                  width="20px"
                  height="20px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                </svg>
              )}
              <span className="dark-text d-flex align-items-center font-sm rajdhani-500 ">
                {item?.token0?.symbol} / {item?.token1?.symbol}
              </span>

              <span className="text-gray-600 rajdhani-500 rounded-10 font-xss border-5 border-box px-1 ms-2">
                {formatAmount(item.share)}%
              </span>
            </div>
          </Link>
          <div className="d-flex align-items-center text-blue pointer  m-0">
            <button
              className="btn form-select  px-3  text-white rounded-10 d-flex justify-content-center b-none "
              onClick={() => setShowV3LiquidityInfo(!showV3LiquidityInfo)}
            >
              {/* <span className=" d-md-block pe-2 d-sm-none d-none">
                Hide closed position
              </span> */}
              <span
                className={`dark-text arrow2 m-sm-0 m-md-1 m-0 ${
                  showV3LiquidityInfo ? "up" : "down"
                }`}
              ></span>
            </button>
          </div>
        </div>
        <hr className="border-5 mt-4" />
        <div className="d-flex justify-content-between align-items-center my-2 mx-3">
          <div className="text-gray-700 rajdhani-400 mb-0 ">
            <p className="d-flex align-items-center rajdhani-500 dark-text mb-0 opacity-75">
              {item?.token0?.icon ? (
                <img
                  src={item?.token0?.icon}
                  alt="user"
                  className="h-26 rounded-circle mx-1"
                />
              ) : (
                <svg
                  viewBox="0 0 24 24"
                  class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                  color="text"
                  width="26px"
                  height="26px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                </svg>
              )}
              <span className="mx-1">{item.token0?.symbol}</span>
            </p>
          </div>
          <div className="text-gray-900 d-flex justify-content-end rajdhani-500 mb-0 ">
            <div>
              <p className="rajdhani-500 dark-text mb-0">
                <span>
                  {formatAmount(item.token0Amount)} {item.token0?.symbol}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center my-2 mx-3 ">
          <div className="text-gray-700 rajdhani-400 mb-0 ">
            <p className="d-flex align-items-center rajdhani-400 dark-text mb-0 opacity-75">
              {item?.token1?.icon ? (
                <img
                  src={item?.token1?.icon}
                  alt="user"
                  className="h-26 rounded-circle mx-1"
                />
              ) : (
                <svg
                  viewBox="0 0 24 24"
                  class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                  color="text"
                  width="26px"
                  height="26px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                </svg>
              )}
              <span className="mx-1">{item.token1?.symbol}</span>
            </p>
          </div>
          <div className="text-gray-900 d-flex justify-content-end rajdhani-500 mb-0 ">
            <div>
              <p className="rajdhani-500 dark-text mb-0">
                <span>
                  {formatAmount(item.token1Amount)} {item.token1?.symbol}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default V2PoolLiquidity;
