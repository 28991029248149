import React from "react";
import { formatAmount } from "../../../../Components/Function/commonFunction";

export default function UserLiquidityBox({ token0, token1, amount0, amount1, feeAmount0, feeAmount1, setCollectFeeLoading }) {
  return (
    <div>
      <div className="row">
        {/* <div className="col-lg-5 col-md-5 col-sm-12  mt-md-0 mt-3 ">
          <div
            className="dark-border border p-2 rounded-15 d-flex justify-content-center py-3"
            style={{ height: "375px" }}
          >
            <img src="./assets/newimg/liquidit-l.svg" alt="user" class="" />
          </div>
        </div> */}

        <div className="col-12 mt-md-0 mt-3">
          <div className="dark-border border p-2 rounded-15">
            <p class="font-xs rajdhani-500 text-gray-600 dark-text mb-0">Liquidity</p>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h5 className="font-md rajdhani-500 mt-1 dark-text">$0</h5>
              {/* <button
              type="button"
              class="btn text-gray-900 rajdhani-700 p-1 font-xsss px-2 py-1 ms-2 rounded-20 btn-success d-flex align-items-start"
            >
              Collect
            </button> */}
            </div>

            <div className="card-body border dark-border bg-l  p-relative rounded-15 p-3 py-1">
              <div className="d-flex  justify-content-between mt-1 mb-0">
                <div>
                  <p className="text-gray-900 rajdhani-600 font-xs dark-text mb-0">
                    {token0?.icon ? (
                      <img src={token0?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                        color="text"
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                    )}
                    {token0?.symbol}
                  </p>
                </div>
                <div className="text-end  d-flex ">
                  <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0">{formatAmount(amount0)}</p>
                  {/* <p className="text-gray-900 rajdhani-400 font-xss dark-text mb-0 ">90%</p> */}
                </div>
              </div>
              <div className="d-flex  justify-content-between m-0">
                <div>
                  <p className="text-gray-900 rajdhani-600 font-xs dark-text mb-0">
                    {token1?.icon ? (
                      <img src={token1?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                        color="text"
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                    )}
                    {token1?.symbol}
                  </p>
                </div>
                <div className="text-end pb-2 d-flex ">
                  <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0">{formatAmount(amount1)}</p>
                  {/* <p className="text-gray-900 rajdhani-400 font-xss dark-text mb-0 ">90%</p> */}
                </div>
              </div>
              <div />
            </div>
          </div>
          <div className="dark-border border p-2 rounded-15 mt-2">
            <div className="d-flex justify-content-between align-items-center  mb-2">
              <div>
                <p class="font-xs rajdhani-500 text-gray-600 dark-text mb-0">Liquidity</p>
                <h5 className="font-md rajdhani-500 mt-1 dark-text">$0</h5>
              </div>

              <button
                type="button"
                class="btn py-1 font-sm px-2 rounded-10 form-select text-white w-111 mx-1 mt-md-0 mt-sm-1 mt-1 b-none"
                disabled={!feeAmount0 || !feeAmount1 || (Number(feeAmount0?.amount) <= 0 && Number(feeAmount1?.amount) <= 0)}
                onClick={() => setCollectFeeLoading(1)}
              >
                Collect fees
              </button>
            </div>

            <div className="card-body border dark-border bg-l  p-relative rounded-15 p-3 py-1">
              <div className="d-flex  justify-content-between mt-1 mb-0">
                <div>
                  <p className="text-gray-900 rajdhani-600 font-xs dark-text mb-0">
                    {token0?.icon ? (
                      <img src={token0?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                        color="text"
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                    )}
                    {token0?.symbol}
                  </p>
                </div>
                <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0 "> {formatAmount(feeAmount0?.amount)}</p>
              </div>
              <div className="d-flex  justify-content-between m-0">
                <p className="text-gray-900 rajdhani-600 font-xs dark-text mb-0">
                  {token1?.icon ? (
                    <img src={token1?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                      color="text"
                      width="20px"
                      height="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                    </svg>
                  )}
                  {token1?.symbol}
                </p>
                <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0"> {formatAmount(feeAmount1?.amount)}</p>
              </div>
              <div />
            </div>
            {/* <div className="d-flex mt-1 justify-content-between align-items-center">
              <p className="font-xs dark-text mb-0 ">Collect as WBNB</p>
              <div className="form-check form-switch switch-lg switch-success p-0 d-flex flex-row justify-content-between mb-2 mt-2">
                <label className="form-check-label w-75 font-sm rajdhani-700 text-gray-900 dark-text lh-28"></label>
                <input className="form-check-input mt-1" style={{ width: "2.5em", height: "1.5em" }} type="checkbox" id="asdasd" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
