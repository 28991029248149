import React, { useContext, useEffect, useState } from "react";
import { globalStates } from "../../contexts/GlobalStates";

function SwapSettingsModel({ setSwapBoxModalChange }) {
  const { settingData, setSettingData } = useContext(globalStates);
  const [showTabH, setShowTabH] = useState(false);

  const [slippageErr, setSlippageErr] = useState(null);

  const handleSettingData = (e, name) => {
    let settings = { ...settingData };
    let value = e.target.value;
    if (name === "ExpertMode") {
      settings = { ...settings, [name]: e.target.checked };
    } else if (name === "slippage") {
      if (e.type === "blur") {
        if (!value) {
          settings.slippagePlaceHolder = "0.5";
        }
        if (!value) {
          settings.slippage = "0.5";
        }
        if (value === "0") {
          settings.slippagePlaceHolder = "0.5";
          settings.slippage = "0.5";
        }
      } else {
        if (!value) {
          setSlippageErr(null);
          settings.slippagePlaceHolder = "0.5";
        } else if (Number(value) > 1) {
          setSlippageErr({
            error:
              "Slippage tolerance above 1% could lead to an unfavorable rate. It’s recommended to use the auto setting.",
            errtype: "error",
          });
        } else {
          setSlippageErr(null);
        }
        settings = { ...settings, [name]: value, slippagePlaceHolder: "" };
      }
    } else if (name === "auto") {
      settings = { ...settings, slippage: "0.5", slippagePlaceHolder: "0.5" };
    } else if (name === "0.1") {
      settings = { ...settings, slippage: "0.1", slippagePlaceHolder: "0.1" };
    } else if (name === "0.5") {
      settings = { ...settings, slippage: "0.5", slippagePlaceHolder: "0.5" };
    } else if (name === "1") {
      settings = { ...settings, slippage: "1", slippagePlaceHolder: "1" };
    } else {
      settings = { ...settings, [name]: value };
    }

    localStorage.setItem("value-settings", JSON.stringify(settings));
    setSettingData(settings);
  };

  return (
    <div className="">
      <div className="row justify-content-center">
        <div className="col-12 rounded-20 pb-3 bg-white-new p-0 ">
          <div className="d-flex py-2 px-3">
            <i
              className="fa fa-chevron-left d-flex align-items-center dark-text pointer"
              onClick={() => setSwapBoxModalChange("swap")}
            ></i>
            <h5 className="rajdhani-700 font-xxxl text-gray-900 mb-0 pt-2 dark-text m-auto">
              Swap Settings
            </h5>
          </div>
          <div
            className="card-body mt-2 border-light rounded-10 p-2 px-sm-2 px-md-3 mx-2"
            style={
              showTabH
                ? {
                    height: "3rem",
                    transition: "0.3s ease-in",
                    overflow: "hidden",
                  }
                : {
                    height: "110px",
                    overflow: "hidden",
                    transition: "0.3s ease-in",
                  }
            }
          >
            <div className="d-flex mb-1 justify-content-between align-items-center">
              <figure className="avater mb-0 me-0">
                <i className="bi bi-water dark-text font-md"></i>
              </figure>
              <div className="me-auto text-grey-500 text-center align-items-center">
                <h6 className="text-gray-700 lh-1 mb-0 font-sm rajdhani-600 text-gray dark-text ms-2">
                  Slippage tolerance
                </h6>
              </div>
              <div
                className="text-gray-900 d-flex justify-content-end rajdhani-500 font-xs mb-0 pointer"
                onClick={() => setShowTabH(!showTabH)}
              >
                <div className="dark-text">
                  <span>
                    {settingData.slippage ? (
                      <>
                        <span
                          className={`d-flex align-items-center ${
                            settingData?.slippage > 1 ? "warning-text" : ""
                          }`}
                        >
                          {settingData?.slippage > 1 && (
                            <img
                              className="me-1 w-20"
                              src="./assets/images/warning.png"
                              alt=""
                            />
                          )}
                          {settingData?.slippage}% Custom
                        </span>
                      </>
                    ) : (
                      `${settingData?.slippagePlaceHolder}%`
                    )}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span
                    className={`arrow2 light-icon-done  ${
                      !showTabH ? "up" : "down"
                    }`}
                  ></span>
                  <span
                    className={`arrow dark-icon-done ${
                      !showTabH ? "up" : "down"
                    }`}
                  ></span>
                </div>
              </div>
            </div>

            <div className={`mb-1 mt-3 ${showTabH ? "show" : ""}`}>
              <div className="slider">
                <button
                  className={`btn dark-text h-30 disabled ${
                    settingData.slippagePlaceHolder === "Auto"
                      ? "bg-slippage slider-item"
                      : "slider-item"
                  }`}
                  // onClick={(e) => handleSettingData(e, "auto")}
                >
                  Auto
                </button>
                <button
                  className={`btn dark-text h-30 ${
                    settingData.slippagePlaceHolder === "0.1" ||
                    settingData.slippage === "0.1"
                      ? "bg-slippage slider-item"
                      : "slider-item"
                  }`}
                  onClick={(e) => handleSettingData(e, "0.1")}
                >
                  0.1%
                </button>
                <button
                  className={`btn dark-text h-30 ${
                    settingData.slippagePlaceHolder === "0.5" ||
                    settingData.slippage === "0.5"
                      ? "bg-slippage slider-item"
                      : "slider-item"
                  }`}
                  onClick={(e) => handleSettingData(e, "0.5")}
                >
                  0.5%
                </button>
                <button
                  className={`btn dark-text h-30 ${
                    settingData.slippagePlaceHolder === "1" ||
                    settingData.slippage === "1"
                      ? "bg-slippage slider-item"
                      : "slider-item"
                  }`}
                  onClick={(e) => handleSettingData(e, "1")}
                >
                  1%
                </button>
                <button
                  className={`btn d-flex align-items-center dark-text px-0 w-80 h-30 ${
                    settingData.slippage ? "border-blue-1" : ""
                  }`}
                >
                  <input
                    type="text"
                    name="slippage"
                    className="w-100 dark-text text-center font-xss mt-1 form-control-x0-placeholder"
                    style={{ border: "none", background: "none" }}
                    value={settingData.slippage}
                    placeholder="Custom"
                    onChange={(e) => {
                      if (
                        e.target.value.match(
                          /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
                        ) &&
                        Number(e.target.value) < 50
                      ) {
                        handleSettingData(e, "slippage");
                      }
                    }}
                    onBlur={(e) => handleSettingData(e, "slippage")}
                  />
                </button>
              </div>
            </div>
          </div>
          {slippageErr && (
            <div className="card-body mt-2 p-2 ">
              <div className="card-body dark-border  border-light rounded-10 w-100 border-box-danger d-flex justify-content-start align-items-start">
                <div className="d-flex justify-content-start ">
                  <div className="text-start">
                    <p className="m-0 font-sm dark-text">
                      {slippageErr?.error}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="card-body mt-2 border-light rounded-10 p-2 px-sm-2 px-md-3 mx-2 ">
            <div className="row ">
              <div className="col-12">
                <div className="form-check form-switch switch-lg switch-success p-0 d-flex flex-row justify-content-between mb-2 mt-2">
                  <label className="form-check-label w-75 font-sm rajdhani-700 text-gray-900 dark-text lh-28">
                    Tx deadline (mins)
                    <div class="tip">
                      <i className="bi bi-info-circle dark-text font-xss ms-2 pointer "></i>
                      <span class="tiptext bg-info-black rajdhani-500">
                        Your transaction will revert if it is left confirming
                        for longer than this time.
                      </span>
                    </div>
                  </label>
                  <div className="bg-slippage  box-hover slider-item p-0 ">
                    <input
                      type="number"
                      className="w-60 text-center dark-text"
                      placeholder="20"
                      defaultValue={settingData.txDeadline || "20"}
                      style={{ border: "none", background: "none" }}
                      onChange={(e) => {
                        handleSettingData(e, "txDeadline");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body mt-2 border-light  rounded-10 p-2 px-sm-2 px-md-3 mx-2">
            <div className="row ">
              <div className="col-12">
                <div className="form-check form-switch switch-lg switch-success p-0 d-flex flex-row justify-content-between mb-2 mt-2">
                  <label className="form-check-label w-75 font-sm rajdhani-700 text-gray-900 dark-text lh-28">
                    Expert Mode
                    <div class="tip">
                      <i className="bi bi-info-circle dark-text font-xss ms-2 pointer "></i>
                      <span class="tiptext bg-info-black rajdhani-500">
                        Bypasses confirmation modals and allows high slippage
                        trades. Use at your own risk.
                      </span>
                    </div>
                  </label>
                  <input
                    className="form-check-input mt-1"
                    style={{ width: "2.5em", height: "1.5em" }}
                    type="checkbox"
                    checked={settingData.ExpertMode}
                    onChange={(e) => {
                      handleSettingData(e, "ExpertMode");
                    }}
                    id="asdasd"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="card-body mt-2 chain-bg border-light rounded-15 mx-2 d-flex align-items-center justify-content-center text-center"
            style={{ height: "180px", backgroundColor: "#f1f4fb" }}
          >
            <div className="text-center">
              <figure className="w-100 mb-0">
                <img
                  src="assets/newimg/swap-setting.svg"
                  alt="blog-post"
                  className="img-fluid rounded-6 ovh"
                />
              </figure>
              <p className="text-gray-500 rajdhani-500 mt-2 mb-0 font-md">
                For extended settings
              </p>
              <p className="text-blue rajdhani-600 mt-2 mb-0 font-md">
                Open advanced mode
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default SwapSettingsModel;
