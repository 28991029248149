/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from "react";
import Footer from "../../../Components/Coman/Footer";
import SwapHeader from "../../../Components/Coman/SwapHeader";
import { Link } from "react-router-dom";
import { walletConnect } from "../../../contexts/WalletConnect";
import V3PoolLiquidity from "./V3PoolLiquidity";
import {
  findTokenForImport,
  getFactory,
  getNonfungiblePositionManagerContract,
  getTokenDecimals,
  getTokenName,
  getTokenSymbol,
  getV3Pool,
  getWethV3Address,
} from "../../../Components/Function/ethersFunctions";
import {
  getMinAndMaxPrices,
  getV3PositionIdsFromContract,
} from "../../../Components/Function/liquidityFunctions";
import { globalStates } from "../../../contexts/GlobalStates";
import { constants } from "ethers";

function V3Pool() {
  const {
    isConnected,
    selectedChain,
    provider,
    address,
    chainId,
    ConnectWallet,
  } = useContext(walletConnect);
  const { chainTokens } = useContext(globalStates);
  const [showMore, setShowMore] = useState(0);
  const [pools, setPools] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClickOutside1 = (event) => {
    if (!event.target.closest(".dropdown-1")) {
      setShowMore(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside1);
    return () => {
      window.removeEventListener("click", handleClickOutside1);
    };
  }, []);

  const getV3PositionIds = async () => {
    try {
      setLoading(true);
      const positionContract = await getNonfungiblePositionManagerContract(
        selectedChain?.routers[0].NonfungiblePositionManager,
        provider
      );
      if (positionContract) {
        const balance = await positionContract.balanceOf(address);
        const weth = await getWethV3Address(
          selectedChain?.routers[0],
          provider
        );
        // const factory = await getFactory(selectedChain?.routers[0], "v3", provider);
        if (Number(balance) > 0) {
          let pairs = [];
          for (let i = 0; i < Number(balance); i++) {
            const tokenOfOwnerByIndex =
              await positionContract.tokenOfOwnerByIndex(address, i);
            const position = await positionContract.positions(
              tokenOfOwnerByIndex
            );
            // const poolAddress = await factory.getPool(position?.token0, position?.token1, position?.fee);
            // const pool = await getV3Pool(poolAddress, provider);

            let token0;
            if (position?.token0?.toLowerCase() === weth?.toLowerCase()) {
              const find = chainTokens?.find(
                (item) =>
                  item.address?.toLowerCase() ===
                  constants?.AddressZero?.toLowerCase()
              );
              token0 = find;
            } else {
              const find = chainTokens?.find(
                (item) =>
                  item.address?.toLowerCase() ===
                  position?.token0?.toLowerCase()
              );
              if (find) {
                token0 = find;
              } else {
                const { address, symbol, name, decimals } =
                  await findTokenForImport(position?.token0, provider);
                token0 = {
                  address: position?.token0?.toLowerCase(),
                  decimals,
                  name,
                  symbol,
                  icon: "",
                };
              }
            }
            let token1;
            if (position?.token1?.toLowerCase() === weth?.toLowerCase()) {
              const find = chainTokens?.find(
                (item) =>
                  item.address?.toLowerCase() ===
                  constants?.AddressZero?.toLowerCase()
              );
              token1 = find;
            } else {
              const find = chainTokens?.find(
                (item) =>
                  item.address?.toLowerCase() ===
                  position?.token1?.toLowerCase()
              );
              if (find) {
                token1 = find;
              } else {
                const { address, symbol, name, decimals } =
                  await findTokenForImport(position?.token1, provider);
                token1 = {
                  address: position?.token1?.toLowerCase(),
                  decimals,
                  name,
                  symbol,
                  icon: "",
                };
              }
            }
            // if (tokenOfOwnerByIndex?.toString() === "14919") {
            // const poolAddress = await factory.getPool(position?.token0, position?.token1, position?.fee);
            // console.log("-----------------------------", tokenOfOwnerByIndex?.toString(), "------------------------------");
            // console.log("pool address : ", poolAddress);
            // console.log("tickLower : ", position?.tickLower);
            // console.log("tickUpper : ", position?.tickUpper);
            // console.log(
            //   "lowPrice : ",
            //   getPriceFromTick(
            //     position?.tickLower,
            //     token0?.decimals,
            //     token1?.decimals,
            //     token0?.address === constants?.AddressZero || token1?.address === constants?.AddressZero ? false : true
            //   )
            // );
            // console.log(
            //   "highPrice : ",
            //   getPriceFromTick(
            //     position?.tickUpper,
            //     token0?.decimals,
            //     token1?.decimals,
            //     token0?.address === constants?.AddressZero || token1?.address === constants?.AddressZero ? false : true
            //   )
            // );
            // console.log("-----------------------------------------------------------");
            // }
            // if (token0?.address !== constants?.AddressZero && token1?.address !== constants?.AddressZero) {
            //   let swap = token0;
            //   token0 = token1;
            //   token1 = swap;
            // }
            let prices = await getMinAndMaxPrices(
              position?.tickLower,
              position?.tickUpper,
              token0?.decimals,
              token1?.decimals
            );
            pairs.push({
              token0,
              token1,
              id: tokenOfOwnerByIndex?.toString(),
              fee: position?.fee,
              minPrice: prices?.minPrice,
              maxPrice: prices?.maxPrice,
              minPriceRev: prices?.minPriceRev,
              maxPriceRev: prices?.maxPriceRev,
            });
          }

          setPools([...pairs]);
          setLoading(false);
        } else {
          setPools(null);
          setLoading(false);
        }
      } else {
        setPools(null);
        setLoading(false);
      }
    } catch (err) {
      console.log("error in getV3PositionIds : ", err);
      setPools(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedChain &&
      provider &&
      address &&
      chainTokens?.length &&
      provider
    ) {
      if (
        selectedChain?.currency?.toLowerCase() ===
        chainTokens[0]?.symbol?.toLowerCase()
      ) {
        getV3PositionIds();
      }
    } else {
      setPools(null);
    }
  }, [selectedChain, provider, address, chainTokens, provider, chainId]);

  return (
    <>
      <div className="bg-dark-black dashboard-bg main-vh-11">
        <div className="main-wrapper ">
          <SwapHeader />
          <div className="banner-wrapper style13 bg-image-none-dark mt-lg-100 mt-0 bg-image-top bg-no-repeat ">
            <div className="container-wide">
              <div className="row justify-content-center">
                <div className="row  pb-3 mt-5 d-flex justify-content-center">
                  <div className="col-lg-8 px-sm-0 px-md-2 px-0">
                    <div className="d-flex mb-0 justify-content-between align-items-center flex-wrap">
                      <div className="d-flex gap-2 justify-content-start align-items-center ">
                        <h4 className="text-gray-900 dark-text  rajdhani-700 mt-3 pb-2 ">
                          Positions
                        </h4>
                        <label className="dropdown-1 bg-wallet-2 rounded-10 ">
                          <div
                            className="dd-button-1 px-2 py-1 text-gray-900 rajdhani-700 font-sm text-blue"
                            onClick={() => setShowMore(!showMore)}
                          >
                            v3
                            <span
                              className={`text-blue arrow2 ${
                                showMore ? "up" : " down"
                              }`}
                            ></span>
                          </div>

                          <input
                            type="checkbox"
                            className="dd-input-1 d-none"
                            id="test"
                          />

                          <ul
                            className={`dd-menu-1 bg-wallet-2  rounded-10 border-light-1  p-2 ${
                              showMore ? "show" : "d-none"
                            }`}
                          >
                            <li className="px-2">
                              <Link to="/v2Pool" className="text-blue">
                                <svg
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  className="text-blue mx-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.2971 7.20007H12.4971V3.19995C12.4971 1.43263 13.9298 0 15.6971 0C17.4644 0 18.8972 1.43263 18.8972 3.19995H17.2971C17.2971 2.31631 16.5807 1.60005 15.6971 1.60005C14.8134 1.60005 14.0972 2.31631 14.0972 3.19995V12.0001H12.4971V8.80012H5.2971V10.4001H3.69705V3.20002C3.69705 1.4327 5.12982 6.80089e-05 6.89715 6.80089e-05C8.66447 6.80089e-05 10.0971 1.4327 10.0971 3.20002H8.49705C8.49705 2.31638 7.78079 1.60011 6.89715 1.60011C6.0135 1.60011 5.2971 2.31638 5.2971 3.20002V7.20007ZM1.59424 14.3428L1 12.8573C3.85069 11.717 6.97772 11.717 10.3501 12.8411C13.3778 13.8503 16.1173 13.8503 18.5999 12.8573L19.1942 14.3428C16.3435 15.4831 13.2164 15.4831 9.84407 14.3589C6.81633 13.3497 4.07687 13.3497 1.59424 14.3428ZM1.59424 18.3426L1 16.8571C3.85069 15.7168 6.97772 15.7168 10.3501 16.841C13.3778 17.8502 16.1173 17.8502 18.5999 16.8571L19.1942 18.3426C16.3435 19.4829 13.2164 19.4829 9.84407 18.3588C6.81633 17.3497 4.07687 17.3497 1.59424 18.3426Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                v2 pools
                              </Link>
                            </li>
                          </ul>
                        </label>
                      </div>
                      <div className="d-flex gap-3 flex-row justify-content-center">
                        <Link
                          to="/addliquidity-v3"
                          className=" d-flex align-items-center form-select dark-border rounded-10 rajdhani-700 px-3 font-xs text-white h-40"
                        >
                          <span className="font-xl me-2">+</span>
                          <span className="font-sm"> New position</span>
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 ">
                        {loading ? (
                          <div className="card-body mt-2 dark-text border-light rounded-10 p-2 border-box d-flex justify-content-center align-items-center">
                            <div className="text-center ">Loading...</div>
                          </div>
                        ) : pools && pools?.length ? (
                          pools?.map((item, index) => {
                            return <V3PoolLiquidity item={item} key={index} />;
                          })
                        ) : (
                          <div className=" border border-light border-2 rounded-10 p-3 mt-2 border-box h-250 d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center ">
                              <div className="text-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="50"
                                  height="50"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="Pool__InboxIcon-sc-1cfc2685-9 iWtrL dark-text"
                                >
                                  <polyline points="22 12 16 12 14 15 10 15 8 12 2 12" />
                                  <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
                                </svg>
                                <h6 className="rajdhani-700 mt-3 dark-text font-sm">
                                  Your active V3 liquidity positions will <br />
                                  appear here.
                                </h6>
                                {!isConnected && (
                                  <button
                                    className="btn header-btn rajdhani-700 rounded-10 px-4 font-sm text-blue bg-wallet-2 w-100 mt-3"
                                    onClick={ConnectWallet}
                                  >
                                    <img
                                      className="h-20 me-2 "
                                      src="assets/image-2/wallet.png"
                                      alt=""
                                    />{" "}
                                    &nbsp;
                                    <span className>Connect Wallet</span>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default V3Pool;
