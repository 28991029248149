import React, { useContext } from "react";
import { walletConnect } from "../../../../contexts/WalletConnect";
import { constants } from "ethers";

function V3LiquidityConnectButton({ spinner, selectedTokenOne, selectedTokenTwo, depositAmountIn, depositAmountOut, addLiquidity }) {
  const { isConnected, ConnectWallet } = useContext(walletConnect);
  return (
    <>
      {spinner?.addLiquiSpinner ? (
        <button className="btn header-btn rajdhani-600 rounded-10 px-4 font-sm text-blue bg-gray-300 btn-new-color w-100 mt-2" disabled>
          <div class="nb-spinner-big"></div>
        </button>
      ) : !isConnected ? (
        <button className="btn header-btn  rajdhani-500 rounded-10 px-4 font-md text-white btn-bg-blue w-100 mt-2" onClick={ConnectWallet}>
          Connect wallet
        </button>
      ) : !selectedTokenOne || !selectedTokenTwo ? (
        <button className="btn header-btn  rajdhani-700 rounded-10  px-4 font-sm  dark-text bg-gray-300 btn-new-color w-100 mt-2" disabled>
          <span className="">Select Token</span>
        </button>
      ) : !depositAmountIn || !depositAmountOut ? (
        <button className="btn header-btn rajdhani-700 rounded-10 px-4 font-sm dark-text bg-gray-300 btn-new-color w-100 mt-2" disabled>
          <span className="">Enter amount</span>
        </button>
      ) : Number(depositAmountIn) > Number(selectedTokenOne?.balance) ? (
        <button
          className="btn header-btn rajdhani-600 rounded-10 px-4 font-sm dark-text bg-gray-300 text-gray-900 btn-new-color w-100 mt-2"
          disabled
        >
          <span className="">Insufficient {selectedTokenOne?.symbol} balance</span>
        </button>
      ) : Number(depositAmountOut) > Number(selectedTokenTwo?.balance) ? (
        <button
          className="btn header-btn rajdhani-600 rounded-10 px-4 font-sm dark-text bg-gray-300 text-gray-900 btn-new-color w-100 mt-2"
          disabled
        >
          <span className="">Insufficient {selectedTokenTwo?.symbol} balance</span>
        </button>
      ) : (selectedTokenOne?.address !== constants?.AddressZero && Number(selectedTokenOne?.allowance) < Number(depositAmountIn)) ||
        (selectedTokenTwo?.address !== constants?.AddressZero && Number(selectedTokenTwo?.allowance) < Number(depositAmountOut)) ? (
        <button
          className="btn header-btn rajdhani-600 rounded-10 px-4 font-sm dark-text bg-gray-300 text-gray-900 btn-new-color w-100 mt-2"
          disabled
        >
          <span className="">Add</span>
        </button>
      ) : (
        <button className="btn header-btn rajdhani-500 rounded-10 px-4 font-md text-white btn-bg-blue w-100 mt-2" onClick={addLiquidity}>
          Add
        </button>
      )}
    </>
  );
}

export default V3LiquidityConnectButton;
