const mainnet = {
  chainId: 1,
  name: "Ethereum Mainnet",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://eth-mainnet.public.blastapi.io",
};

const bscmainnet = {
  chainId: 56,
  name: "BNB Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com/",
  rpcUrl: "https://bsc-dataseed1.bnbchain.org",
};

const bsctestnet = {
  chainId: 97,
  name: "BNB Smart Chain Testnet",
  currency: "tBNB",
  explorerUrl: "https://testnet.bscscan.com/",
  rpcUrl: "https://data-seed-prebsc-1-s3.bnbchain.org:8545",
};

// module.exports = [mainnet, bscmainnet, bsctestnet];
module.exports = [mainnet, bscmainnet];
