/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import copy from "copy-to-clipboard";

const CopyBtn = ({ text }) => {
  const [status, setStatus] = React.useState(false);

  const onCopy = () => {
    copy(text);
    setStatus(true);
    setTimeout(() => {
      setStatus(false);
    }, 2000);
  };

  return (
    <div className="mx-1 d-flex justify-conte-center align-items-center">
      {status ? (
        <button className="btn p-0 mx-1">
          <img
            src="./assets/images/copy-check.svg"
            className="light-icon-done "
            style={{ height: "16px", width: "16px" }}
          />
          <img
            src="./assets/images/copy-check-dark.svg"
            className="dark-icon-done "
            style={{ height: "16px", width: "16px" }}
          />
        </button>
      ) : (
        <button className="btn p-0 mx-1" onClick={onCopy}>
          <img
            src="./assets/images/copy.svg"
            className="light-icon-done"
            style={{ height: "16px", width: "16px" }}
          />
          {/* <img
            src="./assets/images/copy.svg"
            className="light-icon-done"
            style={{ height: "16px", width: "16px" }}
          /> */}
          <img
            src="./assets/images/copy-dark.svg"
            className="dark-icon-done "
            style={{ height: "16px", width: "16px" }}
          />
        </button>
      )}
    </div>
  );
};

export default CopyBtn;
