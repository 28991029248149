import React, { useState, useEffect, useContext } from "react";
import SwapHeader from "../../../../Components/Coman/SwapHeader";
import Footer from "../../../../Components/Coman/Footer";
import { createSearchParams, Link, useNavigate, useSearchParams } from "react-router-dom";
import { walletConnect } from "../../../../contexts/WalletConnect";
import {
  getUsersReserveTokensForV2,
  getV2PerticularPairFromLocalStorage,
  storeV2PairInLocalStorage,
} from "../../../../Components/Function/liquidityFunctions";
import { getFactory, getWethV2Address } from "../../../../Components/Function/ethersFunctions";
import { formatAmount } from "../../../../Components/Function/commonFunction";
import { globalStates } from "../../../../contexts/GlobalStates";

export default function V2LiquidityInfo() {
  const { selectedChain, provider, address, setInputToken, setOutputToken } = useContext(walletConnect);
  const { chainTokens } = useContext(globalStates);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const inputAddress = searchParams.get("inputAddress") || "";
  const outputAddress = searchParams.get("outputAddress") || "";

  const [loading, steLoading] = useState(false);
  const [pair, setPair] = useState(null);

  const getStoredLiquidity = async () => {
    try {
      steLoading(true);
      let pairs = getV2PerticularPairFromLocalStorage(selectedChain?.chainId, inputAddress, outputAddress);
      if (pairs) {
        const factory = await getFactory(selectedChain?.routers[0], "v2", provider);
        const weth = await getWethV2Address(selectedChain?.routers[0], provider);
        const userData = await getUsersReserveTokensForV2(factory, address, weth, pairs?.token0, pairs?.token1, provider);
        if (userData) {
          setPair({
            ...pairs,
            share: userData?.share,
            token0Amount: userData?.token0Amount,
            token1Amount: userData?.token1Amount,
          });
          steLoading(false);
        } else {
          setPair();
          steLoading(false);
        }
      } else {
        const token0 = chainTokens?.find((item) => item.address?.toLowerCase() === inputAddress?.toLowerCase());
        const token1 = chainTokens?.find((item) => item.address?.toLowerCase() === outputAddress?.toLowerCase());
        const pairs = { token0: { ...token0 }, token1: { ...token1 } };
        const factory = await getFactory(selectedChain?.routers[0], "v2", provider);
        const weth = await getWethV2Address(selectedChain?.routers[0], provider);
        const userData = await getUsersReserveTokensForV2(factory, address, weth, pairs?.token0, pairs?.token1, provider);
        if (userData) {
          if (Number(userData?.lptokens) > 0) {
            storeV2PairInLocalStorage(selectedChain?.chainId, token0, token1);
            setPair({
              ...pairs,
              share: userData?.share,
              token0Amount: userData?.token0Amount,
              token1Amount: userData?.token1Amount,
            });
            steLoading(false);
          } else {
            setPair(null);
            steLoading(false);
          }
        } else {
          setPair(null);
          steLoading(false);
        }
      }
    } catch (err) {
      setPair(null);
      steLoading(false);
    }
  };

  useEffect(() => {
    if (inputAddress && outputAddress && selectedChain && chainTokens) {
      getStoredLiquidity();
    } else {
      setPair(null);
    }
  }, [inputAddress, outputAddress, selectedChain, chainTokens]);

  const navigateToAddLiquidity = () => {
    setInputToken(pair?.token0?.address);
    setOutputToken(pair?.token1?.address);
    navigate("/addLiquidity-v2");
  };

  return (
    <div className=" main-vh-11 bg-dark-black dashboard-bg">
      <div
        className="main-wrapper "
        // style={{ minHeight: "100%" }}
      >
        <SwapHeader />
        <div className="container-wide">
          <div className="banner-wrapper py-4 mt-lg-100 mt-0 ">
            <div className="row justify-content-center px-sm-2 px-md-0 px-2 ">
              {loading ? (
                <div className="col-xl-6 col-lg-7 col-md-10 col-sm-12  mt-2 dark-text border-light rounded-10 p-2 d-flex justify-content-center align-items-center">
                  <div className="text-center ">Loading...</div>
                </div>
              ) : (
                <div className="col-xl-6 col-lg-7 col-md-10 col-sm-12  bg-dark-black  dashboard-bg border dark-border rounded-20 ">
                  <div className="d-flex flex-wrap justify-content-between align-items-center my-3">
                    <div className="d-flex align-items-center">
                      <Link to="/v2Pool">
                        <i class="fa fa-chevron-left d-flex align-items-center pointer text-gray-800 dark-text"></i>
                      </Link>
                      <h6 className="text-gray-900 rajdhani-600 mb-0 ms-3 d-flex  align-items-center ">
                        {pair?.token0?.icon ? (
                          <img src={pair?.token0?.icon} alt="user" className="h-26 rounded-circle mx-1" />
                        ) : (
                          <svg
                            viewBox="0 0 24 24"
                            class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                            color="text"
                            width="26px"
                            height="26px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                          </svg>
                        )}
                        {pair?.token1?.icon ? (
                          <img src={pair?.token1?.icon} alt="user" className="h-26 rounded-circle mx-1" />
                        ) : (
                          <svg
                            viewBox="0 0 24 24"
                            class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                            color="text"
                            width="26px"
                            height="26px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                          </svg>
                        )}
                        <span className="dark-text d-flex font-sm align-items-center mx-1">
                          {pair?.token0?.symbol} / {pair?.token1?.symbol}
                        </span>
                      </h6>
                    </div>
                    <div className=" d-flex justify-content-end">
                      <div className="d-flex align-items-center ">
                        <button
                          type="button"
                          className="btn py-1 font-sm w-100 text-blue border-blue rounded-10  mx-1 mt-md-0 mt-sm-2 mt-2"
                          onClick={navigateToAddLiquidity}
                        >
                          Add
                        </button>
                        <Link
                          to={`/remove-liquidity-v2?inputAddress=${pair?.token0?.address}&outputAddress=${pair?.token1?.address}`}
                          type="button"
                          className="btn form-select py-1 font-sm w-100 text-white rounded-10 mx-1 mt-md-0 mt-sm-2 mt-2 b-none"
                        >
                          Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr style={{ border: "1px solid #6C86AD" }} />
                  <div className=" p-2 rounded-15 mt-2">
                    {/* <div className="d-flex justify-content-between align-items-center  mb-2">
                      <div>
                        <p className="font-xs rajdhani-600 text-gray-500 dark-text mb-0">Liquidit</p>
                        <h5 className="font-xl rajdhani-700 mt-1 dark-text">$11.00</h5>
                      </div>
                    </div> */}
                    <div className="card-body border dark-border bg-l  p-relative rounded-15 p-3 py-1">
                      <div className="d-flex  justify-content-between my-1">
                        <div>
                          <p className="d-flex align-items-center text-gray-900 rajdhani-500 font-xs dark-text mb-0">
                            {pair?.token0?.icon ? (
                              <img src={pair?.token0?.icon} alt="user" className="h-26 rounded-circle mx-1" />
                            ) : (
                              <svg
                                viewBox="0 0 24 24"
                                class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                                color="text"
                                width="26px"
                                height="26px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                              </svg>
                            )}
                            <span className="mx-1">{pair?.token0?.symbol}</span>
                          </p>
                        </div>
                        <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0 ">{formatAmount(pair?.token0Amount)}</p>
                      </div>
                      <div className="d-flex  justify-content-between my-1">
                        <div>
                          <p className="d-flex align-items-center text-gray-900 rajdhani-500 font-xs dark-text mb-0">
                            {pair?.token1?.icon ? (
                              <img src={pair?.token1?.icon} alt="user" className="h-26 rounded-circle mx-1" />
                            ) : (
                              <svg
                                viewBox="0 0 24 24"
                                class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                                color="text"
                                width="26px"
                                height="26px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                              </svg>
                            )}
                            <span className="mx-1">{pair?.token1?.symbol}</span>
                          </p>
                        </div>
                        <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0">{formatAmount(pair?.token1Amount)}</p>
                      </div>
                      <div />
                    </div>
                    <div className="d-flex mt-1 justify-content-between align-items-center">
                      <p className="font-xs dark-text mb-0 mt-2 ">Your share in poll : {formatAmount(pair?.share)}%</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
