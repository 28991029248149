import React from "react";

function V2LiquidityWarmimgBox() {
  return (
    <>
      <div className="card-body  rounded-10 p-2 mt-20 border-box2 dark-border bg-Liquidity">
        <p
          className="dark-text rajdhani-400 fw-normal font-sm m-0 "
          style={{ lineHeight: "18.75px" }}
        >
          <span>Tip:</span> When you add liquidity, you will receive pool tokens
          representing your position. These tokens automatically earn fees
          proportional to your share of the pool, and can be redeemed at any
          time.
        </p>
      </div>
    </>
  );
}

export default V2LiquidityWarmimgBox;
