import * as chains from "./chains";

const ETHMAINNET = [
  {
    name: "Ether",
    symbol: "ETH",
    address: "0x0000000000000000000000000000000000000000",
    icon: "./assets/images/token-icons/ethereum.png",
    decimals: 18,
    special: true,
  },
  {
    name: "Wrapped Ether",
    symbol: "WETH",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    icon: "./assets/images/token-icons/ethereum.png",
    decimals: 18,
    special: true,
  },
  {
    name: "Tether USD",
    symbol: "USDT",
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    icon: "./assets/images/token-icons/usdt.png",
    decimals: 6,
    special: true,
  },
  {
    name: "Dai Stablecoin",
    symbol: "DAI",
    address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    icon: "./assets/images/token-icons/dai.png",
    decimals: 18,
    special: true,
  },
];

const BSCMAINNET = [
  {
    name: "BNB",
    symbol: "BNB",
    address: "0x0000000000000000000000000000000000000000",
    icon: "./assets/images/token-icons/wbnb.png",
    decimals: 18,
    special: true,
  },
  {
    name: "Wrapped BNB",
    symbol: "WBNB",
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    icon: "./assets/images/token-icons/wbnb.png",
    decimals: 18,
    special: true,
  },
  {
    name: "Tether USD",
    symbol: "USDT",
    address: "0x55d398326f99059fF775485246999027B3197955",
    icon: "./assets/images/token-icons/usdt.png",
    decimals: 18,
    special: true,
  },
  {
    name: "PancakeSwap Token",
    symbol: "CAKE",
    address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    icon: "./assets/images/token-icons/cake.png",
    decimals: 18,
    special: true,
  },
  {
    name: "Binance Pegged USD Coin",
    symbol: "USDC",
    address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    icon: "./assets/images/token-icons/usdc.png",
    decimals: 18,
    special: true,
  },
];

const BSCTESTNET = [
  {
    name: "Test BNB",
    symbol: "TBNB",
    address: "0x0000000000000000000000000000000000000000",
    icon: "./assets/images/token-icons/wtbnb.png",
    decimals: 18,
    special: true,
  },
  {
    name: "Wrapped BNB",
    symbol: "WTBNB",
    address: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    icon: "./assets/images/token-icons/wtbnb.png",
    decimals: 18,
    special: true,
  },
  {
    name: "Tether USD",
    symbol: "USDT",
    address: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
    icon: "./assets/images/token-icons/usdt.png",
    decimals: 18,
    special: true,
  },
  {
    name: "Bsw",
    symbol: "BSW",
    address: "0x462e75355385fddb19b4f1a7a6fca66cac8cc17a",
    icon: "",
    decimals: 18,
    special: true,
  },
  {
    name: "Onmax",
    symbol: "OMP",
    address: "0x73a8c51baae10844c05854b1cf542d30646fa86a",
    icon: "",
    decimals: 18,
    special: true,
  },
  {
    name: "Avax",
    symbol: "AVAX",
    address: "0x02e757d47adc8d9d099ec5f7807c1ceecfdcf443",
    icon: "",
    decimals: 18,
    special: true,
  },
  {
    name: "Small",
    symbol: "SML",
    address: "0x67d43defb18eb5f01bf6252d6dd474c0c2cd41d9",
    icon: "",
    decimals: 8,
    special: true,
  },
];

const SOLANA = [
  {
    name: "Solana",
    symbol: "SOL",
    address: "1nc1nerator11111111111111111111111111111111",
    icon: "./assets/images/token-icons/solana.svg",
    decimals: 18,
    special: true,
  },
  {
    name: "Wrapped SOL",
    symbol: "WSOL",
    address: "So11111111111111111111111111111111111111112",
    icon: "./assets/images/token-icons/solana.svg",
    decimals: 18,
    special: true,
  },
  {
    name: "Raydium",
    symbol: "RAY",
    address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
    icon: "./assets/images/token-icons/ray.webp",
    decimals: 18,
    special: true,
  },
  // {
  //   name: "Tether USD",
  //   symbol: "USDT",
  //   address: "0xdDc7447cE6CfE015e21564E075f5bC942E697989",
  //   icon: "./assets/images/token-icons/solana.svg",
  //   special: true,
  // },
];

const COINS = new Map();
COINS.set(chains.ChainId.ETHMAINNET, ETHMAINNET);
COINS.set(chains.ChainId.BSCMAINNET, BSCMAINNET);
COINS.set(chains.ChainId.BSCTESTNET, BSCTESTNET);
COINS.set(chains.ChainId.SOLANA, SOLANA);
export default COINS;
