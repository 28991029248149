import React, { useContext, useState, useEffect, useRef } from "react";
import SwapHeader from "../../../../Components/Coman/SwapHeader";
import Footer from "../../../../Components/Coman/Footer";
import { walletConnect } from "../../../../contexts/WalletConnect";
import { globalStates } from "../../../../contexts/GlobalStates";
import { getTokenAllowanceForSwap, getTokenBalance, getTokenDecimals } from "../../../../Components/Function/ethersFunctions";
import TokenModel from "../../../../Components/Coman/TokenModel";
import AddLiquidityBox from "./AddLiquidityBox";
import SwapSettingsModel from "../../../../Components/Coman/SwapSettingsModel";
import { useLocation, useSearchParams } from "react-router-dom";

export default function AddLiquidityV2() {
  const [swapBoxModalChange, setSwapBoxModalChange] = useState("");
  const dropdownRef = useRef();

  const [inputWay, setInputWay] = useState(0);

  const { provider, address, selectedRouter, inputToken, outputToken } = useContext(walletConnect);
  const { handleTokenSelectSearchParams } = useContext(globalStates);
  const { chainTokens } = useContext(globalStates);

  const [selectedTokenOne, setSelectedTokenOne] = useState(null);
  const [selectedTokenTwo, setSelectedTokenTwo] = useState(null);

  const [inputAmountOne, setInputAmountOne] = useState("");
  const [inputAmountTwo, setInputAmountTwo] = useState("");

  const handleSelecteTokneOne = (item) => {
    handleTokenSelectSearchParams(item.address?.toLowerCase(), "input");
    setSwapBoxModalChange("");
  };

  const handleSelecteTokneTwo = (item) => {
    handleTokenSelectSearchParams(item.address?.toLowerCase(), "output");
    setSwapBoxModalChange("");
  };

  const setTokenInfo = async () => {
    if (inputToken) {
      const findToken = chainTokens.find((item) => item?.address?.toLowerCase() === inputToken?.toLowerCase());
      if (findToken) {
        setSelectedTokenOne(findToken);
        if (address && provider) {
          const balance = await getTokenBalance(findToken?.address, address, provider, selectedRouter);
          const allowance = await getTokenAllowanceForSwap(findToken?.address, address, selectedRouter, provider);
          const decimals = await getTokenDecimals(findToken?.address, provider);
          setSelectedTokenOne((prev) => ({
            ...prev,
            balance,
            allowanceV2: allowance?.v2 ? allowance?.v2 : "0",
            allowanceV3: allowance?.v3 ? allowance?.v3 : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenOne(null);
      }
    } else {
      if (chainTokens[0] && outputToken?.toLowerCase() !== chainTokens[0]?.address?.toLowerCase()) {
        setSelectedTokenOne(chainTokens[0]);
        if (address && provider) {
          const balance = await getTokenBalance(chainTokens[0]?.address, address, provider, selectedRouter);
          const allowance = await getTokenAllowanceForSwap(chainTokens[0]?.address, address, selectedRouter, provider);
          const decimals = await getTokenDecimals(chainTokens[0]?.address, provider);
          setSelectedTokenOne((prev) => ({
            ...prev,
            balance,
            allowanceV2: allowance?.v2 ? allowance?.v2 : "0",
            allowanceV3: allowance?.v3 ? allowance?.v3 : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenOne(null);
      }
    }
    if (outputToken) {
      const findToken = chainTokens.find((item) => item?.address?.toLowerCase() === outputToken?.toLowerCase());
      if (findToken) {
        setSelectedTokenTwo(findToken);
        if (address && provider) {
          const balance = await getTokenBalance(findToken?.address, address, provider, selectedRouter);
          const allowance = await getTokenAllowanceForSwap(findToken?.address, address, selectedRouter, provider);
          const decimals = await getTokenDecimals(findToken?.address, provider);
          setSelectedTokenTwo((prev) => ({
            ...prev,
            balance,
            allowanceV2: allowance?.v2 ? allowance?.v2 : "0",
            allowanceV3: allowance?.v3 ? allowance?.v3 : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenTwo(null);
      }
    } else {
      setSelectedTokenTwo(null);
    }
  };

  useEffect(() => {
    if (chainTokens) {
      setTokenInfo();
    }
  }, [inputToken, outputToken, chainTokens, provider]);
  return (
    <div className=" main-vh-11 bg-dark-black dashboard-bg">
      <div className="main-wrapper ">
        <SwapHeader />
        <div className="container-wide">
          <div className="banner-wrapper py-4 mt-lg-100 mt-0 ">
            <div className="row justify-content-center ">
              <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 m-0 p-2 bg-dark-black dashboard-bg" ref={dropdownRef}>
                {swapBoxModalChange === "selectTokenModalInput" ? (
                  <div className="mx-2 p-2">
                    <TokenModel
                      setSwapBoxModalChange={setSwapBoxModalChange}
                      handleSelecteTokne={handleSelecteTokneOne}
                      selectedTokenOne={selectedTokenOne}
                      selectedTokenTwo={selectedTokenTwo}
                    />
                  </div>
                ) : swapBoxModalChange === "setting" ? (
                  <div className="mx-2 p-2">
                    <SwapSettingsModel setSwapBoxModalChange={setSwapBoxModalChange} swapBoxModalChange={swapBoxModalChange} />
                  </div>
                ) : swapBoxModalChange === "selectTokenModalOutput" ? (
                  <div className="mx-2 p-2">
                    <TokenModel
                      setSwapBoxModalChange={setSwapBoxModalChange}
                      handleSelecteTokne={handleSelecteTokneTwo}
                      selectedTokenOne={selectedTokenOne}
                      selectedTokenTwo={selectedTokenTwo}
                    />
                  </div>
                ) : (
                  <AddLiquidityBox
                    setSwapBoxModalChange={setSwapBoxModalChange}
                    selectedTokenOne={selectedTokenOne}
                    selectedTokenTwo={selectedTokenTwo}
                    inputAmountOne={inputAmountOne}
                    inputAmountTwo={inputAmountTwo}
                    setInputAmountOne={setInputAmountOne}
                    setInputAmountTwo={setInputAmountTwo}
                    inputWay={inputWay}
                    setInputWay={setInputWay}
                    setTokenInfo={setTokenInfo}
                  />
                )}
              </div>
            </div>
            {selectedTokenTwo && (
              <div className={`row pb-3 mt-1 p-2 d-flex justify-content-center ${swapBoxModalChange === "setting" ? "d-none" : ""} `}>
                <div
                  className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 m-0 p-0 rounded-20 p-3 bg-liquidity-warning "
                  style={{ lineHeight: "18px" }}
                >
                  <div className="font-xs text-start dark-text">
                    ⭐️ By adding liquidity you’ll earn 0.3% of all trades on this pair proportional to your share of the pool. Fees are
                    added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
