import React, { useContext } from "react";
import useGetAllChainInfo from "../../hooks/useGetAllChainInfo";
import { walletConnect } from "../../contexts/WalletConnect";

const SwitchChainModal = () => {
  const { isConnected, chains, chainId, switchNetwork } = useContext(walletConnect);
  return chainId !== 56 && isConnected ? (
    <>
      <div>
        <>
          <div className="row justify-content-center">
            <div className="col-10 col-sm-3 col-md-4 col-lg-4 col-xl-2 rounded-15 modal-overlay_swapToken bg-white-new">
              <div className="modal-container_swapToken bg-white-new">
                <h6 className="dark-text text-center">Switch Chain</h6>
                <hr />
                <div className="px-3 py-2 w-100">
                  {chains && chains?.length
                    ? chains
                        .filter((item) => item.chainId === 56)
                        ?.map((item, index) => {
                          return (
                            <button
                              className="dropbtn d-flex justify-content-center form-select rounded-10 rajdhani-700 px-3 font-xs text-white mt-lg-0 mt-md-1 mt-1 h-40 w-100 "
                              onClick={() => switchNetwork(item?.chainId)}
                            >
                              <img className="h-20 me-2" src={item?.icon} alt="" />
                              <span className>{item.name}</span>
                            </button>
                          );
                        })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      <div
        // className={!chains?.find((item) => item.chainId === chainId) && isConnected ? "modal-backdrop-swaptoken fade show" : "d-none"}
        className={chainId !== 56 && isConnected ? "modal-backdrop-swaptoken fade show" : "d-none"}
      ></div>
    </>
  ) : (
    ""
  );
};

export default SwitchChainModal;
