import React, { useContext, useState } from "react";
import { walletConnect } from "../../contexts/WalletConnect";
import SmallSpinner from "../../Components/Coman/SmallSpinner";
import { giveAllowance } from "../../Components/Function/ethersFunctions";
import SwapDetailsModel from "../../Components/Coman/SwapDetailsModel";
import { ethers } from "ethers";
import { formatAmount } from "../../Components/Function/commonFunction";
import { globalStates } from "../../contexts/GlobalStates";

export default function SwapConnectButton({
  selectedTokenOne,
  selectedTokenTwo,
  inputAmountOne,
  inputAmountTwo,
  tradeInfo,
  swap,
  spinner,
  setTokenInfo,
  confirmSwapModal,
  setConfirmSwapModal,
}) {
  let zeroAdd = ethers.constants.AddressZero;
  const { address, ConnectWallet, signer, selectedRouter } = useContext(walletConnect);
  const { settingData } = useContext(globalStates);

  const giveAllowanceForTokenOne = async () => {
    try {
      if (tradeInfo.version === "v2") {
        const result = await giveAllowance(
          selectedTokenOne?.address,
          selectedRouter?.routerV2,
          // Math.ceil(Number(inputAmountTwo)),
          signer
        );
        if (result) {
          setTokenInfo();
        }
      } else {
        const result = await giveAllowance(
          selectedTokenOne?.address,
          selectedRouter?.routerV3,
          // Math.ceil(Number(inputAmountTwo)),
          signer
        );
        if (result) {
          setTokenInfo();
        }
      }
    } catch (err) {
      console.log("error in giveAllowanceForTokenOne", err);
    }
  };

  return (
    <div>
      {spinner?.swapSpinner ? (
        <button className="btn header-btn rajdhani-600 rounded-10  px-4 font-sm  text-blue bg-gray-300 btn-new-color w-100 mt-3" disabled>
          <div class="nb-spinner-big"></div>
        </button>
      ) : !address ? (
        <button
          className="btn header-btn  rajdhani-700 rounded-10  px-4 font-sm  text-blue bg-wallet-2 btn-new-color w-100 mt-3"
          onClick={ConnectWallet}
        >
          <img className="h-20 me-2 " src="assets/image-2/wallet.png" alt="" />
          &nbsp;
          <span className="">Connect Wallet</span>
        </button>
      ) : !selectedTokenOne || !selectedTokenTwo ? (
        <button className="btn header-btn  rajdhani-700 rounded-10  px-4 font-sm  dark-text bg-gray-300 btn-new-color w-100 mt-3" disabled>
          <span className="">Swap</span>
        </button>
      ) : !inputAmountOne && !inputAmountTwo ? (
        <button
          className="btn header-btn  rajdhani-600 rounded-10  px-4 font-sm  dark-text bg-gray-300 text-gray-900 btn-new-color w-100 mt-3"
          disabled
        >
          <span className="">Enter an amount</span>
        </button>
      ) : !tradeInfo?.pool || !inputAmountTwo || Number(inputAmountTwo) <= 0 || !inputAmountOne || Number(inputAmountOne) <= 0 ? (
        <button
          className="btn header-btn  rajdhani-600 rounded-10  px-4 font-sm dark-text bg-gray-300 text-gray-900 btn-new-color w-100 mt-3"
          disabled
        >
          <span className="">Insufficient liquidity for this trade.</span>
        </button>
      ) : Number(inputAmountOne) > Number(selectedTokenOne?.balance) ? (
        <button
          className="btn header-btn  rajdhani-600 rounded-10  px-4 font-sm  dark-text bg-gray-300 text-gray-900 btn-new-color w-100 mt-3"
          disabled
        >
          <span className="">Insufficient {selectedTokenOne?.symbol} balance</span>
        </button>
      ) : settingData?.ExpertMode === true && formatAmount(tradeInfo?.priceImpact) >= 5 ? (
        <button
          className="btn header-btn  rajdhani-600 rounded-10  px-4 font-sm  text-white bg-gray-300 btn-red-color w-100 mt-3"
          onClick={() => setConfirmSwapModal(true)}
        >
          <span className="">Swap Anyway</span>
        </button>
      ) : settingData?.ExpertMode === false && formatAmount(tradeInfo?.priceImpact) >= 5 ? (
        <button
          className="btn header-btn  rajdhani-600 rounded-10  px-4 font-sm  dark-text bg-gray-300 text-gray-900 btn-new-color w-100 mt-3"
          disabled
        >
          <span className="">Price impact is too high</span>
        </button>
      ) : (
        // (selectedTokenOne?.address !== zeroAdd &&
        //     tradeInfo.version === "v2" &&
        //     Number(selectedTokenOne?.allowanceV2) < Number(inputAmountOne)) ||
        //   (selectedTokenOne?.address !== zeroAdd &&
        //     tradeInfo.version === "v3" &&
        //     Number(selectedTokenOne?.allowanceV3) < Number(inputAmountOne)) ? (
        //   <button
        //     className="btn header-btn  rajdhani-700 rounded-10 px-4 font-sm  dark-text bg-wallet-2 btn-new-color w-100 mt-3"
        //     onClick={giveAllowanceForTokenOne}
        //   >
        //     <span className="">Approve {selectedTokenOne?.symbol}</span>
        //   </button>
        // ) :
        <button
          className="btn header-btn  rajdhani-700 rounded-10 px-4 font-sm  text-blue bg-wallet-2 btn-new-color w-100 mt-3"
          onClick={() => setConfirmSwapModal(true)}
        >
          <span className="">Swap</span>
        </button>
      )}
      <SwapDetailsModel
        selectedTokenOne={selectedTokenOne}
        selectedTokenTwo={selectedTokenTwo}
        inputAmountOne={inputAmountOne}
        inputAmountTwo={inputAmountTwo}
        tradeInfo={tradeInfo}
        swap={swap}
        spinner={spinner}
        confirmSwapModal={confirmSwapModal}
        setConfirmSwapModal={setConfirmSwapModal}
      />
    </div>
  );
}
