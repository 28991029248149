import React, { useEffect, useRef, useState } from "react";
import SwapHeader from "../../Components/Coman/SwapHeader";
import Footer from "../../Components/Coman/Footer";
import SwapBoxContainer from "../Swap/SwapBoxContainer.jsx";
import TokenModel from "../../Components/Coman/TokenModel";
import TradingViewWidget from "./TradingViewWidget";
import ChainTokenDropdown from "../../Components/Coman/ChainTokenDropdown";
function SwapTokenChart() {
  const [showMore, setShowMore] = useState(true);
  const [isOpenSwapDropdown, setIsOpenSwapDropdown] = useState(false);

  const [isOpenAddToken, setIsOpenAddToken] = useState(false);
  const [isOpenSwapSettings, setIsOpenSwapSettings] = useState(false);
  const dropdownRef = useRef();

  const toggleModalSwapDropdown = () => {
    setIsOpenSwapDropdown(!isOpenSwapDropdown);
  };
  const toggleModalAddToken = () => {
    setIsOpenAddToken(!isOpenAddToken);
  };
  const toggleModalSwapSettings = () => {
    setIsOpenSwapSettings(!isOpenSwapSettings);
  };

  useEffect(() => {
    let handler = (e) => {
      if (!dropdownRef?.current?.contains(e.target)) {
        // setIsOpenSwapDropdown(false);
        setIsOpenAddToken(false);
        setIsOpenSwapSettings(false);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  return (
    <>
      <div>
        <div className="main-wrapper bg-dark-black  dashboard-bg vh-100">
          <SwapHeader />
          <div className="banner-wrapper style13 bg-image-none-dark py-4 bg-dark-black pt-100 bg-image-top bg-no-repeat ">
            <div className="container-wide bg-dark-black ">
              <div className="row justify-content-center">
                <>
                  <div className="row  pb-3 mt-5">
                    <div className="col-lg-7">
                      <div className="d-flex justify-content-between ">
                        <div className="d-flex justify-content-start align-items-center">
                          <img
                            className="w-30 h-30"
                            src="assets/image-2/usdt.png"
                            alt=""
                          />
                          <img
                            className="w-30 h-30 ml--13"
                            src="assets/image-2/eth.png"
                            alt=""
                          />
                          <ChainTokenDropdown />
                        </div>
                      </div>
                      {/* This is Without Graph. If Graph is not visible then this is getting. you will check it then uncomment this and press ETH Select button please...*/}
                      {/* {isOpenSwapDropdown ? (
                        <>
                          <div className="card-body light-bg dark-border border border-light border-2 rounded-10 p-3 mt-20 border-box d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center ">
                              <div className="text-center">
                                <img
                                  className="text-center"
                                  src="assets/image-2/warning.png"
                                  alt=""
                                />
                                <h6 className="text-blue mt-3">
                                  There is not enough data for this time
                                  interval. Try later
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="card-body light-bg dark-border border border-light border-2 rounded-10 p-3 mt-20 border-box2 d-flex justify-content-center align-items-center">
                            <p className="text-black font-sm m-0">
                              Even if you see that the current market price on
                              the chart matches your limit price, there is no
                              guarantee that your limit order will be
                              immediately filled. 
                              <a className="color_blue" href="#">
                                Learn more...
                              </a>
                            </p>
                          </div>
                          <div className="d-flex justify-content-between mt-5">
                            <div>
                              <button className="btn rajdhani-700 rounded-20 font-xs  h-40 ">
                                Active orders
                              </button>
                              <button className="btn rajdhani-700 rounded-20 font-xs bg-wallet h-40 width-165">
                                <i
                                  className="fa fa-circle font-small"
                                  style={{ color: "red" }}
                                ></i>
                                Connection timeout
                              </button>
                            </div>
                            <div>
                              <button className="btn rajdhani-700 rounded-6 font-xs bg-wallet pl-0 h-40">
                                <button className="btn rajdhani-700 rounded-6 font-xs bg-white mx-1 mr-15 h-30 ">
                                  Limited orders
                                </button>
                                Orders history
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <img
                          className="w-100 mt-20"
                          ref={dropdownRef}
                          src="assets/image-2/graph.png"
                          alt=""
                        />
                      )} */}
                      <div className="mt-3">
                        <TradingViewWidget />
                      </div>
                    </div>
                    <div
                      className="col-lg-5"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {isOpenSwapDropdown ? (
                        <TokenModel
                          isOpenSwapDropdown={isOpenSwapDropdown}
                          setIsOpenSwapDropdown={setIsOpenSwapDropdown}
                          toggleModalSwapDropdown={toggleModalSwapDropdown}
                        />
                      ) : (
                        <SwapBoxContainer
                          showMore={showMore}
                          setShowMore={setShowMore}
                          isOpenSwapDropdown={isOpenSwapDropdown}
                          setIsOpenSwapDropdown={setIsOpenSwapDropdown}
                          dropdownRef={dropdownRef}
                          toggleModalSwapDropdown={toggleModalSwapDropdown}
                          isOpenAddToken={isOpenAddToken}
                          setIsOpenAddToken={setIsOpenAddToken}
                          toggleModalAddToken={toggleModalAddToken}
                          isOpenSwapSettings={isOpenSwapSettings}
                          setIsOpenSwapSettings={setIsOpenSwapSettings}
                          toggleModalSwapSettings={toggleModalSwapSettings}
                        />
                      )}
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default SwapTokenChart;
