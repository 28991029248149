import React from "react";

function ComingSoonModel({ showComingSoon, toggleShowComingSoon }) {
  return (
    <>
      {showComingSoon ? (
        <>
          <div className="row justify-content-center">
            <div className="col-10 col-sm-3 col-md-4 col-lg-4 col-xl-2 rounded-15 modal-overlay_ComingSoon bg-white-new">
              <div
                className="modal-container_swapToken bg-white-new"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="d-flex justify-content-end">
                  <i
                    className="bi bi-x-lg pointer dark-text"
                    onClick={() => toggleShowComingSoon()}
                  ></i>
                </div>
                <div className="mt-4">
                  <h5 className="d-flex justify-content-center dark-text">
                    <b>Coming Soon!</b>
                  </h5>
                </div>
                <div className="mt-4">
                  <span className="d-flex text-center dark-text font-sm">
                    This feature is currently undergoing development and will be
                    available soon. We appreciate your patience and
                    understanding as we work to you an improved experience.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop-swaptoken fade show"></div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default ComingSoonModel;
