import React, { useContext, useEffect, useRef, useState } from "react";
import {
  getFactory,
  getTokenAllowanceForSwap,
  getTokenBalance,
  getTokenDecimals,
  getWethV2Address,
  giveAllowance,
} from "../../../../Components/Function/ethersFunctions";
import { globalStates } from "../../../../contexts/GlobalStates";
import { walletConnect } from "../../../../contexts/WalletConnect";
import SwapHeader from "../../../../Components/Coman/SwapHeader";
import SwapSettingsModel from "../../../../Components/Coman/SwapSettingsModel";
import RemoveLiquidityV2Box from "./RemoveLiquidityV2Box";
import { useSearchParams } from "react-router-dom";
import {
  getUsersReserveTokensForV2,
  getV2PerticularPairFromLocalStorage,
  removeLiquidityInV2,
} from "../../../../Components/Function/liquidityFunctions";
import Footer from "../../../../Components/Coman/Footer";
import ApproveModal from "../../../../Components/Coman/ApproveModal";
import RemoveLiquidityInfoModel from "../../../../Components/Coman/RemoveLiquidityInfoModel";
import LiquidityConfirmModel from "../../../../Components/Coman/LiquidityConfirmModel";
import LiquidityTxSubmittedModel from "../../../../Components/Coman/LiquidityTxSubmittedModel";
import { formatAmount } from "../../../../Components/Function/commonFunction";

function RemoveLiquidityV2() {
  const [swapBoxModalChange, setSwapBoxModalChange] = useState("");
  const dropdownRef = useRef();
  const { provider, address, selectedRouter, inputToken, outputToken, selectedChain, signer } = useContext(walletConnect);
  const { handleTokenSelectSearchParams } = useContext(globalStates);
  const { chainTokens } = useContext(globalStates);

  const [selectedTokenOne, setSelectedTokenOne] = useState(null);
  const [selectedTokenTwo, setSelectedTokenTwo] = useState(null);
  const { settingData } = useContext(globalStates);

  const [searchParams, setSearchParams] = useSearchParams();
  const inputAddress = searchParams.get("inputAddress") || "";
  const outputAddress = searchParams.get("outputAddress") || "";

  const [amountOut0, setAmountOut0] = useState("");
  const [amountOut1, setAmountOut1] = useState("");

  const [Value, setValue] = useState(0);
  const handleSliderChange = (event) => {
    setValue(event.target.value);
  };

  const [loading, steLoading] = useState(false);
  const [pair, setPair] = useState(null);

  const [spinner, setSpinner] = useState({
    removeLiquidityModal: false,
    addLiquiTxHash: "",
    addLiquiSpinner: false,
    addLiquiTxSubModal: false,
    approveToken: false,
  });

  const getStoredLiquidity = async () => {
    try {
      steLoading(true);
      const pairs = getV2PerticularPairFromLocalStorage(selectedChain?.chainId, inputAddress, outputAddress);
      if (pairs) {
        const factory = await getFactory(selectedChain?.routers[0], "v2", provider);
        const weth = await getWethV2Address(selectedChain?.routers[0], provider);
        const userData = await getUsersReserveTokensForV2(
          factory,
          address,
          weth,
          pairs?.token0,
          pairs?.token1,
          provider,
          selectedChain?.routers[0]
        );
        if (userData) {
          setPair({
            ...pairs,
            share: userData?.share,
            token0Amount: userData?.token0Amount,
            token1Amount: userData?.token1Amount,
            lptokens: userData?.lptokens,
            reserve: userData?.reserve,
            allowance: userData?.allowance,
            pairAddress: userData?.pairAddress,
            symbol: userData?.symbol,
            decimals: userData?.decimals,
            lptokenswei: userData?.lptokenswei,
            pool0: userData?.pool0,
            pool1: userData?.pool1,
          });
          steLoading(false);
        } else {
          setPair();
          steLoading(false);
        }
      } else {
        setPair(null);
        steLoading(false);
      }
    } catch (err) {
      setPair(null);
      steLoading(false);
    }
  };

  useEffect(() => {
    if (inputAddress && outputAddress && selectedChain) {
      getStoredLiquidity();
    } else {
      setPair(null);
      steLoading(false);
    }
  }, [inputAddress, outputAddress, selectedChain]);

  const calculateOutTokens = () => {
    try {
      if (Number(Value) > 0) {
        let amount0 = (Number(pair?.token0Amount) * Number(Value)) / 100;
        let amount1 = (Number(pair?.token1Amount) * Number(Value)) / 100;
        setAmountOut0(amount0);
        setAmountOut1(amount1);
      } else {
        setAmountOut0("0");
        setAmountOut1("0");
      }
    } catch {}
  };

  const approveToken = async () => {
    try {
      setSpinner((prev) => ({ ...prev, approveToken: true }));
      const result = await giveAllowance(pair?.pairAddress, selectedChain?.routers[0].routerV2, null, signer);
      if (result?.hash) {
        getStoredLiquidity();
        setSpinner((prev) => ({ ...prev, approveToken: false }));
      } else {
        setSpinner((prev) => ({ ...prev, approveToken: false }));
      }
    } catch {
      setSpinner((prev) => ({ ...prev, approveToken: false }));
    }
  };

  const removeLiquidity = async () => {
    try {
      setSpinner((prev) => ({
        ...prev,
        removeLiquidityModal: false,
        addLiquiSpinner: true,
      }));
      const result = await removeLiquidityInV2(
        pair?.token0,
        pair?.token1,
        pair?.pool0,
        pair?.pool1,
        amountOut0,
        amountOut1,
        (Number(pair?.lptokens) * Value) / 100,
        selectedChain?.routers[0],
        address,
        provider,
        settingData?.slippage,
        settingData?.txDeadline,
        pair,
        Value
      );
      if (result?.hash) {
        getStoredLiquidity();
        setSpinner((prev) => ({
          ...prev,
          addLiquiSpinner: false,
          addLiquiTxSubModal: true,
          addLiquiTxHash: result?.hash,
        }));
      } else {
        setSpinner((prev) => ({
          ...prev,
          removeLiquidityModal: true,
          addLiquiSpinner: false,
          addLiquiTxHash: "",
        }));
      }
    } catch (err) {
      console.log("error in addLiquidity : ", err);
      setSpinner((prev) => ({
        ...prev,
        removeLiquidityModal: true,
        addLiquiSpinner: false,
        addLiquiTxHash: "",
      }));
    }
  };

  useEffect(() => {
    if (pair) {
      calculateOutTokens();
    }
  }, [Value, pair]);

  const handleSelecteTokneOne = (item) => {
    handleTokenSelectSearchParams(item.address?.toLowerCase(), "input");
    setSwapBoxModalChange("");
  };

  const handleSelecteTokneTwo = (item) => {
    handleTokenSelectSearchParams(item.address?.toLowerCase(), "output");
    setSwapBoxModalChange("");
  };

  const setTokenInfo = async () => {
    if (inputToken) {
      const findToken = chainTokens.find((item) => item?.address?.toLowerCase() === inputToken?.toLowerCase());
      if (findToken) {
        setSelectedTokenOne(findToken);
        if (address && provider) {
          const balance = await getTokenBalance(findToken?.address, address, provider, selectedRouter);
          const allowance = await getTokenAllowanceForSwap(findToken?.address, address, selectedRouter, provider);
          const decimals = await getTokenDecimals(findToken?.address, provider);
          setSelectedTokenOne((prev) => ({
            ...prev,
            balance,
            allowanceV2: allowance?.v2 ? allowance?.v2 : "0",
            allowanceV3: allowance?.v3 ? allowance?.v3 : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenOne(null);
      }
    } else {
      if (chainTokens[0] && outputToken?.toLowerCase() !== chainTokens[0]?.address?.toLowerCase()) {
        setSelectedTokenOne(chainTokens[0]);
        if (address && provider) {
          const balance = await getTokenBalance(chainTokens[0]?.address, address, provider, selectedRouter);
          const allowance = await getTokenAllowanceForSwap(chainTokens[0]?.address, address, selectedRouter, provider);
          const decimals = await getTokenDecimals(chainTokens[0]?.address, provider);
          setSelectedTokenOne((prev) => ({
            ...prev,
            balance,
            allowanceV2: allowance?.v2 ? allowance?.v2 : "0",
            allowanceV3: allowance?.v3 ? allowance?.v3 : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenOne(null);
      }
    }
    if (outputToken) {
      const findToken = chainTokens.find((item) => item?.address?.toLowerCase() === outputToken?.toLowerCase());
      if (findToken) {
        setSelectedTokenTwo(findToken);
        if (address && provider) {
          const balance = await getTokenBalance(findToken?.address, address, provider, selectedRouter);
          const allowance = await getTokenAllowanceForSwap(findToken?.address, address, selectedRouter, provider);
          const decimals = await getTokenDecimals(findToken?.address, provider);
          setSelectedTokenTwo((prev) => ({
            ...prev,
            balance,
            allowanceV2: allowance?.v2 ? allowance?.v2 : "0",
            allowanceV3: allowance?.v3 ? allowance?.v3 : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenTwo(null);
      }
    } else {
      setSelectedTokenTwo(null);
    }
  };

  useEffect(() => {
    if (chainTokens) {
      setTokenInfo();
    }
  }, [inputToken, outputToken, chainTokens, provider]);
  return (
    <>
      <div className=" main-vh-11 bg-dark-black dashboard-bg">
        <div className="main-wrapper ">
          <SwapHeader />
          <div className="container-wide">
            <div className="banner-wrapper py-4 mt-lg-100 mt-0 ">
              <div className="row justify-content-center px-sm-2 px-md-0 px-2 ">
                {loading ? (
                  <div className="col-xl-4 col-lg-5 col-md-7 col-sm-10  mt-2 dark-text border-light rounded-10 p-2 d-flex justify-content-center align-items-center">
                    <div className="text-center ">Loading...</div>
                  </div>
                ) : !pair ? (
                  <div className="col-xl-4 col-lg-5 col-md-7 col-sm-10  mt-2 dark-text border-light rounded-10 p-2 d-flex justify-content-center align-items-center">
                    <div className="text-center ">data not found</div>
                  </div>
                ) : (
                  <>
                    <div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 m-0 p-0 bg-dark-black dashboard-bg " ref={dropdownRef}>
                      {swapBoxModalChange === "setting" ? (
                        <div className="mx-2 px-2">
                          <SwapSettingsModel setSwapBoxModalChange={setSwapBoxModalChange} swapBoxModalChange={swapBoxModalChange} />
                        </div>
                      ) : (
                        <div className="m-0 p-3 border dark-border rounded-20">
                          <RemoveLiquidityV2Box
                            setSwapBoxModalChange={setSwapBoxModalChange}
                            pair={pair}
                            Value={Value}
                            setValue={setValue}
                            handleSliderChange={handleSliderChange}
                            amountOut0={amountOut0}
                            amountOut1={amountOut1}
                            settingData={settingData}
                            approveToken={approveToken}
                            setSpinner={setSpinner}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className={`row justify-content-center m-0 p-0
                         ${swapBoxModalChange === "setting" ? "d-none" : ""}`}
                    >
                      <div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 m-0 p-0">
                        <div className="card-body mt-2 rounded-15 p-2 px-3 bg-liquidity-warning">
                          <p className="font-xs rajdhani-600  dark-text mb-0">Lg tokens in your wallet</p>
                          <div className="d-flex mb-1 justify-content-between align-items-center mt-2 ">
                            <div className="text-gray-700 rajdhani-400 mb-0 ">
                              <p className="d-flex align-items-center rajdhani-400 dark-text mb-0 ">
                                {pair?.token0?.icon ? (
                                  <img src={pair?.token0?.icon} alt="user" className="h-26 rounded-circle me-1" />
                                ) : (
                                  <svg
                                    viewBox="0 0 24 24"
                                    class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl me-1"
                                    color="text"
                                    width="26px"
                                    height="26px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                                  </svg>
                                )}
                                {pair?.token1?.icon ? (
                                  <img src={pair?.token1?.icon} alt="user" className="h-26 rounded-circle mx-1" />
                                ) : (
                                  <svg
                                    viewBox="0 0 24 24"
                                    class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                                    color="text"
                                    width="26px"
                                    height="26px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                                  </svg>
                                )}
                                <span className="mx-1 opacity-50">
                                  {pair?.token0?.symbol} - {pair?.token1?.symbol} LP
                                </span>
                              </p>
                            </div>
                            <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
                              <div>
                                <p className="rajdhani-400 dark-text mb-0">
                                  <span>{formatAmount(pair?.lptokens)}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mb-1 justify-content-between align-items-top mt-1 ">
                            <div className="text-gray-700 rajdhani-400 mb-0 dark-text">
                              <p className="rajdhani-400 mb-0 opacity-50">Share in Trading Pair</p>
                            </div>
                            <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
                              <div>
                                <p className="rajdhani-400 dark-text mb-0">
                                  <span>{formatAmount(pair?.share)}%</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mb-1 justify-content-between align-items-top mt-1 ">
                            <div className="text-gray-700 rajdhani-400 mb-0 dark-text">
                              <p className="rajdhani-400 mb-0 opacity-50">Pooled {pair?.token0?.symbol}</p>
                            </div>
                            <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
                              <div>
                                <p className="rajdhani-400 dark-text mb-0">
                                  <span>{formatAmount(pair?.token0Amount)}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mb-1 justify-content-between align-items-top mt-1 ">
                            <div className="text-gray-700 rajdhani-400 mb-0 dark-text">
                              <p className="rajdhani-400 mb-0 opacity-50">Pooled {pair?.token1?.symbol}</p>
                            </div>
                            <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
                              <div>
                                <p className="rajdhani-400 dark-text mb-0">
                                  <span>{formatAmount(pair?.token1Amount)}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ApproveModal spinner={spinner?.approveToken} selectedToken={{ symbol: pair?.symbol }} />
        <RemoveLiquidityInfoModel
          spinner={spinner}
          setSpinner={setSpinner}
          pair={pair}
          amountOut0={amountOut0}
          amountOut1={amountOut1}
          Value={Value}
          removeLiquidity={removeLiquidity}
        />
        <LiquidityConfirmModel
          text="Removing"
          spinner={spinner}
          setSpinner={setSpinner}
          selectedTokenOne={pair?.token0}
          selectedTokenTwo={pair?.token1}
          inputAmountOne={amountOut0}
          inputAmountTwo={amountOut1}
        />
        <LiquidityTxSubmittedModel spinner={spinner} setSpinner={setSpinner} />
      </div>
    </>
  );
}

export default RemoveLiquidityV2;
