import { SupportedChains, chainInfo } from "../constants/chains";
import WalletChains from "../constants/WalletChains";

const useGetAllChainInfo = () => {
  // let chains = Array.from(chainInfo, ([key, value]) => value);
  let chains = [];
  chains = SupportedChains.map((item) => {
    let wallchain = WalletChains.find((item2) => item2.chainId === item);
    let chain = chainInfo.get(item);
    if (wallchain) {
      return { ...wallchain, ...chain };
    } else {
      return { ...chain };
    }
  });
  return chains;
};

export default useGetAllChainInfo;
