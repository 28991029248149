import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Components/Coman/Footer";
import SwapHeader from "../../../Components/Coman/SwapHeader";

import { walletConnect } from "../../../contexts/WalletConnect";
import V2PoolLiquidity from "./V2PoolLiquidity";
import {
  getUsersReserveTokensForV2,
  getV2PairFromLocalStorage,
} from "../../../Components/Function/liquidityFunctions";
import {
  getFactory,
  getWethV2Address,
} from "../../../Components/Function/ethersFunctions";

function V2Pool() {
  const { isConnected, address, selectedChain, provider } =
    useContext(walletConnect);
  const [showMore, setShowMore] = useState(0);
  const [loading, steLoading] = useState(false);
  const [pairs, setPairs] = useState(null);

  const handleClickOutside1 = (event) => {
    if (!event.target.closest(".dropdown-1")) {
      setShowMore(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside1);
    return () => {
      window.removeEventListener("click", handleClickOutside1);
    };
  }, []);

  const getStoredLiquidity = async () => {
    try {
      steLoading(true);
      const pairs = getV2PairFromLocalStorage(selectedChain?.chainId);
      const removablePairFromLocal = [];
      if (pairs) {
        const factory = await getFactory(
          selectedChain?.routers[0],
          "v2",
          provider
        );
        const weth = await getWethV2Address(
          selectedChain?.routers[0],
          provider
        );
        const updatedPairData = [];
        for (let key in pairs) {
          const userData = await getUsersReserveTokensForV2(
            factory,
            address,
            weth,
            pairs[key]?.token0,
            pairs[key]?.token1,
            provider
          );
          if (Number(userData?.lptokens) > 0) {
            await updatedPairData.push({
              ...pairs[key],
              share: userData?.share,
              token0Amount: userData?.token0Amount,
              token1Amount: userData?.token1Amount,
            });
          } else {
            removablePairFromLocal.push(
              pairs[key]?.token0?.address?.toLowerCase() +
                ":" +
                pairs[key]?.token1?.address?.toLowerCase()
            );
          }
        }
        setPairs([...updatedPairData]);
        steLoading(false);
      } else {
        setPairs(null);
        steLoading(false);
      }
    } catch (err) {
      setPairs(null);
      steLoading(false);
    }
  };

  useEffect(() => {
    if (isConnected && selectedChain) {
      getStoredLiquidity();
    } else {
      setPairs(null);
      steLoading(false);
    }
  }, [isConnected, selectedChain]);

  return (
    <>
      <div className="bg-dark-black dashboard-bg  main-vh-11">
        <div className="main-wrapper ">
          <SwapHeader />
          <div className="banner-wrapper py-4 mt-lg-100 mt-0 bg-dark-black dashboard-bg">
            <div className="container-wide">
              <div className="row justify-content-center">
                <div className="row  pb-3 mt-5 d-flex justify-content-center">
                  <div className="col-lg-7 px-sm-0 px-md-2 px-0">
                    <div className="d-flex mb-0 justify-content-between align-items-center flex-wrap">
                      <div className="d-flex gap-2 justify-content-start align-items-center ">
                        <h5 className="text-gray-900 rajdhani-700 mt-3 pb-2 dark-text">
                          Your V2 liquidity
                        </h5>
                        <label className="dropdown-1 bg-wallet-2 rounded-10 ">
                          <div
                            className="dd-button-1 px-2 py-1 bg-wallet-2 text-blue  rajdhani-700  font-sm"
                            onClick={() => setShowMore(!showMore)}
                          >
                            v2{" "}
                            <span
                              className={`text-blue arrow2 ${
                                !showMore ? "down" : "up"
                              }`}
                            ></span>
                          </div>
                          <input
                            type="checkbox"
                            className="dd-input-1 d-none"
                            id="test"
                          />
                          <ul
                            className={`dd-menu-1 bg-wallet-2  rounded-10 border-light-1  p-2 ${
                              showMore ? "show" : "d-none"
                            }`}
                          >
                            <li className="px-2">
                              <Link to="/v3pool" className="text-blue">
                                <svg
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  className="text-blue mx-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.2971 7.20007H12.4971V3.19995C12.4971 1.43263 13.9298 0 15.6971 0C17.4644 0 18.8972 1.43263 18.8972 3.19995H17.2971C17.2971 2.31631 16.5807 1.60005 15.6971 1.60005C14.8134 1.60005 14.0972 2.31631 14.0972 3.19995V12.0001H12.4971V8.80012H5.2971V10.4001H3.69705V3.20002C3.69705 1.4327 5.12982 6.80089e-05 6.89715 6.80089e-05C8.66447 6.80089e-05 10.0971 1.4327 10.0971 3.20002H8.49705C8.49705 2.31638 7.78079 1.60011 6.89715 1.60011C6.0135 1.60011 5.2971 2.31638 5.2971 3.20002V7.20007ZM1.59424 14.3428L1 12.8573C3.85069 11.717 6.97772 11.717 10.3501 12.8411C13.3778 13.8503 16.1173 13.8503 18.5999 12.8573L19.1942 14.3428C16.3435 15.4831 13.2164 15.4831 9.84407 14.3589C6.81633 13.3497 4.07687 13.3497 1.59424 14.3428ZM1.59424 18.3426L1 16.8571C3.85069 15.7168 6.97772 15.7168 10.3501 16.841C13.3778 17.8502 16.1173 17.8502 18.5999 16.8571L19.1942 18.3426C16.3435 19.4829 13.2164 19.4829 9.84407 18.3588C6.81633 17.3497 4.07687 17.3497 1.59424 18.3426Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                v3 pools
                              </Link>
                            </li>
                          </ul>
                        </label>
                      </div>
                      <div className="d-flex gap-2 justify-content-start align-items-center">
                        <Link
                          to="/addLiquidity-v2"
                          className="btn rajdhani-600 rounded-10 font-xs text-blue border-blue p-2 px-sm-2"
                        >
                          <span>Create a pair</span>
                        </Link>
                        <Link
                          to="/importv2pool"
                          className="btn rajdhani-600 rounded-10 bg-l-button text-white font-xs b-none p-2 px-sm-2"
                        >
                          <span>Import pool</span>
                        </Link>
                        <Link to="/addLiquidity-v2">
                          <button className="btn rajdhani-600 rounded-10 bg-l-button text-white font-xs b-none p-2 px-sm-2">
                            <span>Add V2 liquidity</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                    {isConnected ? (
                      loading ? (
                        <div className="card-body mt-2 dark-text border-light rounded-10 p-2 border-box d-flex justify-content-center align-items-center">
                          <div className="text-center ">Loading...</div>
                        </div>
                      ) : pairs && pairs?.length ? (
                        pairs?.map((item, index) => {
                          return <V2PoolLiquidity item={item} index={index} />;
                        })
                      ) : (
                        <div className="card-body mt-2 dark-text border-light rounded-10 p-2 border-box d-flex justify-content-center align-items-center">
                          <div className="text-center ">
                            No liquidity found.
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="card-body mt-2 dark-text border-light rounded-10 p-2 border-box d-flex justify-content-center align-items-center">
                        <div className="text-center ">
                          Connect to a wallet to view your liquidity.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default V2Pool;
