import React, { useContext, useEffect, useState } from "react";
import { formatName } from "../Function/commonFunction";
import { walletConnect } from "../../contexts/WalletConnect";
import SmallSpinner from "../Coman/SmallSpinner";
import { globalStates } from "../../contexts/GlobalStates";
import ComingSoonModel from "./ComingSoonModel";

export default function ChainDropdown() {
  const { chains, selectedChain, isConnected, setSelectedChain, switchNetwork, setProvider, setSigner, getAndSetProvder } =
    useContext(walletConnect);
  const { handleTokenSelectSearchParams, setUrlChain, toggleShowComingSoon } = useContext(globalStates);
  const [showDropdown, setShowDropdown] = useState(false);

  const [switchChainSpinner, setSwitchChainSpinner] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const switchChain = async (item) => {
    try {
      // if (item?.chainId === 56 ) {
      if (item?.chainId === 56 || item?.chainId === 97) {
        setSwitchChainSpinner(item.chainId);
        if (isConnected) {
          await switchNetwork(item.chainId);
          setUrlChain(item?.urlname);
          setProvider(null);
          setSigner(null);
          getAndSetProvder();
          handleTokenSelectSearchParams(null, "chainswitch");
          setShowDropdown(false);
          setSwitchChainSpinner(false);
        } else {
          if (item) {
            const found = chains.find((chain) => chain.chainId === item.chainId);
            if (found) {
              setSelectedChain(found);
              setUrlChain(found?.urlname);
              handleTokenSelectSearchParams(null, "chainswitch");
              setProvider(null);
              setSigner(null);
              getAndSetProvder();
              setShowDropdown(false);
              setSwitchChainSpinner(false);
            }
          }
        }
      } else {
        setShowDropdown(false);
        toggleShowComingSoon();
      }
    } catch {
      setSwitchChainSpinner(false);
    }
  };

  return (
    <>
      <div className="dropdown d-flex align-items-center">
        <button
          onClick={toggleDropdown}
          className="dropbtn form-select rounded-10 rajdhani-700 px-3 font-xs text-white mt-lg-0 mt-md-1  mt-1 h-40"
        >
          {selectedChain ? (
            <>
              <img className="h-20 me-2 " src={selectedChain?.icon} alt="" />
              <div className="d-sm-none d-md-none d-lg-block d-xl-block d-xl-block d-none">{formatName(selectedChain?.name)}</div>
            </>
          ) : (
            <>
              <img className="h-20" src="./assets/images/chain-warning.png" alt="" />
              <div className="d-sm-none d-md-none d-lg-block d-xl-block d-xl-block d-none dark-text">Network</div>
            </>
          )}
          <span className={`arrow ${showDropdown ? "up" : "down"}`}></span>
        </button>
        <div className={`dropdown-content bg-white-new ${showDropdown ? "show" : ""}`}>
          {chains?.length
            ? chains?.map((item) => {
                return (
                  <div className=" m-2">
                    <button
                      className="btn d-flex justify-content-between w-100 p-2 dark-text hover_question"
                      key={item.chainId}
                      onClick={() => switchChain(item)}
                    >
                      <div className="d-flex">
                        <img className="h-20 me-2" src={item.icon} alt="" />
                        {formatName(item.name, 12)}&nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                      {switchChainSpinner === item.chainId ? (
                        <SmallSpinner />
                      ) : !switchChainSpinner && selectedChain?.chainId === item.chainId ? (
                        <img className="h-20" src="./assets/images/check.png" alt="" />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      <div className={showDropdown ? "modal-backdrop fade show d-lg-none d-md-none d-sm-none d-block" : "d-none"}></div>
    </>
  );
}
