import React, { useState, useContext, useEffect } from "react";
import Footer from "../../../../Components/Coman/Footer";
import SwapHeader from "../../../../Components/Coman/SwapHeader";
import { Link, useSearchParams } from "react-router-dom";
import UserLiquidityBox from "./UserLiquidityBox";
import PriceBox from "./PriceBox";
import { walletConnect } from "../../../../contexts/WalletConnect";
import {
  findTokenForImport,
  getFactory,
  getNonfungiblePositionManagerContract,
  getTokenDecimals,
  getTokenName,
  getTokenSymbol,
  getV3Pool,
  getWethV3Address,
} from "../../../../Components/Function/ethersFunctions";
import { constants } from "ethers";
import {
  collectLiquidityFee,
  getAmountsForPosition,
  getCollectableFee,
  getMinAndMaxPrices,
  getPriceFromSqrtPrice,
  getPriceFromTick1,
} from "../../../../Components/Function/liquidityFunctions";
import { globalStates } from "../../../../contexts/GlobalStates";
import ClaimFeeModal from "../../../../Components/Coman/ClaimFeeModal";
import CollectFeeConfirmModel from "../../../../Components/Coman/CollectFeeConfirmModel";
import CollectFeeTxSubmittedModel from "../../../../Components/Coman/CollectFeeTxSubmittedModel";

export default function V3LiquidityInfo() {
  const { provider, selectedChain, address, signer, chainId } = useContext(walletConnect);
  const { chainTokens } = useContext(globalStates);

  const [status, setStatus] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("id") || "");

  const [poolData, setPoolData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [collectFeeLoading, setCollectFeeLoading] = useState(0);
  const [collectFeeHash, setCollectFeeHash] = useState("");

  const [amount0, setAmount0] = useState("0");
  const [amount1, setAmount1] = useState("0");

  const [token0, setToken0] = useState(null);
  const [token1, setToken1] = useState(null);

  const [minPrice, setMinPrice] = useState({ minPrice: "0", minPriceRev: "0" });
  const [maxPrice, setMaxPrice] = useState({
    maxPrice: "0",
    maxPriceRev: "0",
  });

  const [feeAmount0, setFeeAmount0] = useState("0");
  const [feeAmount1, setFeeAmount1] = useState("0");

  const [currPrice, setCurrPrice] = useState("0");

  const [swap, setSwap] = useState(true);

  const getPositionDataById = async () => {
    try {
      setLoading(true);
      const positionContract = await getNonfungiblePositionManagerContract(selectedChain?.routers[0].NonfungiblePositionManager, provider);
      const weth = await getWethV3Address(selectedChain?.routers[0], provider);
      const factory = await getFactory(selectedChain?.routers[0], "v3", provider);
      const position = await positionContract.positions(id);
      if (position) {
        const poolAddress = await factory.getPool(position?.token0, position?.token1, position?.fee);
        const pool = await getV3Pool(poolAddress, provider);
        const slot0 = await pool?.slot0();
        const sqrtPriceX96 = slot0?.sqrtPriceX96;
        let token0;
        if (position?.token0?.toLowerCase() === weth?.toLowerCase()) {
          const find = chainTokens?.find((item) => item.address?.toLowerCase() === constants?.AddressZero?.toLowerCase());
          token0 = find;
        } else {
          const find = chainTokens?.find((item) => item.address?.toLowerCase() === position?.token0?.toLowerCase());
          if (find) {
            token0 = find;
          } else {
            const { address, symbol, name, decimals } = await findTokenForImport(position?.token0, provider);
            token0 = {
              address: position?.token0?.toLowerCase(),
              decimals,
              name,
              symbol,
              icon: "",
            };
          }
        }
        let token1;
        if (position?.token1?.toLowerCase() === weth?.toLowerCase()) {
          const find = chainTokens?.find((item) => item.address?.toLowerCase() === constants?.AddressZero?.toLowerCase());
          token1 = find;
        } else {
          const find = chainTokens?.find((item) => item.address?.toLowerCase() === position?.token1?.toLowerCase());
          if (find) {
            token1 = find;
          } else {
            const { address, symbol, name, decimals } = await findTokenForImport(position?.token1, provider);
            token1 = {
              address: position?.token1?.toLowerCase(),
              decimals,
              name,
              symbol,
              icon: "",
            };
          }
        }
        const price = getPriceFromSqrtPrice(sqrtPriceX96, token0?.decimals, token1?.decimals);
        const amount = await getAmountsForPosition(position, slot0, token0, token1);
        if (amount) {
          setAmount0(amount?.amount0);
          setAmount1(amount?.amount1);
        }
        setCurrPrice(price);
        let prices = getMinAndMaxPrices(position?.tickLower, position?.tickUpper, token0?.decimals, token1?.decimals);

        if (price?.price >= prices?.minPrice && price?.price <= prices?.maxPrice) {
          setStatus(1);
        } else if (amount?.amount0 <= 0 && amount?.amount1 <= 0) {
          setStatus(2);
        }
        setToken0(token0);
        setToken1(token1);
        setMinPrice({
          minPrice: prices?.minPrice,
          minPriceRev: prices?.minPriceRev,
        });
        setMaxPrice({
          maxPrice: prices?.maxPrice,
          maxPriceRev: prices?.maxPriceRev,
        });
        setPoolData({
          id,
          fee: position?.fee,
        });
        await getcollectableFeeAmouts(token0?.decimals, token1?.decimals);
        setLoading(false);
      } else {
        setAmount0("0");
        setAmount1("0");
        setMinPrice({
          minPrice: "0",
          minPriceRev: "0",
        });
        setMaxPrice({
          maxPrice: "0",
          maxPriceRev: "0",
        });
        setCurrPrice("0");
        setPoolData(null);
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      console.log("error in getPositionDataById", err);
      setAmount0("0");
      setAmount1("0");
      setMinPrice({
        minPrice: "0",
        minPriceRev: "0",
      });
      setMaxPrice({
        maxPrice: "0",
        maxPriceRev: "0",
      });
      setCurrPrice("0");
      setPoolData(null);
      setLoading(false);
    }
  };

  const getcollectableFeeAmouts = async (decimals0, decimals1) => {
    try {
      if (id && address && signer && decimals0 && decimals1) {
        const positionContract = await getNonfungiblePositionManagerContract(selectedChain?.routers[0].NonfungiblePositionManager, signer);
        const feeData = await getCollectableFee(positionContract, id, address, decimals0, decimals1);
        if (feeData) {
          setFeeAmount0({ amount: feeData?.amount0, amountWei: feeData?.amount0Wei });
          setFeeAmount1({ amount: feeData?.amount1, amountWei: feeData?.amount1Wei });
        } else {
          setFeeAmount0("0");
          setFeeAmount1("0");
        }
      }
    } catch (err) {
      console.log("erorr in getcollectableFeeAmouts : ", err);
      setFeeAmount0("0");
      setFeeAmount1("0");
    }
  };

  useEffect(() => {
    if (id && provider && selectedChain && chainTokens?.length && address && chainId === selectedChain?.chainId) {
      if (selectedChain?.currency?.toLowerCase() === chainTokens[0]?.symbol?.toLowerCase()) {
        getPositionDataById();
      }
    }
  }, [id, provider, selectedChain, chainTokens, address, chainId]);

  const swapTokenAndPrices = () => {
    setToken0(token1);
    setToken1(token0);
    setAmount0(amount1);
    setAmount1(amount0);
    setFeeAmount0(feeAmount1);
    setFeeAmount1(feeAmount0);
    setSwap(!swap);
  };

  const collectFee = async () => {
    try {
      setCollectFeeLoading(2);
      if (id && signer && address) {
        const positionContract = await getNonfungiblePositionManagerContract(selectedChain?.routers[0].NonfungiblePositionManager, signer);
        const result = await collectLiquidityFee(token0, token1, positionContract, id, address, feeAmount0, feeAmount1);
        if (result?.hash) {
          setCollectFeeLoading(3);
          setCollectFeeHash(result?.hash);
          getPositionDataById();
        } else {
          setCollectFeeLoading(0);
          setCollectFeeHash("");
        }
      } else {
        setCollectFeeLoading(0);
        setCollectFeeHash("");
      }
    } catch (err) {
      console.log("error in collectFee : ", err);
      setCollectFeeLoading(0);
      setCollectFeeHash("");
    }
  };

  return (
    <div>
      <div className="bg-dark-black dashboard-bg  main-vh-11 ">
        <SwapHeader />
        <div className="banner-wrapper py-4  pt-lg-100 pt-0 ">
          <div className="container-wide">
            <div className="row mt-5 d-flex justify-content-center px-sm-2 px-md-0 px-2 ">
              <div className="col-xl-7 col-lg-8 col-md-11 col-sm-12 pb-3 bg-dark-black  dashboard-bg  rounded-15">
                {loading ? (
                  <div className="card-body mt-2 dark-text border-light rounded-10 p-2 border-box d-flex justify-content-center align-items-center">
                    <div className="text-center ">Loading...</div>
                  </div>
                ) : (
                  <>
                    <div className="d-flex align-items-center mb-0 pt-3 mb-3">
                      <p className="text-gray-900 rajdhani-700 font-sm pb-0 mb-0">
                        <Link to="/v3Pool">
                          <i class="fa fa-chevron-left d-flex align-items-center pointer dark-text text-black"></i>
                        </Link>
                      </p>
                      <h6 className="text-gray-900 rajdhani-600 mb-0 font-xss d-flex  align-items-center ms-3">
                        <span className="dark-text d-flex align-items-center">Back to Pool</span>
                      </h6>
                    </div>
                    <div className="row align-items-center mb-3">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 ">
                        <div className="d-flex align-items-center">
                          <h6 className="text-gray-900 rajdhani-600 mb-0  d-flex  align-items-center ">
                            {token0?.icon ? (
                              <img src={token0?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                            ) : (
                              <svg
                                viewBox="0 0 24 24"
                                class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                                color="text"
                                width="20px"
                                height="20px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                              </svg>
                            )}
                            {token1?.icon ? (
                              <img src={token1?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                            ) : (
                              <svg
                                viewBox="0 0 24 24"
                                class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                                color="text"
                                width="20px"
                                height="20px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                              </svg>
                            )}
                            <span className="dark-text d-flex font-sm align-items-center text-uppercase">
                              {token0?.symbol} / {token1?.symbol}
                            </span>
                          </h6>
                          <p className=" mx-2  text-gray-900 ls-1 pb-0 mb-0 font-xxs   taxt-black">
                            <span className="font-xsss bg-l dark-text rajdhani-400 px-1 py-1 rounded-6 ">{poolData?.fee / 10000}%</span>
                          </p>
                          <h6 className=" font-xss rajdhani-400 text-gray-900 dark-text m-0  ">
                            {status === 0 ? (
                              <>
                                <span className="text-danger"> Inactive</span>
                                <span className="d-inline-block h-6 w-6 rounded-10 bg-danger mt-1 mx-1" />
                              </>
                            ) : status === 1 ? (
                              <>
                                <span className="text-success"> In range</span>
                                <span className="d-inline-block h-6 w-6 rounded-10 bg-success mt-1 mx-1" />
                              </>
                            ) : (
                              <>
                                <span className="text-secondary"> Closed</span>
                                <span className="d-inline-block h-6 w-6 rounded-10 bg-secondary mt-1 mx-1" />
                              </>
                            )}
                          </h6>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-md-0 mt-sm-2 mt-2">
                        <div className="d-flex align-items-center ">
                          <Link
                            to={`/increase-liquidity?id=${id}`}
                            type="button"
                            className="btn py-1 font-sm px-sm-0 px-md-0 px-lg-1 px-1 w-100 text-blue border-blue rounded-10 mx-1 mt-sm-1 mt-md-0 mt-1"
                          >
                            Increase Liquidity
                          </Link>
                          <Link
                            to={`/remove-liquidity?id=${id}`}
                            type="button"
                            className="btn py-1 font-sm px-sm-0 px-md-0 px-lg-1 px-1 w-100 text-white rounded-10 form-select mx-1 mt-sm-1 mt-md-0 mt-1 b-none"
                          >
                            Remove Liquidity
                          </Link>
                        </div>
                      </div>
                    </div>
                    <UserLiquidityBox
                      token0={token0}
                      token1={token1}
                      amount0={amount0}
                      amount1={amount1}
                      feeAmount0={feeAmount0}
                      feeAmount1={feeAmount1}
                      setCollectFeeLoading={setCollectFeeLoading}
                    />
                    <PriceBox
                      poolData={poolData}
                      token0={token0}
                      token1={token1}
                      minPrice={minPrice}
                      maxPrice={maxPrice}
                      currPrice={currPrice}
                      swap={swap}
                      swapTokenAndPrices={swapTokenAndPrices}
                      status={status}
                      amount0={amount0}
                      amount1={amount1}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ClaimFeeModal
        loading={collectFeeLoading}
        setLoading={setCollectFeeLoading}
        token0={token0}
        token1={token1}
        feeAmount0={feeAmount0}
        feeAmount1={feeAmount1}
        collectFee={collectFee}
      />
      <CollectFeeConfirmModel loading={collectFeeLoading} setLoading={setCollectFeeLoading} />
      <CollectFeeTxSubmittedModel loading={collectFeeLoading} setLoading={setCollectFeeLoading} hash={collectFeeHash} />
    </div>
  );
}
