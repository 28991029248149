import React from "react";
import { formatAmount } from "../../../../Components/Function/commonFunction";

export default function PriceBox({
  poolData,
  token0,
  token1,
  minPrice,
  maxPrice,
  currPrice,
  swap,
  swapTokenAndPrices,
  status,
}) {
  return (
    <div className=" border dark-border p-2 rounded-15 mt-2">
      <div className="row  align-items-center ">
        <div className="col-12 d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <h6 className=" font-xss rajdhani-400 text-gray-900 dark-text m-0 ms-2 ">
              {status === 0 ? (
                <>
                  <span className="text-danger"> Inactive</span>
                  <span className="d-inline-block h-6 w-6 rounded-10 bg-danger mt-1 mx-1" />
                </>
              ) : status === 1 ? (
                <>
                  <span className="text-success"> In range</span>
                  <span className="d-inline-block h-6 w-6 rounded-10 bg-success mt-1 mx-1" />
                </>
              ) : (
                <>
                  <span className="text-secondary"> Closed</span>
                  <span className="d-inline-block h-6 w-6 rounded-10 bg-secondary mt-1 mx-1" />
                </>
              )}
            </h6>
          </div>
          <button
            className=" btn d-flex align-items-center my-1 ms-2 border border-light bg-border-light h-30 rounded-10 pointer m-0 p-0"
            onClick={swapTokenAndPrices}
          >
            <span className={`p-0 m-0 rajdhani-500 font-xss ms-1 dark-text`}>
              {token0?.symbol}
            </span>
            <i class="bi bi-arrow-left-right font-xs dark-text px-2 d-flex align-items-center"></i>
            <span
              className={`p-0 m-0 rajdhani-500 font-xss me-1 dark-text pe-1`}
            >
              {token1?.symbol}
            </span>
          </button>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12  pe-sm-3 pe-md-4 pe-3">
          <div className="card-body border dark-border bg-l  p-relative rounded-15 p-3 py-1">
            <div className="text-center pb-2">
              <p className="font-xs rajdhani-500 text-gray-600 dark-text mb-0">
                Min Price
              </p>
              <h6 className="text-gray-900 rajdhani-600 font-sm dark-text mb-0 py-1">
                {swap
                  ? formatAmount(minPrice?.minPrice)
                  : formatAmount(minPrice?.minPriceRev)}
              </h6>
              <p className="text-gray-600 rajdhani-500 font-xss  ">
                {token0?.symbol} per {token1?.symbol}
              </p>

              {/* <p className="text-gray-500 rajdhani-500 font-xssss   mb-0 " style={{ lineHeight: "0.8" }}>
                9.999426546s5dd
              </p> */}
            </div>
            <div />
          </div>
        </div>
        <i
          class="bi bi-arrow-left-right font-lg rajdhani-700 w-20  mt-5 d-md-block d-sm-none d-none posa"
          style={{ left: "48.75%" }}
        ></i>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-sm-2 mt-md-0 mt-2 ps-sm-1 ps-md-4 ps-3">
          <div className="card-body border dark-border bg-l  p-relative rounded-15 p-3 py-1">
            <div className="text-center pb-2">
              <p className="font-xs rajdhani-500 text-gray-600 dark-text mb-0">
                Max Price
              </p>
              <h6 className="text-gray-900 rajdhani-600 font-sm dark-text mb-0 py-1">
                {swap
                  ? formatAmount(maxPrice?.maxPrice)
                  : formatAmount(maxPrice?.maxPriceRev)}
              </h6>

              <p className="text-gray-600 rajdhani-500 font-xss  ">
                {token0?.symbol} per {token1?.symbol}
              </p>

              {/* <p className="text-gray-500 rajdhani-500 font-xssss   mb-0 " style={{ lineHeight: "0.8" }}>
                9.999426546s5dd
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className=" col-sm-12 mt-2">
          <div className="card-body border dark-border bg-l p-relative rounded-15 p-3 py-2">
            <div className="text-center ">
              <p className="font-xs rajdhani-500 text-gray-600 dark-text mb-0">
                Current Price
              </p>
              <h6 className="text-gray-900 rajdhani-600 font-sm dark-text mb-0 py-1">
                {swap
                  ? currPrice?.price?.toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 45,
                      useGrouping: false,
                    })
                  : currPrice?.priceRev?.toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 45,
                      useGrouping: false,
                    })}
              </h6>
              <p className="text-gray-600 rajdhani-500 font-xss">
                {token0?.symbol} per {token1?.symbol}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
