export const DEFAULT_CHAIN = 56;

export const ChainId = {
  ETHMAINNET: 1,
  BSCMAINNET: 56,
  // BSCTESTNET: 97,
  SOLANA: 901,
  // SOLANA:900
};

export const SupportedChains = [...Object.values(ChainId)];

export const chainInfo = new Map();

chainInfo.set(ChainId.ETHMAINNET, {
  routers: [
    {
      id: 101,
      name: "Pancakeswap",
      routerV2: "0xEfF92A263d31888d860bD50809A8D171709b7b1c",
      routerV3: "0x1b81D678ffb9C0263b24A97847620C99d213eB14",
      quoterV2: "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997",
      quoterV1: "",
      NonfungiblePositionManager: "",
      factoryV2: "",
      factoryV3: "",
      weth: "",
      icon: "assets/newimg/cake.svg",
      feeV3: [100, 500, 2500, 10000],
      feeV2: 2500,
    },
    {
      id: 102,
      name: "Uniswap",
      routerV2: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      routerV3: "0xE592427A0AEce92De3Edee1F18E0157C05861564",
      quoterV2: "0x61fFE014bA17989E743c5F6cB21bF9697530B21e",
      quoterV1: "",
      NonfungiblePositionManager: "",
      factoryV2: "",
      factoryV3: "",
      weth: "",
      icon: "assets/newimg/uniswap.svg",
      feeV3: [100, 500, 3000, 10000],
      feeV2: 3000,
    },
  ],
  usdtAddress: "0xC7E6d7E08A89209F02af47965337714153c529F0",
  decimal: 18,
  urlname: "ethereum",
  testnet: false,
  icon: "./assets/images/chain-icons/ethereum.webp",
});

chainInfo.set(ChainId.BSCMAINNET, {
  routers: [
    {
      id: 101,
      name: "Value",
      routerV2: "0x02cF948128590cD98e24199cA874A8BE7a2Fb4D8",
      routerV3: "0x8e80a1057Ea6d7931A6c70dB6b0D71CA1e100A25",
      quoterV2: "0x982d8B6501fBfcc32Dd7a731d36992204EFB7d1C",
      quoterV1: "",
      NonfungiblePositionManager: "0x031E9f5f0Fd43F4efc1a103873d2eB82Cf45b2De",
      factoryV2: "0xb6d6c4e219ca905f62efe245b84a22411e8c4455",
      factoryV3: "0x1f0E38404fF0e2E1565BBd3e7072de7046E94985",
      weth: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      icon: "assets/newimg/cake.svg",
      feeV3: [100, 500, 2500, 10000],
      feeV2: 2500,
    },
    {
      id: 102,
      name: "Pancakeswap",
      routerV2: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
      routerV3: "0x1b81D678ffb9C0263b24A97847620C99d213eB14",
      quoterV2: "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997",
      quoterV1: "",
      NonfungiblePositionManager: "0x46A15B0b27311cedF172AB29E4f4766fbE7F4364",
      factoryV2: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
      factoryV3: "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865",
      weth: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      icon: "assets/newimg/cake.svg",
      feeV3: [100, 500, 2500, 10000],
      feeV2: 2500,
    },
    {
      id: 103,
      name: "Uniswap",
      routerV2: "0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24",
      routerV3: "0xB971eF87ede563556b2ED4b1C0b0019111Dd85d2",
      quoterV2: "0x78D78E420Da98ad378D7799bE8f4AF69033EB077",
      quoterV1: "",
      NonfungiblePositionManager: "0x7b8A01B39D58278b5DE7e48c8449c9f4F5170613",
      factoryV2: "0x8909Dc15e40173Ff4699343b6eB8132c65e18eC6",
      factoryV3: "0xdB1d10011AD0Ff90774D0C6Bb92e5C5c8b4461F7",
      weth: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      icon: "assets/newimg/uniswap.svg",
      feeV3: [100, 500, 3000, 10000],
      feeV2: 3000,
    },
  ],
  usdtAddress: "0x55d398326f99059fF775485246999027B3197955",
  decimal: 18,
  urlname: "bsc",
  testnet: false,
  icon: "./assets/images/chain-icons/binance.svg",
});

chainInfo.set(ChainId.BSCTESTNET, {
  routers: [
    {
      id: 101,
      name: "Value",
      routerV2: "0x458672538b84F60FDA842e51Db4f2d9eCB1A2919",
      routerV3: "0xda4f5C9e029ff4fAa36f2e600caf27cC675a22A3",
      quoterV2: "0x5aE3306A2947cA3E78C1a81A301a27F188ffDB8e",
      quoterV1: "",
      NonfungiblePositionManager: "0x0e8E4265E76Aa8e8AeCbbF593274A12d3cC5820e",
      factoryV2: "0xC188DaD4FF70926aC5B82582B4d40e48EeD7c246",
      factoryV3: "0xe08824abb3B46ef8B965D7A95fBf29A8De733c5a",
      weth: "",
      icon: "assets/images/logo_01_1.webp",
      feeV3: [100, 500, 3000, 10000],
      feeV2: 2500,
    },
    {
      id: 102,
      name: "Pancakeswap",
      routerV2: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
      routerV3: "0x1b81D678ffb9C0263b24A97847620C99d213eB14",
      quoterV2: "0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2",
      quoterV1: "",
      NonfungiblePositionManager: "0x427bF5b37357632377eCbEC9de3626C71A5396c1",
      factoryV2: "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
      factoryV3: "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865",
      weth: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
      icon: "assets/newimg/cake.svg",
      feeV3: [100, 500, 2500, 10000],
      feeV2: 2500,
    },
  ],
  usdtAddress: "0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8",
  decimal: 18,
  urlname: "bscTestnet",
  testnet: false,
  icon: "./assets/images/chain-icons/binance-testnet.png",
});

chainInfo.set(ChainId.SOLANA, {
  routers: [
    {
      id: 101,
      name: "Jupiter",
      routerV2: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
      routerV3: "0x1b81D678ffb9C0263b24A97847620C99d213eB14",
      quoterV2: "0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2",
      quoterV1: "0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2",
      icon: "assets/newimg/jupiter-logo.svg",
      feeV3: [100, 500, 2500, 10000],
      feeV2: 2500,
    },
  ],
  chainId: 901,
  usdtAddress: "0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8",
  decimal: 18,
  urlname: "solana",
  testnet: false,
  icon: "./assets/images/chain-icons/solana.svg",
  symbol: "SOL",
  name: "Solana",
  explorerUrl: "https://solscan.io/",
  currency: "SOL",
});
