import React, { useContext } from "react";
import { formatAmount } from "../../Components/Function/commonFunction";
import { globalStates } from "../../contexts/GlobalStates";

export default function SwapInfo({
  showMore,
  setShowMore,
  selectedTokenOne,
  selectedTokenTwo,
  inputAmountOne,
  inputAmountTwo,
  tradeInfo,
}) {
  const { settingData } = useContext(globalStates);
  return (
    <div>
      <div
        className="card-body mt-2 dark-border border border-light border-2 rounded-15 p-2 px-3  pointer dashboard-bg bg-info"
        onClick={() => setShowMore(!showMore)}
        style={
          showMore
            ? {
                height: "3rem",
                transition: " 0.3s ease-in",
                overflow: "hidden",
              }
            : {
                height: "165px",
                overflow: "hidden",
                transition: " 0.3s ease-in",
              }
        }
      >
        <div className="d-flex mt-1 mb-0 p-0 justify-content-between align-items-center  ">
          <div className="text-gray-900 font-xss rajdhani-400  mb-0 dark-text ">
            1 {selectedTokenOne?.symbol} ={" "}
            {formatAmount(Number(inputAmountTwo) / Number(inputAmountOne))}{" "}
            {selectedTokenTwo?.symbol}
          </div>
          <div className="text-gray-900 d-flex justify-content-end font-xss rajdhani-400 mb-0 dark-text">
            <div>
              <span className="dark-text-1 d-flex justify-content-center">
                <i class="bi bi-fuel-pump-diesel mx-md-2"></i>
                {"<"}$0
              </span>
            </div>
            <div>
              <span
                className={`dark-text arrow2 ${!showMore ? "up" : "down"}`}
              ></span>
            </div>
          </div>
        </div>
        <div
          className={`d-flex mb-1 justify-content-between align-items-top mt-2 ${
            showMore ? "show" : ""
          }`}
        >
          <div className="text-gray-700 rajdhani-400 mb-0 ">
            <p className="rajdhani-400 dark-text mb-0 opacity-50">
              Slippage tolerance
            </p>
          </div>
          <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
            <div>
              <p className="rajdhani-400 dark-text mb-0">
                <span>{settingData?.slippage}%</span>
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex mb-1 justify-content-between align-items-top mt-1 ">
          <div className="text-gray-700 rajdhani-400 mb-0 dark-text">
            <p className="rajdhani-400 mb-0 opacity-50">Minimum receive</p>
          </div>
          <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
            <div>
              <p className="rajdhani-400 dark-text mb-0">
                <span>
                  {formatAmount(tradeInfo?.minAmountOut)}{" "}
                  {selectedTokenTwo?.symbol}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex mb-1 justify-content-between align-items-top mt-1 ">
          <div className="text-gray-700 rajdhani-400 mb-0 dark-text">
            <p className="rajdhani-400 mb-0 opacity-50">Trading Fee</p>
          </div>
          <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
            <div>
              <p className="rajdhani-400 dark-text mb-0">
                <span>
                  {formatAmount(tradeInfo?.feeAmount)}{" "}
                  {selectedTokenOne?.symbol}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex mb-1 justify-content-between align-items-top mt-1 ">
          <div className="text-gray-700 rajdhani-400 mb-0 dark-text">
            <p className="rajdhani-400 mb-0 opacity-50">Price Impact</p>
          </div>
          <div className="text-gray-900 d-flex justify-content-end rajdhani-400 mb-0 ">
            <div>
              <p className="rajdhani-400 dark-text mb-0">
                <span>
                  {tradeInfo?.priceImpact
                    ? formatAmount(tradeInfo?.priceImpact)
                    : 0}
                  %
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
