import React, { useContext, useEffect, useState, useRef } from "react";
import { createContext } from "react";
import { walletConnect } from "./WalletConnect";
import COINS from "../constants/coins";
import { getImportedTokens } from "../Components/Function/commonFunction";
import { useLocation, useSearchParams } from "react-router-dom";

export const globalStates = createContext(null);

const GlobalStates = ({ children }) => {
  const { selectedChain, urlChain, setUrlChain, inputToken, setInputToken, outputToken, setOutputToken } = useContext(walletConnect);
  const [chainTokens, setChainTokens] = useState(null);
  const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark-mode");

  const [settingData, setSettingData] = useState({
    slippage: "0.5",
    slippagePlaceHolder: "0.5",
    auto: false,
    auto1: false,
    auto5: false,
    auto10: false,
    txDeadline: "20",
    ExpertMode: false,
  });

  // url params
  const location = useLocation();
  const previousPathRef = useRef(location.pathname);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showComingSoon, setShowComingSoon] = useState(false);

  const toggleShowComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  const getAndSetChainTokens = () => {
    if (selectedChain) {
      const staticTokens = COINS.get(selectedChain.chainId);
      const imporedTokens = getImportedTokens();
      const filteredTokens = imporedTokens.filter((item) => item.chainId === selectedChain?.chainId);
      const mergeTokens = [...staticTokens, ...filteredTokens];
      setChainTokens(mergeTokens);
    } else {
      setChainTokens(null);
    }
  };
  const toggleTheme = () => {
    if (theme === "light-mode") {
      setTheme("dark-mode");
    } else {
      setTheme("light-mode");
    }
  };

  useEffect(() => {
    let setting = JSON.parse(localStorage.getItem("value-settings"));
    if (setting) {
      setSettingData({ ...setting });
    }
  }, []);

  useEffect(() => {
    if (selectedChain) {
      getAndSetChainTokens();
    }
  }, [selectedChain]);

  useEffect(() => {
    document.body.className = theme;
    localStorage.setItem("theme", theme);
  }, [theme]);

  useEffect(() => {
    const params = {};
    if (
      location.pathname === "/" ||
      location.pathname === "/addliquidity-v3" ||
      location.pathname === "/addLiquidity-v2" ||
      location.pathname === "/importv2pool"
    ) {
      if (urlChain) {
        params.chain = urlChain;
      }
      if (inputToken) {
        params.inputCurrency = inputToken;
      }
      if (outputToken) {
        params.outputCurrency = outputToken;
      }
      setSearchParams(params);
    } else {
      // if (urlChain) {
      //   params.chain = urlChain;
      // }
      // setSearchParams(params);
      if (inputToken && outputToken) {
        setInputToken(null);
        setOutputToken(null);
      }
    }
  }, [urlChain, inputToken, outputToken, setSearchParams]);

  const handleTokenSelectSearchParams = (address, type) => {
    if (type === "input") {
      setInputToken(address);
      if (outputToken?.toLowerCase() === address?.toLowerCase()) {
        if (inputToken) {
          setOutputToken(inputToken);
        } else {
          setOutputToken("");
        }
      }
    } else if (type === "output") {
      setOutputToken(address);
      if (inputToken?.toLowerCase() === address?.toLowerCase()) {
        if (outputToken) {
          setInputToken(outputToken);
        } else {
          setInputToken("");
        }
      }
    } else if (type === "swap") {
      if (inputToken && chainTokens) {
        setInputToken(outputToken);
        setOutputToken(inputToken);
      } else if (inputToken && !outputToken) {
        setOutputToken(inputToken);
        setInputToken(null);
      } else if (outputToken && !inputToken) {
        if (outputToken?.toLowerCase() === chainTokens[0].address?.toLowerCase()) {
          setInputToken(outputToken);
          setOutputToken(null);
        } else {
          setInputToken(outputToken);
          setOutputToken(chainTokens[0].address);
        }
      } else {
        setOutputToken(chainTokens[0].address);
      }
    } else if (type === "chainswitch") {
      setInputToken("");
      setOutputToken("");
    }
  };

  return (
    <globalStates.Provider
      value={{
        chainTokens,
        handleTokenSelectSearchParams,
        settingData,
        setSettingData,
        theme,
        setTheme,
        toggleTheme,
        urlChain,
        setUrlChain,
        showComingSoon,
        setShowComingSoon,
        toggleShowComingSoon,
      }}
    >
      {children}
    </globalStates.Provider>
  );
};

export default GlobalStates;
