/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function V3InputLiquidityBox({ setSwapBoxModalChange, type, selectedToken }) {
  return (
    <>
      {selectedToken ? (
        <>
          <div className="col-6">
            <a
              className="btn btn-md header-btn border border-light font-sm-new  rajdhani-400 rounded-10 px-2 py-2 bg-border-light dark-text font-sm d-flex justify-content-between align-items-center"
              onClick={() =>
                setSwapBoxModalChange(
                  type === 0
                    ? "selectTokenModalInput"
                    : "selectTokenModalOutput"
                )
              }
            >
              <div className="d-flex align-items-center">
                <img
                  src={
                    selectedToken?.icon
                      ? selectedToken?.icon
                      : "./assets/images/token-icons/unknown.svg"
                  }
                  alt="user"
                  className="h-26 me-2 rounded-circle"
                />
                <span style={{ paddingTop: "1.5px" }}>
                  {selectedToken?.symbol}
                </span>
              </div>
              <span className="bi bi-chevron-down font-xs d-flex align-items-center"></span>
            </a>
          </div>
        </>
      ) : (
        <div className="col-6">
          <a
            className="btn btn-md header-btn border-0 font-sm-new  rajdhani-400 rounded-10 px-2 py-2  btn-bg-blue font-sm text-white d-flex justify-content-between align-items-center"
            onClick={() =>
              setSwapBoxModalChange(
                type === 0 ? "selectTokenModalInput" : "selectTokenModalOutput"
              )
            }
          >
            Select a token
            <i className="bi bi-chevron-down font-xs d-flex align-items-center"></i>
          </a>
        </div>
      )}
    </>
  );
}

export default V3InputLiquidityBox;
