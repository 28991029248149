import ERC20 from "../../abis/ERC20.json";

import IUniswapV2Factory from "../../abis/IUniswapV2Factory.json";
import IUniswapV2Pair from "../../abis/IUniswapV2Pair.json";
import IUniswapV2Router from "../../abis/UniswapV2Router.json";

import IUniswapV3Pool from "../../abis/IUniswapV3Pool.json";
import IUniswapv3Factory from "../../abis/IUniswapv3Factory.json";
import IUniswapv3Router from "../../abis/IUniswapv3Router.json";

import QuoterV2 from "../../abis/QuoterV2.json";
import QuoterV1 from "../../abis/QuoterV1.json";

import IUniswapV3NonfungiblePositionManager from "../../abis/IUniswapV3NonfungiblePositionManager.json";

import { BigNumber, Contract, constants, ethers, providers } from "ethers";
import { formatEther, formatUnits, parseUnits } from "ethers/lib/utils";
import {
  calculatePriceRangeAndTicks,
  getFactory,
  getReserveTokenInPair,
  getWethV2Address,
  getWethV3Address,
  sqrtToPrice,
  tickSpacing,
  tickToPrice,
} from "./ethersFunctions";
import { FeeAmount, Pool, Position, nearestUsableTick } from "@uniswap/v3-sdk";
import { Token } from "@uniswap/sdk-core";
import { TickMath } from "@uniswap/v3-sdk";
import bn from "bignumber.js";
import { formatAmount } from "./commonFunction";
bn.config({ EXPONENTIAL_AT: 999999, DECIMAL_PLACES: 40 });

export async function getV2PoolInfo(poolAddress, provider) {
  try {
  } catch (err) {
    console.log("error in getV2PoolInfo : ", err);
  }
}

const sqrtBigNumber = (value) => {
  if (value.isZero()) return BigNumber.from(0);

  let z = value;
  let x = value.div(2).add(1);
  while (x.lt(z)) {
    z = x;
    x = value.div(x).add(x).div(2);
  }
  return z;
};

export function priceToSqrtpriceX96(price) {
  const sqrtPriceBN = sqrtBigNumber(BigNumber.from(ethers.utils.parseUnits(price, "18")));
  const Q96 = BigNumber.from(BigNumber.from(2).pow(BigNumber.from(192)));
  return formatEther(sqrtPriceBN.mul(Q96));
}

export const storeV2PairInLocalStorage = (chainId, selectedTokenOne, selectedTokenTwo) => {
  try {
    const key = [selectedTokenOne?.address?.toLowerCase() + ":" + selectedTokenTwo?.address?.toLowerCase()];
    const revkey = [selectedTokenTwo?.address?.toLowerCase() + ":" + selectedTokenOne?.address?.toLowerCase()];
    const obj = {
      token0: {
        address: selectedTokenOne?.address,
        chainId: chainId,
        symbol: selectedTokenOne?.symbol,
        name: selectedTokenOne?.name,
        decimals: selectedTokenOne?.decimals,
        icon: selectedTokenOne?.icon,
      },
      token1: {
        address: selectedTokenTwo?.address,
        chainId: chainId,
        symbol: selectedTokenTwo?.symbol,
        name: selectedTokenTwo?.name,
        decimals: selectedTokenTwo?.decimals,
        icon: selectedTokenTwo?.icon,
      },
    };
    const prevPairData = localStorage.getItem("[value:v2pairs]");
    if (prevPairData) {
      const jasonData = JSON.parse(prevPairData);
      const dataByChainId = jasonData[chainId?.toString()];
      if (dataByChainId) {
        if (!dataByChainId[key] && !dataByChainId[revkey]) {
          const storeData = { ...dataByChainId, [key]: obj };
          localStorage.setItem("[value:v2pairs]", JSON.stringify({ [chainId?.toString()]: storeData }));
        }
      } else {
        localStorage.setItem("[value:v2pairs]", JSON.stringify({ [chainId?.toString()]: { [key]: obj } }));
      }
    } else {
      localStorage.setItem("[value:v2pairs]", JSON.stringify({ [chainId?.toString()]: { [key]: obj } }));
    }
  } catch (err) {
    console.log("error in storeV2PairInLocalStorage : ", err);
  }
};

export const removePairFromLocal = (keys, chainId) => {
  try {
    const prevPairData = localStorage.getItem("[value:v2pairs]");
    if (prevPairData) {
      const jasonData = JSON.parse(prevPairData);
      const dataByChainId = jasonData[chainId?.toString()];
    }
  } catch (err) {
    console.log("error in storeV2PairInLocalStorage : ", err);
  }
};
export async function getUsersReserveTokensForV2(factory, account, weth, tokenOne, tokenTwo, provider, selectedRouter) {
  try {
    const token0 = tokenOne?.address === constants?.AddressZero ? weth : tokenOne?.address;
    const token1 = tokenTwo?.address === constants?.AddressZero ? weth : tokenTwo?.address;
    const pairAddress = await factory.getPair(token0, token1);
    if (pairAddress) {
      const pairContract = new Contract(pairAddress, IUniswapV2Pair?.abi, provider);

      const pairDecimals = await pairContract.decimals();
      const symbol = await pairContract.symbol();
      let allowance = "0";
      if (selectedRouter?.routerV2) {
        const allowanceWei = await pairContract.allowance(account, selectedRouter?.routerV2);

        allowance = ethers.utils.formatUnits(allowanceWei, pairDecimals);
      }

      const pairToken0 = await pairContract?.token0();
      const pairToken1 = await pairContract?.token1();

      const reserve = await pairContract.getReserves();
      const reserve0 =
        pairToken0?.toLowerCase() === token0?.toLowerCase()
          ? ethers.utils.formatUnits(
              reserve[0],
              pairToken0?.toLowerCase() === token0?.toLowerCase() ? tokenOne?.decimals : tokenTwo?.decimals
            )
          : ethers.utils.formatUnits(
              reserve[1],
              pairToken1?.toLowerCase() === token1?.toLowerCase() ? tokenTwo?.decimals : tokenOne?.decimals
            );
      const reserve1 =
        pairToken1?.toLowerCase() === token1?.toLowerCase()
          ? ethers.utils.formatUnits(
              reserve[1],
              pairToken1?.toLowerCase() === token1?.toLowerCase() ? tokenTwo?.decimals : tokenOne?.decimals
            )
          : ethers.utils.formatUnits(
              reserve[0],
              pairToken0?.toLowerCase() === token0?.toLowerCase() ? tokenOne?.decimals : tokenTwo?.decimals
            );
      const userLiquidityWei = await pairContract.balanceOf(account);
      const userLiquidity = ethers.utils.formatUnits(userLiquidityWei, pairDecimals);

      // Total supply of liquidity tokens
      const totalSupplyWei = await pairContract.totalSupply();
      const totalSupply = ethers.utils.formatUnits(totalSupplyWei, pairDecimals);

      // User's share in the pool
      const userShare = (userLiquidity * 100) / totalSupply;

      // User's token amounts
      const userToken0Amount = (userShare * reserve0) / 100;
      const userToken1Amount = (userShare * reserve1) / 100;
      return {
        share: userShare,
        token0Amount: userToken0Amount,
        token1Amount: userToken1Amount,
        lptokens: userLiquidity,
        lptokenswei: userLiquidityWei,
        reserve: [reserve0, reserve1],
        allowance,
        pairAddress,
        symbol,
        decimals: pairDecimals,
        pool0: pairToken0,
        pool1: pairToken1,
      };
    } else {
      return null;
    }
  } catch (err) {
    console.log("error in getUsersReserveTokensForV2 : ", err);
    return null;
  }
}

export const getV2PairFromLocalStorage = (chainId) => {
  try {
    const prevPairData = localStorage.getItem("[value:v2pairs]");
    if (prevPairData) {
      const jasonData = JSON.parse(prevPairData);
      return jasonData[chainId?.toString()];
    } else {
      return null;
    }
  } catch (err) {
    console.log("error in storeV2PairInLocalStorage : ", err);
    return null;
  }
};
export const getV2PerticularPairFromLocalStorage = (chainId, token0, token1) => {
  try {
    let key = token0?.toLowerCase() + ":" + token1?.toLowerCase();
    let revkey = token0?.toLowerCase() + ":" + token1?.toLowerCase();
    const prevPairData = localStorage.getItem("[value:v2pairs]");
    if (prevPairData) {
      const jasonData = JSON.parse(prevPairData);
      let chainWiseData = jasonData[chainId?.toString()];
      if (chainWiseData) {
        if (chainWiseData[key]) {
          return chainWiseData[key];
        } else if (chainWiseData[revkey]) {
          return chainWiseData[revkey];
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (err) {
    console.log("error in storeV2PairInLocalStorage : ", err);
    return null;
  }
};

export async function getReserveV2(poolAddress, inputToken, outputToken, weth, address, provider) {
  try {
    const token0 = inputToken?.address === constants?.AddressZero ? weth : inputToken?.address;
    const token1 = outputToken?.address === constants?.AddressZero ? weth : outputToken?.address;
    const pair = new Contract(poolAddress, IUniswapV2Pair?.abi, provider);
    const reservesRaw = await pair.getReserves();
    const results = [
      (await pair.token0())?.toLowerCase() === token0?.toLowerCase() ? reservesRaw[0] : reservesRaw[1],
      (await pair.token1())?.toLowerCase() === token1?.toLowerCase() ? reservesRaw[1] : reservesRaw[0],
    ];
    const decimals = await pair.decimals();
    const totalSupply = await pair.totalSupply();
    return [
      formatUnits(results[0], inputToken?.decimals),
      formatUnits(results[1], outputToken?.decimals),
      formatUnits(totalSupply, decimals),
    ];
  } catch (err) {
    console.log("error in getV2PoolInfo : ", err);
  }
}

export async function addLiquidityInV2(
  inputToken,
  outputToken,
  inputAmount,
  outputAmount,
  selectedRouter,
  address,
  provider,
  slippage,
  txDeadline
) {
  try {
    const weth = await getWethV2Address(selectedRouter, provider);
    const signer = await provider.getSigner();

    const amountIn = ethers.utils.parseUnits(inputAmount, inputToken?.decimals);
    const amountOut = ethers.utils.parseUnits(outputAmount, outputToken?.decimals);

    const amountInMin = amountIn.sub(amountIn.mul(Number(slippage * 10000)).div(100 * 10000)).toString();
    const amountOutMin = amountOut.sub(amountOut.mul(Number(slippage * 10000)).div(100 * 10000)).toString();

    const token0 = inputToken?.address === constants?.AddressZero ? weth : inputToken?.address;
    const token1 = outputToken?.address === constants?.AddressZero ? weth : outputToken?.address;

    const time = Math.floor(Date.now() / 1000) + Number(txDeadline) * 60;
    const deadline = ethers.BigNumber.from(time);

    const routerContract = new Contract(selectedRouter?.routerV2, IUniswapV2Router?.abi, signer);

    if (inputToken?.address === constants?.AddressZero) {
      const tx = await routerContract.addLiquidityETH(outputToken?.address, amountOut, amountOutMin, amountInMin, address, deadline, {
        value: amountIn,
      });
      await tx.wait();
      return tx;
    } else if (outputToken?.address === constants?.AddressZero) {
      const tx = await routerContract.addLiquidityETH(inputToken?.address, amountIn, amountInMin, amountOutMin, address, deadline, {
        value: amountOut,
      });
      await tx.wait();
      return tx;
    } else {
      const tx = await routerContract.addLiquidity(
        inputToken?.address,
        outputToken?.address,
        amountIn,
        amountOut,
        amountInMin,
        amountOutMin,
        address,
        deadline
      );
      await tx.wait();
      return tx;
    }
  } catch (err) {
    console.log("error in addLiquidityInV2 : ", err);
    return false;
  }
}

export async function removeLiquidityInV2(
  inputToken,
  outputToken,
  pool0,
  pool1,
  inputAmount,
  outputAmount,
  lptokens,
  selectedRouter,
  address,
  provider,
  slippage,
  txDeadline,
  pair,
  Value
) {
  try {
    const weth = await getWethV2Address(selectedRouter, provider);

    const token0 = inputToken?.address === constants?.AddressZero ? weth : inputToken?.address;
    const token1 = outputToken?.address === constants?.AddressZero ? weth : outputToken?.address;

    const decimal0 = pool0?.toLowerCase() === token0?.toLowerCase() ? inputToken?.decimals : outputToken?.decimals;
    const decimal1 = pool1?.toLowerCase() === token1?.toLowerCase() ? outputToken?.decimals : inputToken?.decimals;

    const signer = await provider.getSigner();

    const amountIn = ethers.utils.parseUnits(
      inputAmount?.toFixed(pool0?.toLowerCase() === token0?.toLowerCase() ? Number(decimal0) : Number(decimal1))?.toString(),
      pool0?.toLowerCase() === token0?.toLowerCase() ? decimal0 : decimal1
    );
    const amountOut = ethers.utils.parseUnits(
      outputAmount?.toFixed(pool1?.toLowerCase() === token1?.toLowerCase() ? Number(decimal1) : Number(decimal0))?.toString(),
      pool1?.toLowerCase() === token1?.toLowerCase() ? decimal1 : decimal0
    );

    let lptokenswei = "0";
    if (Value < 100) {
      lptokenswei = ethers.utils.parseUnits(lptokens.toFixed(Number(pair?.decimals))?.toString(), pair?.decimals);
    } else {
      lptokenswei = pair?.lptokenswei;
    }

    const amountInMin = amountIn.sub(amountIn.mul(Number(slippage * 10000)).div(100 * 10000)).toString();
    const amountOutMin = amountOut.sub(amountOut.mul(Number(slippage * 10000)).div(100 * 10000)).toString();

    const time = Math.floor(Date.now() / 1000) + Number(txDeadline) * 60;
    const deadline = ethers.BigNumber.from(time);

    const routerContract = new Contract(selectedRouter?.routerV2, IUniswapV2Router?.abi, signer);
    if (inputToken?.address === constants?.AddressZero) {
      const tx = await routerContract.removeLiquidityETH(outputToken?.address, lptokenswei, amountOutMin, amountInMin, address, deadline);
      await tx.wait();
      return tx;
    } else if (outputToken?.address === constants?.AddressZero) {
      const tx = await routerContract.removeLiquidityETH(inputToken?.address, lptokenswei, amountInMin, amountOutMin, address, deadline);
      await tx.wait();
      return tx;
    } else {
      const tx = await routerContract.removeLiquidity(token0, token1, lptokenswei, amountInMin, amountOutMin, address, deadline);
      await tx.wait();
      return tx;
    }
  } catch (err) {
    console.log("error in addLiquidityInV2 : ", err);
    return false;
  }
}

function compareToken(a, b) {
  return a.address.toLowerCase() < b.address.toLowerCase() ? -1 : 1;
}

export function sortedTokens(a, b) {
  return compareToken(a, b) < 0 ? [a, b] : [b, a];
}

export function encodePriceSqrt(price, decimal0, decimal1) {
  const scaledPrice = new bn(price).multipliedBy(new bn(10).pow(decimal0)).dividedBy(new bn(10).pow(decimal1));

  const sqrtPrice = scaledPrice.sqrt().multipliedBy(new bn(2).pow(96));

  return BigNumber.from(sqrtPrice.integerValue(3).toString());
  // return BigNumber.from(new bn(price).sqrt().multipliedBy(new bn(2).pow(96)).integerValue(3).toString());
}

function sqrtToPrice1(sqrt, token0isInput = true) {
  const numerator = sqrt ** 2;
  const denominator = 2 ** 192;
  let ratio = numerator / denominator;
  const shiftdecimals = Math.pow(10, 18 - 18);
  ratio = ratio * shiftdecimals;
  if (!token0isInput) {
    return 1 / ratio;
  }
  return ratio;
}

export async function addLiquidityInV3(
  selectedFeePair,
  inputToken,
  outputToken,
  inputAmount,
  outputAmount,
  selectedRouter,
  address,
  provider,
  slippage,
  txDeadline,
  tickFromPrice,
  selectedChain,
  priceInput,
  minPrice,
  maxPrice
) {
  try {
    const weth = await getWethV2Address(selectedRouter, provider);
    const signer = await provider.getSigner();

    const amountIn = ethers.utils.parseUnits(inputAmount?.toString(), inputToken?.decimals);
    const amountOut = ethers.utils.parseUnits(outputAmount?.toString(), outputToken?.decimals);

    const amountInMin = amountIn.sub(amountIn.mul(Number(slippage * 10000)).div(100 * 10000)).toString();
    const amountOutMin = amountOut.sub(amountOut.mul(Number(slippage * 10000)).div(100 * 10000)).toString();

    const token0Org = inputToken?.address === constants?.AddressZero ? weth : inputToken?.address;
    const token1Org = outputToken?.address === constants?.AddressZero ? weth : outputToken?.address;

    const time = Math.floor(Date.now() / 1000) + Number(txDeadline) * 60;
    const deadline = ethers.BigNumber.from(time);

    const positionContract = new Contract(selectedRouter?.NonfungiblePositionManager, IUniswapV3NonfungiblePositionManager?.abi, signer);

    const token0Instance = new Token(
      selectedChain.chainId,
      inputToken?.address === constants?.AddressZero ? weth : inputToken?.address,
      Number(inputToken?.decimals),
      inputToken.symbol,
      inputToken.name
    );

    const token1Instance = new Token(
      selectedChain.chainId,
      outputToken?.address === constants?.AddressZero ? weth : outputToken?.address,
      Number(outputToken?.decimals),
      outputToken.symbol,
      outputToken.name
    );

    const [token0, token1] = sortedTokens(token0Instance, token1Instance);

    if (selectedFeePair?.pool && selectedFeePair?.poolToken0 && selectedFeePair?.poolToken1) {
      let paramsMint = {
        token0: token0?.address,
        token1: token1?.address,
        fee: selectedFeePair.fee?.toString(),
        tickLower: tickFromPrice?.lowerTick?.toString(),
        tickUpper: tickFromPrice?.upperTick?.toString(),
        amount0Desired: token0?.address?.toLowerCase() === token0Org?.toLowerCase() ? amountIn?.toString() : amountOut?.toString(),
        amount1Desired: token1?.address?.toLowerCase() === token1Org?.toLowerCase() ? amountOut?.toString() : amountIn?.toString(),
        amount0Min: "0",
        amount1Min: "0",
        recipient: address,
        deadline: deadline?.toString(),
      };
      if (inputToken?.address === constants?.AddressZero) {
        let multicallArgs = [
          positionContract.interface.encodeFunctionData("mint", [paramsMint]),
          positionContract.interface.encodeFunctionData("refundETH"),
        ];
        const tx = await positionContract.multicall(multicallArgs, { value: amountIn });
        await tx.wait();
        return tx;
      } else if (outputToken?.address === constants?.AddressZero) {
        let multicallArgs = [
          positionContract.interface.encodeFunctionData("mint", [paramsMint]),
          positionContract.interface.encodeFunctionData("refundETH"),
        ];
        const tx = await positionContract.multicall(multicallArgs, { value: amountOut });
        await tx.wait();
        return tx;
      } else {
        let multicallArgs = [positionContract.interface.encodeFunctionData("mint", [paramsMint])];
        const tx = await positionContract.multicall(multicallArgs);
        await tx.wait();
        return tx;
      }
    } else {
      let sqrtX96PriceSwap;
      let ticks;
      let decimal0 = token0?.address?.toLowerCase() === token0Org?.toLowerCase() ? inputToken?.decimals : outputToken?.decimals;
      let decimal1 = token1?.address?.toLowerCase() === token1Org?.toLowerCase() ? outputToken?.decimals : inputToken?.decimals;
      if (token0?.address?.toLowerCase() === token0Org?.toLowerCase()) {
        sqrtX96PriceSwap = encodePriceSqrt(priceInput, decimal0, decimal1);
        let price = sqrtToPrice(sqrtX96PriceSwap.toString(), decimal0, decimal1);
        ticks = calculatePriceRangeAndTicks(price, 10, tickSpacing(selectedFeePair?.fee), selectedFeePair?.fee, true, minPrice, maxPrice);
      } else {
        sqrtX96PriceSwap = encodePriceSqrt(1 / priceInput, decimal0, decimal1);
        let price = sqrtToPrice(sqrtX96PriceSwap.toString(), decimal0, decimal1);
        ticks = calculatePriceRangeAndTicks(price, 10, tickSpacing(selectedFeePair?.fee), selectedFeePair?.fee, true, minPrice, maxPrice);
      }
      let paramsMint = {
        token0: token0?.address,
        token1: token1?.address,
        fee: selectedFeePair.fee?.toString(),
        tickLower: ticks?.lowerTick,
        tickUpper: ticks?.upperTick,
        amount0Desired: token0?.address?.toLowerCase() === token0Org?.toLowerCase() ? amountIn?.toString() : amountOut?.toString(),
        amount1Desired: token1?.address?.toLowerCase() === token1Org?.toLowerCase() ? amountOut?.toString() : amountIn?.toString(),
        amount0Min: "0",
        amount1Min: "0",
        recipient: address,
        deadline: deadline?.toString(),
      };
      // return false;
      if (inputToken?.address === constants?.AddressZero) {
        let multicallArgs = [
          positionContract.interface.encodeFunctionData("createAndInitializePoolIfNecessary", [
            token0?.address,
            token1?.address,
            selectedFeePair.fee?.toString(),
            sqrtX96PriceSwap?.toString(),
          ]),
          positionContract.interface.encodeFunctionData("mint", [paramsMint]),
          positionContract.interface.encodeFunctionData("refundETH"),
        ];
        const tx = await positionContract.multicall(multicallArgs, {
          value: amountIn?.toString(),
        });
        await tx.wait();
        return tx;
      } else if (outputToken?.address === constants?.AddressZero) {
        let multicallArgs = [
          positionContract.interface.encodeFunctionData("createAndInitializePoolIfNecessary", [
            token0?.address,
            token1?.address,
            selectedFeePair.fee?.toString(),
            sqrtX96PriceSwap?.toString(),
          ]),
          positionContract.interface.encodeFunctionData("mint", [paramsMint]),
          positionContract.interface.encodeFunctionData("refundETH"),
        ];
        const tx = await positionContract.multicall(multicallArgs, { value: amountOut?.toString() });
        await tx.wait();
        return tx;
      } else {
        let multicallArgs = [
          positionContract.interface.encodeFunctionData("createAndInitializePoolIfNecessary", [
            token0?.address,
            token1?.address,
            selectedFeePair.fee?.toString(),
            sqrtX96PriceSwap?.toString(),
          ]),
          positionContract.interface.encodeFunctionData("mint", [paramsMint]),
        ];
        const tx = await positionContract.multicall(multicallArgs);
        await tx.wait();
        return tx;
      }
    }
  } catch (err) {
    console.log("error in addLiquidityInV3 : ", err);
    return false;
  }
}

export const getPriceFromSqrtPrice = (sqrtPriceX96, decimal0, decimal1) => {
  const numerator = sqrtPriceX96 ** 2;
  const denominator = 2 ** 192;
  let ratio = numerator / denominator;
  const shiftdecimals = Math.pow(10, decimal0 - decimal1);
  ratio = ratio * shiftdecimals;
  return { price: 1 / ratio, priceRev: ratio };
};

export function getMinAndMaxPrices(tickLower, tickUpper, decimal0, decimal1) {
  const sqrtPriceX96Lower = Math.pow(1.0001, tickLower / 2) * Math.pow(2, 96);
  const sqrtPriceX96Upper = Math.pow(1.0001, tickUpper / 2) * Math.pow(2, 96);

  const numeratorLower = sqrtPriceX96Lower ** 2;
  const numeratorUpper = sqrtPriceX96Upper ** 2;

  const denominator = 2 ** 192;

  let ratioLower = numeratorLower / denominator;
  let ratioUpper = numeratorUpper / denominator;

  const shiftdecimals = Math.pow(10, decimal0 - decimal1);

  ratioLower = ratioLower * shiftdecimals;
  ratioUpper = ratioUpper * shiftdecimals;

  return { minPrice: 1 / ratioUpper, maxPrice: 1 / ratioLower, minPriceRev: ratioLower, maxPriceRev: ratioUpper };
}

export async function getAmountsForPosition(position, slot0, tokenIn, tokenOut) {
  try {
    const { liquidity, tickLower, tickUpper, token0, token1, fee } = position;

    // Ensure tickLower and tickUpper are integers
    const tickLowerInt = parseInt(tickLower, 10);
    const tickUpperInt = parseInt(tickUpper, 10);
    // Define tokens
    const token0Instance = new Token(1, token0, tokenIn?.decimals); // Adjust chainId as needed
    const token1Instance = new Token(1, token1, tokenOut?.decimals); // Adjust chainId as needed
    // Create pool and position instances
    let pool;
    if (fee === 2500) {
      pool = new Pool(token0Instance, token1Instance, FeeAmount.LOW, slot0?.sqrtPriceX96.toString(), liquidity?.toString(), slot0?.tick);
    } else {
      pool = new Pool(token0Instance, token1Instance, fee, slot0?.sqrtPriceX96.toString(), liquidity?.toString(), slot0?.tick);
    }
    let tickSpac = tickSpacing(fee);
    const positionInstance = new Position({
      pool,
      liquidity: liquidity.toString(),
      tickLower: nearestUsableTick(Number(tickLower), tickSpac),
      tickUpper: nearestUsableTick(Number(tickUpper), tickSpac),
    });
    // Get the amounts of token0 and token1
    const amount0 = positionInstance.amount0.toExact();
    const amount1 = positionInstance.amount1.toExact();
    return { amount0, amount1, pool };
  } catch (err) {
    console.log("error in getAmountsForPosition : ", err);
  }
}

export async function increaseLiquidityInV3(
  inputToken,
  outputToken,
  inputAmount,
  outputAmount,
  selectedRouter,
  provider,
  slippage,
  txDeadline,
  id
) {
  try {
    const signer = await provider.getSigner();

    const amountIn = ethers.utils.parseUnits(inputAmount?.toString(), inputToken?.decimals);
    const amountOut = ethers.utils.parseUnits(outputAmount?.toString(), outputToken?.decimals);

    const amountInMin = amountIn.sub(amountIn.mul(Number(slippage * 10000)).div(100 * 10000)).toString();
    const amountOutMin = amountOut.sub(amountOut.mul(Number(slippage * 10000)).div(100 * 10000)).toString();

    const time = Math.floor(Date.now() / 1000) + Number(txDeadline) * 60;
    const deadline = ethers.BigNumber.from(time);

    const positionContract = new Contract(selectedRouter?.NonfungiblePositionManager, IUniswapV3NonfungiblePositionManager?.abi, signer);
    if (inputToken?.address === constants?.AddressZero) {
      const tx = await positionContract?.increaseLiquidity(
        [
          id?.toString(),
          amountIn?.toString(),
          amountOut?.toString(),
          amountInMin?.toString(),
          amountOutMin?.toString(),
          deadline?.toString(),
        ],
        { value: amountIn }
      );
      // await tx.wait();
      return tx;
    } else if (outputToken?.address === constants?.AddressZero) {
      const tx = await positionContract?.increaseLiquidity(
        [
          id?.toString(),
          amountIn?.toString(),
          amountOut?.toString(),
          amountInMin?.toString(),
          amountOutMin?.toString(),
          deadline?.toString(),
        ],
        { value: amountOut }
      );
      // await tx.wait();
      return tx;
    } else {
      const tx = await positionContract?.increaseLiquidity([
        id?.toString(),
        amountIn?.toString(),
        amountOut?.toString(),
        amountInMin?.toString(),
        amountOutMin?.toString(),
        deadline?.toString(),
      ]);
      // await tx.wait();
      return tx;
    }
  } catch (err) {
    console.log("error in increaseLiquidityInV3 : ", err);
    return false;
  }
}

export async function removeLiquidityInV3(
  inputToken,
  outputToken,
  lptoken,
  liquidity100Per,
  value,
  inputAmount,
  outputAmount,
  selectedRouter,
  provider,
  slippage,
  txDeadline,
  id,
  address
) {
  try {
    let MaxUint128 = "340282366920938463463374607431768211455";
    const signer = await provider.getSigner();

    const amountIn = ethers.utils.parseUnits(Number(inputAmount)?.toFixed(Number(inputToken?.decimals))?.toString(), inputToken?.decimals);
    const amountOut = ethers.utils.parseUnits(
      Number(outputAmount)?.toFixed(Number(outputToken?.decimals))?.toString(),
      outputToken?.decimals
    );

    let lptokeninWei;
    if (Number(value) === 100) {
      lptokeninWei = liquidity100Per;
    } else {
      lptokeninWei = ethers.utils.parseUnits(lptoken?.toString(), "18");
    }

    const amountInMin = amountIn.sub(amountIn.mul(Number(Number(slippage) * 10000)).div(100 * 10000)).toString();
    const amountOutMin = amountOut.sub(amountOut.mul(Number(Number(slippage) * 10000)).div(100 * 10000)).toString();

    const amountMinimumIn = amountIn.sub(amountIn.mul(Number(5 * 10000)).div(100 * 10000)).toString();
    const amountMinimumOut = amountOut.sub(amountOut.mul(Number(5 * 10000)).div(100 * 10000)).toString();

    const time = Math.floor(Date.now() / 1000) + Number(txDeadline) * 60;
    const deadline = ethers.BigNumber.from(time);
    const positionContract = new Contract(selectedRouter?.NonfungiblePositionManager, IUniswapV3NonfungiblePositionManager?.abi, signer);

    let removeLiquidityParams = [
      id?.toString(),
      lptokeninWei?.toString(),
      amountInMin?.toString(),
      amountOutMin?.toString(),
      deadline?.toString(),
    ];

    let collectFeeParams = [id, constants?.AddressZero, MaxUint128, MaxUint128];

    if (inputToken?.address === constants?.AddressZero) {
      let multicallArgs = [
        positionContract.interface.encodeFunctionData("decreaseLiquidity", [removeLiquidityParams]),
        positionContract.interface.encodeFunctionData("collect", [collectFeeParams]),
        positionContract.interface.encodeFunctionData("unwrapWETH9", [amountMinimumIn, address]),
        positionContract.interface.encodeFunctionData("sweepToken", [outputToken?.address, amountMinimumOut, address]),
      ];

      const tx = await positionContract.multicall(multicallArgs);
      // await tx.wait();
      return tx;
    } else if (outputToken?.address === constants?.AddressZero) {
      let multicallArgs = [
        positionContract.interface.encodeFunctionData("decreaseLiquidity", [removeLiquidityParams]),
        positionContract.interface.encodeFunctionData("collect", [collectFeeParams]),
        positionContract.interface.encodeFunctionData("unwrapWETH9", [amountMinimumOut, address]),
        positionContract.interface.encodeFunctionData("sweepToken", [inputToken?.address, amountMinimumIn, address]),
      ];
      const tx = await positionContract.multicall(multicallArgs);
      // await tx.wait();
      return tx;
    } else {
      let multicallArgs = [
        positionContract.interface.encodeFunctionData("decreaseLiquidity", [removeLiquidityParams]),
        positionContract.interface.encodeFunctionData("collect", [id, address, MaxUint128, MaxUint128]),
      ];

      const tx = await positionContract.multicall(multicallArgs);
      // await tx.wait();
      return tx;
    }
  } catch (err) {
    console.log("error in removeLiquidityInV3 : ", err);
    return false;
  }
}

export async function getCollectableFee(positionContract, tokenId, address, decimals0, decimals1) {
  try {
    let MaxUint128 = "340282366920938463463374607431768211455";
    const collectData = await positionContract.callStatic.collect([tokenId, address, MaxUint128, MaxUint128]);
    if (collectData) {
      return {
        amount0: ethers.utils.formatUnits(collectData?.amount0, decimals0),
        amount1: ethers.utils.formatUnits(collectData?.amount1, decimals1),
        amount0Wei: collectData?.amount0,
        amount1Wei: collectData?.amount1,
      };
    } else {
      return null;
    }
  } catch (err) {
    console.log("error in getCollectableFee : ", err);
    return null;
  }
}

export async function collectLiquidityFee(inputToken, outputToken, positionContract, tokenId, address, amount0, amount1) {
  try {
    let MaxUint128 = "340282366920938463463374607431768211455";
    const amountMinimumIn = amount0?.amountWei?.sub(amount0?.amountWei?.mul(Number(5 * 10000)).div(100 * 10000)).toString();
    const amountMinimumOut = amount1?.amountWei?.sub(amount1?.amountWei?.mul(Number(5 * 10000)).div(100 * 10000)).toString();

    let collectFeeParams = [tokenId, constants?.AddressZero, MaxUint128, MaxUint128];

    if (inputToken?.address === constants?.AddressZero) {
      let multicallArgs = [
        positionContract.interface.encodeFunctionData("collect", [collectFeeParams]),
        positionContract.interface.encodeFunctionData("unwrapWETH9", [amountMinimumIn, address]),
        positionContract.interface.encodeFunctionData("sweepToken", [outputToken?.address, amountMinimumOut, address]),
      ];

      const tx = await positionContract.multicall(multicallArgs);
      return tx;
    } else if (outputToken?.address === constants?.AddressZero) {
      let multicallArgs = [
        positionContract.interface.encodeFunctionData("collect", [collectFeeParams]),
        positionContract.interface.encodeFunctionData("unwrapWETH9", [amountMinimumOut, address]),
        positionContract.interface.encodeFunctionData("sweepToken", [inputToken?.address, amountMinimumIn, address]),
      ];
      const tx = await positionContract.multicall(multicallArgs);
      return tx;
    } else {
      let tx = positionContract.collect([tokenId, address, MaxUint128, MaxUint128]);
      return tx;
    }
  } catch (err) {
    console.log("error in getCollectableFee : ", err);
    return null;
  }
}
