import React, { useRef } from "react";

function CollectFeeConfirmModel({ loading, setLoading }) {
  return loading === 2 ? (
    <>
      <div className="row justify-content-center">
        <div className="col-10 col-sm-3 col-md-4 col-lg-4 col-xl-2 rounded-15  modal-overlay_swapToken bg-white-new">
          <div className="modal-container_swapToken bg-white-new" onClick={(e) => e.stopPropagation()}>
            <div className="d-flex justify-content-end">
              <i className="bi bi-x-lg pointer dark-text" onClick={() => setLoading(0)}></i>
            </div>
            <div className="mt-5 d-flex justify-content-center align-items-center">
              <div className="nb-spinner-xl"></div>
            </div>
            <div className="mt-5">
              <h5 className="d-flex justify-content-center font-sm dark-text">
                <b>Confirmation</b>
              </h5>
            </div>
            <div className="mt-3">
              <div className="d-flex justify-content-center align-items-center">
                <span className="dark-text text-center font-xsss">Collecting fees</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={loading === 2 ? "modal-backdrop-swaptoken fade show" : "d-none"}></div>
    </>
  ) : (
    ""
  );
}

export default CollectFeeConfirmModel;
