import React from "react";
import { formatAmount, formatNumber } from "../../../../Components/Function/commonFunction";

function V2LiquidityInfo({ selectedTokenOne, selectedTokenTwo, pairInfo, liquidityInfo }) {
  return (
    <>
      <div className="card-body border p-relative rounded-15 p-0  mt-2 chain-bg dark-border">
        <p className="text-gray-900 rajdhani-500 font-xsm p-3 py-2 mb-0 dark-text">Prices and pool share</p>
        <div className="card-body p-relative rounded-15  p-2 mt-1 dark-border" style={{ borderTop: "1px solid #6C86AD" }}>
          <div className="d-flex justify-content-between">
            <div>
              <p className="font-sm rajdhani-500 mb-0 text-center dark-text">{formatAmount(pairInfo?.price0)}</p>
              <h6 className="m-0 font-xss rajdhani-500 text-gray-600 dark-text">
                {selectedTokenTwo?.symbol} per {selectedTokenOne?.symbol}
              </h6>
            </div>
            <div>
              <p className="font-sm rajdhani-500 mb-0 text-center dark-text">{formatAmount(pairInfo?.price1)}</p>
              <h6 className="m-0 font-xss rajdhani-500 text-gray-600 dark-text">
                {selectedTokenOne?.symbol} per {selectedTokenTwo?.symbol}
              </h6>
            </div>
            <div>
              <p className="font-sm rajdhani-500 mb-0 text-center dark-text">
                {liquidityInfo?.share ? formatAmount(liquidityInfo?.share) + "%" : "-"}
              </p>
              <h6 className="m-0 font-xss rajdhani-500 text-gray-600 dark-text">Share of pool</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default V2LiquidityInfo;
