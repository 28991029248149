/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import V2InputLiquidityBox from "./V2InputLiquidityBox";
import V2LiquidityWarmimgBox from "./V2LiquidityWarmimgBox";
import V2LiquidityInfo from "./V2LiquidityInfo";
import V2LiquidityWalletButton from "./V2LiquidityWalletButton";
import { walletConnect } from "../../../../contexts/WalletConnect";
import { globalStates } from "../../../../contexts/GlobalStates";
import { getPool } from "../../../../Components/Function/swapFunctions";
import { formatAmount } from "../../../../Components/Function/commonFunction";
import { constants } from "ethers";
import { getWethV2Address, giveAllowance } from "../../../../Components/Function/ethersFunctions";
import { getReserveV2, addLiquidityInV2, storeV2PairInLocalStorage } from "../../../../Components/Function/liquidityFunctions";
import ApproveModal from "../../../../Components/Coman/ApproveModal";
import LiquidityConfirmModel from "../../../../Components/Coman/LiquidityConfirmModel";
import AddLiquidityInfoModel from "../../../../Components/Coman/AddLiquidityInfoModel";
import LiquidityTxSubmittedModel from "../../../../Components/Coman/LiquidityTxSubmittedModel";

const AddLiquidityBox = ({
  setSwapBoxModalChange,
  selectedTokenOne,
  selectedTokenTwo,
  inputAmountOne,
  inputAmountTwo,
  setInputAmountOne,
  setInputAmountTwo,
  inputWay,
  setInputWay,
  setTokenInfo,
}) => {
  const { settingData } = useContext(globalStates);
  const { selectedChain, provider, address, signer } = useContext(walletConnect);
  const [pairInfo, setPairInfo] = useState(null);
  const [liquidityInfo, setLiquidityInfo] = useState(null);
  const [spinner, setSpinner] = useState({
    inpuSpinner: false,
    approveTokenOne: false,
    approveTokenTwo: false,
    outputSpinner: false,
    addLiquiSpinner: false,
    addLiquiInfoModal: false,
    addLiquiTxSubModal: false,
    addLiquiTxHash: "",
  });

  const getPoolInfoForV2 = async () => {
    try {
      if (selectedTokenOne && selectedTokenTwo && provider && selectedChain) {
        const selectedRouter = selectedChain?.routers[0];
        const weth = await getWethV2Address(selectedRouter, provider);
        const token0 = selectedTokenOne?.address === constants?.AddressZero ? weth : selectedTokenOne?.address;
        const token1 = selectedTokenTwo?.address === constants?.AddressZero ? weth : selectedTokenTwo?.address;
        const poolAddress = await getPool(token0, token1, selectedRouter?.feeV2, selectedRouter, provider, "v2");
        if (poolAddress !== constants?.AddressZero) {
          const poolReserve = await getReserveV2(poolAddress, selectedTokenOne, selectedTokenTwo, weth, address, provider);
          setPairInfo({
            poolAddress,
            reserve: poolReserve,
            price0: Number(poolReserve[1]) / Number(poolReserve[0]),
            price1: Number(poolReserve[0]) / Number(poolReserve[1]),
          });
        } else {
          setPairInfo(null);
        }
      }
    } catch (err) {
      console.log("error in getPoolInfoForV2 : ", err);
    }
  };

  const handleInputOneChangeOne = async (value) => {
    if (pairInfo && pairInfo?.poolAddress && pairInfo?.reserve) {
      setInputAmountOne(value);
      setInputAmountTwo(formatAmount((Number(value) * Number(pairInfo?.reserve[1])) / Number(pairInfo?.reserve[0])));
      const liquidity0 = (Number(value) * Number(pairInfo?.reserve[2])) / Number(pairInfo?.reserve[0]);
      const liquidity1 = (Number(inputAmountTwo) * Number(pairInfo?.reserve[2])) / Number(pairInfo?.reserve[1]);
      const liquidity = liquidity0 > 0 ? Math.max(liquidity0, liquidity1) : liquidity1;
      setLiquidityInfo({
        liquidity,
        share: (liquidity / (liquidity + Number(pairInfo?.reserve[2]))) * 100,
      });
    } else {
      setInputAmountOne(value);
    }
  };

  useEffect(() => {
    handleInputOneChangeOne(inputAmountOne);
  }, [selectedTokenOne, selectedTokenTwo, pairInfo]);

  const handleInputOneChangeTwo = async (value) => {
    if (pairInfo && pairInfo?.poolAddress && pairInfo?.reserve) {
      setInputAmountTwo(value);
      setInputAmountOne(formatAmount((Number(value) * Number(pairInfo?.reserve[0])) / Number(pairInfo?.reserve[1])));
      const liquidity0 = (Number(value) * Number(pairInfo?.reserve[2])) / Number(pairInfo?.reserve[0]);
      const liquidity1 = (Number(inputAmountTwo) * Number(pairInfo?.reserve[2])) / Number(pairInfo?.reserve[1]);
      const liquidity = liquidity0 > 0 ? Math.max(liquidity0, liquidity1) : liquidity1;
      setLiquidityInfo({
        liquidity,
        share: (liquidity / (liquidity + Number(pairInfo?.reserve[2]))) * 100,
      });
    } else {
      setInputAmountTwo(value);
    }
  };

  useEffect(() => {
    getPoolInfoForV2();
  }, [selectedTokenOne, selectedTokenTwo, provider, selectedChain, address]);

  const addLiquidity = async () => {
    try {
      setSpinner((prev) => ({
        ...prev,
        addLiquiInfoModal: false,
        addLiquiSpinner: true,
      }));
      const result = await addLiquidityInV2(
        selectedTokenOne,
        selectedTokenTwo,
        inputAmountOne,
        inputAmountTwo,
        selectedChain?.routers[0],
        address,
        provider,
        settingData?.slippage,
        settingData?.txDeadline
      );
      if (result?.hash) {
        storeV2PairInLocalStorage(selectedChain?.chainId, selectedTokenOne, selectedTokenTwo);
        setSpinner((prev) => ({
          ...prev,
          addLiquiSpinner: false,
          addLiquiTxSubModal: true,
          addLiquiTxHash: result?.hash,
        }));
      } else {
        setSpinner((prev) => ({
          ...prev,
          addLiquiInfoModal: true,
          addLiquiSpinner: false,
          addLiquiTxHash: "",
        }));
      }
    } catch (err) {
      console.log("error in addLiquidity : ", err);
      setSpinner((prev) => ({
        ...prev,
        addLiquiInfoModal: true,
        addLiquiSpinner: false,
        addLiquiTxHash: "",
      }));
    }
  };

  const approveTokenOne = async () => {
    try {
      setSpinner((prev) => ({ ...prev, approveTokenOne: true }));
      const result = await giveAllowance(selectedTokenOne?.address, selectedChain?.routers[0].routerV2, null, signer);
      if (result?.hash) {
        setTokenInfo();
        setSpinner((prev) => ({ ...prev, approveTokenOne: false }));
      } else {
        setSpinner((prev) => ({ ...prev, approveTokenOne: false }));
      }
    } catch {
      setSpinner((prev) => ({ ...prev, approveTokenOne: false }));
    }
  };

  const approveTokenTwo = async () => {
    try {
      setSpinner((prev) => ({ ...prev, approveTokenTwo: true }));
      const result = await giveAllowance(selectedTokenTwo?.address, selectedChain?.routers[0].routerV2, null, signer);
      if (result?.hash) {
        setTokenInfo();
        setSpinner((prev) => ({ ...prev, approveTokenTwo: false }));
      } else {
        setSpinner((prev) => ({ ...prev, approveTokenTwo: false }));
      }
    } catch {
      setSpinner((prev) => ({ ...prev, approveTokenTwo: false }));
    }
  };

  return (
    <>
      <div className="border dark-border rounded-15 p-3">
        <div className="d-flex mb-0 justify-content-between align-items-center">
          <p className="text-gray-900 rajdhani-700 font-sm pb-0 mb-0">
            <Link to="/v2Pool">
              <i className="fa fa-chevron-left d-flex align-items-center pointer dark-text  text-gray-800 "></i>
            </Link>
          </p>
          <h6 className="text-gray-900 rajdhani-400 pb-0 mb-0 ms-sm-0 ms-md-3 sm-lg-4 ms-xl-5 ms-0 dark-text font-md">Add liquidity</h6>
          <p className="text-gray-900 rajdhani-700 pb-0 mb-0 d-flex justify-content-between align-items-center ">
            <button className="btn rajdhani-400 rounded-10  font-xs dark-text py-1 pe-0" onClick={() => setSwapBoxModalChange("setting")}>
              <i className="fa fa-gear ms-2 font-md mt-1"></i>
            </button>
          </p>
        </div>
        <V2LiquidityWarmimgBox />
        <div className="mt-4 ">
          <V2InputLiquidityBox
            type={0}
            setSwapBoxModalChange={setSwapBoxModalChange}
            selectedToken={selectedTokenOne}
            inputAmount={inputAmountOne}
            setInputAmount={handleInputOneChangeOne}
            spinner={spinner?.inpuSpinner}
          />
        </div>
        <i className="bi bi-plus text-center d-flex justify-content-center font-md my-2 dark-text"></i>
        <V2InputLiquidityBox
          type={1}
          setSwapBoxModalChange={setSwapBoxModalChange}
          selectedToken={selectedTokenTwo}
          inputAmount={inputAmountTwo}
          setInputAmount={handleInputOneChangeTwo}
          spinner={spinner?.outputSpinner}
        />
        {selectedTokenTwo && pairInfo && (
          <div className="mt-4">
            <V2LiquidityInfo
              selectedTokenOne={selectedTokenOne}
              selectedTokenTwo={selectedTokenTwo}
              pairInfo={pairInfo}
              liquidityInfo={liquidityInfo}
            />
          </div>
        )}
        <div className="mt-3">
          <div className="d-flex justify-content-between">
            {selectedTokenOne?.address !== constants?.AddressZero && Number(selectedTokenOne?.allowanceV2) < Number(inputAmountOne) ? (
              <button
                className="btn header-btn rajdhani-500 rounded-10 mb-1 px-4 font-md text-white btn-bg-blue w-100 mt-2 me-2"
                onClick={approveTokenOne}
              >
                Approve {selectedTokenOne?.symbol}
              </button>
            ) : (
              ""
            )}
            {selectedTokenTwo?.address !== constants?.AddressZero && Number(selectedTokenTwo?.allowanceV2) < Number(inputAmountTwo) ? (
              <button
                className="btn header-btn rajdhani-500 rounded-10 mb-1 px-4 font-md text-white btn-bg-blue w-100 mt-2 ms-2"
                onClick={approveTokenTwo}
              >
                Approve {selectedTokenTwo?.symbol}
              </button>
            ) : (
              ""
            )}
          </div>
          <V2LiquidityWalletButton
            selectedTokenOne={selectedTokenOne}
            selectedTokenTwo={selectedTokenTwo}
            inputAmountOne={inputAmountOne}
            inputAmountTwo={inputAmountTwo}
            pairInfo={pairInfo}
            spinner={spinner}
            setSpinner={setSpinner}
          />
        </div>
        <ApproveModal spinner={spinner?.approveTokenOne} selectedToken={selectedTokenOne} />
        <ApproveModal spinner={spinner?.approveTokenTwo} selectedToken={selectedTokenTwo} />
        <LiquidityConfirmModel
          text="Supplying"
          spinner={spinner}
          setSpinner={setSpinner}
          selectedTokenOne={selectedTokenOne}
          selectedTokenTwo={selectedTokenTwo}
          inputAmountOne={inputAmountOne}
          inputAmountTwo={inputAmountTwo}
        />
        <LiquidityTxSubmittedModel spinner={spinner} setSpinner={setSpinner} />
        <AddLiquidityInfoModel
          spinner={spinner}
          setSpinner={setSpinner}
          addLiquidity={addLiquidity}
          selectedTokenOne={selectedTokenOne}
          selectedTokenTwo={selectedTokenTwo}
          inputAmountOne={inputAmountOne}
          inputAmountTwo={inputAmountTwo}
          pairInfo={pairInfo}
          liquidityInfo={liquidityInfo}
        />
      </div>
      {/* <ApproveModal spinner={spinner?.approveTokenOne} selectedToken={selectedTokenOne} />
      <ApproveModal spinner={spinner?.approveTokenTwo} selectedToken={selectedTokenTwo} /> */}
      <LiquidityConfirmModel
        text="Supplying"
        spinner={spinner}
        setSpinner={setSpinner}
        selectedTokenOne={selectedTokenOne}
        selectedTokenTwo={selectedTokenTwo}
        inputAmountOne={inputAmountOne}
        inputAmountTwo={inputAmountTwo}
      />
      <LiquidityTxSubmittedModel spinner={spinner} setSpinner={setSpinner} />
      <AddLiquidityInfoModel
        spinner={spinner}
        setSpinner={setSpinner}
        addLiquidity={addLiquidity}
        selectedTokenOne={selectedTokenOne}
        selectedTokenTwo={selectedTokenTwo}
        inputAmountOne={inputAmountOne}
        inputAmountTwo={inputAmountTwo}
        pairInfo={pairInfo}
        liquidityInfo={liquidityInfo}
      />
    </>
  );
};

export default AddLiquidityBox;
