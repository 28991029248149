/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useEffect, useState } from "react";
import { findTokenForImport } from "../Function/ethersFunctions";
import { walletConnect } from "../../contexts/WalletConnect";
import { getImportedTokens } from "../Function/commonFunction";
import { globalStates } from "../../contexts/GlobalStates";
import { useNavigate } from "react-router-dom";

function AddTokenModel({ setSwapBoxModalChange }) {
  const { provider, isConnected, signer, getAndSetSelectedChain, selectedChain } = useContext(walletConnect);
  const { chainTokens } = useContext(globalStates);
  const [search, setSearch] = useState("");
  const [foundToken, setFoundToken] = useState("");
  const [isImported, setIsImported] = useState("");
  const navigate = useNavigate();

  const findTokenAndImportToLocal = async () => {
    try {
      setIsImported(false);
      const find = chainTokens.find((item) => item.address?.toLowerCase() === search?.toLowerCase());
      if (find) {
        if (find) setFoundToken(find);
        setIsImported(true);
      } else {
        if (provider) {
          const tokenDetails = await findTokenForImport(search, provider);
          if (tokenDetails) setFoundToken(tokenDetails);
          else setFoundToken(null);
        }
      }
    } catch {}
  };

  useEffect(() => {
    findTokenAndImportToLocal();
  }, [search]);

  const importToken = async () => {
    try {
      const impTokens = getImportedTokens();
      impTokens.push({
        ...foundToken,
        icon: "",
        chainId: selectedChain?.chainId,
      });
      localStorage.setItem("${value:importedTokens[v4s254]}", JSON.stringify(impTokens));
      setSwapBoxModalChange("swap");
      getAndSetSelectedChain();
    } catch {}
  };

  return (
    <div className="">
      <div className="row justify-content-center ">
        <div className="col-12 mt-3 rounded-20 pb-3 bg-white-new  p-0  h-400">
          <div className="d-flex py-2 px-3 ">
            <i
              className="fa fa-chevron-left d-flex align-items-center pointer dark-text "
              onClick={() => setSwapBoxModalChange("swap")}
              // onClick={() => navigate(-1)}
            ></i>
            <h5 className="rajdhani-700 font-xxxl text-gray-900 mb-0 py-2 dark-text m-auto">Add a token</h5>
          </div>

          <div className="form-group has-search1 px-md-3 px-sm-1 d-flex mx-md-0 mx-sm-2 mx-2">
            <span className="fa fa-search form-control-feedback1  " />
            <input
              type="text"
              value={search}
              className="form-control rounded-10 dark-text  font-sm"
              placeholder="Search "
              style={{ background: "none" }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {foundToken ? (
            <>
              <div className="mt-4 ">
                <div className="d-flex justify-content-between align-items-center gap-2 eth-hover hover_question  p-2 m-1 mx-2">
                  {foundToken?.icon ? (
                    <img src={foundToken?.icon} alt="user" class="h-30 w-30 me-2 rounded-circle"></img>
                  ) : (
                    <figure className="avater mb-0 me-0">
                      <svg
                        viewBox="0 0 24 24"
                        className="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                        color="text"
                        width="30px"
                        height="30px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                    </figure>
                  )}
                  <div className="me-auto text-grey-500 text-start align-items-center">
                    <h5 className="text-gray-900 rajdhani-600 font-xs dark-text lh-22 dark-text text-start m-0 dark-text">
                      {foundToken.symbol}
                    </h5>
                    <h6 className="text-gray-500 lh-1 mb-0 font-xss rajdhani-300">{foundToken?.name}</h6>
                  </div>
                  <div className="text-end">
                    {isImported ? (
                      <a className="mx-3 dark-text" href={`${selectedChain?.explorerUrl}token/${foundToken?.address}`} target="_blank">
                        <i className="bi bi-box-arrow-up-right dark-text"></i>
                      </a>
                    ) : (
                      <button
                        type="button"
                        className="btn  py-1 font-sm router-width rounded-10 btn-primary text-white "
                        onClick={importToken}
                      >
                        Import
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center m-3">
                <div className="card-body rounded-10 border-box-danger h-170 d-flex justify-content-center align-items-start">
                  <h6 className="rajdhani-500 mb-0 pb-0 font-xs dark-text">
                    Anyone can create any token, including fake versions of the existing tokens. Caution required. Some tokens and their
                    technical parameters may be incompatible with Value protocols. By importing this custom token you acknowledge and accept
                    the risks.
                  </h6>
                </div>
              </div>
            </>
          ) : !foundToken && search ? (
            <div className="d-flex justify-content-center align-items-center gap-2 p-3 dark-text">
              <b>No token found!</b>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default AddTokenModel;
