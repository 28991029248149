import React, { useRef } from "react";

const ApproveModal = ({ spinner, selectedToken }) => {
  const modalRef = useRef(null);
  return spinner ? (
    <>
      <div>
        <>
          <div className="row justify-content-center">
            <div
              ref={modalRef}
              className="col-10 col-sm-3 col-md-4 col-lg-4 col-xl-2 rounded-15 modal-overlay_swapToken bg-white-new"
            >
              <div
                className="modal-container_swapToken bg-white-new"
                onClick={(e) => e.stopPropagation()}
              >
                {/* <div className="d-flex justify-content-end">
                  <i className="bi bi-x-lg pointer dark-text" onClick={closeSwapModel}></i>
                </div> */}
                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <div className="mt-5 nb-spinner-xl"></div>
                </div>
                <div className="mt-5">
                  <h5 className="d-flex justify-content-center font-sm dark-text">
                    <b>Approve {selectedToken?.symbol}</b>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      <div
        className={spinner ? "modal-backdrop-swaptoken fade show" : "d-none"}
      ></div>
    </>
  ) : (
    ""
  );
};

export default ApproveModal;
