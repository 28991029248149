import React, { useState } from "react";
import { FeeText } from "../../../../constants/constants";

function V3LiquidityOpenPair({
  feeWisePairs,
  selectedFeePair,
  handleSelectPair,
}) {
  const [openFeePair, setOpenFeePair] = useState(false);
  const handleOpenFeePair = () => {
    setOpenFeePair(!openFeePair);
  };
  return (
    <>
      <div className="row g-1 align-items-end">
        {selectedFeePair ? (
          <div className="col-12">
            <div className="card-body border dark-border border border-1p-relative rounded-20 p-3 mt-2 ">
              <div className="d-flex  justify-content-between align-items-center mt-1">
                <div>
                  <p className="text-gray-900 rajdhani-400 font-sm  mb-0 dark-text ">
                    {selectedFeePair?.feeInPer}% fee tier
                  </p>
                  <p className=" pe-2  text-gray-900 ls-1 pb-0 mb-0 font-xxs   taxt-black">
                    <span className="font-xsss bg-l dark-text rajdhani-400 px-2 py-1 rounded-6 ">
                      {selectedFeePair?.pool ? "Created" : "Not created"}
                    </span>
                  </p>
                </div>
                <p className="text-gray-900 rajdhani-600  mb-0">
                  <button
                    className="btn header-btn rounded-6 p-2 py-1 ont-sm border border-light bg-border-light dark-text "
                    onClick={() => handleOpenFeePair()}
                  >
                    <span className>{openFeePair ? "Hide" : "Edit"}</span>
                  </button>
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {openFeePair && (
          <>
            {feeWisePairs?.length
              ? feeWisePairs?.map((item, index) => {
                  return (
                    <div
                      className="col-6 col-sm-3 m-0 "
                      onClick={() => handleSelectPair(item)}
                    >
                      <div
                        className={`card-body p-relative rounded-20 p-2 px-2 mt-2 pointer ${
                          item.fee == selectedFeePair?.fee ? "border border-blue" : "border-light"
                        }`}
                        style={{ height: "128px" }}
                      >
                        <div className="d-flex justify-content-between">
                          <p className="text-gray-900 rajdhani-700 font-sm  mb-1 dark-text ">
                            {item.feeInPer}%
                          </p>
                          {item.fee == selectedFeePair?.fee ? (
                            <i class="bi bi-check-circle-fill text-blue"></i>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="h-51 font-xxs  mb-0 ls-1 dark-text">{FeeText.get(item.fee)}</p>

                        <p className=" pe-2  text-gray-900 ls-1 pb-0 mb-0 font-xxs   taxt-black">
                          <span className="font-xsss bg-l dark-text rajdhani-400 px-2 py-1 rounded-6 ">
                            {item.pool ? "Created" : "Not created"}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })
              : ""}
          </>
        )}
      </div>
    </>
  );
}

export default V3LiquidityOpenPair;
