/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import { walletConnect } from "../../../../contexts/WalletConnect";
import { globalStates } from "../../../../contexts/GlobalStates";
import {
  getTokenAllowance,
  getTokenAllowanceForSwap,
  getTokenBalance,
  getTokenDecimals,
} from "../../../../Components/Function/ethersFunctions";
import SwapHeader from "../../../../Components/Coman/SwapHeader";
import TokenModel from "../../../../Components/Coman/TokenModel";
import SwapSettingsModel from "../../../../Components/Coman/SwapSettingsModel";
import V3LiquidityBox from "./V3LiquidityBox";
import Footer from "../../../../Components/Coman/Footer";
import { isPairAvailable, getPoolData } from "../../../../Components/Function/ethersFunctions";

export default function AddLiquidityV3() {
  const [swapBoxModalChange, setSwapBoxModalChange] = useState("");
  const dropdownRef = useRef();
  const { provider, address, selectedRouter, inputToken, outputToken, signer, selectedChain } = useContext(walletConnect);
  const { handleTokenSelectSearchParams, chainTokens } = useContext(globalStates);

  const [selectedTokenOne, setSelectedTokenOne] = useState(null);
  const [selectedTokenTwo, setSelectedTokenTwo] = useState(null);

  const [inputAmountOne, setInputAmountOne] = useState("");
  const [inputAmountTwo, setInputAmountTwo] = useState("");

  const handleSelecteTokneOne = (item) => {
    handleTokenSelectSearchParams(item.address?.toLowerCase(), "input");
    setSwapBoxModalChange("");
  };

  const handleSelecteTokneTwo = (item) => {
    handleTokenSelectSearchParams(item.address?.toLowerCase(), "output");
    setSwapBoxModalChange("");
  };
  const setTokenInfo = async () => {
    if (inputToken) {
      const findToken = chainTokens.find((item) => item?.address?.toLowerCase() === inputToken?.toLowerCase());
      if (findToken) {
        setSelectedTokenOne(findToken);
        if (address && provider) {
          const balance = await getTokenBalance(findToken?.address, address, provider);
          const allowance = await getTokenAllowance(
            findToken?.address,
            address,
            selectedChain?.routers[0]?.NonfungiblePositionManager,
            provider
          );
          const decimals = await getTokenDecimals(findToken?.address, provider);
          setSelectedTokenOne((prev) => ({
            ...prev,
            balance,
            allowance: allowance ? allowance : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenOne(null);
      }
    } else {
      if (chainTokens[0] && outputToken?.toLowerCase() !== chainTokens[0]?.address?.toLowerCase()) {
        setSelectedTokenOne(chainTokens[0]);
        if (address && provider) {
          const balance = await getTokenBalance(chainTokens[0]?.address, address, provider, selectedRouter);
          const allowance = await getTokenAllowance(
            chainTokens[0]?.address,
            address,
            selectedChain?.routers[0]?.NonfungiblePositionManager,
            provider
          );
          const decimals = await getTokenDecimals(chainTokens[0]?.address, provider);
          setSelectedTokenOne((prev) => ({
            ...prev,
            balance,
            allowance: allowance ? allowance : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenOne(null);
      }
    }
    if (outputToken) {
      const findToken = chainTokens.find((item) => item?.address?.toLowerCase() === outputToken?.toLowerCase());
      if (findToken) {
        setSelectedTokenTwo(findToken);
        if (address && provider) {
          const balance = await getTokenBalance(findToken?.address, address, provider, selectedRouter);
          const allowance = await getTokenAllowance(
            findToken?.address,
            address,
            selectedChain?.routers[0]?.NonfungiblePositionManager,
            provider
          );
          const decimals = await getTokenDecimals(findToken?.address, provider);
          setSelectedTokenTwo((prev) => ({
            ...prev,
            balance,
            allowance: allowance ? allowance : "0",
            decimals: decimals,
          }));
        }
      } else {
        setSelectedTokenTwo(null);
      }
    } else {
      setSelectedTokenTwo(null);
    }
  };

  useEffect(() => {
    if (chainTokens) {
      setTokenInfo();
    }
  }, [inputToken, outputToken, chainTokens, provider]);

  return (
    <>
      <div className=" main-vh-11 bg-dark-black dashboard-bg">
        <div
          className="main-wrapper "
          // style={{ minHeight: "100%" }}
        >
          <SwapHeader />
          <div className="container-wide">
            <div className="banner-wrapper py-4 mt-lg-100 mt-0 ">
              <div className="row  pb-3 mt-5 d-flex justify-content-center ">
                <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 m-0 p-2" ref={dropdownRef}>
                  {swapBoxModalChange === "selectTokenModalInput" ? (
                    <div className="mx-2 p-2">
                      <TokenModel
                        setSwapBoxModalChange={setSwapBoxModalChange}
                        handleSelecteTokne={handleSelecteTokneOne}
                        selectedTokenOne={selectedTokenOne}
                        selectedTokenTwo={selectedTokenTwo}
                      />
                    </div>
                  ) : swapBoxModalChange === "setting" ? (
                    <div className="mx-2 p-2">
                      <SwapSettingsModel setSwapBoxModalChange={setSwapBoxModalChange} swapBoxModalChange={swapBoxModalChange} />
                    </div>
                  ) : swapBoxModalChange === "selectTokenModalOutput" ? (
                    <div className="mx-2 p-2">
                      <TokenModel
                        setSwapBoxModalChange={setSwapBoxModalChange}
                        handleSelecteTokne={handleSelecteTokneTwo}
                        selectedTokenOne={selectedTokenOne}
                        selectedTokenTwo={selectedTokenTwo}
                      />
                    </div>
                  ) : (
                    <V3LiquidityBox
                      setSwapBoxModalChange={setSwapBoxModalChange}
                      selectedTokenOne={selectedTokenOne}
                      selectedTokenTwo={selectedTokenTwo}
                      inputAmountOne={inputAmountOne}
                      setInputAmountOne={setInputAmountOne}
                      setInputAmountTwo={setInputAmountTwo}
                      inputAmountTwo={inputAmountTwo}
                      setTokenInfo={setTokenInfo}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
