import React, { useEffect, useRef, memo, useContext } from "react";
import { globalStates } from "../../contexts/GlobalStates";

function TradingViewWidget() {
  const container = useRef();
  const { theme } = useContext(globalStates);

  useEffect(() => {
    const scriptContainer = container.current;

    while (scriptContainer.firstChild) {
      scriptContainer.removeChild(scriptContainer.firstChild);
    }

    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "width": "740",
          "height": "400",
          "symbol": "BINANCE:ETHUSDT",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "${theme === "dark-mode" ? "Dark" : "Light"}",
          "style": "1",
          "locale": "en",
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
    scriptContainer.appendChild(script);
    return () => {
      while (scriptContainer.firstChild) {
        scriptContainer.removeChild(scriptContainer.firstChild);
      }
    };
  }, [theme]);

  return (
    <div
      className="tradingview-widget-container"
      ref={container}
      style={{ height: "400px", width: "100%" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "calc(100% - 32px)", width: "100%" }}
      ></div>
      <div className="tradingview-widget-copyright"></div>
    </div>
  );
}

export default memo(TradingViewWidget);
