import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import ImportInputV2Pool from "./ImportInputV2Pool";
import ImportV2PoolWarning from "./ImportV2PoolWarning";
import ImportV2PoolButton from "./ImportV2PoolButton";
import {
  getFactory,
  getWethV2Address,
} from "../../../../Components/Function/ethersFunctions";
import { getPool } from "../../../../Components/Function/swapFunctions";
import {
  getUsersReserveTokensForV2,
  getV2PerticularPairFromLocalStorage,
} from "../../../../Components/Function/liquidityFunctions";
import { walletConnect } from "../../../../contexts/WalletConnect";
import { constants } from "ethers";

function ImportV2PoolBox({
  setSwapBoxModalChange,
  selectedTokenOne,
  selectedTokenTwo,
}) {
  const { selectedChain, provider, address } = useContext(walletConnect);
  const [buttonStatus, setButtonStatus] = useState(0);

  const [loading, steLoading] = useState(false);
  const [pair, setPair] = useState(null);

  const getPoolInfoForV2 = async () => {
    try {
      const factory = await getFactory(
        selectedChain?.routers[0],
        "v2",
        provider
      );
      const weth = await getWethV2Address(selectedChain?.routers[0], provider);
      const userData = await getUsersReserveTokensForV2(
        factory,
        address,
        weth,
        selectedTokenOne,
        selectedTokenTwo,
        provider
      );
      if (userData) {
        if (Number(userData?.lptokens) > 0) {
          setPair({
            share: userData?.share,
            token0Amount: userData?.token0Amount,
            token1Amount: userData?.token1Amount,
            lptokens: userData?.lptokens,
          });
          setButtonStatus(3);
        } else {
          setButtonStatus(4);
        }
      } else {
        setPair();
        setButtonStatus(2);
      }
    } catch (err) {
      setPair(null);
    }
  };

  useEffect(() => {
    if (
      selectedTokenOne &&
      selectedTokenTwo &&
      provider &&
      selectedChain &&
      address
    ) {
      getPoolInfoForV2();
    }
  }, [selectedTokenOne, selectedTokenTwo, provider, selectedChain, address]);

  useEffect(() => {
    if (address) {
      setButtonStatus(1);
    } else {
      setButtonStatus(0);
    }
  }, [address]);
  return (
    <>
      <div className="border dark-border  rounded-15 p-3">
        <div className="d-flex mb-0 justify-content-between  align-items-center ">
          <p className="text-gray-900 rajdhani-700 font-sm pb-0 mb-0">
            <Link to="/v2Pool">
              <i className="fa fa-chevron-left d-flex align-items-center pointer dark-text  text-gray-800 " />
            </Link>
          </p>
          <h6 className="text-gray-900 rajdhani-400 pb-0 mb-0  ms-0 dark-text font-md">
            Import V2 pool
          </h6>
          <p className="text-gray-900 rajdhani-700 pb-0 mb-0 d-flex justify-content-between align-items-center ">
            <button
              className="btn rajdhani-400 rounded-10  font-xs dark-text py-1 pe-0"
              onClick={() => setSwapBoxModalChange("setting")}
            >
              <i className="fa fa-gear ms-2 font-md mt-1"></i>
            </button>
          </p>
        </div>

        {/*  blue box start*/}
        <ImportV2PoolWarning />

        {/*  blue box end*/}
        <div className=" mt-4">
          <ImportInputV2Pool
            type={0}
            setSwapBoxModalChange={setSwapBoxModalChange}
            selectedToken={selectedTokenOne}
          />
        </div>
        <i className="bi bi-plus text-center d-flex justify-content-center font-md my-2 dark-text" />
        <ImportInputV2Pool
          type={1}
          setSwapBoxModalChange={setSwapBoxModalChange}
          selectedToken={selectedTokenTwo}
        />
        <ImportV2PoolButton
          selectedTokenOne={selectedTokenOne}
          selectedTokenTwo={selectedTokenTwo}
          buttonStatus={buttonStatus}
          pair={pair}
        />
      </div>
    </>
  );
}

export default ImportV2PoolBox;
