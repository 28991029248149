import React from "react";
import { Link } from "react-router-dom";
import { formatAmount } from "../../../../Components/Function/commonFunction";

function RemoveLiquidityV2Box({
  pair,
  Value,
  setValue,
  handleSliderChange,
  amountOut0,
  amountOut1,
  settingData,
  approveToken,
  setSpinner,
  setSwapBoxModalChange,
}) {
  return (
    <>
      <div className="">
        <div className="d-flex mb-0 justify-content-between align-items-center">
          <p className="text-gray-900 rajdhani-700 font-sm pb-0 mb-0">
            <Link to="/v2Pool">
              <i class="fa fa-chevron-left d-flex align-items-center pointer dark-text  text-gray-800"></i>
            </Link>
          </p>
          <h5 className="text-gray-900 rajdhani-400 pb-0 mb-0 ms-sm-0 ms-md-3 sm-lg-4 ms-xl-5 ms-0 dark-text font-sm">
            Remove {pair?.token0?.symbol}-{pair?.token1.symbol} Liquidity
          </h5>
          <p className="text-gray-900 rajdhani-700 pb-0 mb-0 d-flex justify-content-between align-items-center ">
            <button className="btn rajdhani-400 rounded-10  font-xs dark-text py-1 pe-0" onClick={() => setSwapBoxModalChange("setting")}>
              <i className="fa fa-gear ms-2 font-md mt-1"></i>
            </button>
          </p>
        </div>
        <hr style={{ border: "1px solid #6C86AD" }} />

        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="text-gray-900 rajdhani-500 mb-0  d-flex  align-items-center ">
              <span className="d-flex font-sm align-items-center text-gray-600">Amount</span>
            </h6>
            {/* <h6 className="text-gray-900 rajdhani-500 mb-0  d-flex  align-items-center ">
              <span className="text-blue d-flex font-xs align-items-center">
                Detalied
              </span>
            </h6> */}
          </div>
          <div className="card-body border dark-border bg-l  p-relative rounded-15 p-2 py-1 mt-2">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                <h2 className="dark-text rajdhani-700 mb-0">{Value}%</h2>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 col-8 d-flex justify-content-end gap-2">
                <button
                  className="btn form-select rajdhani-600 rounded-10 py-0 font-xs px-sm-1 px-md-2 px-1 h-40-btn text-white b-none"
                  onClick={() => setValue(25)}
                >
                  25%
                </button>
                <button
                  className="btn form-select rajdhani-600 rounded-10 py-0 font-xs px-sm-1 px-md-2 px-1 h-40-btn text-white b-none"
                  onClick={() => setValue(50)}
                >
                  50%
                </button>
                <button
                  className="btn form-select rajdhani-600 rounded-10 py-0 font-xs px-sm-1 px-md-2 px-1 h-40-btn text-white b-none"
                  onClick={() => setValue(75)}
                >
                  75%
                </button>
                <button
                  className="btn form-select rajdhani-600 rounded-10 py-0 font-xs px-sm-1 px-md-2 px-1 h-40-btn text-white b-none"
                  onClick={() => setValue(100)}
                >
                  Max
                </button>
              </div>
            </div>
            <input type="range" value={Value} onChange={handleSliderChange} name="" className="w-100 mt-3 cursor-pointer" id="" />
            <div />
          </div>
          <div className="d-flex justify-content-center mb-0">
            <i class="bi bi-arrow-down-short font-xl rajdhani-600 text-gray-600"></i>
          </div>
          <div className=" rounded-15">
            <p className="font-xs rajdhani-500 mb-1 text-gray-600">RECEIVE</p>

            <div className="card-body border dark-border bg-l  p-relative rounded-15 p-3 py-1">
              <div className="d-flex  justify-content-between align-items-center my-1">
                <div>
                  <p className="d-flex align-items-center text-gray-900 rajdhani-500 font-xs dark-text mb-0">
                    {pair?.token0?.icon ? (
                      <img src={pair?.token0?.icon} alt="user" className="h-26 rounded-circle mx-1" />
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                        color="text"
                        width="26px"
                        height="26px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                    )}
                    <span className="mx-1">{pair?.token0?.symbol}</span>
                  </p>
                </div>
                <div className="text-end  d-flex ">
                  <p className="text-gray-900 rajdhani-500 font-xs dark-text mb-0">{formatAmount(amountOut0)}</p>
                </div>
              </div>
              <div className="d-flex  justify-content-between align-items-center m-0 my-1">
                <div>
                  <p className="d-flex align-items-center text-gray-900 rajdhani-500 font-xs dark-text mb-0">
                    {pair?.token1?.icon ? (
                      <img src={pair?.token1?.icon} alt="user" className="h-26 rounded-circle mx-1" />
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                        color="text"
                        width="26px"
                        height="26px"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "8px" }}
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                    )}
                    <span className="mx-1">{pair?.token1?.symbol}</span>
                  </p>
                </div>
                <div className="text-end d-flex ">
                  <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0">{formatAmount(amountOut1)}</p>
                </div>
              </div>
              <div />
            </div>
          </div>
          {/*  */}
          <div className=" rounded-15 mt-2">
            <p className="font-xs rajdhani-500 mb-1 text-gray-600">Prices</p>
            <div className="card-body border dark-border bg-l  p-relative rounded-15 p-3 py-1">
              <div className="d-flex  justify-content-between mt-1 mb-0">
                <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0 ">1 {pair?.token0?.symbol} =</p>
                <div>
                  <p className="text-gray-900 rajdhani-500 font-xs dark-text mb-0">
                    {formatAmount(Number(pair?.reserve[1] / Number(pair?.reserve[0])))}
                    <span className="ms-1">{pair?.token1?.symbol}</span>
                  </p>
                </div>
              </div>
              <div className="d-flex  justify-content-between m-0">
                <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0">1 {pair?.token1?.symbol} =</p>
                <p className="text-gray-900 rajdhani-500 font-xs dark-text mb-0">
                  {formatAmount(Number(pair?.reserve[0] / Number(pair?.reserve[1])))}
                  <span className="ms-1">{pair?.token0?.symbol}</span>
                </p>
              </div>
              <div />
            </div>
          </div>
          {/*  */}
          <div className="d-flex justify-content-between align-items-center my-3">
            <p className="font-xs rajdhani-500 mb-0 text-gray-600">Slippage Tolerance</p>
            <h6 className="text-gray-900 rajdhani-500 mb-0  d-flex  align-items-center ">
              <span className="dark-text d-flex font-xs align-items-center">{settingData?.slippage}%</span>
            </h6>
          </div>
          <div className=" d-flex justify-content-center">
            <button
              type="button"
              className="btn py-2 font-sm w-100 dark-text text-black rounded-10 btn-outline-primary mx-1 mt-md-0 mt-sm-2 mt-2"
              onClick={approveToken}
              disabled={Number(pair?.allowance) > (Number(pair?.lptokens) * Value) / 100}
            >
              Enable
            </button>
            <button
              type="button"
              className="btn py-2 font-sm w-100 text-white rounded-10 btn-primary mx-1 mt-md-0 mt-sm-2 mt-2"
              onClick={() =>
                setSpinner((prev) => ({
                  ...prev,
                  removeLiquidityModal: true,
                }))
              }
              disabled={
                !amountOut0 ||
                Number(amountOut0) <= 0 ||
                !amountOut1 ||
                Number(amountOut1) <= 0 ||
                !pair ||
                Number(pair?.allowance) < (Number(pair?.lptokens) * Value) / 100
              }
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default RemoveLiquidityV2Box;
