import React, { useContext } from "react";
import { walletConnect } from "../../contexts/WalletConnect";
import SmallSpinner from "../../Components/Coman/SmallSpinner";
import { formatAmount } from "../../Components/Function/commonFunction";

const SwapInputBox = ({
  type,
  setSwapBoxModalChange,
  selectedToken,
  inputAmount,
  setInputAmount,
  maxInput,
  spinner,
}) => {
  const { address } = useContext(walletConnect);
  return (
    <>
      <div
        className={`card-body  dark-border border border-light border-2 p-relative rounded-20 p-2 px-sm-3 py-1 ${
          type === 0 ? "dashboard-bg chain-bg" : ""
        } `}
      >
        <div className="d-flex  justify-content-between mt-1">
          <p className="font-xss dark-text opacity-50  mb-0 ">
            {type === 0 ? "You pay" : "You receive"}
          </p>
          {address ? (
            selectedToken?.balance !== undefined &&
            selectedToken?.balance !== null ? (
              <p className="font-xss dark-text mb-0">
                <span className="opacity-50">
                  Balance:{" "}
                  <span className="me-1">
                    {formatAmount(selectedToken?.balance)}
                  </span>
                </span>
                {selectedToken?.balance &&
                Number(selectedToken?.balance) > 0 &&
                type === 0 ? (
                  <span
                    className="rajdhani-500 rounded-6 p-0 p-1  py-0 font-xss hover-mx-select text-blue bg-wallet hov-text-blue pointer"
                    onClick={() => maxInput(selectedToken?.balance)}
                  >
                    MAX
                  </span>
                ) : (
                  ""
                )}
              </p>
            ) : selectedToken ? (
              <SmallSpinner />
            ) : (
              <p className="font-xss dark-text opacity-50 mb-0">
                Balance: <span className="me-1">0</span>
                {selectedToken?.balance &&
                Number(selectedToken?.balance) > 0 &&
                type === 0 ? (
                  <span>
                    <button
                      className="btn header-btn rounded-5 p-0 px-1 py-0 font-xs hover-mx-select text-blue bg-wallet hov-text-blue"
                      onClick={() => maxInput(selectedToken?.balance)}
                    >
                      <span className="pointer">MAX</span>
                    </button>
                  </span>
                ) : (
                  ""
                )}
              </p>
            )
          ) : (
            ""
          )}
        </div>
        <div>
          <div className="row align-items-center">
            {selectedToken &&
            selectedToken?.symbol &&
            selectedToken?.address ? (
              <>
                <div className="col-sm-6 col-md-5 col-6  ps-2 ms-0">
                  <button
                    className={`rounded-10 btn btn-md header-btn border-0 text-gray-900 dark-text rajdhani-600 rounded-10 p-1 pe-2 font-sm  ${
                      type === 0 ? "bg-white-swap" : "bg-white-select"
                    }`}
                    onClick={() =>
                      setSwapBoxModalChange(
                        type === 0
                          ? "selectTokenModalInput"
                          : "selectTokenModalOutput"
                      )
                    }
                  >
                    {selectedToken?.icon ? (
                      <img
                        src={selectedToken?.icon}
                        alt="user"
                        className="h-30 w-30 me-2 rounded-circle"
                      />
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl"
                        color="text"
                        width="30px"
                        height="30px"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "8px" }}
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                    )}
                    {selectedToken?.symbol}
                    <span className="bi bi-chevron-down font-xsss mx-1 d-flex align-items-center"></span>
                  </button>
                </div>
              </>
            ) : (
              <div className="col-sm-6 col-md-5 col-6 ps-2">
                <button
                  class="btn btn-md header-btn border-0 rajdhani-500 rounded-10 px-2 px--1 py-1 ms-md-0 ms-sm-1 ms-1 bg-wallet-6 font-sm blue-text"
                  onClick={() =>
                    setSwapBoxModalChange(
                      type === 0
                        ? "selectTokenModalInput"
                        : "selectTokenModalOutput"
                    )
                  }
                >
                  Select token
                  <span className="bi bi-chevron-down font-xsss mx-1 d-flex align-items-center"></span>
                </button>
              </div>
            )}
            <div className="col-sm-6 col-md-7 col-6">
              <div className="text-">
                {spinner ? (
                  <div className="d-flex justify-content-end m-3 mx-2">
                    <SmallSpinner />
                  </div>
                ) : (
                  <input
                    type="text"
                    className="text-end font-xl rajdhani-500 w-100 dark-text form-control-xl-placeholder"
                    placeholder="0"
                    style={{ background: "none", border: "none" }}
                    value={inputAmount}
                    onChange={(e) => {
                      if (
                        e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)
                      ) {
                        setInputAmount(e.target.value);
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="font-xss dark-text opacity-50">
              {type === 0 ? selectedToken?.name : selectedToken?.name}
            </span>
            <span className="font-xss dark-text opacity-50">
              {type === 0 ? "~$0 " : "~$0"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwapInputBox;
