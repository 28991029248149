import React from "react";

function V3LiquidityWarning() {
  return (
    <>
      <div className="col-12">
        <div className="card-bod bg-l rounded-10 p-2 mt-2 nt-center align-items-center">
          <p
            className="dark-text rajdhani-500 font-xs m-0"
            style={{ lineHeight: "17px" }}
          >
            This pool must be initialized before you can add liquidity. To
            initialize, select a starting price for the pool. Then, enter your
            liquidity price range and deposit amount. Gas fees will be higher
            than usual due to the initialization transaction.
          </p>
        </div>
      </div>
    </>
  );
}

export default V3LiquidityWarning;
