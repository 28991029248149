/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import SettingPopUp from "./SettingPopUp";
import ChainDropdown from "./ChainDropdown";
import ConnectWallet from "./ConnectWallet";
import { Link } from "react-router-dom";
import ComingSoonModel from "./ComingSoonModel";
import { globalStates } from "../../contexts/GlobalStates";

function SwapHeader() {
  const { showComingSoon, toggleShowComingSoon } = useContext(globalStates);
  const [showNavbar, setShowNavbar] = useState(false);
  const toggleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <>
      <div>
        <header className="header-wrapper w-100 header-center dark-bg">
          <nav className="navbar navbar-expand-lg bg-dark-black border-0 ">
            {/* className={`navbar navbar-expand-lg bg-dark-black border-0  ${
                    showNavbar ? "mat-drawer " : ""
                  }`}> */}
            <div className="container-wide posr ">
              <div className="col-xl-2 col-lg-3  col-md-3 col-sm-6 col-7">
                <Link className="navbar-brand light-logo" to="/">
                  <img
                    src="./logo.png"
                    alt="logo"
                    className="w-50 d-sm-none d-md-block d-lg-block d-xl-block d-xl-block d-none"
                  />
                  <img
                    className="style_logo img-fluid w-40  d-sm-block d-md-none d-lg-none d-xl-none d-xl-none d-block"
                    src="/favicon.png"
                    alt="logo"
                  />
                </Link>
                <Link
                  className="navbar-brand dark-logo d-sm-none d-md-block d-lg-block d-xl-block d-xl-block d-none"
                  to="/"
                >
                  <img className="style_logo" src="/logo-dark.png" alt="logo" />
                </Link>
                <Link
                  className="navbar-brand dark-logo d-sm-block d-md-none d-lg-none d-xl-none d-xl-none d-block"
                  to="/"
                >
                  <img
                    className="style_logo img-fluid w-40"
                    src="/favicon.png"
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-7 d-none d-lg-block">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul
                    className="navbar-nav mb-2 mb-lg-0 ms-lg-4"
                    id="navbar-main"
                  >
                    <li className="nav-item menu-item-has-children">
                      <Link to="/" className="nav-link text-white ">
                        Swap
                      </Link>
                      {/* <ul className="dropdown-content bg-white-new rounded-10">
                        <div className="m-2">
                          <li className=" b-none hover_question">
                            <Link className="dark-text " to="/swap">
                              Simple Mode
                            </Link>
                          </li>
                        </div>
                        <div className="m-2">
                          <li className=" b-none hover_question">
                            <Link className="dark-text" to="/advancedmode">
                              Advanced Mode
                            </Link>
                          </li>
                        </div>
                      </ul> */}
                    </li>
                    <li className="nav-item menu-item-has-children">
                      <Link to="/v3pool" className="nav-link text-white ">
                        Pool
                      </Link>
                    </li>

                    {/* <li className="nav-item menu-item-has-children">
                      <a
                        className="nav-link text-white mega-menu text-uppercase"
                        href="/swaptokenchart"
                      >
                        Advanced Mode
                      </a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="nft-style-1.html">NFT Style 1</a>
                        </li>
                        <li className="menu-item">
                          <a href="nft-style-2.html">NFT Style 2</a>
                        </li>
                        <li className="menu-item">
                          <a href="nft-style-3.html">NFT Style 3</a>
                        </li>
                        <li className="menu-item">
                          <a href="nft-style-4.html">NFT Style 4</a>
                        </li>
                        <li className="menu-item">
                          <a href="nft-style-5.html">NFT Style 5</a>
                        </li>
                        <li className="menu-item">
                          <a href="single-nft-1.html">Single NFT 1</a>
                        </li>
                        <li className="menu-item">
                          <a href="single-nft-2.html">Single NFT 2</a>
                        </li>
                      </ul>
                    </li> */}
                    {/* <li className="nav-item menu-item-has-children menu-item-has-megamenu">
                      <a
                        className="nav-link text-white mega-menu text-uppercase"
                        href="#"
                      >
                        Pages
                      </a>
                      <ul className="megamenu">
                        <li className="menu-item">
                          <span className="rajdhani-500 text-gray-500">
                            Dashboard
                          </span>
                          <ul className="sub-mega-menu">
                            <li>
                              <a href="dashboard.html">Home</a>
                            </li>
                            <li>
                              <a href="dashboard-account.html">My Account</a>
                            </li>
                            <li>
                              <a href="dashboard-author-1.html">Author</a>
                            </li>
                            <li>
                              <a href="dashboard-profile-1.html">Profile</a>
                            </li>
                            <li>
                              <a href="dashboard-history.html">History</a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item">
                          <span className="rajdhani-500 text-gray-500">
                            Page
                          </span>
                          <ul className="sub-mega-menu">
                            <li className="menu-item">
                              <a href="author-style-1.html">Author Style 1 </a>
                            </li>
                            <li className="menu-item">
                              <a href="author-style-2.html">Author Style 2</a>
                            </li>
                            <li className="menu-item">
                              <a href="author-style-2.html">Author Style 3</a>
                            </li>
                            <li className="menu-item">
                              <a href="profile-style-1.html">Profile Style 1</a>
                            </li>
                            <li className="menu-item">
                              <a href="profile-style-2.html">Profile Style 2</a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item">
                          <span className="rajdhani-500 text-gray-500">
                            Authentication
                          </span>
                          <ul className="sub-mega-menu">
                            <li>
                              <a href="login-one.html">
                                Login Page
                                <span className="py-1 px-2 ms-5 font-xsss ls-3 rajdhani-600 rounded-10 alert-warning text-warning">
                                  NEW
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="register-one.html">Register Page</a>
                            </li>
                            <li>
                              <a href="forgot-one.html">Forgot Password</a>
                            </li>
                            <li>
                              <a href="verify-one.html">Verify Page</a>
                            </li>
                            <li>
                              <a href="coming-soon.html">Coming soon</a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item">
                          <span className="rajdhani-500 text-gray-500">
                            Other
                          </span>
                          <ul className="sub-mega-menu">
                            <li className="menu-item">
                              <a href="faq.html">Help &amp; Support</a>
                            </li>
                            <li className="menu-item">
                              <a href="create-nft.html">Create NFT</a>
                            </li>
                            <li>
                              <a href="404.html">404 Page</a>
                            </li>
                            <li>
                              <a href="#">Gaming NFT</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li> */}
                  </ul>
                  <a
                    href="#"
                    className="btn btn-md menu-btn text-gray-800 rajdhani-700 rounded-6 bg-transparent border-dark border-2 px-4 font-sm dark-text"
                    data-bs-toggle="modal"
                    data-bs-target="#connetwallet"
                  >
                    Connect Wallet
                  </a>
                  <a
                    href="dashboard-history.html"
                    className="ms-2 btn menu-btn btn-noti"
                  >
                    <i className="bi-bell font-md m-0" />
                    <span className="dot-count bg-current rajdhani-600">6</span>
                  </a>
                  <a
                    href="#"
                    data-switch-theme=""
                    className="ms-2 menu-btn btn-toggle-dark btn btn-icon btn-round"
                  >
                    <i className="bi-brightness-low font-xl m-0" />
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5  d-flex justify-content-end">
                <div className="d-none d-lg-flex gap-2">
                  <div className="align-items-center mx-2  d-flex">
                    <ChainDropdown />
                  </div>

                  {/* <button className="navbar-toggler collapsed" type="button">
                    <span className="navbar-toggler-icon" />
                  </button> */}
                </div>
                <div className="align-items-center d-flex me-3 d-sm-block d-md-block d-lg-none d-xl-none d-xl-none d-block">
                  <ChainDropdown />
                </div>
                <div className="d-flex align-items-center buton-posi">
                  <ConnectWallet />
                  {/* <QuestionPopUp /> */}
                  <SettingPopUp />
                </div>

                <button
                  className={`navbar-toggler ${showNavbar ? "" : "collapsed "}`}
                  type="button"
                  onClick={toggleShowNavbar}
                >
                  <span className="navbar-toggler-icon" />
                </button>
              </div>
              <div className="col-lg-12 d-lg-none w-100">
                <div
                  className={`collapse navbar-collapse ${
                    showNavbar ? "show  " : ""
                  }`}
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mb-2 mb-lg-0" id="navbar-main">
                    <li className="nav-item">
                      <Link className="nav-link text-white " to="/">
                        Swap
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link text-white " to="/v3pool">
                        Pool
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link
                        className="nav-link text-white text-uppercase"
                        to="/v3liquidityinfo"
                      >
                        Pool
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
      <ComingSoonModel
        toggleShowComingSoon={toggleShowComingSoon}
        showComingSoon={showComingSoon}
      />
    </>
  );
}

export default SwapHeader;
