import React, { createContext, useEffect, useMemo, useState } from "react";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import WalletChains from "../constants/WalletChains";
import { useWeb3Modal, useSwitchNetwork, useWeb3ModalAccount, useDisconnect, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import useGetAllChainInfo from "../hooks/useGetAllChainInfo";
import { DEFAULT_CHAIN } from "../constants/chains";
import { ethers } from "ethers";
import { useLocation, useSearchParams } from "react-router-dom";

// 1. Get projectId
const projectId = "b2b3f80a3ed9fcc26f1f3a108c41212b";

// 3. Create a metadata object
const metadata = {
  name: "Value",
  description: "Value",
  url: "https://value.com", // origin must match your domain & subdomain
  icons: ["https://value.com/"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  defaultChainId: 56, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: WalletChains,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  // allWallets: "HIDE",
  allowUnsupportedChain: true,
});

export const walletConnect = createContext(null);

const WalletConnect = ({ children }) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { open } = useWeb3Modal();
  const { switchNetwork } = useSwitchNetwork();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { disconnect } = useDisconnect();
  const chains = useGetAllChainInfo();
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [selectedChain, setSelectedChain] = useState(null);
  const [selectedRouter, setSelectedRouter] = useState(null);
  const [routerId, setRouterId] = useState(101);

  const [searchParams, setSearchParams] = useSearchParams();

  const [urlChain, setUrlChain] = useState(searchParams.get("chain") || "");
  const [inputToken, setInputToken] = useState(searchParams.get("inputCurrency") || "");
  const [outputToken, setOutputToken] = useState(searchParams.get("outputCurrency") || "");

  const ConnectWallet = async () => {
    await open();
  };
  const getAndSetSelectedChain = () => {
    if (urlChain !== "solana") {
      if (chainId && chains && isConnected && provider) {
        const found = chains.find((chain) => chain.chainId === chainId);

        if (found && (found?.chainId === 56 || found?.chainId === 97)) {
          // if (found && found?.chainId === 56) {
          setSelectedChain(found);
          setUrlChain(found?.urlname);
        } else {
          setSelectedChain(null);
          setUrlChain("");
        }
      } else {
        if (!selectedChain) {
          const found = chains.find((chain) => chain.chainId === DEFAULT_CHAIN);
          setSelectedChain(found);
          setUrlChain(found?.urlname);
        } else {
          const found = chains.find((chain) => chain.chainId === selectedChain?.chainId);
          setSelectedChain(found);
          setUrlChain(found?.urlname);
        }
      }
    } else {
      const found = chains.find((chain) => chain.chainId === 901);
      setSelectedChain(found);
      setUrlChain(found?.urlname);
    }
  };

  useEffect(() => {
    if (chains?.length) {
      getAndSetSelectedChain();
    }
  }, [chainId, isConnected, provider]);

  const getAndSetProvder = async () => {
    try {
      if (walletProvider !== undefined) {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = await provider.getSigner();
        if (provider) setProvider(provider);
        else setProvider(null);
        if (signer) setSigner(signer);
        else setSigner(null);
      } else {
        if (selectedChain) {
          const provider = new ethers.providers.JsonRpcProvider(selectedChain?.rpcUrl);
          setProvider(provider);
          setSigner(null);
        } else {
          const selecChain = chains.find((item) => item.chainId === DEFAULT_CHAIN);
          const provider = new ethers.providers.JsonRpcProvider(selecChain?.rpcUrl);
          setProvider(provider);
          setSigner(null);
        }
      }
    } catch (err) {
      // console.log("error : ", err);
      setProvider(null);
      setSigner(null);
    }
  };

  useEffect(() => {
    getAndSetProvder();
  }, [walletProvider]);

  const setRouter = () => {
    setSelectedRouter(selectedChain.routers.find((item) => item?.id === routerId));
  };

  useEffect(() => {
    if (selectedChain && routerId) {
      setRouter();
    }
  }, [selectedChain, routerId]);

  return (
    <walletConnect.Provider
      value={{
        ConnectWallet,
        isConnected,
        address,
        chainId,
        selectedChain,
        chains,
        setSelectedChain,
        switchNetwork,
        disconnect,
        provider,
        setProvider,
        signer,
        setSigner,
        getAndSetSelectedChain,
        getAndSetProvder,
        routerId,
        setRouterId,
        selectedRouter,
        urlChain,
        setUrlChain,
        inputToken,
        setInputToken,
        outputToken,
        setOutputToken,
        setSearchParams,
      }}
    >
      {children}
    </walletConnect.Provider>
  );
};

export default WalletConnect;
