import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "../../../../Components/Coman/Footer";
import SwapHeader from "../../../../Components/Coman/SwapHeader";
import {
  findTokenForImport,
  getFactory,
  getNonfungiblePositionManagerContract,
  getTokenAllowance,
  getTokenAllowanceForSwap,
  getTokenBalance,
  getTokenDecimals,
  getV3Pool,
  getWethV3Address,
  giveAllowance,
} from "../../../../Components/Function/ethersFunctions";
import { globalStates } from "../../../../contexts/GlobalStates";
import { walletConnect } from "../../../../contexts/WalletConnect";
import SwapSettingsModel from "../../../../Components/Coman/SwapSettingsModel";
import IncreaseLiquidityV3Box from "./IncreaseLiquidityV3Box";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getAmountsForPosition,
  getMinAndMaxPrices,
  getPriceFromSqrtPrice,
  increaseLiquidityInV3,
} from "../../../../Components/Function/liquidityFunctions";
import { constants, ethers } from "ethers";
import { values } from "../../../../constants/WalletChains";
import { Position } from "@uniswap/v3-sdk";
import { formatEther } from "ethers/lib/utils";
import { formatAmount } from "../../../../Components/Function/commonFunction";

function IncreaseLiquidityV3() {
  const { provider, selectedChain, address, chainId } = useContext(walletConnect);
  const { settingData } = useContext(globalStates);
  const { chainTokens } = useContext(globalStates);
  const [swapBoxModalChange, setSwapBoxModalChange] = useState("");

  const [status, setStatus] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("id") || "");

  const [poolData, setPoolData] = useState(null);
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef();

  const [token0, setToken0] = useState(null);
  const [token1, setToken1] = useState(null);

  const [inputAmountOne, setInputAmountOne] = useState("");
  const [inputAmountTwo, setInputAmountTwo] = useState("");

  const [amount0, setAmount0] = useState("0");
  const [amount1, setAmount1] = useState("0");

  const [minPrice, setMinPrice] = useState({ minPrice: "0", minPriceRev: "0" });
  const [maxPrice, setMaxPrice] = useState({
    maxPrice: "0",
    maxPriceRev: "0",
  });

  const [currPrice, setCurrPrice] = useState("0");

  const [swap, setSwap] = useState(true);

  const [depositAmount0, setDepositAmount0] = useState("");
  const [depositAmount1, setDepositAmount1] = useState("");

  const [spinner, setSpinner] = useState({
    approveTokenOne: false,
    approveTokenTwo: false,
    addLiquiSpinner: false,
    addLiquiTxSubModal: false,
    addLiquiTxHash: "",
  });

  const getPositionDataById = async () => {
    try {
      setLoading(true);
      const positionContract = await getNonfungiblePositionManagerContract(selectedChain?.routers[0].NonfungiblePositionManager, provider);
      const weth = await getWethV3Address(selectedChain?.routers[0], provider);
      const factory = await getFactory(selectedChain?.routers[0], "v3", provider);
      const position = await positionContract.positions(id);
      if (position) {
        const poolAddress = await factory.getPool(position?.token0, position?.token1, position?.fee);
        const pool = await getV3Pool(poolAddress, provider);
        const slot0 = await pool?.slot0();
        const sqrtPriceX96 = slot0?.sqrtPriceX96;
        let token0;
        if (position?.token0?.toLowerCase() === weth?.toLowerCase()) {
          const find = chainTokens?.find((item) => item.address?.toLowerCase() === constants?.AddressZero?.toLowerCase());
          token0 = find;
        } else {
          const find = chainTokens?.find((item) => item.address?.toLowerCase() === position?.token0?.toLowerCase());
          if (find) {
            token0 = find;
          } else {
            const { address, symbol, name, decimals } = await findTokenForImport(position?.token0, provider);
            token0 = {
              address: position?.token0?.toLowerCase(),
              decimals,
              name,
              symbol,
              icon: "",
            };
          }
        }
        let token1;
        if (position?.token1?.toLowerCase() === weth?.toLowerCase()) {
          const find = chainTokens?.find((item) => item.address?.toLowerCase() === constants?.AddressZero?.toLowerCase());
          token1 = find;
        } else {
          const find = chainTokens?.find((item) => item.address?.toLowerCase() === position?.token1?.toLowerCase());
          if (find) {
            token1 = find;
          } else {
            const { address, symbol, name, decimals } = await findTokenForImport(position?.token1, provider);
            token1 = {
              address: position?.token1?.toLowerCase(),
              decimals,
              name,
              symbol,
              icon: "",
            };
          }
        }
        const price = getPriceFromSqrtPrice(sqrtPriceX96, token0?.decimals, token1?.decimals);
        setCurrPrice(price);
        let prices = getMinAndMaxPrices(position?.tickLower, position?.tickUpper, token0?.decimals, token1?.decimals);
        const amount = await getAmountsForPosition(position, slot0, token0, token1);
        if (amount) {
          setAmount0(amount?.amount0);
          setAmount1(amount?.amount1);
        }
        if (price?.price >= prices?.minPrice && price?.price <= prices?.maxPrice) {
          setStatus(1);
        } else if (amount?.amount0 <= 0 && amount?.amount1 <= 0) {
          setStatus(2);
        }
        setToken0(token0);
        setToken1(token1);
        await getAndSetTokenBalanceAndAllowance(token0, token1);
        setMinPrice({
          minPrice: prices?.minPrice,
          minPriceRev: prices?.minPriceRev,
        });
        setMaxPrice({
          maxPrice: prices?.maxPrice,
          maxPriceRev: prices?.maxPriceRev,
        });
        setPoolData({
          id,
          fee: position?.fee,
          pool: amount?.pool,
          position,
        });
        setLoading(false);
      } else {
        setAmount0("0");
        setAmount1("0");
        setMinPrice({
          minPrice: "0",
          minPriceRev: "0",
        });
        setMaxPrice({
          maxPrice: "0",
          maxPriceRev: "0",
        });
        setCurrPrice("0");
        setPoolData(null);
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      console.log("error in getPositionDataById", err);
      setAmount0("0");
      setAmount1("0");
      setMinPrice({
        minPrice: "0",
        minPriceRev: "0",
      });
      setMaxPrice({
        maxPrice: "0",
        maxPriceRev: "0",
      });
      setCurrPrice("0");
      setPoolData(null);
      setLoading(false);
    }
  };

  const getAndSetTokenBalanceAndAllowance = async (tkn0, tkn1) => {
    try {
      if (tkn0 && tkn1) {
        const token0Balance = await getTokenBalance(tkn0?.address, address, provider);
        const token1Balance = await getTokenBalance(tkn1?.address, address, provider);
        const token0Allowance = await getTokenAllowance(
          tkn0?.address,
          address,
          selectedChain?.routers[0].NonfungiblePositionManager,
          provider
        );
        const token1Allowance = await getTokenAllowance(
          tkn1?.address,
          address,
          selectedChain?.routers[0].NonfungiblePositionManager,
          provider
        );
        setToken0((prev) => ({
          ...prev,
          balance: token0Balance,
          allowance: token0Allowance,
        }));
        setToken1((prev) => ({
          ...prev,
          balance: token1Balance,
          allowance: token1Allowance,
        }));
      } else {
        if (token0 && token1) {
          const token0Balance = await getTokenBalance(token0?.address, address, provider);
          const token1Balance = await getTokenBalance(token1?.address, address, provider);
          const token0Allowance = await getTokenAllowance(
            token0?.address,
            address,
            selectedChain?.routers[0].NonfungiblePositionManager,
            provider
          );
          const token1Allowance = await getTokenAllowance(
            token1?.address,
            address,
            selectedChain?.routers[0].NonfungiblePositionManager,
            provider
          );
          setToken0((prev) => ({
            ...prev,
            balance: token0Balance,
            allowance: token0Allowance,
          }));
          setToken1((prev) => ({
            ...prev,
            balance: token1Balance,
            allowance: token1Allowance,
          }));
        }
      }
    } catch {}
  };

  useEffect(() => {
    if (id && provider && selectedChain && chainTokens?.length && address && chainId === selectedChain?.chainId) {
      if (selectedChain?.currency?.toLowerCase() === chainTokens[0]?.symbol?.toLowerCase()) {
        getPositionDataById();
      }
    }
  }, [id, provider, selectedChain, chainTokens, address, chainId]);

  const swapTokenAndPrices = () => {
    setAmount0(amount1);
    setAmount1(amount0);
    setSwap(!swap);
  };

  const handleDepositAmount0 = async (value) => {
    try {
      setDepositAmount0({ value, originalValue: value });
      if (value && Number(value) > 0) {
        const position = new Position.fromAmount0({
          pool: poolData?.pool,
          tickLower: Number(poolData?.position?.tickLower),
          tickUpper: Number(poolData?.position?.tickUpper),
          amount0: ethers.utils.parseUnits(value.toString(), token0.decimals).toString(),
          useFullPrecision: false,
        });
        if (position?.mintAmounts?.amount1) {
          let amount = ethers.utils.formatUnits(position?.mintAmounts?.amount1?.toString(), token1.decimals);
          setDepositAmount1({
            value: formatAmount(amount),
            originalValue: amount,
          });
        } else {
          setDepositAmount1({ value: "", originalValue: "" });
        }
      } else {
        setDepositAmount1({ value: "", originalValue: "" });
      }
    } catch (err) {
      setDepositAmount1({ value: "", originalValue: "" });
    }
  };

  const handleDepositAmount1 = async (value) => {
    try {
      setDepositAmount1({ value, originalValue: value });
      if (value && Number(value) > 0) {
        const position = new Position.fromAmount1({
          pool: poolData?.pool,
          tickLower: Number(poolData?.position?.tickLower),
          tickUpper: Number(poolData?.position?.tickUpper),
          amount1: ethers.utils.parseUnits(value.toString(), token1.decimals).toString(),
          useFullPrecision: false,
        });
        if (position?.mintAmounts?.amount0) {
          let amount = ethers.utils.formatUnits(position?.mintAmounts?.amount0?.toString(), token0.decimals);
          setDepositAmount0({
            value: formatAmount(amount),
            originalValue: amount,
          });
        } else {
          setDepositAmount0({ value: "", originalValue: "" });
        }
      } else {
        setDepositAmount0({ value: "", originalValue: "" });
      }
    } catch (err) {
      setDepositAmount0({ value: "", originalValue: "" });
    }
  };

  const increaseLiquidity = async () => {
    try {
      setSpinner((prev) => ({
        ...prev,
        addLiquiSpinner: true,
      }));
      const result = await increaseLiquidityInV3(
        token0,
        token1,
        depositAmount0?.originalValue,
        depositAmount1?.originalValue,
        selectedChain?.routers[0],
        provider,
        settingData?.slippage,
        settingData?.txDeadline,
        id
      );
      if (result?.hash) {
        setSpinner((prev) => ({
          ...prev,
          addLiquiSpinner: false,
          addLiquiTxSubModal: true,
          addLiquiTxHash: result?.hash,
        }));
      } else {
        setSpinner((prev) => ({
          ...prev,
          addLiquiSpinner: false,
          addLiquiTxHash: "",
        }));
      }
    } catch (err) {
      console.log("error in addLiquidity : ", err);
      setSpinner((prev) => ({
        ...prev,
        addLiquiSpinner: false,
        addLiquiTxHash: "",
      }));
    }
  };

  return (
    <>
      <div className=" main-vh-11 bg-dark-black dashboard-bg">
        <div className="main-wrapper ">
          <SwapHeader />
          <div className="container-wide">
            <div className="banner-wrapper py-4 pt-lg-100 pt-0 ">
              <div className="row pb-3 mt-5 d-flex justify-content-center px-sm-2 px-md-0 px-2 ">
                {loading ? (
                  <div className="col-xl-4 col-lg-5 col-md-7 col-sm-10  mt-2 dark-text border-light rounded-10 p-2 d-flex justify-content-center align-items-center">
                    <div className="text-center ">Loading...</div>
                  </div>
                ) : (
                  <div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 m-0 p-0 bg-dark-black dashboard-bg " ref={dropdownRef}>
                    {swapBoxModalChange === "setting" ? (
                      <div className="mx-2 px-2">
                        <SwapSettingsModel setSwapBoxModalChange={setSwapBoxModalChange} swapBoxModalChange={swapBoxModalChange} />
                      </div>
                    ) : (
                      <div className="m-0 p-3 border dark-border rounded-20">
                        <IncreaseLiquidityV3Box
                          setSwapBoxModalChange={setSwapBoxModalChange}
                          token0={token0}
                          token1={token1}
                          minPrice={minPrice}
                          maxPrice={maxPrice}
                          currPrice={currPrice}
                          loading={loading}
                          poolData={poolData}
                          status={status}
                          amount0={amount0}
                          amount1={amount1}
                          swapTokenAndPrices={swapTokenAndPrices}
                          swap={swap}
                          depositAmount0={depositAmount0}
                          depositAmount1={depositAmount1}
                          handleDepositAmount0={handleDepositAmount0}
                          handleDepositAmount1={handleDepositAmount1}
                          spinner={spinner}
                          setSpinner={setSpinner}
                          getAndSetTokenBalanceAndAllowance={getAndSetTokenBalanceAndAllowance}
                          increaseLiquidity={increaseLiquidity}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default IncreaseLiquidityV3;
