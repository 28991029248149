import ERC20 from "../../abis/ERC20.json";

import IUniswapV2Factory from "../../abis/IUniswapV2Factory.json";
import IUniswapV2Pair from "../../abis/IUniswapV2Pair.json";
import IUniswapV2Router from "../../abis/UniswapV2Router.json";

import IUniswapV3Pool from "../../abis/IUniswapV3Pool.json";
import IUniswapv3Factory from "../../abis/IUniswapv3Factory.json";
import IUniswapv3Router from "../../abis/IUniswapv3Router.json";
import IUniswapV3NonfungiblePositionManager from "../../abis/IUniswapV3NonfungiblePositionManager.json";

import QuoterV2 from "../../abis/QuoterV2.json";

import { Contract, constants, ethers, BigNumber } from "ethers";
import { Logger, formatEther, formatUnits, parseUnits } from "ethers/lib/utils";
import { Position, TickMath, nearestUsableTick } from "@uniswap/v3-sdk";

export async function getV3Pool(address, provider) {
  try {
    const pool = new Contract(address, IUniswapV3Pool.abi, provider);
    return pool;
  } catch (err) {
    return null;
  }
}

export async function getNonfungiblePositionManagerContract(address, provider) {
  try {
    const contract = new Contract(address, IUniswapV3NonfungiblePositionManager.abi, provider);
    if (contract) {
      return contract;
    } else {
      return null;
    }
  } catch {
    return null;
  }
}

export async function findTokenForImport(address, provider) {
  try {
    const token = new Contract(address, ERC20.abi, provider);
    if (token) {
      return {
        address: address,
        symbol: await token.symbol(),
        name: await token.name(),
        decimals: await token.decimals(),
      };
    } else {
      return null;
    }
  } catch {
    return null;
  }
}

export const tickSpacing = (fee) => {
  if (fee === "0") return 50;
  const ticks = {
    100: 1,
    500: 10,
    2500: 50,
    3000: 60,
    10000: 200,
  };
  return ticks[fee];
};

export async function getTokenDecimals(address, provider) {
  try {
    if (address === constants?.AddressZero) {
      return 18;
    }
    const token = new Contract(address, ERC20.abi, provider);
    if (token) {
      return await token.decimals();
    } else {
      return null;
    }
  } catch {
    return null;
  }
}

export async function getTokenName(address, provider) {
  try {
    if (address === constants?.AddressZero) {
      return 18;
    }
    const token = new Contract(address, ERC20.abi, provider);
    if (token) {
      return await token.name();
    } else {
      return null;
    }
  } catch {
    return null;
  }
}

export async function getTokenSymbol(address, provider) {
  try {
    if (address === constants?.AddressZero) {
      return 18;
    }
    const token = new Contract(address, ERC20.abi, provider);
    if (token) {
      return await token.symbol();
    } else {
      return null;
    }
  } catch {
    return null;
  }
}

export async function getWethV2Address(selectedRouter, provider) {
  try {
    const router = new Contract(selectedRouter?.routerV2, IUniswapV2Router.abi, provider);
    const weth = await router.WETH();
    if (weth) return weth;
    else return null;
  } catch {
    return null;
  }
}

export async function getFactory(selectedRouter, routerVersion = "v3", provider) {
  if (routerVersion === "v3") {
    const factory = new Contract(selectedRouter?.factoryV3, IUniswapv3Factory?.abi, provider);
    return factory;
  } else {
    const factory = new Contract(selectedRouter?.factoryV2, IUniswapV2Factory?.abi, provider);
    return factory;
  }
}

export async function getWethV3Address(selectedRouter, provider) {
  try {
    const router = new Contract(selectedRouter?.routerV3, IUniswapv3Router.abi, provider);
    const weth = await router.WETH9();
    if (weth) return weth;
    else return null;
  } catch {
    return null;
  }
}

export async function getTokenBalance(address, account, provider) {
  try {
    if (account) {
      if (address === ethers.constants.AddressZero) {
        const balance = await provider.getBalance(account);
        return formatEther(balance);
      } else {
        const token = new Contract(address, ERC20.abi, provider);
        if (token) {
          const decimals = await token.decimals();
          const balance = await token.balanceOf(account);
          return formatUnits(balance, decimals);
        } else {
          return "0";
        }
      }
    } else {
      return "0";
    }
  } catch (err) {
    // console.log("error in getTokenBalance : ", err);
    return "0";
  }
}

export async function getTokenAllowanceForSwap(tokenAddress, account, selectedRouter, provider) {
  try {
    if (tokenAddress === ethers.constants.AddressZero) {
      return "0";
    } else {
      const token = new Contract(tokenAddress, ERC20?.abi, provider);
      const decimals = await token.decimals();
      const allowanceV2 = await token.allowance(account, selectedRouter?.routerV2);
      const allowanceV3 = await token.allowance(account, selectedRouter?.routerV3);
      return { v2: formatUnits(allowanceV2, decimals), v3: formatUnits(allowanceV3, decimals) };
    }
  } catch {
    return { v2: "0", v3: "0" };
  }
}

export async function getTokenAllowance(tokenAddress, account, allowanceAddress, provider) {
  try {
    if (tokenAddress === ethers.constants.AddressZero) {
      return "0";
    } else {
      const token = new Contract(tokenAddress, ERC20?.abi, provider);
      const decimals = await token.decimals();
      const allowance = await token.allowance(account, allowanceAddress);
      return formatUnits(allowance, decimals);
    }
  } catch {
    return "0";
  }
}

export async function giveAllowance(tokenAddress, contractAddress, amount, signer) {
  try {
    if (tokenAddress && contractAddress && signer) {
      const token = new Contract(tokenAddress, ERC20.abi, signer);
      const decimals = await token.decimals();
      if (amount) {
        const tx = await token.approve(contractAddress, parseUnits(amount, decimals));
        await tx.wait();
        return tx;
      } else {
        const totalSupply = await token.totalSupply();
        const totalSupplyEth = ethers.utils.formatUnits(totalSupply, decimals);
        const totalSupplyWei = ethers.utils.parseUnits(Math.ceil(totalSupplyEth)?.toString(), decimals);
        const tx = await token.approve(contractAddress, totalSupplyWei);
        await tx.wait();
        return tx;
      }
    } else {
      return false;
    }
  } catch (err) {
    console.log("error in giveAllowance : ", err);
    return false;
  }
}

export async function getReserveTokenInPair(inputAddress, outputAddress, poolAddress, routerVersion, provider) {
  try {
    if (poolAddress) {
      if (routerVersion === "v2") {
        const pool = new Contract(poolAddress, IUniswapV2Pair.abi, provider);
        const reserve = await pool.getReserves();
        const results = [
          (await pool.token0()).toLowerCase() === inputAddress.toLowerCase() ? reserve[0] : reserve[1],
          (await pool.token1()).toLowerCase() === outputAddress.toLowerCase() ? reserve[1] : reserve[0],
        ];
        return results;
      } else {
        const pool = new Contract(poolAddress, IUniswapV3Pool.abi, provider);
        // const [slot0, liquidity, token0, token1] = await Promise.all([
        //   pool.slot0(),
        //   pool.liquidity(),
        //   pool.token0(),
        //   pool.token1(),
        // ]);

        // const liquidity = new BigNumber("126251893334045162996");
        // const sqrtPriceX96 = new BigNumber("2583984497635755324759675927");

        // const sqrtPriceX96 = new BigNumber(slot0.sqrtPriceX96.toString());
        // const liquidityBN = new BigNumber(liquidity.toString());

        const decimals0 = 18;
        const decimals1 = 18;

        // Define Q96 as a BigNumber
        // const Q96 = new BigNumber(2).pow(96);

        // Calculate the reserve amounts
        // const reserve0 = liquidity.multipliedBy(Q96).dividedBy(sqrtPriceX96);
        // const reserve1 = liquidity.multipliedBy(sqrtPriceX96).dividedBy(Q96);
      }
    } else {
      return [0, 0];
    }
  } catch (err) {
    console.log("error in getReserveTokenInPair : ", err);
  }
}

export async function isPairAvailable(tokenIn, tokenOut, selectedRouter, routerVersion = "v3", fee, factory, provider) {
  if (routerVersion === "v3") {
    let weth = await getWethV3Address(selectedRouter, provider);
    try {
      let token0 = tokenIn?.address === constants.AddressZero ? weth : tokenIn?.address?.toLowerCase();
      let token1 = tokenOut?.address === constants.AddressZero ? weth : tokenOut?.address?.toLowerCase();
      let pairAddress = await factory.getPool(token0, token1, fee);

      if (pairAddress !== constants.AddressZero) {
        const poolContract = new Contract(pairAddress, IUniswapV3Pool?.abi, provider);
        const poolData = await getPoolData(poolContract);
        const token0IsInput = token0?.toLowerCase() === poolData?.token0?.toLowerCase();
        const sqrtPrice = sqrtToPrice(
          poolData.sqrtPriceX96.toString(),
          tokenIn?.decimals,
          tokenOut?.decimals
          // token0?.toLowerCase() === tokenIn?.address?.toLowerCase() ? true : false
        );
        const originalPrice = sqrtToPrice(
          poolData.sqrtPriceX96.toString(),
          tokenIn?.decimals,
          tokenOut?.decimals,
          token0?.toLowerCase() === tokenIn?.address?.toLowerCase() ? true : false
        );
        return {
          fee: fee,
          pool: pairAddress && pairAddress !== constants.AddressZero ? pairAddress : null,
          feeInPer: fee / 10000,
          tickSpacing: poolData?.tickSpacing,
          liquidity: poolData?.liquidity,
          sqrtPriceX96: poolData.sqrtPriceX96,
          tick: poolData?.tick,
          token0: poolData?.token0,
          token1: poolData?.token1,
          weth: weth,
          sqrtPrice: sqrtPrice,
          token0IsInput: token0IsInput,
          poolToken0: poolData?.token0,
          poolToken1: poolData?.token1,
          originalPrice: originalPrice,
        };
      } else {
        return {
          fee: fee,
          pool: pairAddress && pairAddress !== constants.AddressZero ? pairAddress : null,
          feeInPer: fee / 10000,
          weth: weth,
        };
      }
    } catch (err) {
      console.log("error ", err);
      return { fee: fee, pool: null, feeInPer: fee / 10000, weth: weth };
    }
  }
}

export async function getPoolData(poolContract) {
  const [tickSpacing, fee, liquidity, slot0, token0, token1] = await Promise.all([
    poolContract.tickSpacing(),
    poolContract.fee(),
    poolContract.liquidity(),
    poolContract.slot0(),
    poolContract.token0(),
    poolContract.token1(),
  ]);

  return {
    tickSpacing: tickSpacing.toString(),
    fee: fee.toString(),
    liquidity: liquidity,
    sqrtPriceX96: slot0[0],
    tick: slot0[1].toString(),
    token0: token0,
    token1: token1,
  };
}

export function sqrtToPrice(sqrt, decimals0, decimals1, token0isInput = true) {
  const numerator = BigNumber.from(sqrt).pow(2);
  const denominator = BigNumber.from(2).pow(192);
  let ratio = numerator / denominator;
  const diff = Number(decimals1) - Number(decimals0);
  let shiftdecimals = BigNumber.from(10).pow(Math.abs(diff));
  if (diff >= 0) {
    ratio = ratio / shiftdecimals;
  } else {
    ratio = ratio / shiftdecimals;
  }
  if (!token0isInput) {
    return 1 / ratio;
  }
  return ratio;
}

// export function sqrtToPrice(sqrt, decimals0, decimals1, token0isInput = true) {
//   const numerator = BigNumber.from(sqrt).pow(2);
//   const denominator = BigNumber.from(2).pow(192);
//   let ratio = numerator / denominator;
//   const shiftdecimals = BigNumber.from(10).pow(Number(decimals0) - Number(decimals1));
//   ratio = ratio * Number(shiftdecimals);
//   if (!token0isInput) {
//     return 1 / ratio;
//   }
//   return ratio;
// }

export const priceToTick = (price) => {
  return Math.floor(Math.log(price) / Math.log(1.0001));
};

export const priceToTickWithDecimals = (price, decimals0, decimals1) => {
  const diff = Number(decimals0) - Number(decimals1);
  let shiftdecimals = BigNumber.from(10).pow(Math.abs(diff));
  if (diff >= 0) {
    return Math.floor(Math.log(price * shiftdecimals) / Math.log(1.0001));
  } else {
    return Math.floor(Math.log(price * shiftdecimals) / Math.log(1.0001));
  }
};

export const tickToPrice = (tick) => {
  return Math.pow(1.0001, tick);
};

export const tickToPriceWithDecimals = (tick, decimals0, decimals1) => {
  let price = Math.pow(1.0001, tick);
  const diff = Number(decimals0) - Number(decimals1);
  let shiftdecimals = BigNumber.from(10).pow(Math.abs(diff));
  if (diff >= 0) {
    return price / shiftdecimals;
  } else {
    return price / shiftdecimals;
  }
};

export const calculatePriceRangeAndTicks = (currentPrice, percentage, tick, fee, token0isInput, lPrice, hPrice, decimals0, decimals1) => {
  let currentTick = priceToTickWithDecimals(currentPrice, decimals0, decimals1);
  if (currentTick >= TickMath?.MAX_TICK || currentTick <= TickMath?.MIN_TICK) {
    return "TICK_BOUND";
  }
  let prices = {};
  let lowerPrice = currentPrice * (1 - percentage / 100);
  let upperPrice = currentPrice * (1 + percentage / 100);
  if (!tick) {
    tick = tickSpacing(fee);
  }
  if (!token0isInput) {
    let lowerPriceSwap;
    let upperPriceSwap;
    lowerPriceSwap = 1 / upperPrice;
    upperPriceSwap = 1 / lowerPrice;
    let lowerTick = priceToTickWithDecimals(lowerPriceSwap, decimals0, decimals1);
    let upperTick = priceToTickWithDecimals(upperPriceSwap, decimals0, decimals1);
    prices.lowerPriceSwap = tickToPriceWithDecimals(nearestUsableTick(lowerTick, Number(tick)), decimals0, decimals1);
    prices.upperPriceSwap = tickToPriceWithDecimals(nearestUsableTick(upperTick, Number(tick)), decimals0, decimals1);
    if (lPrice && hPrice) {
      lowerPrice = 1 / hPrice;
      upperPrice = 1 / lPrice;
    }
  } else {
    if (lPrice && hPrice) {
      lowerPrice = lPrice;
      upperPrice = hPrice;
    }
  }
  let lowerTick = nearestUsableTick(priceToTickWithDecimals(lowerPrice, decimals0, decimals1), Number(tick));
  let upperTick = nearestUsableTick(priceToTickWithDecimals(upperPrice, decimals0, decimals1), Number(tick));

  lowerPrice = tickToPriceWithDecimals(lowerTick, decimals0, decimals1);
  upperPrice = tickToPriceWithDecimals(upperTick, decimals0, decimals1);

  prices.lowerPrice = lowerPrice;
  prices.upperPrice = upperPrice;
  prices.lowerTick = lowerTick;
  prices.upperTick = upperTick;

  return prices;
};

export const getPositions = (pool, selectedToken, selectedFeePair, priceRangeData, amount, decimals) => {
  if (selectedToken.address.toLowerCase() === selectedFeePair.token0.toLowerCase()) {
    const position = new Position.fromAmount0({
      pool: pool,
      tickLower: priceRangeData?.lowerTick,
      tickUpper: priceRangeData?.upperTick,
      amount0: ethers.utils.parseUnits(amount.toString(), decimals).toString(),
      useFullPrecision: true,
    });
    return position.mintAmounts.amount1.toString();
  } else {
    const position = new Position.fromAmount1({
      pool: pool,
      tickLower: priceRangeData?.lowerTick,
      tickUpper: priceRangeData?.upperTick,
      amount1: ethers.utils.parseUnits(amount.toString(), decimals).toString(),
    });
    return position.mintAmounts.amount0.toString();
  }
};
