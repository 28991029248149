import React from "react";

const V3LiquidityPriceInput = ({
  priceInput,
  setPriceInput,
  selectedTokenOne,
  selectedTokenTwo,
}) => {
  return (
    <>
      <div className="bg-l border dark-border mt-2 rounded-10">
        <input
          type="text"
          className="ps-2 py-1 font-md w-100 dark-text form-control-xl-placeholder"
          value={priceInput}
          placeholder="0"
          style={{ background: "none", border: "none" }}
          onChange={(e) => {
            if (e.target.value.match(/^([0-9]{1,})?(\.[0-9]{0,18})?$/)) {
              setPriceInput(e.target.value);
            }
          }}
        />
      </div>
      <div className="mt-1 d-flex justify-content-between mb-0">
        <span className="op-8 dark-text">
          Starting {selectedTokenOne?.symbol} Price:
        </span>
        <span className="op-8 dark-text mx-2">
          {selectedTokenOne && selectedTokenTwo && priceInput
            ? `${priceInput} ${selectedTokenTwo?.symbol} per ${selectedTokenOne?.symbol}`
            : "-"}
        </span>
      </div>
    </>
  );
};

export default V3LiquidityPriceInput;
