import React from "react";
import { formatAmount } from "../Function/commonFunction";

function RemoveLiquidityInfoModel({
  spinner,
  setSpinner,
  pair,
  amountOut0,
  amountOut1,
  Value,
  removeLiquidity,
}) {
  return spinner?.removeLiquidityModal ? (
    <div className="row justify-content-center">
      <div className="col-10 col-sm-3 col-md-4 col-lg-4 col-xl-3 rounded-15 p-3 modal-overlay_RemoveLiquidity bg-white-new m-0 in-model in-model-auto ">
        <div className="modal-container_swapDetails bg-white-new rounded-15 p-0 m-0">
          <div className="d-flex justify-content-between mt-1 mb-3">
            <h5 className="d-flex justify-content-center font-sm dark-text">
              <b>Your Will Receive</b>
            </h5>
            <i
              className="bi bi-x-lg pointer dark-text"
              onClick={() =>
                setSpinner((prev) => ({ ...prev, removeLiquidityModal: false }))
              }
            ></i>
          </div>
          <div className="mod-in-over  px-2">
            <div className="card-body border dark-border bg-l  p-relative rounded-15 p-3 py-1">
              <div className="d-flex  justify-content-between mt-1 mb-0">
                <div>
                  <p className="d-flex align-items-center text-gray-900 rajdhani-500 font-xs dark-text mb-0">
                    {pair?.token0?.icon ? (
                      <img
                        src={pair?.token0?.icon}
                        alt="user"
                        className="h-20 rounded-circle mx-1"
                      />
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        className="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                        color="text"
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                    )}
                    <span className="mx-1">{pair?.token0?.symbol}</span>
                  </p>
                </div>
                <p className="text-gray-900 rajdhani-400 font-sm dark-text mb-0 ">
                  {formatAmount(amountOut0)}
                </p>
              </div>
              <div className="d-flex  justify-content-between m-0">
                <p className="d-flex align-items-center text-gray-900 rajdhani-500 font-sm dark-text mb-0">
                  {pair?.token1?.icon ? (
                    <img
                      src={pair?.token1?.icon}
                      alt="user"
                      className="h-20 rounded-circle mx-1"
                    />
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      className="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                      color="text"
                      width="20px"
                      height="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                    </svg>
                  )}
                  <span className="mx-1">{pair?.token1?.symbol}</span>
                </p>
                <p className="text-gray-900 rajdhani-400 font-sm dark-text mb-0">
                  {formatAmount(amountOut1)}
                </p>
              </div>
              <div />
            </div>
            <div className="d-flex justify-content-between mt-4">
              <span className=" font-xs dark-text ">
                {pair?.token0?.symbol + "/" + pair?.token1?.symbol} Burned
              </span>
              <span className=" font-xs dark-text ">
                {pair?.token0?.icon ? (
                  <img
                    src={pair?.token0?.icon}
                    alt="user"
                    className="h-20 rounded-circle mx-1"
                  />
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    className="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                    color="text"
                    width="20px"
                    height="20px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                  </svg>
                )}
                {pair?.token1?.icon ? (
                  <img
                    src={pair?.token1?.icon}
                    alt="user"
                    className="h-20 rounded-circle mx-1"
                  />
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    className="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                    color="text"
                    width="20px"
                    height="20px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                  </svg>
                )}
                <span className="mx-1">
                  {formatAmount((Number(pair?.lptokens) * Value) / 100)}
                </span>
              </span>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <span className="d-flex justify-content-start font-xs dark-text ">
                Output is estimated. If the price changes by more than 0.5% your
                transaction will revert.
              </span>
            </div>
            <button
              className="btn header-btn  rajdhani-700 rounded-10  px-4 font-sm  text-blue bg-wallet btn-new-color w-100 mt-3"
              onClick={removeLiquidity}
            >
              <span className>Confirm</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          spinner?.removeLiquidityModal
            ? "modal-backdrop-swaptoken fade show"
            : "d-none"
        }
      ></div>
    </div>
  ) : (
    ""
  );
}

export default RemoveLiquidityInfoModel;
