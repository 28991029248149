import React, { useContext } from "react";
import { walletConnect } from "../../../../contexts/WalletConnect";
import { constants } from "ethers";

function V3IncreaseLiquidityConnectButton({ spinner, token0, token1, depositAmount0, depositAmount1, increaseLiquidity }) {
  const { isConnected, ConnectWallet } = useContext(walletConnect);
  return (
    <>
      {spinner?.addLiquiSpinner ? (
        <button className="btn header-btn rajdhani-600 rounded-10 px-4 font-sm text-blue bg-gray-300 btn-new-color w-100 mt-2" disabled>
          <div class="nb-spinner-big"></div>
        </button>
      ) : !isConnected ? (
        <button className="btn header-btn  rajdhani-500 rounded-10 px-4 font-md text-white btn-bg-blue w-100 mt-2" onClick={ConnectWallet}>
          Connect wallet
        </button>
      ) : !depositAmount0?.originalValue || !depositAmount1?.originalValue ? (
        <button className="btn header-btn rajdhani-700 rounded-10 px-4 font-sm dark-text bg-gray-300 btn-new-color w-100 mt-2" disabled>
          <span className="">Enter an amount</span>
        </button>
      ) : Number(depositAmount0?.originalValue) > Number(token0?.balance) ? (
        <button className="btn header-btn rajdhani-600 rounded-10 px-4 font-sm dark-text bg-gray-300 text-gray-900 btn-new-color w-100 mt-2" disabled>
          <span className="">Insufficient {token0?.symbol} balance</span>
        </button>
      ) : Number(depositAmount1?.originalValue) > Number(token1?.balance) ? (
        <button className="btn header-btn rajdhani-600 rounded-10 px-4 font-sm dark-text bg-gray-300 text-gray-900 btn-new-color w-100 mt-2" disabled>
          <span className="">Insufficient {token1?.symbol} balance</span>
        </button>
      ) : (token0?.address !== constants?.AddressZero && Number(token0?.allowance) < Number(depositAmount0?.originalValue)) ||
        (token1?.address !== constants?.AddressZero && Number(token1?.allowance) < Number(depositAmount1?.originalValue)) ? (
        <button className="btn header-btn rajdhani-600 rounded-10 px-4 font-sm dark-text bg-gray-300 text-gray-900 btn-new-color w-100 mt-2" disabled>
          <span className="">Increase</span>
        </button>
      ) : (
        <button className="btn header-btn rajdhani-500 rounded-10 px-4 font-md text-white btn-bg-blue w-100 mt-2" onClick={increaseLiquidity}>
          Increase
        </button>
      )}
    </>
  );
}

export default V3IncreaseLiquidityConnectButton;
