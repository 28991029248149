import React, { useContext, useEffect, useRef, useState } from "react";
import { walletConnect } from "../../contexts/WalletConnect";
import { formatAddress } from "../Function/commonFunction";
import ConnectWallectPopUP from "./ConnectWallectPopUP";
import CopyBtn from "./CopyBtn";

export default function ConnectWallet() {
  const { ConnectWallet, isConnected, address, urlChain, disconnect } =
    useContext(walletConnect);
  const [isOpenConnectWallet, setIsOpenConnectWallet] = useState(false);
  const dropdownRef = useRef();

  const toggleModalConnectWallet = () => {
    setIsOpenConnectWallet(!isOpenConnectWallet);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown-5")) {
      setIsOpenConnectWallet(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      {urlChain !== "solana" ? (
        isConnected ? (
          <>
            <div className="dropdown-5 d-flex align-items-center">
              <button
                className="open-modal-btn_wallet btn rajdhani-700 rounded-10  font-xs text-blue bg-wallet-2 h-40 wallet-width"
                onClick={toggleModalConnectWallet}
              >
                {!isOpenConnectWallet ? (
                  <>
                    <span className="">{formatAddress(address)}</span>
                    <CopyBtn text={address} />
                  </>
                ) : (
                  <>
                    <span className="">{formatAddress(address)}</span>
                    <CopyBtn text={address} />
                    <div
                      className={`dropdown-content dropdown-content-wallet bg-white-new rounded-15 ${
                        isOpenConnectWallet ? "show" : ""
                      }`}
                    >
                      <div
                        className=" bg-white-new p-2"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div
                          className="d-flex justify-content-start align-items-center p-md-3 m-md-0 m-sm-2 m-2 p-md-0 p-sm-3 p-3 rounded-15 chain-bg bg-card pointer"
                          onClick={disconnect}
                        >
                          <i className="bi bi-box-arrow-right font-md me-2 mx-2"></i>
                          <h6 className="text-gray-900 rajdhani-500 font-xs dark-text mx-2 mb-0">
                            Disconnect Wallet
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        isOpenConnectWallet
                          ? "modal-backdrop fade show d-lg-none d-md-none d-sm-none d-block"
                          : "d-none"
                      }
                    ></div>
                  </>
                )}
              </button>
            </div>
          </>
        ) : (
          <button
            className="btn rajdhani-700 rounded-10  font-xs text-blue bg-wallet-2 h-40 wallet-width"
            onClick={ConnectWallet}
            ref={dropdownRef}
          >
            <img className="h-15 me-2" src="assets/image-2/wallet.png" alt="" />
            <span className="">Connect Wallet</span>
          </button>
        )
      ) : false ? (
        <>
          <div onClick={(e) => e.stopPropagation()}>
            <button
              className="btn rajdhani-700 rounded-10  font-xs text-blue bg-wallet-2 h-40 wallet-width"
              onClick={toggleModalConnectWallet}
            >
              {!isOpenConnectWallet ? (
                <>
                  <span className="">{formatAddress(address)}</span>
                  <CopyBtn text={address} />
                </>
              ) : (
                <>
                  <span className="">{formatAddress(address)}</span>
                  <CopyBtn text={address} />
                  <div
                    className={`dropdown-content dropdown-content-wallet bg-white-new rounded-15 ${
                      isOpenConnectWallet ? "show" : ""
                    }`}
                  >
                    <div
                      className=" bg-white-new p-2"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        className="d-flex justify-content-start align-items-center px-md-1 py-md-2 m-md-0 m-sm-2 m-2 p-md-0 p-sm-3 p-3 rounded-15 chain-bg pointer"
                        onClick={disconnect}
                      >
                        <i className="bi bi-box-arrow-right font-md me-2"></i>
                        <h6 className="text-gray-900 rajdhani-500 font-xss dark-text m-0">
                          Disconnect Wallet
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      isOpenConnectWallet
                        ? "modal-backdrop fade show d-lg-none d-md-none d-sm-none d-block"
                        : "d-none"
                    }
                  ></div>
                </>
              )}
            </button>
          </div>
        </>
      ) : (
        <button
          className="btn rajdhani-700 rounded-10  font-xs text-blue bg-wallet-2 h-40 wallet-width"
          // onClick={ConnectWalletSolana}
          ref={dropdownRef}
        >
          <img className="h-15 me-2" src="assets/image-2/wallet.png" alt="" />
          <span className="">Connect Wallet</span>
        </button>
      )}
    </>
  );
}
