import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { walletConnect } from "../../../../contexts/WalletConnect";
import { globalStates } from "../../../../contexts/GlobalStates";
import {
  findTokenForImport,
  getFactory,
  getNonfungiblePositionManagerContract,
  getTokenAllowance,
  getTokenBalance,
  getV3Pool,
  getWethV3Address,
} from "../../../../Components/Function/ethersFunctions";
import {
  getAmountsForPosition,
  getMinAndMaxPrices,
  getPriceFromSqrtPrice,
  removeLiquidityInV3,
  getCollectableFee,
} from "../../../../Components/Function/liquidityFunctions";
import { Position } from "@uniswap/v3-sdk";
import { constants } from "ethers";
import { ethers } from "ethers";
import { formatAmount } from "../../../../Components/Function/commonFunction";
import V3RemoveLiquidityButton from "./V3RemoveLiquidityButton";
import ApproveModal from "../../../../Components/Coman/ApproveModal";
import LiquidityTxSubmittedModel from "../../../../Components/Coman/LiquidityTxSubmittedModel";
import V3RemoveLiquiditytInfoModel from "../../../../Components/Coman/V3RemoveLiquiditytInfoModel";

function RemoveLiquidityV3Box({ setSwapBoxModalChange }) {
  const [value, setValue] = useState(0);

  const handleSliderChange = (value) => {
    setValue(value);
    if (Number(value) === 100) {
      setDepositAmount0(amount0);
      setDepositAmount1(amount1);
      setLptokenInPer(lptoken);
    } else {
      setDepositAmount0(formatAmount((Number(amount0) * Number(value)) / 100));
      setDepositAmount1(formatAmount((Number(amount1) * Number(value)) / 100));
      setLptokenInPer(formatAmount((Number(lptoken) * Number(value)) / 100));
    }
  };

  const navigate = useNavigate();

  const { provider, selectedChain, address, signer, chainId } = useContext(walletConnect);
  const { settingData, chainTokens } = useContext(globalStates);

  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("id") || "");

  const [lptoken, setLptoken] = useState("0");
  const [lptokenInPer, setLptokenInPer] = useState("0");
  const [liquidity100Per, setLiquidity100Per] = useState("");

  const [token0, setToken0] = useState(null);
  const [token1, setToken1] = useState(null);

  const [amount0, setAmount0] = useState("");
  const [amount1, setAmount1] = useState("");

  const [minPrice, setMinPrice] = useState({ minPrice: "0", minPriceRev: "0" });
  const [maxPrice, setMaxPrice] = useState({
    maxPrice: "0",
    maxPriceRev: "0",
  });

  const [currPrice, setCurrPrice] = useState("0");

  const [feeAmount0, setFeeAmount0] = useState("0");
  const [feeAmount1, setFeeAmount1] = useState("0");

  const [depositAmount0, setDepositAmount0] = useState("0");
  const [depositAmount1, setDepositAmount1] = useState("0");

  const [spinner, setSpinner] = useState({
    approveTokenOne: false,
    approveTokenTwo: false,
    addLiquiSpinner: false,
    addLiquiInfoModal: false,
    addLiquiTxSubModal: false,
    addLiquiTxHash: "",
  });

  const getPositionDataById = async () => {
    try {
      const positionContract = await getNonfungiblePositionManagerContract(selectedChain?.routers[0].NonfungiblePositionManager, provider);
      const weth = await getWethV3Address(selectedChain?.routers[0], provider);
      const factory = await getFactory(selectedChain?.routers[0], "v3", provider);
      if (positionContract) {
        let position;
        position = await positionContract.positions(id);
        if (position) {
          const poolAddress = await factory.getPool(position?.token0, position?.token1, position?.fee);
          const pool = await getV3Pool(poolAddress, provider);
          const slot0 = await pool?.slot0();
          const sqrtPriceX96 = slot0?.sqrtPriceX96;
          let token0;
          if (position?.token0?.toLowerCase() === weth?.toLowerCase()) {
            const find = chainTokens?.find((item) => item.address?.toLowerCase() === constants?.AddressZero?.toLowerCase());
            token0 = find;
          } else {
            const find = chainTokens?.find((item) => item.address?.toLowerCase() === position?.token0?.toLowerCase());
            if (find) {
              token0 = find;
            } else {
              const { address, symbol, name, decimals } = await findTokenForImport(position?.token0, provider);
              token0 = {
                address: position?.token0?.toLowerCase(),
                decimals,
                name,
                symbol,
                icon: "",
              };
            }
          }
          let token1;
          if (position?.token1?.toLowerCase() === weth?.toLowerCase()) {
            const find = chainTokens?.find((item) => item.address?.toLowerCase() === constants?.AddressZero?.toLowerCase());
            token1 = find;
          } else {
            const find = chainTokens?.find((item) => item.address?.toLowerCase() === position?.token1?.toLowerCase());
            if (find) {
              token1 = find;
            } else {
              const { address, symbol, name, decimals } = await findTokenForImport(position?.token1, provider);
              token1 = {
                address: position?.token1?.toLowerCase(),
                decimals,
                name,
                symbol,
                icon: "",
              };
            }
          }
          setLptoken(ethers.utils.formatUnits(position?.liquidity?.toString(), "18"));
          setLiquidity100Per(position?.liquidity);
          const price = getPriceFromSqrtPrice(sqrtPriceX96, token0?.decimals, token1?.decimals);
          setCurrPrice(price);
          let prices = getMinAndMaxPrices(position?.tickLower, position?.tickUpper, token0?.decimals, token1?.decimals);
          const amount = await getAmountsForPosition(position, slot0, token0, token1);
          if (amount) {
            setAmount0(amount?.amount0);
            setAmount1(amount?.amount1);
          }
          if (price?.price >= prices?.minPrice && price?.price <= prices?.maxPrice) {
            setStatus(1);
          } else if (amount?.amount0 <= 0 && amount?.amount1 <= 0) {
            setStatus(2);
          }
          setToken0(token0);
          setToken1(token1);
          await getAndSetTokenBalanceAndAllowance(token0, token1);
          setMinPrice({
            minPrice: prices?.minPrice,
            minPriceRev: prices?.minPriceRev,
          });
          setMaxPrice({
            maxPrice: prices?.maxPrice,
            maxPriceRev: prices?.maxPriceRev,
          });
          await getcollectableFeeAmouts(token0?.decimals, token1?.decimals);
          setLoading(false);
        } else {
          setAmount0("0");
          setAmount1("0");
          setMinPrice({
            minPrice: "0",
            minPriceRev: "0",
          });
          setMaxPrice({
            maxPrice: "0",
            maxPriceRev: "0",
          });
          setCurrPrice("0");
          setLoading(false);
        }
      }
    } catch (err) {
      console.log("error in getPositionDataById", err);
      setAmount0("0");
      setAmount1("0");
      setMinPrice({
        minPrice: "0",
        minPriceRev: "0",
      });
      setMaxPrice({
        maxPrice: "0",
        maxPriceRev: "0",
      });
      setCurrPrice("0");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && provider && chainTokens?.length && address && chainId === selectedChain?.chainId) {
      if (selectedChain?.currency?.toLowerCase() === chainTokens[0]?.symbol?.toLowerCase()) {
        setLoading(true);
        getPositionDataById();
      }
    }
  }, [id, provider, chainTokens, address, chainId]);

  const getcollectableFeeAmouts = async (decimals0, decimals1) => {
    try {
      if (id && address && signer && decimals0 && decimals1) {
        const positionContract = await getNonfungiblePositionManagerContract(selectedChain?.routers[0].NonfungiblePositionManager, signer);
        const feeData = await getCollectableFee(positionContract, id, address, decimals0, decimals1);
        if (feeData) {
          setFeeAmount0({ amount: feeData?.amount0, amountWei: feeData?.amount0Wei });
          setFeeAmount1({ amount: feeData?.amount1, amountWei: feeData?.amount1Wei });
        } else {
          setFeeAmount0("0");
          setFeeAmount1("0");
        }
      }
    } catch (err) {
      console.log("erorr in getcollectableFeeAmouts : ", err);
      setFeeAmount0("0");
      setFeeAmount1("0");
    }
  };

  const getAndSetTokenBalanceAndAllowance = async (tkn0, tkn1) => {
    try {
      if (tkn0 && tkn1) {
        const token0Balance = await getTokenAllowance(tkn0?.address, address, provider);
        const token1Balance = await getTokenBalance(tkn1?.address, address, provider);
        const token0Allowance = await getTokenAllowance(
          tkn0?.address,
          address,
          selectedChain?.routers[0].NonfungiblePositionManager,
          provider
        );
        const token1Allowance = await getTokenAllowance(
          tkn1?.address,
          address,
          selectedChain?.routers[0].NonfungiblePositionManager,
          provider
        );
        setToken0((prev) => ({
          ...prev,
          balance: token0Balance,
          allowance: token0Allowance,
        }));
        setToken1((prev) => ({
          ...prev,
          balance: token1Balance,
          allowance: token1Allowance,
        }));
      } else {
        if (token0 && token1) {
          const token0Balance = await getTokenBalance(token0?.address, address, provider);
          const token1Balance = await getTokenBalance(token1?.address, address, provider);
          const token0Allowance = await getTokenAllowance(
            token0?.address,
            address,
            selectedChain?.routers[0].NonfungiblePositionManager,
            provider
          );
          const token1Allowance = await getTokenAllowance(
            token1?.address,
            address,
            selectedChain?.routers[0].NonfungiblePositionManager,
            provider
          );
          setToken0((prev) => ({
            ...prev,
            balance: token0Balance,
            allowance: token0Allowance,
          }));
          setToken1((prev) => ({
            ...prev,
            balance: token1Balance,
            allowance: token1Allowance,
          }));
        }
      }
    } catch {}
  };

  const removeLiquidity = async () => {
    try {
      setSpinner((prev) => ({
        ...prev,
        addLiquiSpinner: true,
      }));
      const result = await removeLiquidityInV3(
        token0,
        token1,
        lptokenInPer,
        liquidity100Per,
        value,
        depositAmount0,
        depositAmount1,
        selectedChain?.routers[0],
        provider,
        settingData?.slippage,
        settingData?.txDeadline,
        id,
        address
      );
      if (result?.hash) {
        setSpinner((prev) => ({
          ...prev,
          addLiquiSpinner: false,
          addLiquiTxSubModal: true,
          addLiquiTxHash: result?.hash,
          addLiquiInfoModal: false,
        }));
        setValue(0);
        getPositionDataById();
        getcollectableFeeAmouts();
        getAndSetTokenBalanceAndAllowance();
        setDepositAmount0("");
        setDepositAmount1("");
      } else {
        setSpinner((prev) => ({
          ...prev,
          addLiquiSpinner: false,
          addLiquiTxHash: "",
          addLiquiInfoModal: false,
        }));
      }
    } catch (err) {
      setSpinner((prev) => ({
        ...prev,
        addLiquiSpinner: false,
        addLiquiTxHash: "",
        addLiquiInfoModal: false,
      }));
    }
  };

  return (
    <>
      <div className="d-flex mb-0 justify-content-between align-items-center">
        <p className="text-gray-900 rajdhani-700 font-sm pb-0 mb-0">
          <a type="button" onClick={() => navigate(-1)}>
            <i class="fa fa-chevron-left d-flex align-items-center pointer text-gray-800 dark-text"></i>
          </a>
        </p>
        <h5 className="text-gray-900 font-md rajdhani-500 pb-0 mb-0 ms-sm-0 ms-md-  3 sm-lg-4 ms-xl-5 ms-0 dark-text">Remove Liquidity</h5>
        <p className="text-gray-900 rajdhani-700 pb-0 mb-0 d-flex justify-content-between align-items-center ">
          <button className="btn rajdhani-400 rounded-10  font-xs dark-text py-1 pe-0" onClick={() => setSwapBoxModalChange("setting")}>
            <i className="fa fa-gear ms-2 font-md mt-1"></i>
          </button>
        </p>
      </div>
      <hr style={{ border: "1px solid #6C86AD" }} />
      {loading ? (
        <div className="card-body mt-2 dark-text border-light rounded-10 p-2 border-box d-flex justify-content-center align-items-center">
          <div className="text-center ">Loading...</div>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="text-gray-900 rajdhani-600 mb-0  d-flex  align-items-center ">
              {token0?.icon ? (
                <img src={token0?.icon} alt="user" className="h-30 rounded-circle mx-1" />
              ) : (
                <svg
                  viewBox="0 0 24 24"
                  class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                  color="text"
                  width="30px"
                  height="30px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                </svg>
              )}
              {token1?.icon ? (
                <img src={token1?.icon} alt="user" className="h-30 rounded-circle mx-1" />
              ) : (
                <svg
                  viewBox="0 0 24 24"
                  class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                  color="text"
                  width="30px"
                  height="30px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                </svg>
              )}
              <span className="dark-text d-flex font-md align-items-center text-uppercase">
                {token0?.symbol} / {token1?.symbol}
              </span>
            </h6>
            <h6 className=" font-xss rajdhani-400 text-gray-900 dark-text m-0  ">
              {status === 0 ? (
                <>
                  <span className="text-danger"> Inactive</span>
                  <span className="d-inline-block h-6 w-6 rounded-10 bg-danger mt-1 mx-1" />
                </>
              ) : status === 1 ? (
                <>
                  <span className="text-success"> In range</span>
                  <span className="d-inline-block h-6 w-6 rounded-10 bg-success mt-1 mx-1" />
                </>
              ) : (
                <>
                  <span className="text-secondary"> Closed</span>
                  <span className="d-inline-block h-6 w-6 rounded-10 bg-secondary mt-1 mx-1" />
                </>
              )}
            </h6>
          </div>
          <div className="card-body border dark-border bg-l  p-relative rounded-15 p-2 py-1 mt-2">
            <h6 class="rajdhani-400 dark-text mt-2 op-8">Amount</h6>
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                <h2 className="dark-text rajdhani-700 mb-0">{value} %</h2>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 col-8 d-flex justify-content-end gap-2">
                <button
                  className="btn rajdhani-600 rounded-10 py-0 font-xs  btn-primary px-sm-1 px-md-2 px-1 h-40-btn text-white"
                  onClick={() => handleSliderChange(25)}
                  disabled={!amount0 || !amount1}
                >
                  25%
                </button>
                <button
                  className="btn rajdhani-600 rounded-10 py-0 font-xs px-sm-1 px-md-2 px-1 btn-primary h-40-btn text-white"
                  onClick={() => handleSliderChange(50)}
                  disabled={!amount0 || !amount1}
                >
                  50%
                </button>
                <button
                  className="btn rajdhani-600 rounded-10 py-0 font-xs px-sm-1 px-md-2 px-1  btn-primary h-40-btn text-white"
                  onClick={() => handleSliderChange(75)}
                  disabled={!amount0 || !amount1}
                >
                  75%
                </button>
                <button
                  className="btn rajdhani-600 rounded-10 py-0 font-xs px-sm-1 px-md-2 px-1  btn-primary h-40-btn text-white"
                  onClick={() => handleSliderChange(100)}
                  disabled={!amount0 || !amount1}
                >
                  Max
                </button>
              </div>
            </div>
            <input
              type="range"
              value={value}
              onChange={(e) => handleSliderChange(e.target.value)}
              style={{ width: "100%" }}
              name=""
              className="w-100 mt-3 cursor-pointer"
              id=""
              disabled={!amount0 || !amount1}
            />
            <div />
          </div>
          <div className="card-body border dark-border bg-l  p-relative rounded-15 p-2 py-1 mt-2">
            <div className="d-flex  justify-content-between align-items-center mt-1">
              <p className="text-gray-900 rajdhani-600 font-sm dark-text mb-0">Pooled {token0?.symbol} :</p>
              <div>
                <p className="text-gray-900 rajdhani-600 font-sm dark-text mb-0 ">
                  {depositAmount0 ? formatAmount(depositAmount0) : "0"}
                  {token0?.icon ? (
                    <img src={token0?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                      color="text"
                      width="20px"
                      height="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                    </svg>
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex  justify-content-between align-items-center mt-2 ">
              <p className="text-gray-900 rajdhani-600 font-sm dark-text mb-0">Pooled {token1?.symbol} :</p>
              <div>
                <p className="text-gray-900 rajdhani-600 font-sm dark-text mb-0 ">
                  {depositAmount1 ? formatAmount(depositAmount1) : "0"}
                  {token1?.icon ? (
                    <img src={token1?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                      color="text"
                      width="20px"
                      height="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                    </svg>
                  )}
                </p>
              </div>
            </div>
            <hr className=" my-2" style={{ border: "1px solid rgb(108, 134, 173)" }} />
            <div className="d-flex  justify-content-between align-items-center mt-1">
              <p className="text-gray-900 rajdhani-600 font-sm dark-text mb-0">{token0?.symbol} Fee Earned :</p>
              <div>
                <p className="text-gray-900 rajdhani-600 font-sm dark-text mb-0 ">
                  {feeAmount0?.amount ? formatAmount(feeAmount0?.amount) : "0"}
                  {token0?.icon ? (
                    <img src={token0?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                      color="text"
                      width="20px"
                      height="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                    </svg>
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex  justify-content-between align-items-center mt-1">
              <p className="text-gray-900 rajdhani-600 font-sm dark-text mb-0">{token1?.symbol} Fee Earned :</p>
              <div>
                <p className="text-gray-900 rajdhani-600 font-sm dark-text mb-0 ">
                  {feeAmount1?.amount ? formatAmount(feeAmount1?.amount) : "0"}
                  {token1?.icon ? (
                    <img src={token1?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                      color="text"
                      width="20px"
                      height="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                    </svg>
                  )}
                </p>
              </div>
            </div>
            <div />
          </div>
          {/* <div className="d-flex mt-1 justify-content-between align-items-center py-3">
          <p className="font-sm rajdhani-600 dark-text mb-0 ">Collect as WBNB</p>
          <div className="form-check form-switch switch-lg switch-success p-0 d-flex flex-row justify-content-between mb-2 mt-2">
            <label className="form-check-label w-75 font-sm rajdhani-700 text-gray-900 dark-text lh-28" />
            <input className="form-check-input mt-1" type="checkbox" id="asdasd" style={{ width: "2.5em", height: "1.5em" }} />
          </div>
        </div> */}
          <V3RemoveLiquidityButton
            spinner={spinner}
            token0={token0}
            token1={token1}
            depositAmount0={depositAmount0}
            depositAmount1={depositAmount1}
            setSpinner={setSpinner}
          />
          <LiquidityTxSubmittedModel spinner={spinner} setSpinner={setSpinner} />
          <ApproveModal spinner={spinner?.approveTokenOne} selectedToken={token0} />
          <V3RemoveLiquiditytInfoModel
            spinner={spinner}
            setSpinner={setSpinner}
            token0={token0}
            token1={token1}
            amount0={depositAmount0}
            amount1={depositAmount1}
            feeAmount0={feeAmount0}
            feeAmount1={feeAmount1}
            removeLiquidity={removeLiquidity}
          />
        </div>
      )}
    </>
  );
}

export default RemoveLiquidityV3Box;
