/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import { formatAmount } from "../Function/commonFunction";
import { walletConnect } from "../../contexts/WalletConnect";

function SwapTokenModel({
  inputAmountOne,
  inputAmountTwo,
  selectedTokenOne,
  selectedTokenTwo,
  swapTxStatus,
  setSwapTxStatus,
}) {
  const { selectedChain } = useContext(walletConnect);
  const modalRef = useRef(null);

  const closeSwapModel = () => {
    setSwapTxStatus((prev) => ({ ...prev, status: 0 }));
  };
  return (
    <>
      <div>
        {swapTxStatus?.status === 1 ? (
          <>
            <div className="row justify-content-center">
              <div
                ref={modalRef}
                className="col-10 col-sm-3 col-md-4 col-lg-4 col-xl-2 rounded-15 modal-overlay_swapToken bg-white-new"
              >
                <div
                  className="modal-container_swapToken bg-white-new"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="d-flex justify-content-end">
                    <i
                      className="bi bi-x-lg pointer dark-text"
                      onClick={closeSwapModel}
                    ></i>
                  </div>
                  <div className="mt-4 d-flex justify-content-center align-items-center">
                    <div className="mt-5 nb-spinner-xl"></div>
                  </div>
                  <div className="mt-5">
                    <h5 className="d-flex justify-content-center font-sm dark-text">
                      <b>Approve {selectedTokenOne?.symbol}</b>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : swapTxStatus?.status === 2 ? (
          <>
            <div className="row justify-content-center">
              <div
                ref={modalRef}
                className="col-10 col-sm-3 col-md-4 col-lg-4 col-xl-2 rounded-15  modal-overlay_swapToken bg-white-new"
              >
                <div
                  className="modal-container_swapToken bg-white-new"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="d-flex justify-content-end">
                    <i
                      className="bi bi-x-lg pointer dark-text"
                      onClick={closeSwapModel}
                    ></i>
                  </div>
                  <div className="mt-3 d-flex justify-content-center align-items-center">
                    <div className="nb-spinner-xl"></div>
                  </div>
                  <div className="mt-5">
                    <h5 className="d-flex justify-content-center font-sm dark-text">
                      <b>Confirm Swap</b>
                    </h5>
                  </div>
                  <div className="mt-4">
                    <div className="d-flex justify-content-center align-items-center dark-text font-xs">
                      <span className="mx-1">
                        {formatAmount(inputAmountOne)}{" "}
                        {selectedTokenOne?.symbol}
                      </span>
                      {selectedTokenOne?.icon ? (
                        <img
                          className="h-20 rounded-pill mx-1"
                          src={selectedTokenOne?.icon}
                          alt=""
                        />
                      ) : (
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                          color="text"
                          width="20px"
                          height="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                        </svg>
                      )}
                    </div>
                    <div className="d-flex justify-content-center">
                      <span>
                        <i className="bi bi-arrow-down mx-2 dark-text"></i>
                      </span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mb-4 dark-text font-xs">
                      <span className="mx-1">
                        {formatAmount(inputAmountTwo)}{" "}
                        {selectedTokenTwo?.symbol}
                      </span>
                      {selectedTokenTwo?.icon ? (
                        <img
                          className="h-20 rounded-pill mx-1"
                          src={selectedTokenTwo?.icon}
                          alt=""
                        />
                      ) : (
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                          color="text"
                          width="20px"
                          height="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                        </svg>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : swapTxStatus?.status === 3 ? (
          <>
            <div className="row justify-content-center">
              <div
                ref={modalRef}
                className="col-10 col-sm-3 col-md-4 col-lg-4 col-xl-2 rounded-15 modal-overlay_swapToken bg-white-new"
              >
                <div
                  className="modal-container_swapToken bg-white-new"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="d-flex justify-content-end">
                    <i
                      className="bi bi-x-lg pointer dark-text"
                      onClick={closeSwapModel}
                    ></i>
                  </div>
                  <div className="mt-3 d-flex justify-content-center align-items-center">
                    <i className="bi bi-arrow-up-circle display1-size dark-text"></i>
                  </div>
                  <div className=" d-flex justify-content-center align-items-center flex-column">
                    <h5 className="mt-3 d-flex justify-content-center font-md dark-text">
                      <span>Transaction receipt</span>
                    </h5>
                    <a
                      className="mt-4 btn rounded-15 d-flex justify-content-center align-items-center font-xs text-white pointer form-select w-75 p-2 b-none"
                      href={`${selectedChain?.explorerUrl}/tx/${swapTxStatus?.hash}`}
                      target="_blank"
                    >
                      <span>View </span>
                      <span className="ms-1">
                        {swapTxStatus?.hash?.substr(0, 8) + "..."}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : swapTxStatus?.status === 4 ? (
          <>
            <div className="row justify-content-center">
              <div
                ref={modalRef}
                className="col-10 col-sm-3 col-md-4 col-lg-4 col-xl-2 rounded-15 modal-overlay_swapToken bg-white-new"
              >
                <div
                  className="modal-container_swapToken bg-white-new"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="d-flex justify-content-end">
                    <i
                      className="bi bi-x-lg pointer dark-text"
                      onClick={closeSwapModel}
                    ></i>
                  </div>
                  <div className="mt-3 d-flex justify-content-center align-items-center">
                    <i className="bi bi-exclamation-circle font-50 text-red"></i>
                  </div>
                  <div className="mt-1  ">
                    <h5 className="d-flex justify-content-center font-sm text-red m-0 p-0">
                      <b>Transaction</b>
                    </h5>
                    <h5 className="d-flex justify-content-center font-sm text-red m-0 p-0">
                      <b>Rejected </b>
                    </h5>
                  </div>
                  <div className="mt-4 mb-5 d-flex justify-content-center">
                    <button
                      className="btn px-2 py-1 bg-wallet text-blue rounded-10 rajdhani-700  font-sm"
                      onClick={closeSwapModel}
                    >
                      Dismiss
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          swapTxStatus?.status !== 0
            ? "modal-backdrop-swaptoken fade show"
            : "d-none"
        }
      ></div>
    </>
  );
}

export default SwapTokenModel;
