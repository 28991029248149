import React, { useContext, useEffect, useRef, useState } from "react";
import SwapHeader from "../../../../Components/Coman/SwapHeader";
import { Link } from "react-router-dom";
import Footer from "../../../../Components/Coman/Footer";
import { walletConnect } from "../../../../contexts/WalletConnect";
import { globalStates } from "../../../../contexts/GlobalStates";
import {
  getTokenAllowanceForSwap,
  getTokenBalance,
  getTokenDecimals,
} from "../../../../Components/Function/ethersFunctions";
import SwapSettingsModel from "../../../../Components/Coman/SwapSettingsModel";
import RemoveLiquidityV3Box from "./RemoveLiquidityV3Box";

function RemoveLiquidityV3() {
  const [swapBoxModalChange, setSwapBoxModalChange] = useState("");
  const dropdownRef = useRef();
  return (
    <>
      <div className=" main-vh-11 bg-dark-black dashboard-bg">
        <div className="main-wrapper ">
          <SwapHeader />
          <div className="container-wide">
            <div className="banner-wrapper py-4 mt-lg-100 mt-0 ">
              <div className="row justify-content-center px-sm-2 px-md-0 px-2 ">
                <div
                  className="col-xl-4 col-lg-6 col-md-8 col-sm-10 m-0 p-0 bg-dark-black dashboard-bg"
                  ref={dropdownRef}
                >
                  {swapBoxModalChange === "setting" ? (
                    <div className="mx-2 px-2">
                      <SwapSettingsModel
                        setSwapBoxModalChange={setSwapBoxModalChange}
                        swapBoxModalChange={swapBoxModalChange}
                      />
                    </div>
                  ) : (
                    <div className="m-0 p-3 border dark-border rounded-20">
                      <RemoveLiquidityV3Box
                        setSwapBoxModalChange={setSwapBoxModalChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default RemoveLiquidityV3;
