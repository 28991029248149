/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import V3IncreaseLiquidityConnectButton from "../V3LiquidityBox/V3IncreaseLiquidityConnectButton";
import { Link, useNavigate } from "react-router-dom";
import { formatAmount } from "../../../../Components/Function/commonFunction";
import { giveAllowance } from "../../../../Components/Function/ethersFunctions";
import { walletConnect } from "../../../../contexts/WalletConnect";
import ApproveModal from "../../../../Components/Coman/ApproveModal";
import { constants } from "ethers";
import LiquidityTxSubmittedModel from "../../../../Components/Coman/LiquidityTxSubmittedModel";

function IncreaseLiquidityV3Box({
  setSwapBoxModalChange,
  token0,
  token1,
  minPrice,
  maxPrice,
  currPrice,
  loading,
  poolData,
  status,
  amount0,
  amount1,
  swapTokenAndPrices,
  swap,
  depositAmount0,
  depositAmount1,
  handleDepositAmount0,
  handleDepositAmount1,
  spinner,
  setSpinner,
  getAndSetTokenBalanceAndAllowance,
  increaseLiquidity,
}) {
  const navigate = useNavigate();
  const { selectedChain, signer } = useContext(walletConnect);
  const approveTokenOne = async () => {
    try {
      setSpinner((prev) => ({ ...prev, approveTokenOne: true }));
      const result = await giveAllowance(token0?.address, selectedChain?.routers[0].NonfungiblePositionManager, null, signer);
      if (result?.hash) {
        getAndSetTokenBalanceAndAllowance();
        setSpinner((prev) => ({ ...prev, approveTokenOne: false }));
      } else {
        setSpinner((prev) => ({ ...prev, approveTokenOne: false }));
      }
    } catch {
      setSpinner((prev) => ({ ...prev, approveTokenOne: false }));
    }
  };

  const approveTokenTwo = async () => {
    try {
      setSpinner((prev) => ({ ...prev, approveTokenTwo: true }));
      const result = await giveAllowance(token1?.address, selectedChain?.routers[0].NonfungiblePositionManager, null, signer);
      if (result?.hash) {
        getAndSetTokenBalanceAndAllowance();
        setSpinner((prev) => ({ ...prev, approveTokenTwo: false }));
      } else {
        setSpinner((prev) => ({ ...prev, approveTokenTwo: false }));
      }
    } catch {
      setSpinner((prev) => ({ ...prev, approveTokenTwo: false }));
    }
  };
  return (
    <>
      <div className="d-flex mb-0 justify-content-between align-items-center">
        <p className="text-gray-900 rajdhani-700 font-sm pb-0 mb-0">
          <a type="button" onClick={() => navigate(-1)}>
            <i class="fa fa-chevron-left d-flex align-items-center pointer text-gray-800 dark-text"></i>
          </a>
        </p>
        <h5 className="text-gray-900 font-md rajdhani-500 pb-0 mb-0 ms-sm-0 ms-md-3 sm-lg-4 ms-xl-5 ms-0 dark-text">Add liquidity</h5>
        <p className="text-gray-900 rajdhani-700 pb-0 mb-0 d-flex justify-content-between align-items-center ">
          <button className="btn rajdhani-400 rounded-10  font-xs dark-text py-1 pe-0" onClick={() => setSwapBoxModalChange("setting")}>
            <i className="fa fa-gear ms-2 font-md mt-1"></i>
          </button>
        </p>
      </div>
      <hr style={{ border: "1px solid #6C86AD" }} />
      <>
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="text-gray-900 rajdhani-600 mb-0  d-flex  align-items-center ">
              {token0?.icon ? (
                <img src={token0?.icon} alt="user" className="h-20  rounded-circle mx-1" />
              ) : (
                <svg
                  viewBox="0 0 24 24"
                  class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                  color="text"
                  width="20px"
                  height="20px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                </svg>
              )}
              {token1?.icon ? (
                <img src={token1?.icon} alt="user" className="h-20  rounded-circle mx-1" />
              ) : (
                <svg
                  viewBox="0 0 24 24"
                  class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                  color="text"
                  width="20px"
                  height="20px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                </svg>
              )}
              <span className="dark-text d-flex font-md align-items-center text-uppercase">
                {token0?.symbol} / {token1?.symbol}
              </span>
            </h6>

            <h6 className=" font-xss rajdhani-400 text-gray-900 dark-text m-0  ">
              {status === 0 ? (
                <>
                  <span className="text-danger"> Inactive</span>
                  <span className="d-inline-block h-6 w-6 rounded-10 bg-danger mt-1 mx-1" />
                </>
              ) : status === 1 ? (
                <>
                  <span className="text-success"> In range</span>
                  <span className="d-inline-block h-6 w-6 rounded-10 bg-success mt-1 mx-1" />
                </>
              ) : (
                <>
                  <span className="text-secondary"> Closed</span>
                  <span className="d-inline-block h-6 w-6 rounded-10 bg-secondary mt-1 mx-1" />
                </>
              )}
            </h6>
          </div>
          <div className="card-body border dark-border bg-l  p-relative rounded-15 p-2 py-1 mt-2">
            <div className="d-flex  justify-content-between mt-1 mb-0">
              <div>
                <p className="text-gray-900 rajdhani-600 font-xs dark-text mb-0">
                  {swap ? (
                    token0?.icon ? (
                      <img src={token0?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                        color="text"
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                    )
                  ) : token1?.icon ? (
                    <img src={token1?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                      color="text"
                      width="20px"
                      height="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                    </svg>
                  )}
                  {swap ? token0?.symbol : token1?.symbol}
                </p>
              </div>
              <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0 ">{formatAmount(amount0)}</p>
            </div>
            <div className="d-flex  justify-content-between m-0">
              <p className="text-gray-900 rajdhani-600 font-xs dark-text mb-0">
                {swap ? (
                  token1?.icon ? (
                    <img src={token1?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                      color="text"
                      width="20px"
                      height="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                    </svg>
                  )
                ) : token0?.icon ? (
                  <img src={token0?.icon} alt="user" className="h-20  rounded-circle mx-1" />
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    class="sc-grXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                    color="text"
                    width="20px"
                    height="20px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                  </svg>
                )}
                {swap ? token1?.symbol : token0?.symbol}
              </p>
              <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0">{formatAmount(amount1)}</p>
            </div>
            <div className="px-3 py-2">
              <hr className="m-0" />
            </div>
            <div className="d-flex  justify-content-between m-0">
              <p className="text-gray-900 rajdhani-600 font-xs dark-text mb-0 ps-2">Free tier</p>
              <p className="text-gray-900 rajdhani-400 font-xs dark-text mb-0">{poolData?.fee ? poolData?.fee / 10000 : 0}%</p>
            </div>
            <div />
          </div>
        </div>
        {/*  */}
        <div>
          <div className="row  align-items-center ">
            <div className="col-12 d-flex justify-content-between my-2 align-items-center   ">
              <h6 className="rajdhani-400 dark-text mt-2 op-8">Selected range</h6>
              <button
                className=" btn d-flex align-items-center my-1 ms-2 border border-light bg-border-light h-30 rounded-10 pointer m-0 p-0"
                onClick={swapTokenAndPrices}
              >
                <span className={`p-0 m-0 rajdhani-500 font-xss ms-1 dark-text`}>{swap ? token0?.symbol : token1?.symbol}</span>
                <i class="bi bi-arrow-left-right font-xs dark-text px-2 d-flex align-items-center"></i>
                <span className={`p-0 m-0 rajdhani-500 font-xss me-1 dark-text pe-1`}>{swap ? token1?.symbol : token0?.symbol}</span>
              </button>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="card-body border dark-border bg-l  p-relative rounded-15  py-1">
                <div className="text-center pb-2">
                  <p className="font-xs rajdhani-500 op-8 dark-text mb-0">Min Price</p>
                  <h6 className="text-gray-900 rajdhani-600 font-sm dark-text mb-0 py-1">
                    {swap ? formatAmount(minPrice?.minPrice) : formatAmount(minPrice?.minPriceRev)}
                  </h6>

                  <p className="dark-text rajdhani-500 font-xss op-8 mb-0 mb-1">
                    {swap ? token0?.symbol : token1?.symbol} per {swap ? token1?.symbol : token0?.symbol}
                  </p>
                  <p className="op-8 dark-text rajdhani-500 font-xssss   mb-0 " style={{ lineHeight: "1.2" }}>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, eum.
                  </p>
                </div>
                <div />
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mt-md-0 mt-sm-2 mt-2">
              <div className="card-body border dark-border bg-l  p-relative rounded-15 py-1">
                <div className="text-center pb-2">
                  <p className="font-xs rajdhani-500 op-8 dark-text mb-0">Max Price</p>
                  <h6 className="text-gray-900 rajdhani-600 font-sm dark-text mb-0 py-1">
                    {swap ? formatAmount(maxPrice?.maxPrice) : formatAmount(maxPrice?.maxPriceRev)}
                  </h6>
                  <p className="dark-text rajdhani-500 font-xss op-8 mb-0 mb-1">
                    {swap ? token0?.symbol : token1?.symbol} per {swap ? token1?.symbol : token0?.symbol}
                  </p>
                  <p className="op-8 dark-text rajdhani-500 font-xssss   mb-0 " style={{ lineHeight: "1.2" }}>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, eum.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-sm-12 mt-2">
              <div className="card-body border dark-border bg-l  p-relative rounded-15 p-0 py-1">
                <div className="text-center p-0">
                  <p className="font-xs rajdhani-500 op-8 dark-text mb-0">Current Price</p>
                  <h6 className="text-gray-900 rajdhani-600 font-sm dark-text mb-0">
                    {currPrice?.price ? (swap ? formatAmount(currPrice?.price) : formatAmount(currPrice?.priceRev)) : 0}
                  </h6>
                  <p className="dark-text rajdhani-500 font-xss op-8 mb-0 mb-1">
                    {swap ? token0?.symbol : token1?.symbol} per {swap ? token1?.symbol : token0?.symbol}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <h6 className="rajdhani-400 dark-text mt-3">Deposit amounts</h6>
        <div className="card-body   border dark-border bg-l p-relative rounded-20 p-2 py-3  mt-2">
          <div>
            <div className="row justify-content-between align-items-center">
              <div className="col-6 col-sm-9">
                <div className="text-">
                  <input
                    type="text"
                    className=" font-xl w-100 dark-text form-control-xl-placeholder"
                    placeholder={0}
                    style={{ background: "none", border: "none" }}
                    value={depositAmount0?.value}
                    disabled={!poolData || !poolData?.pool}
                    onChange={(e) => {
                      if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                        handleDepositAmount0(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-5 col-sm-3 d-flex justify-content-end">
                <div className="bg-white-1 rounded-10 pe-1 ps-1">
                  <a className="rounded-10 btn btn-md header-btn border-0 dark-text rajdhani-500 rounded-10 p-1 pe-2 font-sm d-flex align-items-center  ">
                    {token0?.icon ? (
                      <img src={token0?.icon} alt="user" className="h-26 me-2 rounded-circle" />
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        class="sc-g rXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                        color="text"
                        width="26px"
                        height="26px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                    )}
                    {token0?.symbol}
                  </a>
                </div>
              </div>
              <div className="d-flex gap-3 flex-row justify-content-between ms-auto align-items-center pe-2">
                <p className=" rajdhani-500 mb-0 dark-text">$0</p>
                <div className="d-flex">
                  <p className="font-xss dark-text mb-0">
                    <span className="">
                      Balance: <span className="me-1">{formatAmount(token0?.balance)}</span>
                    </span>
                    <span
                      className="rajdhani-500 rounded-6 p-0 p-1 me-2  py-0 font-xss hover-mx-select text-blue bg-wallet hov-text-blue pointer"
                      onClick={() => handleDepositAmount0(token0?.balance)}
                    >
                      MAX
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body   border dark-border bg-l p-relative rounded-20 p-2 py-3  mt-2">
          <div>
            <div className="row justify-content-between align-items-center">
              <div className="col-6 col-sm-9">
                <div className="text-">
                  <input
                    type="text"
                    className=" font-xl w-100 dark-text form-control-xl-placeholder"
                    placeholder={0}
                    style={{ background: "none", border: "none" }}
                    value={depositAmount1?.value}
                    disabled={!poolData || !poolData?.pool}
                    onChange={(e) => {
                      if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                        handleDepositAmount1(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-5 col-sm-3 d-flex justify-content-end">
                <div className="bg-white-1 rounded-10 pe-1 ps-1">
                  <a className="rounded-10 btn btn-md header-btn border-0 dark-text rajdhani-500 rounded-10 p-1 pe-2 font-sm d-flex align-items-center  ">
                    {token1?.icon ? (
                      <img src={token1?.icon} alt="user" className="h-26 me-2 rounded-circle" />
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        class="sc-g rXZZQ ktVOoD sc-ZPwkx jTOfNl mx-1"
                        color="text"
                        width="26px"
                        height="26px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z"></path>
                      </svg>
                    )}
                    {token1?.symbol}
                  </a>
                </div>
              </div>
              <div className="d-flex gap-3 flex-row justify-content-between ms-auto pe-2">
                <p className=" rajdhani-500 mb-0 dark-text">$0</p>
                <div className="d-flex">
                  <p className="font-xss dark-text mb-0">
                    <span className="">
                      Balance: <span className="me-1">{formatAmount(token1?.balance)}</span>
                    </span>
                    <span
                      className="rajdhani-500 rounded-6 p-0 p-1 me-2  py-0 font-xss hover-mx-select text-blue bg-wallet hov-text-blue pointer"
                      onClick={() => handleDepositAmount1(token1?.balance)}
                    >
                      MAX
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          {token0?.address !== constants?.AddressZero && Number(token0?.allowance) < Number(depositAmount0?.originalValue) ? (
            <button
              className="btn header-btn rajdhani-500 rounded-10 mb-1 px-4 font-md text-white btn-bg-blue w-100 mt-2 me-2"
              onClick={approveTokenOne}
            >
              Approve {token0?.symbol}
            </button>
          ) : (
            ""
          )}
          {token1?.address !== constants?.AddressZero && Number(token1?.allowance) < Number(depositAmount1?.originalValue) ? (
            <button
              className="btn header-btn rajdhani-500 rounded-10 mb-1 px-4 font-md text-white btn-bg-blue w-100 mt-2 ms-2"
              onClick={approveTokenTwo}
            >
              Approve {token1?.symbol}
            </button>
          ) : (
            ""
          )}
        </div>
        <V3IncreaseLiquidityConnectButton
          spinner={spinner}
          token0={token0}
          token1={token1}
          depositAmount0={depositAmount0}
          depositAmount1={depositAmount1}
          increaseLiquidity={increaseLiquidity}
        />
      </>
      <LiquidityTxSubmittedModel spinner={spinner} setSpinner={setSpinner} />
      <ApproveModal spinner={spinner?.approveTokenOne} selectedToken={token0} />
      <ApproveModal spinner={spinner?.approveTokenTwo} selectedToken={token1} />
    </>
  );
}

export default IncreaseLiquidityV3Box;
