/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import SwapInfo from "./SwapInfo";
import SwapConnectButton from "./SwapConnectButton";
import SwapInputBox from "./SwapInputBox";
import RefreshIcon from "../../Components/Coman/RefreshIcon";
import { globalStates } from "../../contexts/GlobalStates";
import { calculateAmountIn, calculateAmountOut, getTradeInfo, swapInV2, swapInV3 } from "../../Components/Function/swapFunctions";
import { walletConnect } from "../../contexts/WalletConnect";
import { formatAmount } from "../../Components/Function/commonFunction";
import SwapTokenModel from "../../Components/Coman/SwapTokenModel";
import { giveAllowance } from "../../Components/Function/ethersFunctions";
import { ethers } from "ethers";

const SwapBox = ({
  setSwapBoxModalChange,
  selectedTokenOne,
  selectedTokenTwo,
  inputAmountOne,
  inputAmountTwo,
  setInputAmountOne,
  setInputAmountTwo,
  setTokenInfo,
  inputWay,
  setInputWay,
  fetchRouterWithFilledValue,
  setRouterWithFilledValue,
}) => {
  const { handleTokenSelectSearchParams, settingData } = useContext(globalStates);
  const dropdownRef = useRef();
  const { selectedChain, selectedRouter, provider, address } = useContext(walletConnect);
  const [showMore, setShowMore] = useState(true);
  const [swapTab, setSwapTab] = useState(0);
  const [tradeInfo, setTradeInfo] = useState(null);
  const [swapTxStatus, setSwapTxStatus] = useState({ status: 0, hash: "" });
  const [confirmSwapModal, setConfirmSwapModal] = useState(false);
  const [spinner, setSpinner] = useState({
    inpuSpinner: false,
    outputSpinner: false,
    swapSpinner: false,
  });

  const handleInputOneChangeOne = async (value) => {
    setInputAmountOne(value);
    setInputWay(0);
    if (value) {
      if (Number(value) > 0) {
        if (selectedRouter && selectedTokenOne && selectedTokenTwo && selectedRouter && provider) {
          setSpinner((prev) => ({
            ...prev,
            outputSpinner: true,
            swapSpinner: true,
          }));
          const getAmoutnOut = await calculateAmountOut(
            value,
            selectedTokenOne,
            selectedTokenTwo,
            selectedRouter,
            provider,
            settingData?.slippage
          );
          if (getAmoutnOut) {
            if (Number(getAmoutnOut?.amountOut) <= 0 || selectedRouter?.id !== 101) {
              fetchRouterWithFilledValue(0, value);
            } else {
              setRouterWithFilledValue([]);
            }
            setInputAmountTwo(formatAmount(getAmoutnOut?.amountOut ? getAmoutnOut.amountOut.toString() : "0"));
            setSpinner((prev) => ({
              ...prev,
              outputSpinner: false,
            }));
            // console.log(
            //   "------------------- OUT :",
            //   getAmoutnOut?.routerVersion,
            //   " : ",
            //   getAmoutnOut?.fee,
            //   "--- ",
            //   getAmoutnOut?.amountOut,
            //   " -------------------"
            // );
            const info = await getTradeInfo(
              getAmoutnOut,
              selectedTokenOne,
              selectedTokenTwo,
              inputAmountOne,
              selectedRouter,
              settingData?.slippage,
              provider
            );
            setTradeInfo((prev) => ({
              ...prev,
              fee: getAmoutnOut?.fee,
              pool: getAmoutnOut?.poolAddress,
              router: getAmoutnOut?.router,
              version: getAmoutnOut?.routerVersion,
              feeAmount: info?.feeAmount,
              minAmountOut: info?.minAmountOut,
              priceImpact: info?.priceImpact,
            }));
            setSpinner((prev) => ({
              ...prev,
              outputSpinner: false,
              swapSpinner: false,
            }));
          } else {
            setInputAmountTwo("");
            setSpinner((prev) => ({
              ...prev,
              outputSpinner: false,
              swapSpinner: false,
            }));
            fetchRouterWithFilledValue(0, value);
          }
        } else {
          setInputAmountTwo("");
          setSpinner((prev) => ({
            ...prev,
            outputSpinner: false,
            swapSpinner: false,
          }));
        }
      } else {
        setRouterWithFilledValue([]);
      }
    } else {
      setInputAmountTwo("");
      setSpinner((prev) => ({
        ...prev,
        outputSpinner: false,
        swapSpinner: false,
      }));
      setRouterWithFilledValue([]);
    }
  };

  useEffect(() => {
    if (inputWay === 0) {
      setInputAmountTwo("");
      setTradeInfo(null);
      handleInputOneChangeOne(inputAmountOne);
    } else {
      setInputAmountOne("");
      setTradeInfo(null);
      handleInputOneChangeTwo(inputAmountTwo);
    }
  }, [selectedTokenOne, selectedTokenTwo, selectedRouter, provider, selectedChain, address]);

  // use

  const maxInputOne = (value) => {
    handleInputOneChangeOne(value);
  };

  const handleInputOneChangeTwo = async (value) => {
    setInputAmountTwo(value);
    setInputWay(1);
    if (value) {
      if (Number(value) > 0) {
        if (selectedRouter && selectedTokenOne && selectedTokenTwo && selectedRouter && provider) {
          setSpinner((prev) => ({
            ...prev,
            inpuSpinner: true,
            swapSpinner: true,
          }));
          const getAmoutnIn = await calculateAmountIn(
            value,
            selectedTokenOne,
            selectedTokenTwo,
            selectedRouter,
            provider,
            settingData?.slippage
          );
          if (getAmoutnIn) {
            if (Number(getAmoutnIn?.amountOut) <= 0 || selectedRouter?.id !== 101) {
              fetchRouterWithFilledValue(1, value);
            } else {
              setRouterWithFilledValue([]);
            }
            setInputAmountOne(formatAmount(getAmoutnIn?.amountOut ? getAmoutnIn?.amountOut?.toString() : "0"));
            setSpinner((prev) => ({
              ...prev,
              outputSpinner: false,
            }));
            // console.log("------------------- IN :", getAmoutnIn?.routerVersion, " : ", getAmoutnIn?.fee, " -------------------");
            const info = await getTradeInfo(
              getAmoutnIn,
              selectedTokenOne,
              selectedTokenTwo,
              inputAmountOne,
              selectedRouter,
              settingData?.slippage,
              provider
            );
            setTradeInfo((prev) => ({
              ...prev,
              fee: getAmoutnIn?.fee,
              pool: getAmoutnIn?.poolAddress,
              router: getAmoutnIn?.router,
              version: getAmoutnIn?.routerVersion,
              feeAmount: info?.feeAmount,
              minAmountOut: info?.minAmountOut,
              priceImpact: info?.priceImpact,
            }));
            setSpinner((prev) => ({
              ...prev,
              outputSpinner: false,
              swapSpinner: false,
            }));
            setSpinner((prev) => ({
              ...prev,
              inpuSpinner: false,
              swapSpinner: false,
            }));
          } else {
            setInputAmountOne("");
            setSpinner((prev) => ({
              ...prev,
              inpuSpinner: false,
              swapSpinner: false,
            }));
            fetchRouterWithFilledValue(1, value);
          }
        } else {
          setInputAmountOne("");
          setSpinner((prev) => ({
            ...prev,
            inpuSpinner: false,
            swapSpinner: false,
          }));
        }
      } else {
        setRouterWithFilledValue([]);
      }
    } else {
      setInputAmountOne("");
      setSpinner((prev) => ({
        ...prev,
        inpuSpinner: false,
        swapSpinner: false,
      }));
      setRouterWithFilledValue([]);
    }
  };

  const swapTokenWithValue = async () => {
    if (inputWay === 0) {
      await handleInputOneChangeTwo(inputAmountOne);
      handleTokenSelectSearchParams(null, "swap");
      setInputWay(1);
    } else {
      await handleInputOneChangeOne(inputAmountTwo);
      handleTokenSelectSearchParams(null, "swap");
      setInputWay(0);
    }
  };

  const swap = async () => {
    try {
      const signer = await provider.getSigner();
      if (selectedTokenOne && selectedTokenTwo && inputAmountOne && inputAmountTwo && tradeInfo) {
        setSpinner((prev) => ({
          ...prev,
          swapSpinner: true,
          confirmSwapSpinner: true,
        }));
        const getAmoutnOut = await calculateAmountOut(
          inputAmountOne,
          selectedTokenOne,
          selectedTokenTwo,
          selectedRouter,
          provider,
          settingData?.slippage
        );
        const info = await getTradeInfo(
          getAmoutnOut,
          selectedTokenOne,
          selectedTokenTwo,
          inputAmountOne,
          selectedRouter,
          settingData?.slippage,
          provider
        );
        setTradeInfo((prev) => ({
          ...prev,
          fee: getAmoutnOut?.fee,
          pool: getAmoutnOut?.poolAddress,
          router: getAmoutnOut?.router,
          version: getAmoutnOut?.routerVersion,
          feeAmount: info?.feeAmount,
          minAmountOut: info?.minAmountOut,
          priceImpact: info?.priceImpact,
        }));
        setInputAmountTwo(formatAmount(getAmoutnOut?.amountOut ? getAmoutnOut?.amountOut.toString() : "0"));
        setSwapTxStatus((prev) => ({ ...prev, status: 4, hash: "" }));
        setConfirmSwapModal(false);
        setSpinner((prev) => ({
          ...prev,
          swapSpinner: true,
          confirmSwapSpinner: false,
        }));
        if (getAmoutnOut?.routerVersion === "v2") {
          if (
            selectedTokenOne?.address !== ethers.constants.AddressZero &&
            Number(selectedTokenOne?.allowanceV2) < Number(getAmoutnOut.amountOut)
          ) {
            setSwapTxStatus((prev) => ({ ...prev, status: 1 }));
            const result = await giveAllowance(selectedTokenOne?.address, selectedRouter?.routerV2, null, signer);
            if (result?.hash) {
              setSwapTxStatus((prev) => ({ ...prev, status: 2 }));
            } else {
              setSwapTxStatus((prev) => ({ ...prev, status: 4 }));
              setSpinner((prev) => ({
                ...prev,
                swapSpinner: false,
              }));
              return;
            }
          } else {
            setSwapTxStatus((prev) => ({ ...prev, status: 2 }));
          }
          const result = await swapInV2(
            selectedTokenOne,
            selectedTokenTwo,
            address,
            inputAmountOne,
            getAmoutnOut.amountOut,
            selectedRouter,
            settingData?.slippage,
            settingData?.txDeadline,
            tradeInfo,
            provider
          );
          if (result?.hash) {
            setSwapTxStatus((prev) => ({
              ...prev,
              status: 3,
              hash: result?.hash,
            }));
            setSpinner((prev) => ({
              ...prev,
              swapSpinner: false,
            }));
            setInputAmountOne("");
            setInputAmountTwo("");
          } else {
            setSwapTxStatus((prev) => ({ ...prev, status: 4 }));
            setSpinner((prev) => ({
              ...prev,
              swapSpinner: false,
            }));
          }
        } else {
          if (
            selectedTokenOne?.address !== ethers.constants.AddressZero &&
            Number(selectedTokenOne?.allowanceV3) < Number(getAmoutnOut.amountOut)
          ) {
            setSwapTxStatus((prev) => ({ ...prev, status: 1 }));
            const result = await giveAllowance(selectedTokenOne?.address, selectedRouter?.routerV3, null, signer);
            if (result?.hash) {
              setSwapTxStatus((prev) => ({ ...prev, status: 2 }));
            } else {
              setSwapTxStatus((prev) => ({ ...prev, status: 4 }));
              setSpinner((prev) => ({
                ...prev,
                swapSpinner: false,
              }));
              return;
            }
          } else {
            setSwapTxStatus((prev) => ({ ...prev, status: 2 }));
          }
          const result = await swapInV3(
            selectedTokenOne,
            selectedTokenTwo,
            address,
            inputAmountOne,
            getAmoutnOut.amountOut,
            selectedRouter,
            settingData?.slippage,
            settingData?.txDeadline,
            tradeInfo,
            provider
          );
          if (result?.hash) {
            setSwapTxStatus((prev) => ({
              ...prev,
              status: 3,
              hash: result?.hash,
            }));
            setSpinner((prev) => ({
              ...prev,
              swapSpinner: false,
            }));
            setInputAmountOne("");
            setInputAmountTwo("");
          } else {
            setSwapTxStatus((prev) => ({ ...prev, status: 4 }));
            setSpinner((prev) => ({
              ...prev,
              swapSpinner: false,
            }));
          }
        }
      }
    } catch (err) {
      // console.log("error : ", err);
      setSwapTxStatus((prev) => ({ ...prev, status: 0 }));
      setSpinner((prev) => ({
        ...prev,
        swapSpinner: false,
      }));
    }
  };

  useEffect(() => {
    let handler = (e) => {
      if (!dropdownRef?.current?.contains(e.target)) {
        setSwapTxStatus((prev) => ({ ...prev, status: 0, hash: "" }));
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  // useEffect(() => {
  // setInterval(() => {
  //   if (selectedTokenOne && selectedTokenTwo && selectedRouter && provider && selectedChain && (inputAmountOne || inputAmountTwo)) {
  //     console.log("i'm here");
  //     if (inputWay === 0) {
  //       setInputAmountTwo("");
  //       setTradeInfo(null);
  //       handleInputOneChangeOne(inputAmountOne);
  //     } else {
  //       setInputAmountOne("");
  //       setTradeInfo(null);
  //       handleInputOneChangeTwo(inputAmountTwo);
  //     }
  //   }
  // }, 10000);
  // }, []);

  return (
    <div className="col-12 mt-3 rounded-20 pb-3 bg-white-new">
      <div className="d-flex justify-content-between my-2 px-2">
        {/* <button
          className="text-gray-900 rajdhani-700 mt-1 font-md mx-2 mb-0 pointer bg-none b-none"
          onClick={() => setSwapTab(0)}
        >
          <span className={`${!swapTab ? "dark-text" : "text-gray"}`}>
            Swap
          </span>
        </button> */}
        {/* <button
          className="text-gray-900 rajdhani-700 mt-1 font-md mx-2 mb-0 pointer bg-none b-none "
          onClick={() => setSwapTab(1)}
        >
          <span className={`${!swapTab ? "text-gray" : "dark-text"}`}>
            Limit
          </span>
        </button> */}
        <div className="d-flex gap-1 flex-row justify-content-center ms-auto pe-md-3 pe-0 align-items-center">
          <button className="btn p-0 m-0" onClick={() => handleInputOneChangeOne(inputAmountOne)}>
            <RefreshIcon />
          </button>
          <a
            href="#"
            id="addToken"
            className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text p-2 btn-hover"
            onClick={() => setSwapBoxModalChange("addToken")}
          >
            <img src="assets/newimg/add.svg" alt="user" className="w-20 h-20 light-icon-done" />
            <img src="assets/newimg/add-dark.svg" alt="user" className="w-20 h-20 dark-icon-done" />
          </a>
          <a
            href="#"
            className="d-flex flex-row gap-2 font-sm rajdhani-600 text-gray-900 dark-text p-2 btn-hover"
            onClick={() => setSwapBoxModalChange("setting")}
          >
            <img src="assets/newimg/settings.svg" alt="user" className="w-20 h-20 light-icon-done" />
            <img src="assets/newimg/settings-dark.svg" alt="user" className="w-20 h-20 dark-icon-done" />
          </a>
        </div>
      </div>
      <>
        <SwapInputBox
          type={0}
          setSwapBoxModalChange={setSwapBoxModalChange}
          selectedToken={selectedTokenOne}
          inputAmount={inputAmountOne}
          setInputAmount={handleInputOneChangeOne}
          maxInput={maxInputOne}
          spinner={spinner?.inpuSpinner}
        />
        <div className="d-flex justify-content-center align-items-center ">
          <button
            type="button"
            className="btn bg-white-100 border-light-1 rounded-pill btn-new-color pointer p-0 my-1"
            onClick={() => swapTokenWithValue()}
          >
            <i class="bi bi-arrow-down-short font-xl dark-text i-img-roted m-0"></i>
          </button>
        </div>
        <SwapInputBox
          type={1}
          setSwapBoxModalChange={setSwapBoxModalChange}
          selectedToken={selectedTokenTwo}
          inputAmount={inputAmountTwo}
          setInputAmount={handleInputOneChangeTwo}
          spinner={spinner?.outputSpinner}
        />
      </>
      {selectedTokenOne && selectedTokenTwo && tradeInfo && inputAmountOne && inputAmountTwo ? (
        <SwapInfo
          showMore={showMore}
          setShowMore={setShowMore}
          selectedTokenOne={selectedTokenOne}
          selectedTokenTwo={selectedTokenTwo}
          inputAmountOne={inputAmountOne}
          inputAmountTwo={inputAmountTwo}
          tradeInfo={tradeInfo}
        />
      ) : (
        ""
      )}
      <SwapConnectButton
        selectedTokenOne={selectedTokenOne}
        selectedTokenTwo={selectedTokenTwo}
        inputAmountOne={inputAmountOne}
        inputAmountTwo={inputAmountTwo}
        tradeInfo={tradeInfo}
        swap={swap}
        spinner={spinner}
        setTokenInfo={setTokenInfo}
        confirmSwapModal={confirmSwapModal}
        setConfirmSwapModal={setConfirmSwapModal}
      />
      <SwapTokenModel
        inputAmountOne={inputAmountOne}
        inputAmountTwo={inputAmountTwo}
        selectedTokenOne={selectedTokenOne}
        selectedTokenTwo={selectedTokenTwo}
        swapTxStatus={swapTxStatus}
        setSwapTxStatus={setSwapTxStatus}
      />
    </div>
  );
};

export default SwapBox;
