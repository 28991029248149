import React, { useContext } from "react";
import { walletConnect } from "../../../../contexts/WalletConnect";
import { constants } from "ethers";

const V3RemoveLiquidityButton = ({ spinner, token0, token1, depositAmount0, depositAmount1, setSpinner }) => {
  const { isConnected, ConnectWallet } = useContext(walletConnect);
  return (
    <>
      {!isConnected ? (
        <button className="btn header-btn  rajdhani-500 rounded-10 px-4 font-md text-white btn-bg-blue w-100 mt-2" onClick={ConnectWallet}>
          Connect wallet
        </button>
      ) : (!depositAmount0 && !depositAmount1) || (Number(depositAmount0) <= 0 && Number(depositAmount1) <= 0) ? (
        <button className="btn header-btn rajdhani-700 rounded-10 px-4 font-sm dark-text bg-gray-300 btn-new-color w-100 mt-2" disabled>
          <span className="">Enter an amount</span>
        </button>
      ) : (token0?.address !== constants?.AddressZero && Number(token0?.allowance) < Number(depositAmount0?.originalValue)) ||
        (token1?.address !== constants?.AddressZero && Number(token1?.allowance) < Number(depositAmount1?.originalValue)) ? (
        <button
          className="btn header-btn rajdhani-600 rounded-10 px-4 font-sm dark-text bg-gray-300 text-gray-900 btn-new-color w-100 mt-2"
          disabled
        >
          <span className="">Remove</span>
        </button>
      ) : (
        <button
          className="btn header-btn rajdhani-500 rounded-10 px-4 font-md text-white btn-bg-blue w-100 mt-2"
          onClick={() => setSpinner((prev) => ({ ...prev, addLiquiInfoModal: true }))}
        >
          Remove
        </button>
      )}
    </>
  );
};

export default V3RemoveLiquidityButton;
