import React, { useState, useEffect, useContext } from "react";
import { walletConnect } from "../../contexts/WalletConnect";
import { globalStates } from "../../contexts/GlobalStates";
import { DEFAULT_CHAIN } from "../../constants/chains";
export default function SwapTab() {
  const {
    chains,
    isConnected,
    setSelectedChain,
    chainId,
    setProvider,
    setSigner,
    getAndSetProvder,
    urlChain,
    setUrlChain,
  } = useContext(walletConnect);
  const {
    handleTokenSelectSearchParams,
    showComingSoon,
    setShowComingSoon,
    toggleShowComingSoon,
  } = useContext(globalStates);

  const switchChain = (activeTab) => {
    if (activeTab === 0) {
      if (isConnected && chainId) {
        const found = chains.find((chain) => chain.chainId === chainId);
        if (found) {
          setSelectedChain(found);
          setUrlChain(found?.urlname);
          handleTokenSelectSearchParams(null, "chainswitch");
          setProvider(null);
          setSigner(null);
          getAndSetProvder();
        }
      } else {
        const found = chains.find((chain) => chain.chainId === DEFAULT_CHAIN);
        if (found) {
          setSelectedChain(found);
          setUrlChain(found?.urlname);
          handleTokenSelectSearchParams(null, "chainswitch");
          setProvider(null);
          setSigner(null);
          getAndSetProvder();
        }
      }
    } else if (activeTab === 1) {
      toggleShowComingSoon();
    } else {
      const found = chains.find((chain) => chain.chainId === 901);
      if (found) {
        setSelectedChain(found);
        setUrlChain(found?.urlname);
        handleTokenSelectSearchParams(null, "chainswitch");
        setProvider(null);
        setSigner(null);
      }
    }
  };

  return (
    <>
      <div className="row justify-content-center align-items-center">
        <div className="col-10 col-sm-4 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center W-100 rounded-10">
          <div className="d-flex align-items-center">
            <button
              className={`btn header-btn rajdhani-600 round-10 px-4 h-35 px-6 ${
                urlChain !== "solana"
                  ? "active-l b-none"
                  : "bg-white-new text-blue"
              }`}
              onClick={() => switchChain(0)}
            >
              <span className="font-xs">EVM</span>
            </button>
          </div>
          <div className="d-flex align-items-center">
            <button
              className={`btn header-btn rajdhani-600 px-4 reverse-round-10 h-35 px-6 ${
                urlChain === "solana"
                  ? "active-l b-none"
                  : "bg-white-new text-blue"
              }`}
              onClick={() => switchChain(1)}
            >
              <span className="font-xs">Solana</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
