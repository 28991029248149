import React, { useContext, useEffect, useState } from "react";
import { globalStates } from "../../contexts/GlobalStates";

function SettingPopUp() {
  const { theme, toggleTheme } = useContext(globalStates);

  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleClickOutsid = (event) => {
    if (!event.target.closest(".dropdown-2")) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutsid);
    return () => {
      window.removeEventListener("click", handleClickOutsid);
    };
  }, []);

  return (
    <>
      <div className="dropdown-2 d-flex align-items-center">
        <button
          onClick={toggleDropdown}
          className="open-modal-btn_setting mt-1 btn-toggle-dark btn btn-icon btn-round"
          style={{ border: "none" }}
        >
          <i className="bi bi-gear icon_position"></i>
        </button>
        <div
          className={`dropdown-content dropdown-content-Setting bg-white-new rounded-15 ${
            showDropdown ? "show" : ""
          }`}
        >
          <div
            className=" bg-white-new p-2"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="d-flex justify-content-between d-sm-block d-md-none d-lg-none  px-3 pt-3">
              <h6 className="text-gray-900 rajdhani-500 font-xss dark-text m-0">
                Theme
              </h6>
              <i
                className="bi bi-x-lg pointer dark-text"
                onClick={toggleDropdown}
              ></i>
            </div>
            <div className="row-cols-2 row mx-n1">
              <div className="col px-1 my-1 w-100">
                <div className="px-2 py-md-2 py-sm-3 py-3 rounded-15 bg-card d-flex flex-column gap-1 hover_setting pointer chain-bg">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      {theme === "dark-mode" ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            className="bi bi-moon-stars-fill dark-icon-done"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
                            <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.73 1.73 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.73 1.73 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.73 1.73 0 0 0 1.097-1.097zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            className="bi bi-sun dark-text light-icon-done"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                        </>
                      )}
                    </div>
                    <div className="me-5 d-flex align-items-center">
                      <h6 className="text-gray-900 rajdhani-400 font-xss dark-text m-0">
                        {theme === "dark-mode" ? "Dark" : "Light"}
                      </h6>
                    </div>
                    <div className="form-check form-switch switch-lg switch-success p-0">
                      <input
                        className="form-check-input mt-1"
                        onChange={() => toggleTheme()}
                        style={{ width: "2.5em", height: "1.5em" }}
                        type="checkbox"
                        checked={theme === "dark-mode"}
                        id="themeToggle"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          showDropdown
            ? "modal-backdrop fade show d-lg-none d-md-none d-sm-none d-block"
            : "d-none"
        }
      ></div>
    </>
  );
}

export default SettingPopUp;
