import React, { useState } from "react";

function ChainTokenDropdown() {
  const [selectedToken, setSelectedToken] = useState("ETH/USDT");
  const [isOpenTokenSwap, setIsOpenTokenSwap] = useState(false);

  const toggleDropdown = () => {
    setIsOpenTokenSwap(!isOpenTokenSwap);
  };

  const handleTokenSelect = (token) => {
    setSelectedToken(token);
    setIsOpenTokenSwap(false);
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-toggle-dark btn-icon btn-round d-flex justify-content-between ml-10 align-items-center"
        onClick={toggleDropdown}
      >
        <p className="rajdhani-700 font-md ls-1 black-text-new mb-0 d-flex align-items-center">
          {selectedToken}
        </p>
        <i
          className={`fa ${
            isOpenTokenSwap ? "fa-chevron-up" : "fa-chevron-down"
          } mx-2`}
        ></i>
      </button>
      {isOpenTokenSwap && (
        <div className=" dropdown-menu bg-white-new p-2 show rounded-10">
          <div className="d-flex justify-content-start align-items-center p-2 hover_question pointer">
            <button
              className="btn dropdown-item pointer font-sm text-gray-600 rajdhani-600 dark-text"
              onClick={() => handleTokenSelect("ETH/USDT")}
            >
              ETH / USDT
            </button>
          </div>
          <div className="d-flex justify-content-start align-items-center p-2 hover_question pointer">
            <button
              className="btn dropdown-item pointer font-sm text-gray-600 rajdhani-600 m-0 dark-text theme_hover"
              onClick={() => handleTokenSelect("USDT/ETH")}
            >
              USDT / ETH
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChainTokenDropdown;
