/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import SwapHeader from "../../Components/Coman/SwapHeader";
import Footer from "../../Components/Coman/Footer";
import SwapTab from "./SwapTab";
import SwapBoxContainer from "./SwapBoxContainer";

export default function Swapbox() {
  const [swapBoxModalChange, setSwapBoxModalChange] = useState("swap");
  const dropdownRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!dropdownRef?.current?.contains(e.target)) {
        setSwapBoxModalChange("swap");
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  return (
    <>
      <div className=" main-vh-11 bg-dark-black dashboard-bg">
        <div
          className="main-wrapper "
          // style={{ minHeight: "100%" }}
        >
          <SwapHeader />
          <div className="container-wide">
            <div className="banner-wrapper py-4 mt-lg-100 mt-0 ">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-0">
                  <SwapTab />
                  <SwapBoxContainer setSwapBoxModalChange={setSwapBoxModalChange} swapBoxModalChange={swapBoxModalChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
