// import { Contract, ethers } from "ethers";

export const formatAddress = (text, size = 5) => {
  if (text) {
    if (text?.length > size) {
      // return text.substr(0, size) + "...";
      return text.substr(0, size) + "..." + text?.substr(size - size * 2, size);
    } else {
      return text;
    }
  } else {
    return "";
  }
};

export const formatName = (text, size = 8) => {
  if (text) {
    if (text?.length > size) {
      return text.substr(0, size) + "...";
    } else {
      return text;
    }
  } else {
    return "";
  }
};
// export const formatNumber = (text, size = 3) => {
//   if (text) {
//     if (text?.length > size) {
//       return text.substr(0, size) + "..." + text.substr(size - size * 2, size);
//     } else {
//       return text;
//     }
//   } else {
//     return "";
//   }
// };

export const formatNumber = (text, size = 6) => {
  if (text) {
    if (text.length > size) {
      return text.substr(0, size - 3) + "..." + text?.substr(size - size * 2, size);
    } else {
      return text;
    }
  } else {
    return "";
  }
};

export function getImportedTokens() {
  try {
    const tokensData = localStorage.getItem("${value:importedTokens[v4s254]}");
    if (tokensData) return JSON.parse(tokensData);
    else return [];
  } catch {
    return [];
  }
}

function removeTrailingZeros(num) {
  let numStr = num.toString();
  if (numStr.indexOf(".") !== -1) {
    numStr = numStr.replace(/0+$/, ""); // Remove trailing zeros
    numStr = numStr.replace(/\.$/, ""); // Remove trailing dot if there is any
  }
  return numStr;
}

export function formatAmount(number) {
  let ethnum = parseFloat(number);
  let output = 0;
  if (ethnum <= 0) {
    return 0;
  } else if (ethnum >= 0.000000000000001 && ethnum < 0.00000000000001) {
    output = parseFloat(ethnum).toFixed(20);
  } else if (ethnum >= 0.00000000000001 && ethnum < 0.0000000000001) {
    output = parseFloat(ethnum).toFixed(19);
  } else if (ethnum >= 0.0000000000001 && ethnum < 0.000000000001) {
    output = parseFloat(ethnum).toFixed(18);
  } else if (ethnum >= 0.000000000001 && ethnum < 0.00000000001) {
    output = parseFloat(ethnum).toFixed(17);
  } else if (ethnum >= 0.00000000001 && ethnum < 0.0000000001) {
    output = parseFloat(ethnum).toFixed(16);
  } else if (ethnum >= 0.0000000001 && ethnum < 0.000000001) {
    output = parseFloat(ethnum).toFixed(15);
  } else if (ethnum >= 0.000000001 && ethnum < 0.00000001) {
    output = parseFloat(ethnum).toFixed(14);
  } else if (ethnum >= 0.00000001 && ethnum < 0.0000001) {
    output = parseFloat(ethnum).toFixed(13);
  } else if (ethnum >= 0.0000001 && ethnum < 0.000001) {
    output = parseFloat(ethnum).toFixed(12);
  } else if (ethnum >= 0.000001 && ethnum < 0.00001) {
    output = parseFloat(ethnum).toFixed(11);
  } else if (ethnum >= 0.00001 && ethnum < 0.0001) {
    output = parseFloat(ethnum).toFixed(10);
  } else if (ethnum >= 0.0001 && ethnum < 0.001) {
    output = parseFloat(ethnum).toFixed(9);
  } else if (ethnum >= 0.001 && ethnum < 0.01) {
    output = parseFloat(ethnum).toFixed(8);
  } else if (ethnum >= 0.01 && ethnum < 0.1) {
    output = parseFloat(ethnum).toFixed(7);
  } else if (ethnum >= 0.1 && ethnum < 1) {
    output = parseFloat(ethnum).toFixed(6);
  } else if (ethnum >= 1 && ethnum < 10) {
    output = parseFloat(ethnum).toFixed(5);
  } else if (ethnum >= 10 && ethnum < 100) {
    output = parseFloat(ethnum).toFixed(4);
  } else if (ethnum >= 100 && ethnum < 1000) {
    output = parseFloat(ethnum).toFixed(3);
  } else if (ethnum >= 1000 && ethnum < 10000) {
    output = parseFloat(ethnum).toFixed(2);
  } else if (ethnum >= 10000 && ethnum < 100000) {
    output = parseFloat(ethnum).toFixed(1);
  } else {
    output = parseFloat(ethnum).toFixed(0);
  }
  return removeTrailingZeros(output);
}
