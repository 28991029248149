/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { formatAmount } from "../../../../Components/Function/commonFunction";

function V3LiquidityDepositAmounts({ type, selectedToken, depositAmount, setDepositAmount, disableInputs }) {
  return (
    <>
      <div>
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-9 col-md-8 col-6">
            <div className="text-">
              <input
                type="text"
                className=" font-xl w-100 dark-text form-control-xl-placeholder"
                placeholder={0}
                style={{ background: "none", border: "none" }}
                value={depositAmount}
                disabled={disableInputs}
                onChange={(e) => {
                  if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,18})?$/)) {
                    setDepositAmount(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          {selectedToken ? (
            <>
              <div className="col-sm-3 col-5 d-flex justify-content-end">
                <div className="bg-white-1 rounded-10 pe-1 ps-1">
                  <a className="rounded-10 btn btn-md header-btn border-0 dark-text rajdhani-500 rounded-10 p-1 pe-2 font-sm d-flex align-items-center  ">
                    <img
                      src={selectedToken?.icon ? selectedToken?.icon : "./assets/images/token-icons/unknown.svg"}
                      alt="user"
                      className="h-26 me-2 rounded-circle"
                    />
                    {selectedToken?.symbol}
                  </a>
                </div>
              </div>
              <div className="d-flex gap-3 flex-row justify-content-between ms-auto px-3">
                <p className=" rajdhani-500 mb-0 dark-text">$0</p>
                <h6 className=" rajdhani-500 mt-1 mb-0 font-xs dark-text">
                  Balance :<span className="me-1">{formatAmount(selectedToken?.balance)}</span>
                </h6>
              </div>
            </>
          ) : (
            <div className="col-sm-6 col-md-4 col-6 d-flex justify-content-end">
              <button className="rounded-10 btn btn-md header-btn border-0 rajdhani-600 rounded-10 px-3 py-2 font-xss btn-bg-blue text-white">
                Select token
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default V3LiquidityDepositAmounts;
