import React from "react";

function ImportV2PoolWarning() {
  return (
    <>
      <div className="card-body  rounded-10 p-2 mt-20 border-box2 dark-border bg-Liquidity">
        <p
          className="text-blue rajdhani-400 fw-normal font-sm m-0 "
          style={{ lineHeight: "18.75px" }}
        >
          <span> Tip:</span> Use this tool to find v2 pools that don’t
          automatically appear in the interface.
        </p>
      </div>
    </>
  );
}

export default ImportV2PoolWarning;
