import "./App.css";
import { Routes, Route } from "react-router-dom";
// import Index from "./Components/Home/Index";
import Swap from "./Pages/Swap/Swap";
import AdvancedMode from "./Pages/AdvancedMod/AdvnacedMode";
import V3Pool from "./Pages/Pool/V3/V3Pool";
// import Migrate from "./Pages/Pool/V3/Migrate/Migrate";
import V2Pool from "./Pages/Pool/V2/V2Pool";
import V3LiquidityInfo from "./Pages/Pool/V3/V3LiquidityInfo/V3LiquidityInfo";
import AddLiquidityV2 from "./Pages/Pool/V2/V2LiquidityBox/AddLiquidityV2";
import ImportV2Pool from "./Pages/Pool/V2/ImportV2PoolBox/ImportV2Pool";
import AddLiquidityV3 from "./Pages/Pool/V3/V3LiquidityBox/AddLiquidityV3";
import V2LiquidityInfo from "./Pages/Pool/V2/V2LiquidityInfo/V2LiquidityInfo";
import RemoveLiquidityV3 from "./Pages/Pool/V3/Remove-Liquidity/RemoveLiquidityV3";
import IncreaseLiquidityV3 from "./Pages/Pool/V3/Increase-Liquidity/IncreaseLiquidityV3";
import RemoveLiquidityV2 from "./Pages/Pool/V2/RemoveLiquidityV2/RemoveLiquidityV2";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Swap />} />
      <Route path="/advancedmode" element={<AdvancedMode />} />
      <Route exact="/v3Pool" path="/v3Pool" element={<V3Pool />} />
      <Route exact="/v2Pool" path="/v2Pool" element={<V2Pool />} />
      <Route path="/addliquidity-v3" element={<AddLiquidityV3 />} />
      <Route path="/addLiquidity-v2" element={<AddLiquidityV2 />} />
      <Route path="/importv2pool" element={<ImportV2Pool />} />
      <Route path="/v3liquidityinfo" element={<V3LiquidityInfo />} />
      <Route path="/increase-liquidity" element={<IncreaseLiquidityV3 />} />
      <Route path="/remove-liquidity" element={<RemoveLiquidityV3 />} />
      <Route path="/v2liquidityInfo" element={<V2LiquidityInfo />} />
      <Route path="/remove-liquidity-v2" element={<RemoveLiquidityV2 />} />
      {/* <Route path="/migrate" element={<Migrate />} /> */}
    </Routes>
  );
}

export default App;
