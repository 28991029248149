import React, { useContext } from "react";
import { globalStates } from "../../../../contexts/GlobalStates";
import { formatAmount } from "../../../../Components/Function/commonFunction";

function V3LiquiditySetPrice({
  selectedTokenOne,
  selectedTokenTwo,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  selectedFeePair,
  handleMinPriceBlur,
  handleMaxPriceBlur,
}) {
  const { handleTokenSelectSearchParams } = useContext(globalStates);
  const handleMinPrices = (operation) => {
    let incDecValue = minPrice && Number(minPrice) > 0 ? (Number(minPrice) * 1) / 100 : selectedFeePair?.sqrtPrice;
    if (operation === "inc") {
      setMinPrice(formatAmount((Number(minPrice) + incDecValue).toString()));
    } else {
      setMinPrice(formatAmount((Number(minPrice) - incDecValue).toString()));
    }
  };
  const handleMaxPrices = (operation) => {
    let incDecValue = minPrice && Number(minPrice) > 0 ? (Number(minPrice) * 1) / 100 : selectedFeePair?.sqrtPrice;
    if (operation === "inc") {
      setMaxPrice(formatAmount((Number(maxPrice) + incDecValue).toString()));
    } else {
      setMaxPrice(formatAmount((Number(maxPrice) - incDecValue).toString()));
    }
  };
  return (
    <>
      <div className="card-body p-0   py-1 px-0">
        <div className="d-flex justify-content-between mt-2 align-items-center">
          <h6 className="rajdhani-400 dark-text mb-0">Set price </h6>
          {selectedTokenOne && selectedTokenTwo && (
            <div className="d-flex">
              <button className="btn rounded-10 rajdhani-500 p-0 px-2 py-1 my-1 font-xss dark-text border border-light bg-border-light ">
                Full range
              </button>
              <button className=" btn d-flex align-items-center my-1 ms-2 border border-light bg-border-light h-30 rounded-10 pointer m-0 p-0">
                <span
                  className={`p-0 m-0 rajdhani-500 font-xss ms-1 dark-text`}
                  onClick={() => handleTokenSelectSearchParams(null, "swap")}
                >
                  {selectedTokenTwo?.symbol}
                </span>
                <i class="bi bi-arrow-left-right font-xs dark-text px-2 d-flex align-items-center"></i>
                <span
                  className={`p-0 m-0 rajdhani-500 font-xss me-1 dark-text pe-1`}
                  onClick={() => handleTokenSelectSearchParams(null, "swap")}
                >
                  {selectedTokenOne?.symbol}
                </span>
              </button>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card-body border dark-border bg-l p-relative rounded-20 p-3 py-1 mt-2 ">
              <div className="row mt-2 pe-md-3 pe-0">
                <div className="col-10 col-sm-10 col-md-11 col-lg-11">
                  <div>
                    <p className="text-gray-600 rajdhani-500 font-xss   mb-0 ">Low price</p>
                    <input
                      type="number"
                      className=" rajdhani-700 m-0 p-0 dark-text w-100"
                      placeholder={0}
                      value={minPrice}
                      style={{ background: "none", border: "none" }}
                      onChange={(e) => {
                        if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                          setMinPrice(e.target.value);
                        }
                      }}
                      onBlur={(e) => handleMinPriceBlur(e.target.value)}
                    />

                    <p className="text-gray-600 rajdhani-500 font-xss   mb-0">
                      {selectedTokenTwo?.symbol} per {selectedTokenOne?.symbol}
                    </p>
                  </div>
                </div>
                <div className="col-2 col-sm-2 col-md-1 col-lg-1">
                  <div>
                    <p className="mb-3">
                      <span
                        className="text-gray-900 rajdhani-600 rounded-6 p-2 bg-white-1 bg-white-new-hover pointer"
                        onClick={() => handleMinPrices("inc")}
                      >
                        <i class="bi bi-plus dark-text"></i>
                      </span>
                    </p>
                    <p className="mb-0 ">
                      <span
                        className="text-gray-900 rajdhani-600 rounded-6  p-2 bg-white-1 bg-white-new-hover pointer"
                        onClick={() => handleMinPrices("dec")}
                      >
                        <i class="bi bi-dash dark-text"></i>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div />
            </div>
          </div>
          <div className="col-12">
            <div className="card-body border dark-border bg-l  p-relative rounded-20 p-3 py-1 mt-2 ">
              <div className="row mt-2 pe-md-3 pe-0">
                <div className="col-10 col-sm-10 col-md-11 col-lg-11">
                  <div>
                    <p className="text-gray-600 rajdhani-500 font-xss   mb-0 ">High price</p>
                    <input
                      type="number"
                      className=" rajdhani-700 m-0 p-0 dark-text w-100"
                      placeholder={0}
                      value={maxPrice}
                      style={{ background: "none", border: "none" }}
                      onChange={(e) => {
                        if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                          setMaxPrice(e.target.value);
                        }
                      }}
                      onBlur={(e) => handleMaxPriceBlur(e.target.value)}
                    />

                    <p className="text-gray-600 rajdhani-500 font-xss mb-0">
                      {selectedTokenTwo?.symbol} per {selectedTokenOne?.symbol}
                    </p>
                  </div>
                </div>
                <div className="col-2 col-sm-2 col-md-1 col-lg-1">
                  <div>
                    <p className="mb-3">
                      <span
                        className=" text-gray-900 rajdhani-600 rounded-6  p-2 bg-white-1 bg-white-new-hover pointer"
                        onClick={() => handleMaxPrices("inc")}
                      >
                        <i class="bi bi-plus  dark-text"></i>
                      </span>
                    </p>
                    <p className="mb-0 ">
                      <span
                        className=" text-gray-900 rajdhani-600 rounded-6  p-2 bg-white-1 bg-white-new-hover pointer"
                        onClick={() => handleMaxPrices("dec")}
                      >
                        <i class="bi bi-dash dark-text"></i>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default V3LiquiditySetPrice;
