/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import V3InputLiquidityBox from "./V3InputLiquidityBox";
import V3LiquidityOpenPair from "./V3LiquidityOpenPair";
import V3LiquiditySetPrice from "./V3LiquiditySetPrice";
import V3LiquidityDepositAmounts from "./V3LiquidityDepositAmounts";
import V3LiquidityConnectButton from "./V3LiquidityConnectButton";
import V3LiquidityWarning from "./V3LiquidityWarning";
import { walletConnect } from "../../../../contexts/WalletConnect";
import {
  calculatePriceRangeAndTicks,
  getFactory,
  getPositions,
  giveAllowance,
  isPairAvailable,
  priceToTick,
  tickToPrice,
  tickSpacing,
  priceToTickWithDecimals,
} from "../../../../Components/Function/ethersFunctions";
import V3LiquidityPriceInput from "./V3LiquidityPriceInput";
import { formatAmount } from "../../../../Components/Function/commonFunction";
import { Pool, TickMath, nearestUsableTick } from "@uniswap/v3-sdk";
import { Token } from "@uniswap/sdk-core";
import { BigNumber, constants, ethers } from "ethers";
import ApproveModal from "../../../../Components/Coman/ApproveModal";
import { addLiquidityInV3, encodePriceSqrt, priceToSqrtpriceX96, sortedTokens } from "../../../../Components/Function/liquidityFunctions";
import { globalStates } from "../../../../contexts/GlobalStates";

function V3LiquidityBox({ setSwapBoxModalChange, selectedTokenOne, selectedTokenTwo, setTokenInfo }) {
  const { selectedChain, provider, signer, address } = useContext(walletConnect);
  const { settingData } = useContext(globalStates);
  const [feeWisePairs, setFeeWisePairs] = useState(null);
  const [selectedFeePair, setSelectedFeePair] = useState(null);
  const [priceInput, setPriceInput] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [depositAmountIn, setDepositAmountIn] = useState("");
  const [depositAmountOut, setDepositAmountout] = useState("");
  const [tickFromPrice, setTickFromPrice] = useState("");
  const [sqrtX96Price, setSqrtX96Price] = useState("");
  const [disableInputs, setDisableInputs] = useState(false);
  const [spinner, setSpinner] = useState({
    approveTokenOne: false,
    approveTokenTwo: false,
    addLiquiSpinner: false,
    addLiquiTxSubModal: false,
    addLiquiTxHash: "",
  });

  const handleSelectPair = (item) => {
    setSelectedFeePair(item);
    setMinPrice("");
    setMaxPrice("");
    setDepositAmountIn("");
    setDepositAmountout("");
  };

  const findPairs = async () => {
    if (!selectedFeePair) {
      const feeTier = selectedChain?.routers[0]?.feeV3;
      if (feeTier) {
        let findPairs = [];
        const factory = await getFactory(selectedChain?.routers[0], "v3", provider);
        for (let fee of feeTier) {
          const pool = await isPairAvailable(selectedTokenOne, selectedTokenTwo, selectedChain?.routers[0], "v3", fee, factory, provider);
          findPairs.push(pool);
        }
        setFeeWisePairs([...findPairs]);
        setSelectedFeePair(findPairs[2]);
      }
    } else {
      const feeTier = selectedChain?.routers[0]?.feeV3;
      if (feeTier) {
        let findPairs = [];
        const factory = await getFactory(selectedChain?.routers[0], "v3", provider);
        for (let fee of feeTier) {
          const pool = await isPairAvailable(selectedTokenOne, selectedTokenTwo, selectedChain?.routers[0], "v3", fee, factory, provider);
          findPairs.push(pool);
        }
        setFeeWisePairs([...findPairs]);
        setSelectedFeePair(findPairs.find((item) => item.fee === selectedFeePair?.fee));
      }
    }
  };

  useEffect(() => {
    if (selectedTokenOne?.decimals && selectedTokenTwo?.decimals && selectedChain) {
      findPairs();
    }
  }, [selectedTokenOne, selectedTokenTwo, selectedChain]);

  const updateAmount0 = (amount) => {
    setDepositAmountIn(amount);
    if (selectedFeePair && selectedChain) {
      if (!selectedFeePair?.pool) {
        if (amount && Number(amount) > 0) {
          const out = Number(amount) * Number(priceInput);
          return setDepositAmountout(formatAmount(out));
          // const Token1 = new Token(
          //   selectedChain.chainId,
          //   selectedTokenOne?.address === constants?.AddressZero ? selectedFeePair?.weth : selectedTokenOne.address,
          //   Number(selectedTokenOne?.decimals),
          //   selectedTokenOne?.symbol,
          //   selectedTokenOne?.name
          // );

          // const Token2 = new Token(
          //   selectedChain.chainId,
          //   selectedTokenTwo?.address === constants?.AddressZero ? selectedFeePair?.weth : selectedTokenTwo.address,
          //   Number(selectedTokenTwo?.decimals),
          //   selectedTokenTwo?.symbol,
          //   selectedTokenTwo?.name
          // );

          // let [token0, token1] = sortedTokens(Token1, Token2);

          // let tick = priceToTickWithDecimals(1 / priceInput, selectedTokenOne?.decimals, selectedTokenTwo?.decimals);

          // const pool = new Pool(Token1, Token2, Number(selectedFeePair.fee), sqrtX96Price, "0", tick);

          // let updatedPool = {
          //   fee: pool.fee,
          //   liquidity: pool.liquidity,
          //   sqrtRatioX96: pool.sqrtRatioX96,
          //   tickCurrent: pool.tickCurrent,
          //   tickDataProvider: {},
          //   token0: pool.token0,
          //   token1: pool.token1,
          // };
          // if (selectedFeePair.fee === 2500) {
          //   updatedPool.tickSpacing = 50;
          // }
          // updatedPool.tickSpacing = tickSpacing(selectedFeePair.fee);
          // const priceRangeData = calculatePriceRangeAndTicks(
          //   selectedFeePair?.originalPrice,
          //   10,
          //   updatedPool.tickSpacing,
          //   selectedFeePair?.fee,
          //   Token1?.address?.toLowerCase() === token0?.address?.toLowerCase() ? true : false,
          //   minPrice,
          //   maxPrice,
          //   selectedTokenOne?.decimals,
          //   selectedTokenTwo?.decimals
          // );
          // if (priceRangeData === "TICK_BOUND") {
          //   setDisableInputs(true);
          // } else {
          //   setDisableInputs(false);
          //   const position = getPositions(
          //     updatedPool,
          //     selectedTokenOne,
          //     { token0: token0?.address },
          //     priceRangeData,
          //     amount,
          //     selectedTokenOne?.decimals
          //   );
          //   setTickFromPrice({
          //     lowerTick: priceRangeData?.lowerTick,
          //     upperTick: priceRangeData?.upperTick,
          //   });
          //   setDepositAmountout(formatAmount(ethers.utils.formatUnits(position?.toString(), selectedTokenTwo?.decimals)));
          // }
        } else {
          setDepositAmountout("");
        }
      } else {
        if (amount && Number(amount) > 0) {
          const Token1 = new Token(
            selectedChain.chainId,
            selectedTokenOne.address === constants?.AddressZero ? selectedFeePair?.weth : selectedTokenOne.address,
            Number(selectedTokenOne?.decimals),
            selectedTokenOne.symbol,
            selectedTokenOne.name
          );

          const Token2 = new Token(
            selectedChain.chainId,
            selectedTokenTwo.address === constants?.AddressZero ? selectedFeePair?.weth : selectedTokenTwo.address,
            Number(selectedTokenTwo?.decimals),
            selectedTokenTwo.symbol,
            selectedTokenTwo.name
          );

          const pool = new Pool(
            Token1,
            Token2,
            Number(selectedFeePair.fee),
            selectedFeePair.sqrtPriceX96.toString(),
            selectedFeePair.liquidity.toString(),
            Number(selectedFeePair.tick)
          );
          let updatedPool = {
            fee: pool.fee,
            liquidity: pool.liquidity,
            sqrtRatioX96: pool.sqrtRatioX96,
            tickCurrent: pool.tickCurrent,
            tickDataProvider: {},
            token0: pool.token0,
            token1: pool.token1,
          };
          if (selectedFeePair.fee === 2500) {
            updatedPool.tickSpacing = 50;
          }
          updatedPool.tickSpacing = tickSpacing(selectedFeePair.fee);
          const priceRangeData = calculatePriceRangeAndTicks(
            selectedFeePair?.originalPrice,
            10,
            selectedFeePair.tickSpacing.toString(),
            selectedFeePair?.fee,
            selectedFeePair?.token0IsInput,
            minPrice,
            maxPrice,
            selectedTokenOne?.decimals,
            selectedTokenTwo?.decimals
          );
          if (priceRangeData === "TICK_BOUND") {
            setDisableInputs(true);
          } else {
            setDisableInputs(false);
            const position = getPositions(
              updatedPool,
              selectedTokenOne,
              selectedFeePair,
              priceRangeData,
              amount,
              selectedTokenOne?.decimals
            );
            setTickFromPrice({
              lowerTick: priceRangeData?.lowerTick,
              upperTick: priceRangeData?.upperTick,
            });
            setDepositAmountout(formatAmount(ethers.utils.formatUnits(position?.toString(), selectedTokenTwo?.decimals)));
          }
        } else {
          setDepositAmountout("");
        }
      }
    }
  };

  const updateAmount1 = (amount) => {
    setDepositAmountout(amount);
    if (selectedFeePair && selectedChain) {
      if (!selectedFeePair?.pool) {
        if (amount && Number(amount) > 0) {
          const out = Number(amount) / Number(priceInput);
          return setDepositAmountIn(formatAmount(out));
        } else {
          setDepositAmountIn("");
        }
      } else {
        if (amount && Number(amount) > 0) {
          const Token1 = new Token(
            selectedChain.chainId,
            selectedTokenOne.address === constants?.AddressZero ? selectedFeePair?.weth : selectedTokenOne.address,
            Number(selectedTokenOne?.decimals),
            selectedTokenOne.symbol,
            selectedTokenOne.name
          );

          const Token2 = new Token(
            selectedChain.chainId,
            selectedTokenTwo.address === constants?.AddressZero ? selectedFeePair?.weth : selectedTokenTwo.address,
            Number(selectedTokenTwo?.decimals),
            selectedTokenTwo.symbol,
            selectedTokenTwo.name
          );

          const pool = new Pool(
            Token1,
            Token2,
            Number(selectedFeePair.fee),
            selectedFeePair.sqrtPriceX96.toString(),
            selectedFeePair.liquidity.toString(),
            Number(selectedFeePair.tick)
          );
          let updatedPool = {
            fee: pool.fee,
            liquidity: pool.liquidity,
            sqrtRatioX96: pool.sqrtRatioX96,
            tickCurrent: pool.tickCurrent,
            tickDataProvider: {},
            token0: pool.token0,
            token1: pool.token1,
          };
          if (selectedFeePair.fee === 2500) {
            updatedPool.tickSpacing = 50;
          }
          updatedPool.tickSpacing = tickSpacing(selectedFeePair.fee);
          const priceRangeData = calculatePriceRangeAndTicks(
            selectedFeePair?.sqrtPrice,
            10,
            selectedFeePair.tickSpacing.toString(),
            selectedFeePair?.fee,
            selectedFeePair?.token0IsInput,
            minPrice,
            maxPrice,
            selectedTokenOne?.decimals,
            selectedTokenTwo?.decimals
          );
          if (priceRangeData === "TICK_BOUND") {
            setDisableInputs(true);
          } else {
            setDisableInputs(false);
            setTickFromPrice({
              lowerTick: priceRangeData?.lowerTick,
              upperTick: priceRangeData?.upperTick,
            });
            const position = getPositions(
              updatedPool,
              selectedTokenTwo,
              selectedFeePair,
              priceRangeData,
              amount,
              selectedTokenTwo?.decimals
            );
            setDepositAmountIn(formatAmount(ethers.utils.formatUnits(position?.toString(), selectedTokenOne?.decimals)));
            // return ethers.utils.formatEther(position?.toString(),selectedTokenOne?.decimals)
          }
        } else {
          setDepositAmountIn("");
        }
      }
    }
  };

  const calculatePriceRange = (lPrice, hPrice) => {
    try {
      let priceRangeData;
      if (!selectedFeePair?.pool) {
        priceRangeData =
          lPrice && hPrice
            ? calculatePriceRangeAndTicks(
                priceInput,
                10,
                selectedFeePair?.tickSpacing?.toString(),
                selectedFeePair?.fee,
                true,
                lPrice,
                hPrice,
                selectedTokenOne?.decimals,
                selectedTokenTwo?.decimals
              )
            : calculatePriceRangeAndTicks(
                priceInput,
                10,
                selectedFeePair?.tickSpacing?.toString(),
                selectedFeePair?.fee,
                true,
                null,
                null,
                selectedTokenOne?.decimals,
                selectedTokenTwo?.decimals
              );
      }
      if (selectedFeePair?.sqrtPrice) {
        priceRangeData =
          lPrice && hPrice
            ? calculatePriceRangeAndTicks(
                selectedFeePair?.sqrtPrice,
                10,
                selectedFeePair?.tickSpacing?.toString(),
                selectedFeePair?.fee,
                selectedFeePair?.token0IsInput,
                lPrice,
                hPrice,
                selectedTokenOne?.decimals,
                selectedTokenTwo?.decimals
              )
            : calculatePriceRangeAndTicks(
                selectedFeePair?.sqrtPrice,
                10,
                selectedFeePair?.tickSpacing?.toString(),
                selectedFeePair?.fee,
                selectedFeePair?.token0IsInput,
                null,
                null,
                selectedTokenOne?.decimals,
                selectedTokenTwo?.decimals
              );
      }
      if (priceRangeData === "TICK_BOUND") {
        setDisableInputs(true);
        setMinPrice("");
        setMaxPrice("");
      } else {
        setDisableInputs(false);

        setTickFromPrice({
          lowerTick: priceRangeData?.lowerTick,
          upperTick: priceRangeData?.upperTick,
        });
        setMinPrice(formatAmount(priceRangeData?.lowerPriceSwap ? priceRangeData.lowerPriceSwap : priceRangeData?.lowerPrice));
        setMaxPrice(formatAmount(priceRangeData?.upperPriceSwap ? priceRangeData.upperPriceSwap : priceRangeData?.upperPrice));
      }
    } catch (err) {
      if (err?.message === "Invariant failed: TICK_BOUND") {
        console.log("error : ", err?.message);
      }
    }
  };

  useEffect(() => {
    if (selectedFeePair?.pool) {
      calculatePriceRange();
    }
  }, [selectedFeePair]);

  useEffect(() => {
    if (minPrice && maxPrice) {
      setDisableInputs(false);
      updateAmount0(depositAmountIn);
    } else {
      setDisableInputs(true);
    }
  }, [minPrice, maxPrice]);

  const handleMinPriceBlur = (value) => {
    if (value && Number(value) > 0) {
      let tick = tickSpacing(selectedFeePair?.fee);
      const price = tickToPrice(nearestUsableTick(priceToTick(value), Number(tick)));
      setMinPrice(formatAmount(price));
    }
  };

  const handleMaxPriceBlur = (value) => {
    if (value && Number(value) > 0) {
      let tick = tickSpacing(selectedFeePair?.fee);
      const price = tickToPrice(nearestUsableTick(priceToTick(value), Number(tick)));
      setMaxPrice(formatAmount(price));
    }
  };

  useEffect(() => {
    if (sqrtX96Price && depositAmountIn) {
      updateAmount0(depositAmountIn);
    }
  }, [sqrtX96Price]);

  useEffect(() => {
    if (priceInput && Number(priceInput) > 0) {
      const sqrtprice = encodePriceSqrt(priceInput, selectedTokenOne?.decimals, selectedTokenTwo?.decimals);
      setSqrtX96Price(sqrtprice);
      calculatePriceRange();
    } else {
      setDepositAmountIn("");
      setDepositAmountout("");
      setSqrtX96Price("");
    }
  }, [priceInput, selectedFeePair]);

  const approveTokenOne = async () => {
    try {
      setSpinner((prev) => ({ ...prev, approveTokenOne: true }));
      const result = await giveAllowance(selectedTokenOne?.address, selectedChain?.routers[0].NonfungiblePositionManager, null, signer);
      if (result?.hash) {
        setTokenInfo();
        setSpinner((prev) => ({ ...prev, approveTokenOne: false }));
      } else {
        setSpinner((prev) => ({ ...prev, approveTokenOne: false }));
      }
    } catch {
      setSpinner((prev) => ({ ...prev, approveTokenOne: false }));
    }
  };

  const approveTokenTwo = async () => {
    try {
      setSpinner((prev) => ({ ...prev, approveTokenTwo: true }));
      const result = await giveAllowance(selectedTokenTwo?.address, selectedChain?.routers[0].NonfungiblePositionManager, null, signer);
      if (result?.hash) {
        setTokenInfo();
        setSpinner((prev) => ({ ...prev, approveTokenTwo: false }));
      } else {
        setSpinner((prev) => ({ ...prev, approveTokenTwo: false }));
      }
    } catch {
      setSpinner((prev) => ({ ...prev, approveTokenTwo: false }));
    }
  };

  const addLiquidity = async () => {
    try {
      setSpinner((prev) => ({
        ...prev,
        addLiquiSpinner: true,
      }));
      const result = await addLiquidityInV3(
        selectedFeePair,
        selectedTokenOne,
        selectedTokenTwo,
        depositAmountIn,
        depositAmountOut,
        selectedChain?.routers[0],
        address,
        provider,
        settingData?.slippage,
        settingData?.txDeadline,
        tickFromPrice,
        selectedChain,
        priceInput,
        minPrice,
        maxPrice
      );
      if (result?.hash) {
        setDepositAmountIn("");
        setDepositAmountout("");
        setPriceInput("");
        setTokenInfo();
        setSpinner((prev) => ({
          ...prev,
          addLiquiSpinner: false,
          addLiquiTxSubModal: true,
          addLiquiTxHash: result?.hash,
        }));
      } else {
        setSpinner((prev) => ({
          ...prev,
          addLiquiSpinner: false,
          addLiquiTxHash: "",
        }));
      }
    } catch (err) {
      console.log("error in addLiquidity : ", err);
      setSpinner((prev) => ({
        ...prev,
        addLiquiSpinner: false,
        addLiquiTxHash: "",
      }));
    }
  };

  return (
    <>
      <div className="bg-dark-black  border rounded-15 p-3">
        <div className="d-flex mb-0 justify-content-between align-items-center">
          <p className="text-gray-900 rajdhani-700 font-sm pb-0 mb-0">
            <Link to="/v3Pool">
              <i class="fa fa-chevron-left d-flex align-items-center pointer text-gray-800 dark-text"></i>
            </Link>
          </p>
          <h5 className="text-gray-900 font-md rajdhani-500 pb-0 mb-0 ms-sm-0 ms-md-3 sm-lg-4 ms-xl-5 ms-0 dark-text">Add liquidity</h5>
          <p className="text-gray-900 rajdhani-500 pb-0 mb-0 d-flex font-xssss justify-content-between align-items-center">
            <span className="me-2 d-md-block d-sm-none d-none">
              <Link to="/addliquidity-v3">Clear all</Link>
            </span>
            <button
              className="btn rajdhani-500 d-flex rounded-10  font-xss  dark-text py-1 pe-0 "
              onClick={() => setSwapBoxModalChange("setting")}
            >
              <i class="bi bi-gear ms-2 font-md "></i>
            </button>
          </p>
        </div>
        <hr style={{ border: "1px solid #6C86AD" }} />
        <div className="card-body p-0 py-1">
          <h6 className="rajdhani-400 dark-text ">Select pair</h6>
          <div className="row align-items-center">
            <V3InputLiquidityBox type={0} setSwapBoxModalChange={setSwapBoxModalChange} selectedToken={selectedTokenOne} />
            <V3InputLiquidityBox type={1} setSwapBoxModalChange={setSwapBoxModalChange} selectedToken={selectedTokenTwo} />
          </div>
        </div>
        <div className={feeWisePairs?.length && selectedFeePair ? "" : "opacity-20"}>
          <V3LiquidityOpenPair feeWisePairs={feeWisePairs} selectedFeePair={selectedFeePair} handleSelectPair={handleSelectPair} />
          <V3LiquiditySetPrice
            selectedTokenOne={selectedTokenOne}
            selectedTokenTwo={selectedTokenTwo}
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
            selectedFeePair={selectedFeePair}
            handleMinPriceBlur={handleMinPriceBlur}
            handleMaxPriceBlur={handleMaxPriceBlur}
          />
          {/* <div className="col-12">
            <div className="card-bod b-warning rounded-10 p-2 mt-1 nt-center align-items-center d-flex">
              <i
                class="bi bi-exclamation-triangle-fill mx-2"
                style={{ color: "#ff7b00" }}
              ></i>
              <p
                className="dark-text rajdhani-500 font-xs m-0 mx-2"
                style={{ lineHeight: "17px" }}
              >
                Invalid range selected. The min price must be lower than the max
                price.
              </p>
            </div>
          </div>
          <div className="col-12">
            <div className="card-bod b-warning rounded-10 p-2 mt-1 nt-center align-items-center d-flex">
              <i
                class="bi bi-exclamation-triangle-fill mx-2"
                style={{ color: "#ff7b00" }}
              ></i>
              <p
                className="dark-text rajdhani-500 font-xs m-0 mx-2"
                style={{ lineHeight: "17px" }}
              >
                Your position will not earn fees or be used in trades until the
                market price moves into your range.
              </p>
            </div>
          </div> */}
          <V3LiquidityWarning />
          {!selectedFeePair?.pool ? (
            <V3LiquidityPriceInput
              priceInput={priceInput}
              setPriceInput={setPriceInput}
              selectedTokenOne={selectedTokenOne}
              selectedTokenTwo={selectedTokenTwo}
            />
          ) : (
            ""
          )}
          <div className={(!selectedFeePair?.pool && !priceInput) || disableInputs ? "opacity-20" : ""}>
            <h6 className="rajdhani-400 dark-text mt-2">Deposit amounts</h6>
            <div className="card-body border dark-border bg-l p-relative rounded-20 p-2 py-1  mt-2">
              <V3LiquidityDepositAmounts
                type={0}
                selectedToken={selectedTokenOne}
                depositAmount={depositAmountIn}
                setDepositAmount={updateAmount0}
                disableInputs={disableInputs}
              />
            </div>
            <div className="card-body border dark-border bg-l p-relative rounded-20 p-2 py-1  mt-2">
              <V3LiquidityDepositAmounts
                type={1}
                selectedToken={selectedTokenTwo}
                depositAmount={depositAmountOut}
                setDepositAmount={updateAmount1}
                disableInputs={disableInputs}
              />
            </div>
            <div className="d-flex justify-content-between">
              {selectedTokenOne?.address !== constants?.AddressZero && Number(selectedTokenOne?.allowance) < Number(depositAmountIn) ? (
                <button
                  className="btn header-btn rajdhani-500 rounded-10 mb-1 px-4 font-md text-white btn-bg-blue w-100 mt-2 me-2"
                  onClick={approveTokenOne}
                >
                  Approve {selectedTokenOne?.symbol}
                </button>
              ) : (
                ""
              )}
              {selectedTokenTwo?.address !== constants?.AddressZero && Number(selectedTokenTwo?.allowance) < Number(depositAmountOut) ? (
                <button
                  className="btn header-btn rajdhani-500 rounded-10 mb-1 px-4 font-md text-white btn-bg-blue w-100 mt-2 ms-2"
                  onClick={approveTokenTwo}
                >
                  Approve {selectedTokenTwo?.symbol}
                </button>
              ) : (
                ""
              )}
            </div>
            <V3LiquidityConnectButton
              spinner={spinner}
              selectedTokenOne={selectedTokenOne}
              selectedTokenTwo={selectedTokenTwo}
              depositAmountIn={depositAmountIn}
              depositAmountOut={depositAmountOut}
              addLiquidity={addLiquidity}
            />
          </div>
        </div>
      </div>
      <ApproveModal spinner={spinner?.approveTokenOne} selectedToken={selectedTokenOne} />
      <ApproveModal spinner={spinner?.approveTokenTwo} selectedToken={selectedTokenTwo} />
    </>
  );
}

export default V3LiquidityBox;
